import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  Paper,
  Box,
  IconButton,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {updateInspection, submitInspection, getInspectionsByUser, getInspection} from "../../../actions/swagerAction"
import {getLocations, getUsers} from "../../../actions/usersAction";
import Feedback from "../../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },

  btn2:{
    marginBottom: 30,
    marginLeft: 10
  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 400,
      [theme.breakpoints.down("sm")]: {
          minWidth:330
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      }
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 400,
    [theme.breakpoints.down("sm")]: {
       minWidth:330
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },

  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },

}));

const INIT_STATE = {
    unitNumber: "",
    inspector: "",
    supervisor: undefined,
    date: "",
    location: undefined,
    hydraulicFluid: "1",
    bolts: "1",
    hydraulicLeaks: "1",
    hydraulicGuage: "1",
    pin: "1",
    equipment: "1",
    comment:""
};

const EditInspectionModal = ({ openEditInspection, handleCloseEditInspection, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { swager, error, loading } = useSelector((state) => state.swager);
  const { profile, locations, users } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getInspectionsByUser());
    dispatch(getLocations());
    dispatch(getUsers());
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    if (id){
      dispatch(getInspection(id));
    }
    
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      unitNumber: swager && swager.unitNumber,
      inspector: swager && swager.inspector,
      supervisor: swager && swager.supervisor,
      date: swager && swager.date,
      location: swager && swager.location._id,
      hydraulicFluid: swager && swager.hydraulicFluid,
      bolts: swager && swager.bolts,
      hydraulicLeaks: swager && swager.hydraulicLeaks,
      hydraulicGuage: swager && swager.hydraulicGuage,
      pin: swager && swager.pin,
      equipment: swager && swager.equipment,
      comment: swager && swager.comment 
        
    }));
  }, [swager]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    
  };

  const handleSave = (e) => {
    e.preventDefault();
    let data = {
        unitNumber: state.unitNumber,
        inspector: state.inspector,
        supervisor: state.supervisor,
        date: state.date,
        location: state.location,
        hydraulicFluid: state.hydraulicFluid,
        bolts: state.bolts,
        hydraulicLeaks: state.hydraulicLeaks,
        hydraulicGuage: state.hydraulicGuage,
        pin: state.pin,
        equipment: state.equipment,
        comment:state.comment,
        id
    };
    dispatch(updateInspection(data));

    setTimeout(() => {
      handleCloseEditInspection();
      dispatch(getInspectionsByUser());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Inspection Saved Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        unitNumber: state.unitNumber,
        inspector: state.inspector,
        supervisor: state.supervisor,
        date: state.date,
        location: state.location,
        hydraulicFluid: state.hydraulicFluid,
        bolts: state.bolts,
        hydraulicLeaks: state.hydraulicLeaks,
        hydraulicGuage: state.hydraulicGuage,
        pin: state.pin,
        equipment: state.equipment,
        comment:state.comment,
        path: "swager/inspection/view",
        id
    };
    dispatch(submitInspection(data));
    setTimeout(() => {
      handleCloseEditInspection()
        dispatch(getInspectionsByUser());
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Inspection Submitted Successfully.",
          success: true,
        }));
    }, 1500);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.unitNumber || !state.location

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openEditInspection}
        onClose={handleCloseEditInspection}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit Swager Press Inspection
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseEditInspection}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
          <form onSubmit={handleSubmit}>
            
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="unitNumber"
                    fullWidth
                    variant="outlined"
                    label="Unit Number"
                    onChange={handleChange}
                    value={state.unitNumber || ""}
                    required
                />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Operator"
                      onChange={handleChange}
                      value={state.inspector || ""}
                      required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <Autocomplete 
                            id="supervisor"
                            options={users}
                            getOptionSelected={(option, value) => option.displayname === state.supervisor?.displayname}
                            getOptionLabel={(option) => option.displayname || ''} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, supervisor: value }))}
                            value={state.supervisor || ''}
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Supervisor" />} 
                        />
                    </FormControl>
                    
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="date"
                      fullWidth
                      variant="outlined"
                      label="Date"
                      onChange={handleChange}
                      value={state.date?.split("T")[0] || ''}
                      required
                      type="date"   
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                    Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={state.location || ""}
                      name="location"
                      onChange={handleChange}
                      label="Location"
                      required
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {locations &&
                        locations.map((location) => (
                          <MenuItem key={location._id} value={location._id}>
                            {location.displayname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
              <Typography variant="body2" gutterBottom color="primary">
                  1 - Ok;
                  2 - Requires Scheduled Maintenance;
                  3 - Requires Immediate Fix
                </Typography>
                </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Hydraulic Fluid Level</FormLabel>
                  <RadioGroup row name="hydraulicFluid" value={state.hydraulicFluid || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" />
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" />
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Bolts for Proper Torque</FormLabel>
                  <RadioGroup row name="bolts" value={state.bolts || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Hydraulics for Leaks/Damage</FormLabel>
                  <RadioGroup row name="hydraulicLeaks" value={state.hydraulicLeaks || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Hydraulic Guage</FormLabel>
                  <RadioGroup row name="hydraulicGuage" value={state.hydraulicGuage || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Pins for Alignment Issues</FormLabel>
                  <RadioGroup row name="pin" value={state.pin || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Equipment for Adequate Grease</FormLabel>
                  <RadioGroup row name="equipment" value={state.equipment || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
                
                
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Comments/Rapairs Required"
                  onChange={handleChange}
                  multiline
                  value={state.comment || ""}
                  />
              </Grid>
   
            </Grid>

            <br />
          
            <Typography align="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSave}
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save"}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save & Submit"}
                </Button>
              </Typography>
            </form>
          </Box>
          </Paper>


        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditInspectionModal;
