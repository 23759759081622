module.exports = {
    CREATE_FORKLIFT: "CREATE_FORKLIFT",
    CREATE_FORKLIFT_REQUEST: "CREATE_FORKLIFT_REQUEST",
    CREATE_FORKLIFT_FAIL: "CREATE_FORKLIFT_FAIL",

    SUBMIT_FORKLIFT: "SUBMIT_FORKLIFT",
    SUBMIT_FORKLIFT_REQUEST: "SUBMIT_FORKLIFT_REQUEST",
    SUBMIT_FORKLIFT_FAIL: "SUBMIT_FORKLIFT_FAIL",

    UPDATE_FORKLIFT: "UPDATE_FORKLIFT",
    UPDATE_FORKLIFT_REQUEST: "UPDATE_FORKLIFT_REQUEST",
    UPDATE_FORKLIFT_FAIL: "UPDATE_FORKLIFT_FAIL",

    SET_FORKLIFT_DRAFT: "SET_FORKLIFT_DRAFT",
    SET_FORKLIFT_DRAFT_REQUEST: "SET_FORKLIFT_DRAFT_REQUEST",
    SET_FORKLIFT_DRAFT_FAIL: "SET_FORKLIFT_DRAFT_FAIL",

    SET_FORKLIFT_SERVICED: "SET_FORKLIFT_SERVICED",
    SET_FORKLIFT_SERVICED_REQUEST: "SET_FORKLIFT_SERVICED_REQUEST",
    SET_FORKLIFT_SERVICED_FAIL: "SET_FORKLIFT_SERVICED_FAIL",

    GET_FORKLIFTS: "GET_FORKLIFTS",
    GET_FORKLIFTS_REQUEST: "GET_FORKLIFTS_REQUEST",
    GET_FORKLIFTS_FAIL: "GET_FORKLIFTS_FAIL",

    GET_FORKLIFT: "GET_FORKLIFT",
    GET_FORKLIFT_REQUEST: "GET_FORKLIFT_REQUEST",
    GET_FORKLIFT_FAIL: "GET_FORKLIFT_FAIL",

    GET_FORKLIFTS_BY_USER: "GET_FORKLIFTS_BY_USER",
    GET_FORKLIFTS_BY_USER_REQUEST: "GET_FORKLIFTS_BY_USER_REQUEST",
    GET_FORKLIFTS_BY_USER_FAIL: "GET_FORKLIFTS_BY_USER_FAIL",

    GET_FORKLIFTS_BY_LOCATION: "GET_FORKLIFTS_BY_LOCATION",
    GET_FORKLIFTS_BY_LOCATION_REQUEST: "GET_FORKLIFTS_BY_LOCATION_REQUEST",
    GET_FORKLIFTS_BY_LOCATION_FAIL: "GET_FORKLIFTS_BY_LOCATION_FAIL",

    DELETE_FORKLIFT: "DELETE_FORKLIFT",
    DELETE_FORKLIFT_REQUEST: "DELETE_FORKLIFT_REQUEST",
    DELETE_FORKLIFT_FAIL: "DELETE_FORKLIFT_FAIL",
}