import axios from "axios";
import {
    CREATE_FLEET,
    CREATE_FLEET_REQUEST,
    CREATE_FLEET_FAIL,
    GET_FLEET,
    GET_FLEET_REQUEST,
    GET_FLEET_FAIL,
    GET_FLEET_BY_SUPERVISOR,
    GET_FLEET_BY_SUPERVISOR_REQUEST,
    GET_FLEET_BY_SUPERVISOR_FAIL,
    GET_FLEET_BY_MANAGER,
    GET_FLEET_BY_MANAGER_REQUEST,
    GET_FLEET_BY_MANAGER_FAIL,
    GET_FLEET_BY_LOCATION,
    GET_FLEET_BY_LOCATION_REQUEST,
    GET_FLEET_BY_LOCATION_FAIL,
    GET_FLEETS,
    GET_FLEETS_REQUEST,
    GET_FLEETS_FAIL,
    GET_ARCHIVED_FLEETS,
    GET_ARCHIVED_FLEETS_REQUEST,
    GET_ARCHIVED_FLEETS_FAIL,
    UPDATE_FLEET,
    UPDATE_FLEET_REQUEST,
    UPDATE_FLEET_FAIL,
    FLEET_LOST,
    FLEET_LOST_REQUEST,
    FLEET_LOST_FAIL,
    FLEET_OUT_OF_SERVICE,
    FLEET_OUT_OF_SERVICE_REQUEST,
    FLEET_OUT_OF_SERVICE_FAIL,
    FLEET_RESTORE,
    FLEET_RESTORE_REQUEST,
    FLEET_RESTORE_FAIL,
    CREATE_SERVICE_REQUEST,
    CREATE_SERVICE,
    CREATE_SERVICE_FAIL,
    DELETE_SERVICE_REQUEST,
    DELETE_SERVICE,
    DELETE_SERVICE_FAIL,
    DELETE_FLEET,
    DELETE_FLEET_REQUEST,
    DELETE_FLEET_FAIL,
    ARCHIVE_FLEET,
    ARCHIVE_FLEET_REQUEST,
    ARCHIVE_FLEET_FAIL,

    CREATE_DAILYINSPECTION,
    CREATE_DAILYINSPECTION_REQUEST,
    CREATE_DAILYINSPECTION_FAIL,
    SUBMIT_DAILYINSPECTION,
    SUBMIT_DAILYINSPECTION_REQUEST,
    SUBMIT_DAILYINSPECTION_FAIL,
    UPDATE_DAILYINSPECTION,
    UPDATE_DAILYINSPECTION_REQUEST,
    UPDATE_DAILYINSPECTION_FAIL,
    SET_DAILYINSPECTION_DRAFT,
    SET_DAILYINSPECTION_DRAFT_REQUEST,
    SET_DAILYINSPECTION_DRAFT_FAIL,
    CREATE_WEEKLYINSPECTION,
    CREATE_WEEKLYINSPECTION_REQUEST,
    CREATE_WEEKLYINSPECTION_FAIL,
    SUBMIT_WEEKLYINSPECTION,
    SUBMIT_WEEKLYINSPECTION_REQUEST,
    SUBMIT_WEEKLYINSPECTION_FAIL,
    UPDATE_WEEKLYINSPECTION,
    UPDATE_WEEKLYINSPECTION_REQUEST,
    UPDATE_WEEKLYINSPECTION_FAIL,
    SET_WEEKLYINSPECTION_DRAFT,
    SET_WEEKLYINSPECTION_DRAFT_REQUEST,
    SET_WEEKLYINSPECTION_DRAFT_FAIL,
    GET_INSPECTION,
    GET_INSPECTION_REQUEST,
    GET_INSPECTION_FAIL,
    GET_INSPECTIONS,
    GET_INSPECTIONS_REQUEST,
    GET_INSPECTIONS_FAIL,
    GET_INSPECTIONS_BY_USER,
    GET_INSPECTIONS_BY_USER_REQUEST,
    GET_INSPECTIONS_BY_USER_FAIL,
    GET_INSPECTIONS_BY_VEHICLE,
    GET_INSPECTIONS_BY_VEHICLE_REQUEST,
    GET_INSPECTIONS_BY_VEHICLE_FAIL,
    GET_INSPECTIONS_BY_LOCATION,
    GET_INSPECTIONS_BY_LOCATION_REQUEST,
    GET_INSPECTIONS_BY_LOCATION_FAIL,
    DELETE_INSPECTION,
    DELETE_INSPECTION_REQUEST,
    DELETE_INSPECTION_FAIL,

} from "../types/vehicleTypes"

const token = localStorage.getItem("token:data");

export const createFleet = (fleet) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_FLEET_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/vehicle/fleet`, fleet, config);
        dispatch({ 
            type: CREATE_FLEET, 
            payload: data 
        });
    } catch (error) {
        dispatch({
            type: CREATE_FLEET_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getFleet = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_FLEET_REQUEST });
        const { data } = await axios.get(`/api/v1/vehicle/fleet/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_FLEET,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_FLEET_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getFleetBySupervisor = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_FLEET_BY_SUPERVISOR_REQUEST });
        const { data } = await axios.get(`/api/v1/vehicle/fleet/supervisor/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_FLEET_BY_SUPERVISOR,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_FLEET_BY_SUPERVISOR_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getFleetByManager = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_FLEET_BY_MANAGER_REQUEST });
        const { data } = await axios.get(`/api/v1/vehicle/fleet/manager/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_FLEET_BY_MANAGER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_FLEET_BY_MANAGER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getFleetByLocation = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_FLEET_BY_LOCATION_REQUEST });
        const { data } = await axios.get(`/api/v1/vehicle/fleet/location/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_FLEET_BY_LOCATION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_FLEET_BY_LOCATION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getFleets = () => async (dispatch) => {
    try {
        dispatch({ type: GET_FLEETS_REQUEST });
        const { data } = await axios.get(`/api/v1/vehicle/fleet`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_FLEETS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_FLEETS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getArchivedFleets = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ARCHIVED_FLEETS_REQUEST });
        const { data } = await axios.get(`/api/v1/vehicle/archivedfleet`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_ARCHIVED_FLEETS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_ARCHIVED_FLEETS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const updateFleet = (fleet) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_FLEET_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/vehicle/fleet/${fleet.id}`, fleet, config);
        dispatch({ 
            type: UPDATE_FLEET, 
            payload: data 
        });
    } catch (error) {
        dispatch({
            type: UPDATE_FLEET_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const fleetLost = (id) => async (dispatch) => {
    try {
        dispatch({ type: FLEET_LOST_REQUEST });
        const { data } = await axios.put(`/api/v1/vehicle/fleet/lost/${id}`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
      
        dispatch({
            type: FLEET_LOST,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: FLEET_LOST_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const fleetOutOfService = (id) => async (dispatch) => {
    try {
        dispatch({ type: FLEET_OUT_OF_SERVICE_REQUEST });
        const { data } = await axios.put(`/api/v1/vehicle/fleet/outofservice/${id}`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FLEET_OUT_OF_SERVICE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: FLEET_OUT_OF_SERVICE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const fleetRestore = (id) => async (dispatch) => {
    try {
        dispatch({ type: FLEET_RESTORE_REQUEST });
        const { data } = await axios.put(`/api/v1/vehicle/fleet/restore/${id}`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
      
        dispatch({
            type: FLEET_RESTORE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: FLEET_RESTORE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const createService = (service) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_SERVICE_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/vehicle/fleet/service/${service.id}`, service, config);
        dispatch({ 
            type: CREATE_SERVICE, 
            payload: data 
        });
    } catch (error) {
        dispatch({
            type: CREATE_SERVICE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const deleteService = (service) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_SERVICE_REQUEST });
       
        const { data } = await axios.put(`/api/v1/vehicle/fleet/${service.fleetId}/service/${service.serviceId}`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({ 
            type: DELETE_SERVICE, 
            payload: data 
        });
    } catch (error) {
        dispatch({
            type: DELETE_SERVICE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const deleteFleet = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_FLEET_REQUEST });
        const { data } = await axios.delete(`/api/v1/vehicle/fleet/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: DELETE_FLEET,
        });
    } catch (error) {
        dispatch({
            type: DELETE_FLEET_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const archiveFleet = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ARCHIVE_FLEET_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.put(`/api/v1/vehicle/fleet/archive/${id}`, null, config);
        dispatch({
            type: ARCHIVE_FLEET,
        });
    } catch (error) {
        dispatch({
            type: ARCHIVE_FLEET_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const createDailyInspection = (daily) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_DAILYINSPECTION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/vehicle/dailyinspection`, daily, config);
        dispatch({
            type: CREATE_DAILYINSPECTION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_DAILYINSPECTION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const submitDailyInspection = (daily) => async (dispatch) => {
    
    try {
        dispatch({
            type: SUBMIT_DAILYINSPECTION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/vehicle/dailyinspection/submit`, daily, config);
        dispatch({
            type: SUBMIT_DAILYINSPECTION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUBMIT_DAILYINSPECTION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const updateDailyInspection = (daily) => async (dispatch) => {
    
    try {
        dispatch({
            type: UPDATE_DAILYINSPECTION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/vehicle/dailyinspection/${daily.id}`, daily, config);
        dispatch({
            type: UPDATE_DAILYINSPECTION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_DAILYINSPECTION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setDailyInspectionDraft = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_DAILYINSPECTION_DRAFT_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/vehicle/dailyinspection/draft/${id}`, null, config);
        dispatch({
            type: SET_DAILYINSPECTION_DRAFT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_DAILYINSPECTION_DRAFT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}


export const createWeeklyInspection = (weekly) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_WEEKLYINSPECTION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/vehicle/weeklyinspection`, weekly, config);
        dispatch({
            type: CREATE_WEEKLYINSPECTION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_WEEKLYINSPECTION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const updateWeeklyInspection = (weekly) => async (dispatch) => {
    
    try {
        dispatch({
            type: UPDATE_WEEKLYINSPECTION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/vehicle/weeklyinspection/${weekly.id}`, weekly, config);
        dispatch({
            type: UPDATE_WEEKLYINSPECTION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_WEEKLYINSPECTION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setWeeklyInspectionDraft = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_WEEKLYINSPECTION_DRAFT_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/vehicle/weeklyinspection/draft/${id}`, null, config);
        dispatch({
            type: SET_WEEKLYINSPECTION_DRAFT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_WEEKLYINSPECTION_DRAFT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}

export const submitWeeklyInspection = (weekly) => async (dispatch) => {
    
    try {
        dispatch({
            type: SUBMIT_WEEKLYINSPECTION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/vehicle/weeklyinspection/submit`, weekly, config);
        dispatch({
            type: SUBMIT_WEEKLYINSPECTION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUBMIT_WEEKLYINSPECTION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const getInspections = () => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_INSPECTIONS_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/vehicle/inspections`, config);
        dispatch({
            type: GET_INSPECTIONS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_INSPECTIONS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
                });
    }
}

export const getInspection = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_INSPECTION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/vehicle/inspection/${id}`, config);
        dispatch({
            type: GET_INSPECTION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_INSPECTION_FAIL,
            payload:
                error.response && error.response.data?.error
                ? error.response.data?.error
                : error.request
                ? error.request.data?.error
                : error.message,
        });
    }
}

export const getInspectionsByUser = () => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_INSPECTIONS_BY_USER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/vehicle/myinspections/user`, config);
        dispatch({
            type: GET_INSPECTIONS_BY_USER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_INSPECTIONS_BY_USER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getInspectionsByVehicle = (id) => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_INSPECTIONS_BY_VEHICLE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/vehicle/inspections/fleet/${id}`, config);
        dispatch({
            type: GET_INSPECTIONS_BY_VEHICLE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_INSPECTIONS_BY_VEHICLE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getInspectionsByLocation = (id) => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_INSPECTIONS_BY_LOCATION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/vehicle/inspections/location/${id}`, config);
        dispatch({
            type: GET_INSPECTIONS_BY_LOCATION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_INSPECTIONS_BY_LOCATION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const deleteInspection= (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_INSPECTION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/vehicle/inspection/${id}`, config);
        dispatch({
            type: DELETE_INSPECTION,
        });
    } catch (error) {
        dispatch({
            type: DELETE_INSPECTION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

