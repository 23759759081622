import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  Paper,
  Box,
  IconButton,
  FormControl,
  FormLabel,
  FormControlLabel,
  Button,
  ButtonGroup,
  Radio,
  RadioGroup,
  Checkbox,
  Switch
} from "@material-ui/core";
import {ToggleButton} from '@material-ui/lab';

import clsx from "clsx";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import CloseIcon from "@material-ui/icons/Close";
import BuildIcon from '@material-ui/icons/Build';
import AddIcon from "@material-ui/icons/Add"
import { useDispatch, useSelector } from "react-redux";
import {getInspection, setInspectionDraft, getInspections, getInspectionsByUser} from "../../../actions/craneAction";
import ServiceInspectionModal from "./ServiceInspectionModal";
import Feedback from "../../../utils/Feedback";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  btnGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
  },
  
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 380,
      [theme.breakpoints.down("sm")]: {
          width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      },
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 350,
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
    color: "#4caf50",
  },
  paper3:{
    textAlign: "center",
    width: "fit-content",
    height: 50,
    display: "flex",
    justifyContent: "center",
    marginTop: 30
  },

}));

const INIT_STATE = {
    unitNumber: "",
    inspector: "",
    supervisor: undefined,
    date: "",
    location: undefined,
    mainVisualDamage:"1",
    reason:"",
    mainControl: "1",
    mainPushButtons: "1",
    mainSoundsNormal: "1",
    mainMovement: "1",
    mainBreaksPositive: "1",
    mainUpperLimits: "1",
    mainLowerLimits: "1",
    mainBlock:"1",
    mainSheaves:"1",
    mainRopeConnection:"1",
    mainProperDrum: "1",
    mainHooks: "1",
    auxHoist: false,
    auxVisualDamage:"1",
    auxControl: "1",
    auxPushButtons: "1",
    auxSoundsNormal: "1",
    auxMovement: "1",
    auxBreaksPositive: "1",
    auxUpperLimits: "1",
    auxLowerLimits: "1",
    auxBlock:"1",
    auxSheaves:"1",
    auxRopeConnection:"1",
    auxProperDrum: "1",
    auxHooks: "1",
    auxTrolley: "1",
    checkCrane:"1",
    oilLeaks: "1",
    inspectPendant: "1",
    remoteControl: false,
    chainHoist: "1",
    checkChain: "1",
    inspectSlings: "1",
    spreaderBars: "1",
    approvedOperator: false,
    reviewLog: false,
    raiseHook: false,
    switchController: false,
    inspectRigging: false,
    comment:"",
    fuel:"",
    nextService:"",
    service:{
      date:"",
      description:"",
      servicedBy: "",
      cost: ""
    },
    error: null,
    success: false,
    loading: false,
};

const ViewInspectionModal = ({ openViewInspection, handleCloseViewInspection, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, crane } = useSelector((state) => state.crane);

  const [openService, setOpenService] = useState(false);
  const [getId, setGetId] = useState("");

  const { profile, loading } = useSelector((state) => state.users);

    let isAdminGuard = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isCraneAdmin = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("crane_admin"))
        : false;


useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
        setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
        }));
    }
    return () => {
        abortController.abort();
    };
    }, [error]);

  useEffect(() => {
    if (id){
      dispatch(getInspection(id));
    }
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      unitNumber: crane && crane.unitNumber,
      inspector: crane && crane.inspector,
      supervisor: crane && crane.supervisor,
      date: crane && crane.date,
      location: crane && crane.location.displayname,
      mainVisualDamage:crane && crane.mainVisualDamage,
      reason:crane && crane.reason,
      mainControl: crane && crane.mainControl,
      mainPushButtons: crane && crane.mainPushButtons,
      mainSoundsNormal: crane && crane.mainSoundsNormal,
      mainMovement: crane && crane.mainMovement,
      mainBreaksPositive: crane && crane.mainBreaksPositive,
      mainUpperLimits: crane && crane.mainUpperLimits,
      mainLowerLimits: crane && crane.mainLowerLimits,
      mainBlock:crane && crane.mainBlock,
      mainSheaves:crane && crane.mainSheaves,
      mainRopeConnection:crane && crane.mainRopeConnection,
      mainProperDrum: crane && crane.mainProperDrum,
      mainHooks: crane && crane.mainHooks,
      auxHoist: crane && crane.auxHoist,
      auxVisualDamage:crane && crane.auxVisualDamage,
      auxControl: crane && crane.auxControl,
      auxPushButtons: crane && crane.auxPushButtons,
      auxSoundsNormal: crane && crane.auxSoundsNormal,
      auxMovement: crane && crane.auxMovement,
      auxBreaksPositive: crane && crane.auxBreaksPositive,
      auxUpperLimits: crane && crane.auxUpperLimits,
      auxLowerLimits: crane && crane.auxLowerLimits,
      auxBlock:crane && crane.auxBlock,
      auxSheaves:crane && crane.auxSheaves,
      auxRopeConnection:crane && crane.auxRopeConnection,
      auxProperDrum: crane && crane.auxProperDrum,
      auxHooks: crane && crane.auxHooks,
      auxTrolley: crane && crane.auxTrolley,
      checkCrane:crane && crane.checkCrane,
      oilLeaks: crane && crane.oilLeaks,
      inspectPendant: crane && crane.inspectPendant,
      remoteControl: crane && crane.remoteControl,
      chainHoist: crane && crane.chainHoist,
      checkChain: crane && crane.checkChain,
      inspectSlings: crane && crane.inspectSlings,
      spreaderBars: crane && crane.spreaderBars,
      approvedOperator: crane && crane.approvedOperator,
      reviewLog: crane && crane.reviewLog,
      raiseHook: crane && crane.raiseHook,
      switchController: crane && crane.switchController,
      inspectRigging: crane && crane.inspectRigging,
      comment:crane && crane.comment,
      fuel:crane && crane.fuel,
      nextService:crane && crane.nextService,
      service: crane && crane.service

    }));
  }, [crane]);

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleSetDraft = (e) => {
    e.preventDefault();
    dispatch(setInspectionDraft(id));
    setTimeout(() => {
      handleCloseViewInspection();
        dispatch(getInspections());
        dispatch(getInspectionsByUser());

        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Inspection set to draft successfully!",
          success: true,
        }));
    }, 1500);
  };

  const handleOpenService = (id) => {
    setGetId(id);
    setOpenService(true);
  };

  const handleCloseService = () => {
    setOpenService(false);
  };


  const printRef = React.useRef()
  const handleDownloadPdf = async () => {
    const element = printRef.current
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4', true);
    
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight()
    const imgWidth = canvas.width
    const imgHeight = canvas.height
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
    const imgX = (pdfWidth - imgWidth * ratio) / 2
    const imgY = (pdfHeight - imgHeight * ratio) / 2

    pdf.addImage(data, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    pdf.save(`Crane_${state.unitNumber}_${state.date?.split("T")[0]}.pdf`);
    };  

  return (
    <div>
      <ServiceInspectionModal {...{ openService, handleCloseService, id: getId }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openViewInspection}
        onClose={handleCloseViewInspection}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                View Crane Inspection
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseViewInspection}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        {(crane && (crane.status === "Submitted" || crane.status === "Serviced")) && (isAdminGuard || isCraneAdmin) && (
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={8} sm={8}>
                <ButtonGroup className={classes.btnGroup} variant="outlined">
                    <Button
                        size="small"
                        className={classes.btnLink}
                        color="primary"
                        onClick={handleSetDraft}
                    >
                        Set to Draft
                    </Button>
                    <Button
                        size="small"
                        className={clsx(classes.btnLink, classes.statusDarkGreen)}
                        onClick={handleDownloadPdf}
                    >
                        Download as PDF
                    </Button>
                
                </ButtonGroup>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Paper variant="outlined" className={classes.paper3}>
                  <IconButton color="primary" onClick={() => handleOpenService(crane._id)}>
                    <BuildIcon/>
                    <Tooltip title="Record Services & Repairs">
                        <AddIcon />
                    </Tooltip>
                </IconButton>
                </Paper>
              </Grid>
          </Grid>
                
          )}
          <br/>   
          <Divider />

        <DialogContent className={classes.dialogContent}>
        <div ref={printRef}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>

            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="unitNumber"
                      fullWidth
                      variant="outlined"
                      label="Unit Number"
                      value={state.unitNumber || ""}
                      disabled
                    />
              </Grid>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Operator"
                      value={state.inspector || ""}
                      disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="supervisor"
                      fullWidth
                      variant="outlined"
                      label="Supervisor"
                      value={state.supervisor?.displayname || ""}
                      disabled
                    />
                </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                    name="date"
                    fullWidth
                    variant="outlined"
                    label="Date"
                    value={state.date?.split("T")[0] || ''}
                    disabled    
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    name="location"
                    fullWidth
                    variant="outlined"
                    label="Location"
                    value={state.location || ""}
                    disabled    
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                    name="fuel"
                    fullWidth
                    variant="outlined"
                    label="Fuel Level"
                    value={state.fuel || ""}
                    disabled
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body2" gutterBottom color="primary">
                  1 - Ok;
                  2 - Requires Scheduled Maintenance;
                  3 - Requires Immediate Fix
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Walk Around Visual Damage Inspection</FormLabel>
                  <RadioGroup row name="mainVisualDamage" value={state.mainVisualDamage || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              {(state.mainVisualDamage === "2" || state.mainVisualDamage === "3") && (
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="reason"
                  fullWidth
                  variant="outlined"
                  label="Briefly describe the damage"
                  disabled
                  multiline
                  value={state.reason || ""}
                  />
              </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Controls</FormLabel>
                  <RadioGroup row name="mainControl" value={state.mainControl || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Push Buttons</FormLabel>
                  <RadioGroup row name="mainPushButtons" value={state.mainPushButtons || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Sounds Normaly</FormLabel>
                  <RadioGroup row name="mainSoundsNormal" value={state.mainSoundsNormal || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Movement is Smooth</FormLabel>
                  <RadioGroup row name="mainMovement" value={state.mainMovement || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Breaks Positive</FormLabel>
                  <RadioGroup row name="mainBreaksPositive" value={state.mainBreaksPositive || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Upper Limits</FormLabel>
                  <RadioGroup row name="mainUpperLimits" value={state.mainUpperLimits || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Lower Limits</FormLabel>
                  <RadioGroup row name="mainLowerLimits" value={state.mainLowerLimits || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Upper and Lower Block</FormLabel>
                  <RadioGroup row name="mainBlock" value={state.mainBlock || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Sheaves</FormLabel>
                  <RadioGroup row name="mainSheaves" value={state.mainSheaves || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Rope and Connections</FormLabel>
                  <RadioGroup row name="mainRopeConnection" value={state.mainRopeConnection || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Proper Drum Spooling</FormLabel>
                  <RadioGroup row name="mainProperDrum" value={state.mainProperDrum || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Hooks</FormLabel>
                  <RadioGroup row name="mainHooks" value={state.mainHooks || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ToggleButton>
                    <Typography >
                        Auxilliary Hoist (If Equipped, Flip Switch)
                    </Typography>
                    <Switch
                        checked={state.auxHoist}
                        name="auxHoist"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        value={state.auxHoist || false}
                        disabled
                    />
                </ToggleButton>
              </Grid>
              {state.auxHoist && (
              <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Walk Around Visual Damage Inspection</FormLabel>
                    <RadioGroup row name="auxVisualDamage" value={state.auxVisualDamage || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Controls</FormLabel>
                    <RadioGroup row name="auxControl" value={state.auxControl || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Push Buttons</FormLabel>
                    <RadioGroup row name="auxPushButtons" value={state.auxPushButtons || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Sounds Normaly</FormLabel>
                    <RadioGroup row name="auxSoundsNormal" value={state.auxSoundsNormal || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Movement is Smooth</FormLabel>
                    <RadioGroup row name="auxMovement" value={state.auxMovement || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Breaks Positive</FormLabel>
                    <RadioGroup row name="auxBreaksPositive" value={state.auxBreaksPositive || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Upper Limits</FormLabel>
                    <RadioGroup row name="auxUpperLimits" value={state.auxUpperLimits || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Lower Limits</FormLabel>
                    <RadioGroup row name="auxLowerLimits" value={state.auxLowerLimits || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Upper and Lower Block</FormLabel>
                    <RadioGroup row name="auxBlock" value={state.auxBlock || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Sheaves</FormLabel>
                    <RadioGroup row name="auxSheaves" value={state.auxSheaves || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Rope and Connections</FormLabel>
                    <RadioGroup row name="auxRopeConnection" value={state.auxRopeConnection || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Proper Drum Spooling</FormLabel>
                    <RadioGroup row name="auxProperDrum" value={state.auxProperDrum || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Hooks</FormLabel>
                    <RadioGroup row name="auxHooks" value={state.auxHooks || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Trolley</FormLabel>
                    <RadioGroup row name="auxTrolley" value={state.auxTrolley || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
              </Grid>

              )}
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Crane for Loose Parts </FormLabel>
                  <RadioGroup row name="checkCrane" value={state.checkCrane || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Oil Leaks or Other Visual Damage</FormLabel>
                  <RadioGroup row name="oilLeaks" value={state.oilLeaks || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Inspect Pendants Control Casing for Damage to Buttons and Festoon Cable</FormLabel>
                  <RadioGroup row name="inspectPendant" value={state.inspectPendant || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Chain Hoist - Inspect Hoist and Hook for Damage</FormLabel>
                  <RadioGroup row name="chainHoist" value={state.chainHoist || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Chain for Twist and Stretch</FormLabel>
                  <RadioGroup row name="checkChain" value={state.checkChain || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Inspect Slings and Hardware for Damage</FormLabel>
                  <RadioGroup row name="inspectSlings" value={state.inspectSlings || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Spreader Bars, Lifting Beams, Tag lines</FormLabel>
                  <RadioGroup row name="spreaderBars" value={state.spreaderBars || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
                <Typography align="left">
                    Remote Control Battery is Charged
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.remoteControl || false}
                        name="remoteControl"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
                <Typography  align="left">
                    Approved Operator
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.approvedOperator || false}
                        name="approvedOperator"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
                <Typography  align="left">
                    Review Log Entries
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.reviewLog || false}
                        name="reviewLog"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
                <Typography  align="left">
                    Raise Hook Close to Upper Limit
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.raiseHook || false}
                        name="raiseHook"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
                <Typography  align="left">
                    Switch Controller Off and Secure
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.switchController || false}
                        name="switchController"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
                <Typography  align="left">
                    Inspect Rigging and Store Correctly
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.inspectRigging || false}
                        name="inspectRigging"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>
              
              <Grid item xs={12} sm={6}>
              <TextField
                    name="nextService"
                    fullWidth
                    variant="outlined"
                    label=" Next Service Due Date"
                    disabled
                    value={state.nextService?.split("T")[0] || ''}
                    type="date"     
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Comments/Rapairs Required"
                  multiline
                  value={state.comment || ""}
                  disabled
                  />
              </Grid>

            </Grid>

            {crane && crane.service && (
              <Grid>
                <br/>
                <Divider/>
                <Typography variant="overline" className={classes.title}>
                  Services/Repairs Completed
                </Typography>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="serviceDate"
                        fullWidth
                        variant="outlined"
                        label="Service Date"
                        value={state.service?.date?.split("T")[0] || ""}
                        type="date"
                        disabled
                        size="small"     
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="description"
                      fullWidth
                      variant="outlined"
                      label="Description"
                      multiline
                      value={state.service?.description || ""}
                      disabled
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="servicedBy"
                        fullWidth
                        variant="outlined"
                        label="Serviced By"
                        value={state.service?.servicedBy || ""}
                        disabled
                        size="small"    
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="cost"
                      fullWidth
                      variant="outlined"
                      label="Cost"
                      value={state.service?.cost || ""}
                      disabled
                      size="small"
                    />
                  </Grid>
                  </Grid>
                </Grid>
            )}
            
          </Box>
          </Paper>
          </div>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewInspectionModal;
