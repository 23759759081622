import React from "react";
import {
  ImageList,
  makeStyles,
  Paper,
  Typography,
  ImageListItem,
  Button,
  lighten,

} from "@material-ui/core";
import ButtonIcon from "@material-ui/core/Button";
import FolderIcon from "@material-ui/icons/Folder";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {

    color: lighten(theme.palette.common.black, 0.75),
    display: "block",
    fontSize: 14,
    fontWeight: 900,
  },

  btn: {
    marginBottom: 30,
  },
  paper: {
    padding: theme.spacing(2),
    // height: 120,
  },
  
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  icon: {
    width: 50,
    height: 50,
    fill: "#bdbdbd",
  },

    folder: {
        width: 100,
        height: 100,
        fill: "#3498DB",
    },

  imageListItem: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(-2),
    },
  },
  imageList: { 
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(1),

    },
    },


}));
const Document = () => {
  const classes = useStyles();

  const history = useHistory();

  const route = (path) => {
    history.push(path);
  };

  const { profile, loading } = useSelector((state) => state.users);

    let isAdminGuard = profile
    ? (profile &&
        // profile.role && profile.role.name === "admin")
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isToolboxAdmin = profile
    ? (profile &&
        // profile.role && profile.role.name === "toolbox_admin")
        profile.role && profile.role.map((role) => role.name).includes("toolbox_admin"))
        : false;

    let isToolboxSupervisor = profile
    ? (profile &&
        // profile.role && profile.role.name === "toolbox_supervisor")
        profile.role && profile.role.map((role) => role.name).includes("toolbox_supervisor"))
        : false;

    let isToolboxViewer = profile
      ? (profile &&
          // profile.role && profile.role.name === "toolbox_supervisor")
          profile.role && profile.role.map((role) => role.name).includes("toolbox_viewer"))
        : false;


  return (
    <div className={classes.root}>
        <Typography variant="overline" gutterBottom className={classes.title}>
            Document Folders
        </Typography>
        <Button
            color="primary"
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ChevronLeftIcon />}
            className={classes.btn}
        >
            Back
        </Button>
      <Paper className={classes.paper}>
        
        <div>
            <ImageList rowHeight={160}  cols={4}>
            {(isAdminGuard || isToolboxAdmin || isToolboxSupervisor || isToolboxViewer) && (
                <ImageListItem className={classes.imageListItem}>
                    <ButtonIcon onClick={() => route("/document/toolbox")}>
                        <FolderIcon className={classes.folder} />
                    </ButtonIcon>
                    <Typography variant="subtitle2">
                        Toolbox Talks
                    </Typography>

                </ImageListItem>
            )}
                <ImageListItem className={classes.imageListItem}>
                    <ButtonIcon onClick={() => route("/document/safety")}>
                        <FolderIcon className={classes.folder} />
                    </ButtonIcon>
                    <Typography variant="subtitle2">
                        Safety Manuals
                    </Typography>
                </ImageListItem>
                <ImageListItem className={classes.imageListItem}>
                    <ButtonIcon onClick={() => route("/document/quality")}>
                        <FolderIcon className={classes.folder} />
                    </ButtonIcon>
                    <Typography variant="subtitle2">
                        Quality Manuals
                    </Typography>
                </ImageListItem>
                <ImageListItem className={classes.imageListItem}>
                    <ButtonIcon onClick={() => route("/document/employee")}>
                        <FolderIcon className={classes.folder} />
                    </ButtonIcon>
                    <Typography variant="subtitle2">
                        Employee Documents
                    </Typography>
                </ImageListItem>
                <ImageListItem className={classes.imageListItem}>
                    <ButtonIcon onClick={() => route("/document/hse")}>
                        <FolderIcon className={classes.folder} />
                    </ButtonIcon>
                    <Typography variant="subtitle2">
                        HSE Documents
                    </Typography>
                </ImageListItem>
            </ImageList>
        </div>
      </Paper>
     
    </div>
  );
};

export default Document;
