module.exports = {
    CREATE_SWAGER: "CREATE_SWAGER",
    CREATE_SWAGER_REQUEST: "CREATE_SWAGER_REQUEST",
    CREATE_SWAGER_FAIL: "CREATE_SWAGER_FAIL",

    SUBMIT_SWAGER: "SUBMIT_SWAGER",
    SUBMIT_SWAGER_REQUEST: "SUBMIT_SWAGER_REQUEST",
    SUBMIT_SWAGER_FAIL: "SUBMIT_SWAGER_FAIL",

    UPDATE_SWAGER: "UPDATE_SWAGER",
    UPDATE_SWAGER_REQUEST: "UPDATE_SWAGER_REQUEST",
    UPDATE_SWAGER_FAIL: "UPDATE_SWAGER_FAIL",

    SET_SWAGER_DRAFT: "SET_SWAGER_DRAFT",
    SET_SWAGER_DRAFT_REQUEST: "SET_SWAGER_DRAFT_REQUEST",
    SET_SWAGER_DRAFT_FAIL: "SET_SWAGER_DRAFT_FAIL",

    SET_SWAGER_SERVICED: "SET_SWAGER_SERVICED",
    SET_SWAGER_SERVICED_REQUEST: "SET_SWAGER_SERVICED_REQUEST",
    SET_SWAGER_SERVICED_FAIL: "SET_SWAGER_SERVICED_FAIL",

    GET_SWAGERS: "GET_SWAGERS",
    GET_SWAGERS_REQUEST: "GET_SWAGERS_REQUEST",
    GET_SWAGERS_FAIL: "GET_SWAGERS_FAIL",

    GET_SWAGER: "GET_SWAGER",
    GET_SWAGER_REQUEST: "GET_SWAGER_REQUEST",
    GET_SWAGER_FAIL: "GET_SWAGER_FAIL",

    GET_SWAGERS_BY_USER: "GET_SWAGERS_BY_USER",
    GET_SWAGERS_BY_USER_REQUEST: "GET_SWAGERS_BY_USER_REQUEST",
    GET_SWAGERS_BY_USER_FAIL: "GET_SWAGERS_BY_USER_FAIL",

    GET_SWAGERS_BY_LOCATION: "GET_SWAGERS_BY_LOCATION",
    GET_SWAGERS_BY_LOCATION_REQUEST: "GET_SWAGERS_BY_LOCATION_REQUEST",
    GET_SWAGERS_BY_LOCATION_FAIL: "GET_SWAGERS_BY_LOCATION_FAIL",

    DELETE_SWAGER: "DELETE_SWAGER",
    DELETE_SWAGER_REQUEST: "DELETE_SWAGER_REQUEST",
    DELETE_SWAGER_FAIL: "DELETE_SWAGER_FAIL",
}