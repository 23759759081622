import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Backdrop,
  Chip,
  lighten,
  Button,
  ButtonGroup,
  useTheme,
  CircularProgress,
  Tooltip
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ListAltIcon from "@material-ui/icons/ListAlt";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import { useDispatch, useSelector } from "react-redux";
import { TablePaginationActions } from "../../utils/TablePaginationAction";
import Search from "../../utils/Search";
import { getStores } from "../../actions/inventoryAction";
import CreateStoreModal from "../Modals/Store/CreateStoreModal";
import Feedback from "../../utils/Feedback";
import EditStoreModal from "../Modals/Store/EditStoreModal";
import DeleteStoreModal from "../Modals/Store/DeleteStoreModal";
import AllItemModal from "../Modals/Store/AllItemModal";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),

    // width: "100%",
    overflow: "hidden",
  },
  title: {
    color: lighten(theme.palette.common.black, 0.75),
    display: "block",
    fontSize: 15,
    fontWeight: 900,
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  btn2: {
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 350,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.8rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  info: {
    padding: theme.spacing(4),
    marginBottom: 30,
  },
  typo: {
    lineHeight: 1,
    fontWeight: 900,
  },
  avatar: {
    margin: "auto",
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: 30,
  },
  link: {
    color: theme.palette.type === "light" ? "#333" : "#fff",
  },
}));

const Store = () => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAllItem, setOpenAllItem] = useState(false);

  const [getId, setGetId] = useState("");

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { loading, error, stores } = useSelector((state) => state.inventory);
  const { profile } = useSelector((state) => state.users)

  useEffect(() => {
    dispatch(getStores());
  }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const handleOpenEdit = (id) => {
    setGetId(id);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenDelete = (id) => {
    setGetId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenAllItem = (id) => {
    setGetId(id);
    setOpenAllItem(true);
  };

  const handleCloseAllItem = () => {
    setOpenAllItem(false);
  };


  let filteredRows = () =>
    stores === undefined
      ? []
      : stores.filter((row) => {
          if (search !== "") {
            return (
              row.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.location?.displayname.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
          } else {
            return row;
          }
        });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, Array(50).length - page * rowsPerPage);

    
    let isAdmin = profile
    ? (profile && profile.role && 
        ((profile.role.map((role) => role.name).includes("admin") || profile.role.map((role) => role.name).includes("inventory_admin")))
    )
    : false;

  return (
    <div className={classes.root}>
      <CreateStoreModal {...{ openCreate, handleCloseCreate }} />
      <EditStoreModal {...{ openEdit, handleCloseEdit, id: getId }} />
      <DeleteStoreModal {...{ openDelete, handleCloseDelete, id: getId }} />
      <AllItemModal {...{ openAllItem, handleCloseAllItem, id: getId }} />

      {feed.success === false && (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Typography className={classes.title} variant="overline">
        Stores
      </Typography>
      <Grid container alignItems="center" spacing={3}>
        <Grid item>
          {isAdmin && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleOpenCreate}
              className={classes.btn2}
            >
              Create
            </Button>
          )}
        </Grid>
        <Grid item>
          <Search
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>
     
      <Paper>
        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Location</TableCell>    
                {isAdmin && <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                        {row.name}  
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.type}  
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.location?.displayname}
                    </TableCell>
                    
                    {isAdmin && (
                      <TableCell>
                        <ButtonGroup variant="contained">
                            <Tooltip title="Current Stock">
                                <Button
                                    color="default"
                                    onClick={() => handleOpenAllItem(row._id)}
                                >
                                    <ListAltIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Edit Store">
                            <Button
                                color="primary"
                                onClick={() => handleOpenEdit(row._id)}
                            >
                                <EditIcon />
                            </Button>
                            </Tooltip>
                            <Tooltip title="Delete Store">
                            <Button
                                color="secondary"
                                onClick={() => handleOpenDelete(row._id)}
                            >
                                <DeleteIcon />
                            </Button>
                            </Tooltip>
                        </ButtonGroup>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{
                      fontWeight: 900,
                      color: "#616161",
                      textAlign: "center",
                    }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Store