import {
    CREATE_AWP,
    CREATE_AWP_REQUEST,
    CREATE_AWP_FAIL,
    SUBMIT_AWP,
    SUBMIT_AWP_REQUEST,
    SUBMIT_AWP_FAIL,
    UPDATE_AWP,
    UPDATE_AWP_REQUEST,
    UPDATE_AWP_FAIL,
    SET_AWP_DRAFT,
    SET_AWP_DRAFT_REQUEST,
    SET_AWP_DRAFT_FAIL,
    SET_AWP_SERVICED,
    SET_AWP_SERVICED_REQUEST,
    SET_AWP_SERVICED_FAIL,
    GET_AWP,
    GET_AWP_REQUEST,
    GET_AWP_FAIL,
    GET_AWPS,
    GET_AWPS_REQUEST,
    GET_AWPS_FAIL,
    GET_AWPS_BY_USER,
    GET_AWPS_BY_USER_REQUEST,
    GET_AWPS_BY_USER_FAIL,
    GET_AWPS_BY_LOCATION,
    GET_AWPS_BY_LOCATION_REQUEST,
    GET_AWPS_BY_LOCATION_FAIL,
    DELETE_AWP,
    DELETE_AWP_REQUEST,
    DELETE_AWP_FAIL,

} from "../types/awpTypes"

const initState = {
    awp: null,
    awps: [],
    awpsByUser: [],
    error: null,
    loading: false,
  };

  const awpReducer = (state = initState, action) => {
    switch (action.type) {
        case CREATE_AWP_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_AWP:
            return {
                ...state,
                awp: action.payload.data,
                loading: false,
            };
        case CREATE_AWP_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UPDATE_AWP_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_AWP:
            return {
                ...state,
                awp: action.payload.data,
                loading: false
            }
        case UPDATE_AWP_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_AWP_DRAFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_AWP_DRAFT:
            return {
                ...state,
                awp: action.payload.data,
                loading: false
            }
        case SET_AWP_DRAFT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_AWP_SERVICED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_AWP_SERVICED:
            return {
                ...state,
                awp: action.payload.data,
                loading: false
            }
        case SET_AWP_SERVICED_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SUBMIT_AWP_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SUBMIT_AWP: 
            return {
                ...state,
                awp: action.payload.data,
                loading: false
            }
        case SUBMIT_AWP_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case GET_AWPS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_AWPS:
            return {
                ...state,
                loading: false,
                awps: action.payload.data
            };
        case GET_AWPS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_AWP_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_AWP:
            return {
                ...state,
                loading: false,
                awp: action.payload.data,
            };
        case GET_AWP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_AWPS_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_AWPS_BY_USER:
            return {
                ...state,
                loading: false,
                awpsByUser: action.payload.data
            };
        case GET_AWPS_BY_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_AWPS_BY_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_AWPS_BY_LOCATION:
            return {
                ...state,
                loading: false,
                awps: action.payload.data
            };
        case GET_AWPS_BY_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        case DELETE_AWP_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_AWP:
            return {
                ...state,
                loading: false,
                awp: action.payload,
            };
        case DELETE_AWP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
    };

    export default awpReducer;

