import axios from "axios";
import {
    CREATE_SCAFFOLD,
    CREATE_SCAFFOLD_REQUEST,
    CREATE_SCAFFOLD_FAIL,
    SUBMIT_SCAFFOLD,
    SUBMIT_SCAFFOLD_REQUEST,
    SUBMIT_SCAFFOLD_FAIL,
    UPDATE_SCAFFOLD,
    UPDATE_SCAFFOLD_REQUEST,
    UPDATE_SCAFFOLD_FAIL,
    SET_SCAFFOLD_DRAFT,
    SET_SCAFFOLD_DRAFT_REQUEST,
    SET_SCAFFOLD_DRAFT_FAIL,
    GET_SCAFFOLD,
    GET_SCAFFOLD_REQUEST,
    GET_SCAFFOLD_FAIL,
    GET_SCAFFOLDS,
    GET_SCAFFOLDS_REQUEST,
    GET_SCAFFOLDS_FAIL,
    GET_SCAFFOLD_BY_USER,
    GET_SCAFFOLD_BY_USER_REQUEST,
    GET_SCAFFOLD_BY_USER_FAIL,
    GET_SCAFFOLD_BY_LOCATION,
    GET_SCAFFOLD_BY_LOCATION_REQUEST,
    GET_SCAFFOLD_BY_LOCATION_FAIL,
    DELETE_SCAFFOLD,
    DELETE_SCAFFOLD_REQUEST,
    DELETE_SCAFFOLD_FAIL,

} from "../types/scaffoldTypes"

const token = localStorage.getItem("token:data");
export const createInspection = (scaffold) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_SCAFFOLD_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/scaffold/inspection`, scaffold, config);
        dispatch({
            type: CREATE_SCAFFOLD,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_SCAFFOLD_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const submitInspection = (scaffold) => async (dispatch) => {
    
    try {
        dispatch({
            type: SUBMIT_SCAFFOLD_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/scaffold/inspection/submit`, scaffold, config);
        dispatch({
            type: SUBMIT_SCAFFOLD,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUBMIT_SCAFFOLD_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const updateInspection = (scaffold) => async (dispatch) => {
    
    try {
        dispatch({
            type: UPDATE_SCAFFOLD_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/scaffold/inspection/${scaffold.id}`, scaffold, config);
        dispatch({
            type: UPDATE_SCAFFOLD,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_SCAFFOLD_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setInspectionDraft = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_SCAFFOLD_DRAFT_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/scaffold/inspection/draft/${id}`, null, config);
        dispatch({
            type: SET_SCAFFOLD_DRAFT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_SCAFFOLD_DRAFT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getInspections = () => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_SCAFFOLDS_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/scaffold/inspections`, config);
        dispatch({
            type: GET_SCAFFOLDS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SCAFFOLDS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
                });
    }
}

export const getInspection = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_SCAFFOLD_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/scaffold/inspection/${id}`, config);
        dispatch({
            type: GET_SCAFFOLD,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SCAFFOLD_FAIL,
            payload:
                error.response && error.response.data?.error
                ? error.response.data?.error
                : error.request
                ? error.request.data?.error
                : error.message,
        });
    }
}

export const getInspectionsByUser = () => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_SCAFFOLD_BY_USER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/scaffold/myinspections/user`, config);
        dispatch({
            type: GET_SCAFFOLD_BY_USER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SCAFFOLD_BY_USER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const getInspectionsByLocation = (id) => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_SCAFFOLD_BY_LOCATION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/scaffold/inspections/location/${id}`, config);
        dispatch({
            type: GET_SCAFFOLD_BY_LOCATION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SCAFFOLD_BY_LOCATION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const deleteInspection = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_SCAFFOLD_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/scaffold/inspection/${id}`, config);
        dispatch({
            type: DELETE_SCAFFOLD,
        });
    } catch (error) {
        dispatch({
            type: DELETE_SCAFFOLD_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

