import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  Paper,
  Fab,
  Tab,
  Box,
  IconButton,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  ImageList,
  ImageListItem,
  ButtonGroup
} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from '@material-ui/lab';
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import ButtonIcon from "@material-ui/core/Button";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from '@material-ui/icons/Create';
import { useDispatch, useSelector } from "react-redux";
import { updateIncident, uploadDocument, deleteDocument, submitIncident, getContacts, getCauses, getEvents, getIncident, getIncidentsByUser, getIncidents, setIncidentDraft, setIncidentClosed} from "../../../actions/incidentAction";
import {getLocations} from "../../../actions/usersAction";
import IncidentSignature from "./IncidentSignature"
import Feedback from "../../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },

  btn2:{
    marginBottom: 30,
    marginLeft: 10
  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
    minWidth: 760,
    [theme.breakpoints.down("sm")]: {
      minWidth: 640,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    }
},
formControl2: {
  textAlign: "left",
  minWidth: 370,
  [theme.breakpoints.down("sm")]: {
    minWidth: 310,
  },
  [theme.breakpoints.down("xs")]: {
    minWidth: "100%",
  },
},
formControl3: {
  textAlign: "left",
  minWidth: 240,
  [theme.breakpoints.down("sm")]: {
    minWidth: 200,
  },
  [theme.breakpoints.down("xs")]: {
    minWidth: "100%",
  },
},
formControl4: {
    textAlign: "left",
    minWidth: 500,
    [theme.breakpoints.down("sm")]: {
      minWidth: 420,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
formControl5: {
textAlign: "left",
minWidth: 180,
maxWidth: 180,
[theme.breakpoints.down("sm")]: {
    minWidth: 150,
    maxWidth: 150,
},
[theme.breakpoints.down("xs")]: {
    minWidth: "100%",
},
},

  divider: {
    height: 40,
    margin: 4,
    width: 2,
  },

  status:{
    width: "fit-content",
    height: 50,
    padding:10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginLeft: 20
  },
  iconButton: {
    paddingLeft: 50,
    paddingRight: 50,
  },

  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  sign : {
    width: 60,
    height: 30,
    [theme.breakpoints.down("sm")]: {
      width: 60,
      height: 30,
    },
    [theme.breakpoints.down("xs")]: {
      width: 40,
      height: 20,
    },
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },
  imageListItem: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(-2),
    },
  },
  imageList: { 
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(1),

    },
  },

  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 20,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  btnGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
  },

  delete: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(-1),
        marginTop: theme.spacing(-3),
    }
  },

  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },

}));

const INIT_STATE = {
    reporter: undefined,
    location: undefined,
    exactLocation: "",
    date: "",
    rootCause: false,
    incidentType: [],
    worker: "",
    injury: [],
    workType: [],
    day: "",
    daysInShift: "",
    testing: "",
    rotation: "",
    sequence: '',
    contactType: [],
    directCause: [],
    causeDescription: "",
    eventType: [],
    eventDescription: "",
    injuryData: [],
    committee: [],
    statement:[],
    riskFactors: [],
    description: "",
    conclusion: "",
    correctiveActions: [],
    reviews:[],
    attachment: [],
    status: "Draft",
    incidentNo: ""
};

const EditIncidentReport = ({ openEditIncidentReport, handleCloseEditIncidentReport, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { incident, error, loading, contacts, causes, events} = useSelector((state) => state.incident);
  const { profile, locations } = useSelector((state) => state.users);
  const [val, setVal] = useState('1');
  const [file, setFile] = React.useState(undefined)
  const [openSignature, setOpenSignature] = useState(false);
  const [getId, setGetId] = useState("");

  let isAdminGuard = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isIncidentAdmin = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("incident_admin"))
        : false;


  const typeOfIncident = [
    {id: 1, name: "Chemical Spill or Release"},
    {id: 2, name: "Environmental"},
    {id: 3, name: "Fire/Explosion"},
    {id: 4, name: "Hazard Observation"},
    {id: 5, name: "Illness"},
    {id: 6, name: "Injury"},
    {id: 7, name: "Property Damage"},
    {id: 8, name: "Near Miss"},
    {id: 9, name: "Security"},
    {id: 10, name: "Vehicle/Equipment Damage"},
    {id: 11, name: "Workplace Violence"},
  ]

  const injuryList = [
    {id: 1, name: "CVNT"},
    {id: 2, name: "First Aid"},
    {id: 3, name: "Medical Treatment"},
    {id: 4, name: "Restricted Work Day"},
    {id: 5, name: "Lost Work Day"},
    {id: 6, name: "Fatality"},
  ]

  const workList = [
    {id: 1, name: "Civil"},
    {id: 2, name: "Structural/Crane"},
    {id: 3, name: "Piping/Mechanical"},
    {id: 4, name: "Electrical/Instr."},
    {id: 5, name: "Scaffold/Insulation"},
    {id: 6, name: "Other"},
  ]

  const riskFactorsList = [
    {id: 1, name: "Overestimating Personal Experience or Capabilities"},
    {id: 2, name: "Personal Experience with an Outcome"},
    {id: 3, name: "Potential Profit/Gain from Actions"},
    {id: 4, name: "Familiarity with the Task"},
    {id: 5, name: "Cost of Non Compliance"},
    {id: 6, name: "Role Models Accepting Risk"},
    {id: 7, name: "Serioiusness of Outcome"},
    {id: 8, name: "Overconfidence in Equipment"},
    {id: 9, name: "Overconfidence in Rescue and PPE"},
    {id: 10, name: "Voluntary Actions and Being in Control"},
    {id: 11, name: "Others"},
    ]

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getContacts())
    dispatch(getCauses())
    dispatch(getEvents())
  }, [profile._id, dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    if(id) {
      dispatch(getIncident(id));
    }
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
      ...prev,
        reporter: incident && incident.reporter,
        location: incident && incident.location?._id,
        exactLocation: incident && incident.exactLocation,
        date: incident && incident.date,
        rootCause: incident && incident.rootCause,
        incidentType: incident && incident.incidentType,
        worker: incident && incident.worker,
        injury: incident && incident.injury,
        workType: incident && incident.workType,
        day: incident && incident.day,
        daysInShift: incident && incident.daysInShift,
        testing: incident && incident.testing,
        rotation: incident && incident.rotation,
        sequence: incident && incident.sequence,
        contactType: incident && incident.contactType,
        directCause: incident && incident.directCause,
        causeDescription: incident && incident.causeDescription,
        eventType: incident && incident.eventType,
        eventDescription: incident && incident.eventDescription,
        injuryData: incident && incident.injuryData,
        committee: incident && incident.committee,
        statement: incident && incident.statement,
        riskFactors: incident && incident.riskFactors,
        description: incident && incident.description,
        conclusion: incident && incident.conclusion,
        correctiveActions: incident && incident.correctiveActions,
        reviews: incident && incident.reviews,
        attachment: incident && incident.attachment,
        status: incident && incident.status,
        incidentNo: incident && incident.incidentNo
        
    }));
  }, [incident]);

  const handleSetDraft = (e) => {
    e.preventDefault();
    dispatch(setIncidentDraft(id));
    setTimeout(() => {
      handleCloseEditIncidentReport();
        dispatch(getIncidents());
        dispatch(getIncidentsByUser());

        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Incident Report Set to Draft Successfully!",
          success: true,
        }));
    }, 1500);
  };

  const handleSetClosed = (e) => {
    e.preventDefault();
    dispatch(setIncidentClosed(id));
    setTimeout(() => {
      handleCloseEditIncidentReport();
        dispatch(getIncidents());
        dispatch(getIncidentsByUser());

        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Incident Report Closed Successfully!",
          success: true,
        }));
    }, 1500);
  };

  React.useEffect(() => {
    const upload = async () => {
      if (file) {
        const formData = new FormData();
        formData.append("doc", file)
  
        dispatch(uploadDocument(formData, id));
        setTimeout(() => {
          dispatch(getIncident(id))
          setFile(undefined)
        }, 1500);
      }
    }
    upload();
    }, [file, dispatch]);

  const handleUploadDocument = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };

  const handleDeleteDocument = (docId) => {
    const doc = { id, docId}
    dispatch(deleteDocument(doc));
    setTimeout(() => {
        dispatch(getIncident(id))
    }, 1500);
  };

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    
  };

  const handleChangeInjuryData = (index) => (e) => {
    e.persist();
    const { name, value } = e.target;
    const list = [...state.injuryData];
    list[index][name] = value;
    setState((prevState) => ({ ...prevState, injuryData: list }));
  };

  const handleAddInjury = (e) => {
    e.persist();
    const data = {
        part: '',
        comment: ''
    }
    setState((prevState) => ({ ...prevState, injuryData: [...prevState.injuryData, data] }));
  };

  const handleRemoveInjury = (index) => (e) => {
    e.persist();
    const list = [...state.injuryData];
    list.splice(index, 1);
    setState((prevState) => ({ ...prevState, injuryData: list }));
  };

  const handleChangeCommitte = (index) => (e) => {
    e.persist();
    const { name, value } = e.target;
    const list = [...state.committee];
    list[index][name] = value;
    setState((prevState) => ({ ...prevState, committee: list }));
  };

  const handleAddCommitee = (e) => {
    e.persist();
    const data = {
        name: '',
        employee: '',
        trade: ''
    }
    setState((prevState) => ({ ...prevState, committee: [...prevState.committee, data] }));
  };

  const handleRemoveCommitee = (index) => (e) => {
    e.persist();
    const list = [...state.committee];
    list.splice(index, 1);
    setState((prevState) => ({ ...prevState, committee: list }));
  };

  const handleChangeStatement = (index) => (e) => {
    e.persist();
    const { name, value } = e.target;
    const list = [...state.statement];
    list[index][name] = value;
    setState((prevState) => ({ ...prevState, statement: list }));
  };

  const handleAddStatement = (e) => {
    e.persist();
    const data = {
        name: '',
        trade: '',
        date: ''
    }
    setState((prevState) => ({ ...prevState, statement: [...prevState.statement, data] }));
  };

  const handleRemoveStatement = (index) => (e) => {
    e.persist();
    const list = [...state.statement];
    list.splice(index, 1);
    setState((prevState) => ({ ...prevState, statement: list }));
  };

  const handleChangeCorrection = (index) => (e) => {
    e.persist();
    const { name, value } = e.target;
    const list = [...state.correctiveActions];
    list[index][name] = value;
    setState((prevState) => ({ ...prevState, correctiveActions: list }));
  };

  const handleAddCorrection = (e) => {
    e.persist();
    const data = {
        action: '',
        responsible: '',
        date: '',
        completed: ''
    }
    setState((prevState) => ({ ...prevState, correctiveActions: [...prevState.correctiveActions, data] }));
  };

  const handleRemoveCorrection = (index) => (e) => {
    e.persist();
    const list = [...state.correctiveActions];
    list.splice(index, 1);
    setState((prevState) => ({ ...prevState, correctiveActions: list }));
  };

  const handleChangeReview = (index) => (e) => {
    e.persist();
    const { name, value } = e.target;
    const list = [...state.reviews];
    list[index][name] = value;
    setState((prevState) => ({ ...prevState, reviews: list }));
  };

  const handleAddReview = (e) => {
    e.persist();
    const data = {
        name: "",
        position: "",
        signature: "",
        date: "",
    }
    setState((prevState) => ({ ...prevState, reviews: [...prevState.reviews, data] }))

    let currentState = {
        reviews: [...state.reviews, data],
        id
    };

    dispatch(updateIncident(currentState));
    setTimeout(() => {
        dispatch(getIncident(id))
      }, 1500);
  };

  const handleRemoveReview = (index) => (e) => {
    e.persist();
    const list = [...state.reviews];
    list.splice(index, 1);
    setState((prevState) => ({ ...prevState, reviews: list }));
  };

  const handleOpenSignature = (id) => {
    setGetId(id);
    setOpenSignature(true);
  };

  const handleCloseSignature = () => {
    setOpenSignature(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    let data = {
        location: state.location,
        exactLocation: state.exactLocation,
        date: state.date,
        rootCause: state.rootCause,
        incidentType: [...state.incidentType],
        worker: state.worker,
        injury: [...state.injury],
        workType: [...state.workType],
        day: state.day,
        daysInShift: state.daysInShift,
        testing: state.testing,
        rotation: state.rotation,
        sequence: state.sequence,
        contactType: [...state.contactType],
        directCause: [...state.directCause],
        causeDescription: state.causeDescription,
        eventType: [...state.eventType],
        eventDescription: state.eventDescription,
        injuryData: state.injuryData,
        committee: state.committee,
        statement: state.statement,
        riskFactors: state.riskFactors,
        description: state.description,
        conclusion: state.conclusion,
        correctiveActions: state.correctiveActions,
        reviews: state.reviews,
        attachment: state.attachment,
        id
    };
    dispatch(updateIncident(data));

    setTimeout(() => {
      dispatch(getIncident(id));
      dispatch(getIncidentsByUser());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Incident Report Saved Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        location: state.location,
        exactLocation: state.exactLocation,
        date: state.date,
        rootCause: state.rootCause,
        incidentType: [...state.incidentType],
        worker: state.worker,
        injury: [...state.injury],
        workType: [...state.workType],
        day: state.day,
        daysInShift: state.daysInShift,
        testing: state.testing,
        rotation: state.rotation,
        sequence: state.sequence,
        contactType: [...state.contactType],
        directCause: [...state.directCause],
        causeDescription: state.causeDescription,
        eventType: [...state.eventType],
        eventDescription: state.eventDescription,
        injuryData: state.injuryData,
        committee: state.committee,
        statement: state.statement,
        riskFactors: state.riskFactors,
        description: state.description,
        conclusion: state.conclusion,
        correctiveActions: state.correctiveActions,
        reviews: state.reviews,
        attachment: state.attachment,
        path: "incident/report/view",
        id
    };
    dispatch(submitIncident(data));
    setTimeout(() => {
      handleCloseEditIncidentReport()
        dispatch(getIncidentsByUser());
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Incident Report Submitted Successfully.",
          success: true,
        }));
    }, 1500);
  };

  const handleTabChange = (event, newVal) => {
    setVal(newVal);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.date || !state.location

  const isValidSubmit = () =>
    !state.date || !state.location || state.status === "Submitted"

const isValidClose = () =>
  state.status === "Closed"

  return (
    <div>
        <IncidentSignature {...{ openSignature, handleCloseSignature, id: getId }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openEditIncidentReport}
        onClose={handleCloseEditIncidentReport}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit Incident Report
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseEditIncidentReport}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
            <Grid container justifyContent="space-between" alignItems="center">
                {(incident && (incident.status === "Submitted" || incident.status === "Serviced")) && (isAdminGuard || isIncidentAdmin) && (
                <Grid item xs={5} sm={4}>
                    <ButtonGroup className={classes.btnGroup} variant="outlined">
                        <Button
                            size="small"
                            className={classes.btnLink}
                            color="primary"
                            onClick={handleSetDraft}
                        >
                            Set to Draft
                        </Button>
                    
                    </ButtonGroup>
                </Grid>
                )}
            
              <Grid item xs={7} sm={8}>
                <Paper variant="outlined" className={clsx(classes.status, classes.statusDarkGreen)}
                >
                    <Typography variant="overline">
                        Incident No
                    </Typography>
                    <Divider orientation="vertical" className={classes.divider}/>
                    {incident && incident.incidentNo}
                </Paper>
            </Grid>
          </Grid>
                
          <br/>   
          <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={val}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} textColor="secondary" indicatorColor="secondary">
                  <Tab label="Preliminary Report" className={classes.tab} value="1"/>
                  <Tab label="Other Information"  className={classes.tab} value="2"/>
                  <Tab label="Investigation"  className={classes.tab} value="3"/>
                </TabList>
              </Box>
            <TabPanel value="1">
                <Grid container justifyContent="center" alignItems="center" spacing={3}>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="reporter"
                            fullWidth
                            variant="outlined"
                            label="Reporter"
                            value={state.reporter?.displayname || ""}
                            disabled
                            required
                            />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <FormControl variant="outlined" className={classes.formControl2}>
                            <InputLabel id="demo-simple-select-outlined-label">
                                Site
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            fullWidth
                            value={state.location || ""}
                            name="location"
                            onChange={handleChange}
                            label="Site"
                            required
                            >
                            <MenuItem value={null}>
                                <em>None</em>
                            </MenuItem>
                            {locations &&
                                locations.map((location) => (
                                <MenuItem key={location._id} value={location._id}>
                                    {location.displayname}
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                        
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="exactLocation"
                            fullWidth
                            variant="outlined"
                            label="Exact Location"
                            onChange={handleChange}
                            value={state.exactLocation || ""}
                            />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="date"
                            fullWidth
                            variant="outlined"
                            label="Date & Time"
                            onChange={handleChange}
                            value={moment(state.date).format('YYYY-MM-DD HH:mm') || ""}
                            required
                            type="datetime-local"   
                        />
                    </Grid>

                    <Grid item xs={10} sm={5} md={5} >
                        <Typography variant="subtitle2" align="left">
                            Root Cause Required?
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.rootCause || false}
                                name="rootCause"
                                color="primary"
                                onChange={(e, value) => {
                                e.persist()
                                setState((prevState) =>({ ...prevState, rootCause: value }))
                                }}
                            />
                            }
                        />       
                    </Grid> 
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" className={classes.formControl2}>
                            <Autocomplete 
                                multiple
                                id="incidentType"
                                value={state.incidentType || []}
                                options={typeOfIncident}
                                getOptionSelected={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.name} 
                                onChange={(e, value) => setState((prevState) => ({ ...prevState, incidentType: value }))} 
                                renderInput={(params) => 
                                <TextField {...params} variant="outlined" label="Primary Type of Incident" />} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="subtitle2" align="left">
                            If Injury or Illness, List Worker's Name(s) & Implication
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="worker"
                            fullWidth
                            variant="outlined"
                            label="Worker Name"
                            onChange={handleChange}
                            value={state.worker || ""}
                            multiline
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" className={classes.formControl2}>
                            <Autocomplete 
                                multiple
                                id="injury"
                                value={state.injury || []}
                                options={injuryList}
                                getOptionSelected={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.name} 
                                onChange={(e, value) => setState((prevState) => ({ ...prevState, injury: value }))} 
                                renderInput={(params) => 
                                <TextField {...params} variant="outlined" label="Implication" />} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" className={classes.formControl2}>
                            <Autocomplete 
                                multiple
                                id="workType"
                                value={state.workType || []}
                                options={workList}
                                getOptionSelected={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.name} 
                                onChange={(e, value) => setState((prevState) => ({ ...prevState, workType: value }))} 
                                renderInput={(params) => 
                                <TextField {...params} variant="outlined" label="Type of Work" />} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" className={classes.formControl2}>
                            <InputLabel id="day">
                                Day of Week
                            </InputLabel>
                            <Select
                                labelId="day"
                                fullWidth
                                value={state.day || ""}
                                name="day"
                                onChange={handleChange}
                                variant="outlined"
                            >
                                <MenuItem value="Sunday">Sunday</MenuItem>
                                <MenuItem value="Monday">Monday</MenuItem>
                                <MenuItem value="Tuesday">Tuesday</MenuItem>
                                <MenuItem value="Wednesday">Wednesday</MenuItem>
                                <MenuItem value="Thursday">Thursday</MenuItem>
                                <MenuItem value="Friday">Friday</MenuItem>
                                <MenuItem value="Saturday">Saturday</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="daysInShift"
                            fullWidth
                            variant="outlined"
                            label="Days Into Shifts"
                            onChange={handleChange}
                            value={state.daysInShift || ""}
                            type="number"
                            />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" className={classes.formControl3}>
                            <InputLabel id="testing">
                                Post Incident Testing
                            </InputLabel>
                            <Select
                                labelId="testing"
                                fullWidth
                                value={state.testing || ""}
                                name="testing"
                                onChange={handleChange}
                                variant="outlined"
                            >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                                <MenuItem value="N/A">N/A</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="rotation"
                            fullWidth
                            variant="outlined"
                            label="Shift Rotation"
                            onChange={handleChange}
                            value={state.rotation || ""}
                            />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="subtitle2" align="center">
                            SUPPORTING DOCUMENTS
                        </Typography>
                    </Grid>
                    {state.attachment && state.attachment?.length > 0 && (
                    <Grid item xs={12} sm={12}>
                        <Paper className={classes.paper} variant="outlined" square>
                        <ImageList cols={6} rowHeight={100}>
                            {state.attachment.map((item) => (
                                <ImageListItem key={item.id} className={classes.imageListItem}>
                                
                                    <ButtonIcon onClick={() => window.open(item.url, "_blank", "noopener, noreferrer")} className={classes.file}>
                                        {item.url?.split(".").pop() === "pdf"? <i className="fas fa-5x fa-file-pdf" ></i>:                        
                                        (item.url?.split(".").pop() === "ppt") || (item.url?.split(".").pop() === "pptx")? <i className="fas fa-5x fa-file-powerpoint"></i>:
                                        item.url?.split(".").pop() === "pptx"? <i className="fas fa-5x fa-file-powerpoint"></i>:
                                        (item.url?.split(".").pop() ==="png") || (item.url?.split(".").pop() ==="jpg") || (item.url?.split(".").pop() ==="jpeg")? <i className="fas fa-5x fa-file-image"></i>:
                                        <i className="fas fa-5x fa-file"></i>}
                                    </ButtonIcon>

                                    <IconButton onClick={() => handleDeleteDocument(item.id)} className={classes.delete}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <Tooltip title={item.filename}>    
                                        <Typography variant="subtitle2" noWrap className={classes.typo}>
                                            {item.filename}
                                        </Typography>
                                    </Tooltip>

                                </ImageListItem>
                            ))}
                        </ImageList> 

                        </Paper>
                    </Grid>
                    )}
                    <Typography variant="subtitle2" align="left">
                        Click to Upload Additional Documents
                    </Typography>
                    <label htmlFor="photo">
                    <Tooltip arrow title="Click to Upload Document">
                        <IconButton 
                            type="file"
                            color="primary"
                            size="medium"
                            variant="contained"
                            component="span">
                            <AttachFileIcon/>
                        </IconButton>
                    </Tooltip>
                    </label>
                    <input 
                        type="file"
                        id="photo"
                        name="photo"
                        accept="file/*"
                        onChange={handleUploadDocument}
                        hidden
                    />
                </Grid>
            </TabPanel>
            <TabPanel value="2">
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="sequence"
                            fullWidth
                            variant="outlined"
                            label="Sequence of Events"
                            onChange={handleChange}
                            value={state.sequence || ""}
                            multiline
                            helperText="(Pre-Incident, Incident, Post-Incident) - Attach Additional Documents for More Information"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl className={classes.formControl}>
                            <Autocomplete 
                                multiple
                                id="contactTypes"
                                value={state.contactType || []}
                                options={contacts}
                                getOptionSelected={(option, value) => option._id === value._id}
                                getOptionLabel={(option) => option.name} 
                                onChange={(e, value) => setState((prevState) => ({ ...prevState, contactType: value }))} 
                                renderInput={(params) => 
                                <TextField {...params} variant="outlined" label=" Primary Type of Contact" />} 
                            />
                            
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormControl className={classes.formControl}>
                            <Autocomplete 
                                multiple
                                id="directCause"
                                value={state.directCause || []}
                                options={causes}
                                getOptionSelected={(option, value) => option._id === value._id}
                                getOptionLabel={(option) => option.name} 
                                onChange={(e, value) => setState((prevState) => ({ ...prevState, directCause: value }))} 
                                renderInput={(params) => 
                                <TextField {...params} variant="outlined" label="Immediate Direct Causes"/>} 
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="causeDescription"
                            fullWidth
                            variant="outlined"
                            label="Direct Cause Description"
                            onChange={handleChange}
                            value={state.causeDescription || ""}
                            multiline
                            helperText="Provide Description of Each Selected Direct Cause"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl className={classes.formControl}>
                            <Autocomplete 
                                multiple
                                id="eventType"
                                value={state.eventType || []}
                                options={events}
                                getOptionSelected={(option, value) => option._id === value._id}
                                getOptionLabel={(option) => option.name} 
                                onChange={(e, value) => setState((prevState) => ({ ...prevState, eventType: value }))} 
                                renderInput={(params) => 
                                <TextField {...params} variant="outlined" label="Type of Event"/>} 
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="eventDescription"
                            fullWidth
                            variant="outlined"
                            label="Event Type Description"
                            onChange={handleChange}
                            value={state.eventDescription || ""}
                            multiline
                            helperText="Provide Description of Each Selected Event Type"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="subtitle2" align="center">
                            INJURY STATISTICAL DATA
                        </Typography>
                    </Grid>
                    {state.injuryData && state.injuryData?.length > 0 && (
                    <Grid item xs={12} sm={12}>
                        <Paper className={classes.paper} variant="outlined" square>
                            <Typography variant="subtitle2" align="center">
                                Select All That Apply and Provide a Brief Description of Injury
                            </Typography>
                            <img src="/img/body-parts.jpg" alt="" width="200"/>
                            
                            <br />
                            {state.injuryData?.map((item, index) => (
                            <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl variant="outlined" className={classes.formControl3} size="small">
                                        <InputLabel id="part">
                                            Body Part
                                        </InputLabel>
                                        <Select
                                            labelId="part"
                                            fullWidth
                                            value={item.part || ""}
                                            name="part"
                                            onChange={handleChangeInjuryData(index)}
                                            variant="outlined"
                                            required
                                        >
                                            <MenuItem value="Head/Skull/Scalp">Head/Skull/Scalp</MenuItem>
                                            <MenuItem value="Shoulder">Shoulder</MenuItem>
                                            <MenuItem value="Back">Back</MenuItem>
                                            <MenuItem value="Elbow">Elbow</MenuItem>
                                            <MenuItem value="Abdomen/Groin">Abdomen/Groin</MenuItem>
                                            <MenuItem value="Knee">Knee</MenuItem>
                                            <MenuItem value="Ankle">Ankle</MenuItem>
                                            <MenuItem value="Face/Ears">Face/Ears</MenuItem>
                                            <MenuItem value="Eyes">Eyes</MenuItem>
                                            <MenuItem value="Hand">Hand</MenuItem>
                                            <MenuItem value="Arm">Arm</MenuItem>
                                            <MenuItem value="Wrist">Wrist</MenuItem>
                                            <MenuItem value="Leg">Leg</MenuItem>
                                            <MenuItem value="Foot">Foot</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={10} sm={7} md={7}>
                                    <TextField
                                        name="comment"
                                        fullWidth
                                        variant="outlined"
                                        label="Description"
                                        onChange={handleChangeInjuryData(index)}
                                        value={item.comment}
                                        size="small"
                                        multiline
                                    />
                                </Grid>   
                                <Grid item xs={2} sm={1} md={1}>
                                    <IconButton color="secondary" onClick={handleRemoveInjury(index)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                                

                            </Grid>
                            ))}

                        </Paper>
                    </Grid>
                    )}
                    <Typography variant="subtitle2" align="left">
                        Click to Add Body Parts Affected
                    </Typography>
                    <Fab color="primary" aria-label="add" size="medium" onClick={handleAddInjury} className={classes.fab}>
                        <AddIcon />
                    </Fab>
                    
                </Grid>    
                <br />
                <br />
                <Typography align="center">
                    <Button
                    variant="contained"
                    size="large"
                    onClick={handleSave}
                    className={classes.btn2}
                    disabled={isValid() || loading}
                    >
                    {loading ? "Loading..." : "Save"}
                    </Button>
                    <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={handleSubmit}
                    className={classes.btn2}
                    disabled={isValidSubmit() || loading}
                    >
                    {loading ? "Loading..." : "Save & Submit"}
                    </Button>
                </Typography>
            </TabPanel>
            <TabPanel value="3" hidden={incident && incident.status==="Draft"}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle2" align="center">
                        INVESTIGATING COMMITTEE
                    </Typography>
                </Grid>
                {state.committee && state.committee?.length > 0 && (
                <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper} variant="outlined" square>
                        {state.committee?.map((item, index) => (
                        <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                            <Grid item xs={6} sm={4} md={4}>
                                <TextField
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    label="Name"
                                    onChange={handleChangeCommitte(index)}
                                    value={item.name}
                                    size="small"
                                />
                            </Grid> 
                            <Grid item xs={6} sm={3} md={3}>
                                <TextField
                                    name="employer"
                                    fullWidth
                                    variant="outlined"
                                    label="Employer"
                                    onChange={handleChangeCommitte(index)}
                                    value={item.employer}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} md={4}>
                                <TextField
                                    name="trade"
                                    fullWidth
                                    variant="outlined"
                                    label="Trade"
                                    onChange={handleChangeCommitte(index)}
                                    value={item.trade}
                                    size="small"
                                />
                            </Grid>   
                            <Grid item xs={2} sm={1} md={1}>
                                <IconButton color="secondary" onClick={handleRemoveCommitee(index)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            

                        </Grid>
                        ))}

                    </Paper>
                </Grid>
                )}
                <Typography variant="subtitle2" align="left">
                    Click to Add Committee Member
                </Typography>
                <Fab color="primary" aria-label="add" size="medium" onClick={handleAddCommitee} className={classes.fab}>
                    <AddIcon />
                </Fab>

                <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle2" align="center">
                        STATEMENTS TAKEN FROM
                    </Typography>
                </Grid>
                {state.statement && state.statement?.length > 0 && (
                <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper} variant="outlined" square>
                        {state.statement?.map((item, index) => (
                        <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                            <Grid item xs={6} sm={4} md={4}>
                                <TextField
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    label="Name"
                                    onChange={handleChangeStatement(index)}
                                    value={item.name}
                                    size="small"
                                />
                            </Grid> 
                            <Grid item xs={6} sm={3} md={3}>
                                <TextField
                                    name="trade"
                                    fullWidth
                                    variant="outlined"
                                    label="Trade"
                                    onChange={handleChangeStatement(index)}
                                    value={item.trade}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} md={4}>
                                <TextField
                                    name="date"
                                    fullWidth
                                    variant="outlined"
                                    label="Date & Time"
                                    onChange={handleChangeStatement(index)}
                                    value={moment(item.date).format('YYYY-MM-DD HH:mm') || ""}
                                    size="small"
                                    type="datetime-local"   
                                />
                            </Grid>   
                            <Grid item xs={2} sm={1} md={1}>
                                <IconButton color="secondary" onClick={handleRemoveStatement(index)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            

                        </Grid>
                        ))}

                    </Paper>
                </Grid>
                )}
                <Typography variant="subtitle2" align="left">
                    Click to Add Statement
                </Typography>
                <Fab color="primary" aria-label="add" size="medium" onClick={handleAddStatement} className={classes.fab}>
                    <AddIcon />
                </Fab>
                <Grid item xs={12} sm={12}></Grid>

                <Grid item xs={12} sm={8}>
                    <FormControl variant="outlined" className={classes.formControl4}>
                        <Autocomplete 
                            multiple
                            id="riskFactors"
                            value={state.riskFactors || []}
                            options={riskFactorsList}
                            getOptionSelected={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option.name} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, riskFactors: value }))} 
                            renderInput={(params) => 
                                <TextField {...params} variant="outlined" label="Risk Tolerance Factors"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="description"
                        fullWidth
                        variant="outlined"
                        label="If Others, Describe"
                        value={state.description || ""}
                        onChange={handleChange}
                        multiline
                        />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        name="conclusion"
                        fullWidth
                        variant="outlined"
                        label="Investigators Conclusion and Additional Learnings"
                        value={state.conclusion || ""}
                        onChange={handleChange}
                        multiline
                        />
                </Grid>

                <Typography variant="subtitle2" align="center">
                    CORRECTIVE ACTIONS - Safety Work Orders May be Attached and Used for Audit Purpose
                </Typography>
                <label htmlFor="photo">
                    <Tooltip arrow title="Click to Upload Document">
                        <IconButton 
                            type="file"
                            color="primary"
                            size="medium"
                            variant="contained"
                            component="span">
                            <AttachFileIcon/>
                        </IconButton>
                    </Tooltip>
                </label>

                <input 
                    type="file"
                    id="photo"
                    name="photo"
                    accept="file/*"
                    onChange={handleUploadDocument}
                    hidden
                />
                <Typography variant="body2" align="center" color="primary">
                    NOTE: Please save before you attach documents, you will loose your update if you don't save first.
                </Typography>
                
                {state.correctiveActions && state.correctiveActions?.length > 0 && (
                <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper} variant="outlined" square>
                        {state.correctiveActions?.map((item, index) => (
                        <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                            <Grid item xs={12} sm={8} md={8}>
                                <TextField
                                    name="action"
                                    fullWidth
                                    variant="outlined"
                                    label="Specific Action"
                                    onChange={handleChangeCorrection(index)}
                                    value={item.action}
                                    size="small"
                                    multiline
                                />
                            </Grid> 
                            <Grid item xs={6} sm={4} md={4}>
                                <TextField
                                    name="responsible"
                                    fullWidth
                                    variant="outlined"
                                    label="Responsible Person"
                                    onChange={handleChangeCorrection(index)}
                                    value={item.responsible}
                                    size="small"
                                    multiline
                                />
                            </Grid>
                            <Grid item xs={6} sm={5} md={5}>
                                <TextField
                                    name="date"
                                    fullWidth
                                    variant="outlined"
                                    label="Target Completion Date"
                                    onChange={handleChangeCorrection(index)}
                                    value={item.date?.split("T")[0] || ''}
                                    size="small"
                                    type="date"   
                                />
                            </Grid>
                            <Grid item xs={6} sm={5} md={5}>
                                <TextField
                                    name="completed"
                                    fullWidth
                                    variant="outlined"
                                    label="Date Completed"
                                    onChange={handleChangeCorrection(index)}
                                    value={item.completed?.split("T")[0] || ''}
                                    size="small"
                                    type="date"   
                                />
                            </Grid>     
                            <Grid item xs={2} sm={2} md={2}>
                                <IconButton color="secondary" onClick={handleRemoveCorrection(index)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            

                        </Grid>
                        ))}

                    </Paper>
                </Grid>
                )}
                <Typography variant="subtitle2" align="left">
                    Click to Add Corrective Action
                </Typography>
                <Fab color="primary" aria-label="add" size="medium" onClick={handleAddCorrection} className={classes.fab}>
                    <AddIcon />
                </Fab>
                <Grid item xs={12} sm={12}></Grid>
                <Typography variant="subtitle2" align="center">
                    REVIEWS/SIGNATURES
                </Typography>

                {state.reviews && state.reviews?.length > 0 && (
                <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper} variant="outlined" square>
                        {state.reviews?.map((item, index) => (
                        <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                            <Grid item xs={6} sm={3} md={3}>
                                <TextField
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    label="Name"
                                    onChange={handleChangeReview(index)}
                                    value={item.name}
                                    size="small"
                                />
                            </Grid> 
                            <Grid item xs={6} sm={3} md={3}>
                            <FormControl variant="outlined" className={classes.formControl5} size="small">
                                <InputLabel id="position">
                                    Position
                                </InputLabel>
                                <Select
                                    name="position"
                                    labelId="position"
                                    fullWidth
                                    variant="outlined"
                                    value={item.position || ""}
                                    onChange={handleChangeReview(index)}
                                >
                                    <MenuItem value="Investigator">Investigator</MenuItem>
                                    <MenuItem value="Superintendent/Manager">Superintendent/Manager</MenuItem>
                                    <MenuItem value="Project Manager">Project Manager</MenuItem>
                                </Select>
                            </FormControl>
                            </Grid>
                            <Grid item xs={2} sm={1} md={1}>
                              <Tooltip title="Click to Sign">
                                <IconButton
                                  color="primary"
                                  onClick={() => handleOpenSignature(index)}
                                >
                                  <CreateIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3} sm={2} md={2}>
                              <img src={item.signature} alt="signature" className={classes.sign} />
                            </Grid>
                           
                            <Grid item xs={5} sm={2} md={2}>
                                <TextField
                                    name="date"
                                    fullWidth
                                    variant="outlined"
                                    label="Date"
                                    onChange={handleChangeReview(index)}
                                    value={item.date?.split("T")[0] || ''}
                                    size="small"
                                    type="date"   
                                />
                            </Grid>     
                            <Grid item xs={2} sm={1} md={1}>
                                <IconButton color="secondary" onClick={handleRemoveReview(index)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            

                        </Grid>
                        ))}

                    </Paper>
                </Grid>
                )}
                <Grid item xs={12} sm={12}></Grid>
                <Typography variant="subtitle2" align="left">
                    Click to Add Review/Signature
                </Typography>
                <Fab color="primary" aria-label="add" size="medium" onClick={handleAddReview} className={classes.fab}>
                    <AddIcon />
                </Fab>

            </Grid>
            
            <br />
            <br />
                <Typography align="center">
                    <Button
                        variant="contained"
                        size="large"
                        onClick={handleSave}
                        className={classes.btn2}
                        disabled={isValid() || loading}
                        >
                        {loading ? "Loading..." : "Save"}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={handleSetClosed}
                        className={classes.btn2}
                        disabled={isValidClose() || loading}
                        >
                        {loading ? "Loading..." : "Save & Close Investigation"}
                    </Button>
                </Typography>
            </TabPanel>
          </TabContext>
          </Box>
          </Paper>


        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditIncidentReport;
