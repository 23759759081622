import React from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  DialogActions,
  ButtonGroup,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ArchiveIcon from "@material-ui/icons/Archive";
import { deleteItem, getItems, archiveItem } from "../../../actions/inventoryAction";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../../utils/Feedback";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(4
      ),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 20,
    marginBottom: 30,
  },
  typo: {
    lineHeight: 1,
    textTransform: "uppercase",
    fontWeight: 600,
  },
  dialog: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",

    },
  },

}));

const DeleteItemModal = ({ openDelete, handleCloseDelete, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const { loading, error } = useSelector((state) => state.users);

  React.useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const handleDeleteHandler = () => {
    dispatch(deleteItem(id));
    setTimeout(() => {
      handleCloseDelete();
      dispatch(getItems());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Item Deleted Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleArchiveHandler = () => {
    dispatch(archiveItem(id));
    setTimeout(() => {
      handleCloseDelete();
      dispatch(getItems());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Item Archived Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Delete Item
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseDelete}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <Typography variant="body1" className={classes.typo} color="secondary">
            Are You Sure You Want To Delete This Item?
          </Typography>
          <br />
          <Typography variant="body1" className={classes.typo} color="secondary">
            If Not, You Should Archive It Instead
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonGroup variant="contained">
            <Button color="default" onClick={handleCloseDelete}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleArchiveHandler}
              endIcon={<ArchiveIcon />}
              disabled={loading}
            >
              {loading ? "Loading..." : "Archive"}
            </Button>
            <Button
              color="secondary"
              onClick={handleDeleteHandler}
              endIcon={<DeleteIcon />}
              disabled={loading}
            >
              {loading ? "Loading..." : "Delete"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteItemModal;
