import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Backdrop,
  Chip,
  lighten,
  Button,
  ButtonGroup,
  useTheme,
  Avatar,
  CircularProgress,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import clsx from "clsx";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import BuildIcon from '@material-ui/icons/Build';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import { useDispatch, useSelector } from "react-redux";
import { TablePaginationActions } from "../../utils/TablePaginationAction";
import Search from "../../utils/Search";
import { getFleets, getFleetByManager, getFleetBySupervisor, getArchivedFleets } from "../../actions/vehicleAction";
import CreateFleetModal from "../Modals/Vehicle/CreateFleetModal";
import EditFleetModal from "../Modals/Vehicle/EditFleetModal";
import DeleteFleetModal from "../Modals/Vehicle/DeleteFleetModal";
import ServiceFleetModal from "../Modals/Vehicle/ServiceFleetModal";
import Feedback from "../../utils/Feedback";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),

    // width: "100%",
    overflow: "hidden",
  },
  title: {
    color: lighten(theme.palette.common.black, 0.75),
    display: "block",
    fontSize: 15,
    fontWeight: 900,
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  btn2: {
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 350,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.8rem",

  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  green: {
    color: "#4caf50",
    fontWeight: 500,
    },  
  red: {
    color: "#ef5350",
    fontWeight: 500,
    },
  orange: {
    color: "#F5B041",
    fontWeight: 500,
    },

  marginBottom: {
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  info: {
    padding: theme.spacing(4),
    marginBottom: 30,
  },
  typo: {
    lineHeight: 1,
    fontWeight: 900,
  },
  avatar: {
    margin: "auto",
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: 30,
  },
  link: {
    color: theme.palette.type === "light" ? "#333" : "#fff",
  },
  formcontrol: {
    textAlign: "left",
    marginTop: theme.spacing(-3),
    marginLeft: theme.spacing(60),
    minWidth: 300,
    [theme.breakpoints.down("sm")]: {
        minWidth:300,
        marginLeft:0
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
      marginLeft:0
    },
  },
}));

const INIT_STATE = {
  filter: "Active"
};

const Fleets = () => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openService, setOpenService] = useState(false);
  const [state, setState] = useState(INIT_STATE);

  const [getId, setGetId] = useState("");

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { loading, error, fleets } = useSelector((state) => state.vehicle);
  const {profile} = useSelector((state) => state.users);

  let isVehicleAdmin = profile
  ? (profile &&
      // profile.role && profile.role.name === "vehicle_admin")
      profile.role && profile.role.map((role) => role.name).includes("vehicle_admin"))
  : false;

  let isVehicleManager = profile
  ? (profile &&
      // profile.role && profile.role.name === "vehicle_manager")
      profile.role && profile.role.map((role) => role.name).includes("vehicle_manager"))
  : false;

  let isVehicleSupervisor = profile
  ? (profile &&
      // profile.role && profile.role.name === "vehicle_supervisor")
      profile.role && profile.role.map((role) => role.name).includes("vehicle_supervisor"))
  : false;

  let isAdmin = profile
  ? (profile &&
      // profile.role && profile.role.name === "admin")
      profile.role && profile.role.map((role) => role.name).includes("admin"))
  : false;

  useEffect(() => {
    if (isVehicleManager){
        dispatch(getFleetByManager(profile._id))
    }
    else if (isVehicleSupervisor){
        dispatch(getFleetBySupervisor(profile._id))
    }
    else{
      let filter = state.filter
      if (filter === "Archived"){
        dispatch(getArchivedFleets())
      } else{
        dispatch(getFleets());
      }
    }
  }, [dispatch, state.filter]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const handleOpenEdit = (id) => {
    setGetId(id);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenDelete = (id) => {
    setGetId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenService = (id) => {
    setGetId(id);
    setOpenService(true);
  };

  const handleCloseService = () => {
    setOpenService(false);
  };

  let filteredRows = () =>
    fleets === undefined
      ? []
      : fleets.filter((row) => {
          if (search !== "") {
            return (
              row.name?.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.supervisor?.displayname.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.location?.displayname.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.status.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.licencePlate?.toLowerCase().indexOf(search.toLowerCase()) !== -1
            )
          } else {
            return row;
          }
        });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, Array(50) - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <CreateFleetModal {...{ openCreate, handleCloseCreate }} />
      <EditFleetModal {...{ openEdit, handleCloseEdit, id: getId }} />
      <DeleteFleetModal {...{ openDelete, handleCloseDelete, id: getId }} />
      <ServiceFleetModal {...{ openService, handleCloseService, id: getId }} />

      {feed.success === false && (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Typography className={classes.title} variant="overline">
        Fleet
      </Typography>
      <Grid container alignItems="center" spacing={3}>
        <Grid item>
          {(isAdmin || isVehicleAdmin) && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleOpenCreate}
              className={classes.btn2}
            >
              Create
            </Button>
          )}
        </Grid>
        <Grid item>
          <Search
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" className={classes.formcontrol}>
              <InputLabel id="demo-simple-select-outlined-label">
                  Filter
              </InputLabel>
              <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  fullWidth
                  value={state.filter || "Active"}
                  name="filter"
                  label="Filter"
                  variant="outlined"
                  onChange={handleChange}
              >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Archived">Archived</MenuItem>
                  
              </Select>
          </FormControl>
        </Grid>
        </Grid>
     
      <Paper>
        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Trade_Name</TableCell>
                <TableCell>Supervisor</TableCell>
                <TableCell>Licence Plate</TableCell>
                <TableCell>License Expiry</TableCell>
                <TableCell>Inspection Expiry</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Status</TableCell>
                {(isAdmin || isVehicleAdmin || isVehicleManager || isVehicleSupervisor) && <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                        {row.name}  
                    </TableCell>

                    <TableCell component="th" scope="row">
                            <Chip
                                avatar={
                                <Avatar
                                    alt={row.supervisor?.photo}
                                    src={row.supervisor?.photo}
                                />
                                }
                                label={row.supervisor?.displayname}
                                className={classes.chip}
                            />

                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.licencePlate}
                    </TableCell>
                    <TableCell component="th" scope="row">

                        {new Date(row.expiryDate) > new Date() && (new Date(row.expiryDate) - new Date())/(1000 * 3600 * 24) <= 60 ? (
                            <Typography className={classes.orange}>
                                {row.expiryDate?.split("T")[0]}
                            </Typography>
                        ) : new Date(row.expiryDate) > new Date() && (new Date(row.expiryDate) - new Date())/(1000 * 3600 * 24) > 60 ? (
                            <Typography className={classes.green}>
                                {row.expiryDate?.split("T")[0]}
                            </Typography>
                        ) : new Date(row.expiryDate) <= new Date() ? (
                            <Typography className={classes.red}>
                                {row.expiryDate?.split("T")[0]}
                            </Typography>

                        ) : (
                            <Typography>
                                {row.expiryDate?.split("T")[0]}
                            </Typography>
                        )
                    }

                    </TableCell>
                    <TableCell component="th" scope="row">

                        {new Date(row.inspectionExpiry) > new Date() && (new Date(row.inspectionExpiry) - new Date())/(1000 * 3600 * 24) <= 30 ? (
                            <Typography className={classes.orange}>
                                {row.inspectionExpiry?.split("T")[0]}
                            </Typography>
                        ) : new Date(row.inspectionExpiry) > new Date() && (new Date(row.inspectionExpiry) - new Date())/(1000 * 3600 * 24) > 30 ? (
                            <Typography className={classes.green}>
                                {row.inspectionExpiry?.split("T")[0]}
                            </Typography>
                        ) : new Date(row.inspectionExpiry) <= new Date() ? (
                            <Typography className={classes.red}>
                                {row.inspectionExpiry?.split("T")[0]}
                            </Typography>

                        ) : (
                            <Typography>
                                {row.inspectionExpiry?.split("T")[0]}
                            </Typography>
                        )
                    }

                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.location?.displayname}
                    </TableCell>
                    <TableCell component="th" scope="row">
                    <Chip
                            label={row.status}
                            variant="outlined"
                            className={row.status === "Ok" ? clsx(classes.chip, classes.statusDarkGreen) : 
                            row.status === "Service Required" ? clsx(classes.chip, classes.statusOrange) :
                            row.status === "Repair Required" ? clsx(classes.chip, classes.statusOrange) :
                            row.status === "Out of Service" ? clsx(classes.chip, classes.statusRed) :
                            classes.chip}
                        />
                    </TableCell>

                    {(isAdmin || isVehicleAdmin || isVehicleManager || isVehicleSupervisor) && (
                      <TableCell>
                        <ButtonGroup variant="contained">
                        <Tooltip title="Record Service & Repair">
                            <Button
                                color="default"
                                onClick={() => handleOpenService(row._id)}
                            >
                                <BuildIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <Button
                                color="primary"
                                onClick={() => handleOpenEdit(row._id)}
                            >
                                <EditIcon />
                            </Button>
                          </Tooltip>
                          
                          <Tooltip title="Delete">
                            <Button
                                color="secondary"
                                onClick={() => handleOpenDelete(row._id)}
                            >
                                <DeleteIcon />
                            </Button>
                          </Tooltip>
                          
                        </ButtonGroup>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{
                      fontWeight: 900,
                      color: "#616161",
                      textAlign: "center",
                    }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Fleets;
