import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  Paper,
  Box,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {createDailyInspection, submitDailyInspection, getInspectionsByUser, getFleetByLocation} from "../../../actions/vehicleAction"
import { getLocations, getUsers } from "../../../actions/usersAction";
import Feedback from "../../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },

  btn2:{
    marginBottom: 30,
    marginLeft: 10
  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 400,
      [theme.breakpoints.down("sm")]: {
          minWidth:330,
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      }
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 400,
    [theme.breakpoints.down("sm")]: {
        minWidth:330,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },
  green: {
    color: "#4caf50",
    fontWeight: 500,
    },  
  red: {
    color: "#ef5350",
    fontWeight: 500,
    },
  orange: {
    color: "#F5B041",
    fontWeight: 500,
    },


}));

const INIT_STATE = {
    vehicle: "",
    inspector: "",
    supervisor: undefined,
    date: moment().format('YYYY-MM-DD'),
    location: undefined,
    vehicleType: "Vehicle",
    mileage: "",
    visualDamage: "1",
    fireExtinguisher: "1",
    emergencyContact: "1",
    visualTire: "1",
    spareTire: "1",
    leaks: "1",
    horn: "1",
    signalLight: "1",
    interiorLight: "1",
    runningLight: "1",
    dashGuage: "1",
    lightConnection: "1",
    underHood: "1",
    backupAlarm: "1",
    parkBrake: "1",
    seatBelt: "1",
    brakeTest: "1",
    trailerBrakes: "1",
    trailerHitch: "1",
    trailerLights: "1",
    trailerFireExtinguisher: "1",
    ratchetStraps: "Yes",
    wheelWrench1: "Yes",
    wheelWrench2: "Yes",
    hydralicJack: "Yes",
    softeners: "Yes",
    tireChocks: "Yes",
    fuel: "",
    comment: "",   
    nextService: ""
};


const CreateDailyInspectionModal = ({ openCreateDailyInspection, handleCloseCreateDailyInspection }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, loading, locFleets } = useSelector((state) => state.vehicle);
  const { profile, locations, users } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getInspectionsByUser());
    dispatch(getUsers());
    dispatch(getLocations());
    }, [dispatch]);

  useEffect(() => {
    dispatch(getFleetByLocation(profile.location?._id));
  }, [profile, dispatch]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      location: profile && profile.location?._id,
    }));
  }, [profile]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    
  };


  const handleSave = (e) => {
    e.preventDefault();
    let data = {
        vehicle: state.vehicle,
        inspector: state.inspector,
        supervisor: state.supervisor,
        date: state.date,
        location: state.location,
        vehicleType: state.vehicleType,
        mileage: state.mileage,
        visualDamage: state.visualDamage,
        fireExtinguisher: state.fireExtinguisher,
        emergencyContact: state.emergencyContact,
        visualTire: state.visualTire,
        spareTire: state.spareTire,
        leaks: state.leaks,
        horn: state.horn,
        signalLight: state.signalLight,
        interiorLight: state.interiorLight,
        runningLight: state.runningLight,
        dashGuage: state.dashGuage,
        lightConnection: state.lightConnection,
        underHood: state.underHood,
        backupAlarm: state.backupAlarm,
        parkBrake: state.parkBrake,
        seatBelt: state.seatBelt,
        brakeTest: state.brakeTest,
        trailerBrakes: state.trailerBrakes,
        trailerHitch: state.trailerHitch,
        trailerLights: state.trailerLights,
        trailerFireExtinguisher: state.trailerFireExtinguisher,
        ratchetStraps: state.ratchetStraps,
        wheelWrench1: state.wheelWrench1,
        wheelWrench2: state.wheelWrench2,
        hydralicJack: state.hydralicJack,
        softeners: state.softeners,
        tireChocks: state.tireChocks,
        comment: state.comment,
        fuel: state.fuel,
        nextService: state.nextService
    };
    dispatch(createDailyInspection(data));

    setTimeout(() => {
      handleCloseCreateDailyInspection();
      dispatch(getInspectionsByUser());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Daily Inspection Saved Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        vehicle: state.vehicle,
        inspector: state.inspector,
        supervisor: state.supervisor,
        date: state.date,
        location: state.location,
        vehicleType: state.vehicleType,
        mileage: state.mileage,
        visualDamage: state.visualDamage,
        fireExtinguisher: state.fireExtinguisher,
        emergencyContact: state.emergencyContact,
        visualTire: state.visualTire,
        spareTire: state.spareTire,
        leaks: state.leaks,
        horn: state.horn,
        signalLight: state.signalLight,
        interiorLight: state.interiorLight,
        runningLight: state.runningLight,
        dashGuage: state.dashGuage,
        lightConnection: state.lightConnection,
        underHood: state.underHood,
        backupAlarm: state.backupAlarm,
        parkBrake: state.parkBrake,
        seatBelt: state.seatBelt,
        brakeTest: state.brakeTest,
        trailerBrakes: state.trailerBrakes,
        trailerHitch: state.trailerHitch,
        trailerLights: state.trailerLights,
        trailerFireExtinguisher: state.trailerFireExtinguisher,
        ratchetStraps: state.ratchetStraps,
        wheelWrench1: state.wheelWrench1,
        wheelWrench2: state.wheelWrench2,
        hydralicJack: state.hydralicJack,
        softeners: state.softeners,
        tireChocks: state.tireChocks,
        comment: state.comment,
        fuel: state.fuel,
        nextService: state.nextService,
        path: "vehicle/inspection/daily"
    };
    dispatch(submitDailyInspection(data));
    setTimeout(() => {
      handleCloseCreateDailyInspection()
      dispatch(getInspectionsByUser());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Daily Inspection Submitted Successfully.",
        success: true,
      }));
    }, 1500);
  };


  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.vehicle || !state.date || !state.location

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openCreateDailyInspection}
        onClose={handleCloseCreateDailyInspection}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Vehicle Daily Inspection
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseCreateDailyInspection}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <form onSubmit={handleSubmit}>
        
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Inspector"
                      onChange={handleChange}
                      value={state.inspector}
                      required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <Autocomplete 
                            id="supervisor"
                            options={users}
                            getOptionSelected={(option, value) => option._id === value._id}
                            getOptionLabel={(option) => option.displayname} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, supervisor: value }))} 
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Supervisor" />} 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                       Type
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        fullWidth
                        value={state.vehicleType || "Vehicle"}
                        name="vehicleType"
                        onChange={handleChange}
                        label="Type"
                        variant="outlined"
                        defaultValue={"Vehicle"}
                    >
                        <MenuItem value="Vehicle">Vehicle</MenuItem>
                        <MenuItem value="Trailer">Trailer</MenuItem>
                    </Select>
                </FormControl>

              </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete 
                        id="vehicle"
                        options={locFleets}
                        getOptionSelected={(option, value) => option._id === value._id}
                        getOptionLabel={(option) => option.displayname} 
                        onChange={(e, value) => {
                          e.persist();
                          setState((prevState) => (
                            { ...prevState, vehicle: value}))
                        }}
                        value={state.vehicle || null}
                        renderInput={(params) => 
                        <TextField {...params} variant="outlined" label="Vehicle/Trailer" required/>}
                          
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="date"
                      fullWidth
                      variant="outlined"
                      label="Date"
                      onChange={handleChange}
                      value={state.date || moment().format('YYYY-MM-DD')}
                      required
                      type="date"   
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                    Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={state.location || ""}
                      name="location"
                      onChange={handleChange}
                      label="Location"
                      required
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {locations &&
                        locations.map((location) => (
                          <MenuItem key={location._id} value={location._id}>
                            {location.displayname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    name="mileage"
                    fullWidth
                    variant="outlined"
                    label="Mileage"
                    onChange={handleChange}
                    value={state.mileage}
                />
              </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                        Fuel Level
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        fullWidth
                        value={state.fuel || ""}
                        name="fuel"
                        onChange={handleChange}
                        label="Fuel Level"
                        variant="outlined"
                    >
                        <MenuItem value="Empty">Empty</MenuItem>
                        <MenuItem value="1/4">1/4</MenuItem>
                        <MenuItem value="1/2">1/2</MenuItem>
                        <MenuItem value="3/4">3/4</MenuItem>
                        <MenuItem value="Full">Full</MenuItem>
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body2" gutterBottom color="primary">
                  1 - Ok;
                  2 - Requires Scheduled Maintenance;
                  3 - Requires Immediate Fix
                </Typography>
              </Grid>
              {state.vehicleType === "Vehicle" && (
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Walk Around Visual Damage Inspection</FormLabel>
                      <RadioGroup row aria-label="visualDamage" name="visualDamage" value={state.visualDamage || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" />
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" />
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Fire Extinguisher</FormLabel>
                      <RadioGroup row name="fireExtinguisher" value={state.fireExtinguisher || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Emergency Contact Information</FormLabel>
                      <RadioGroup row name="emergencyContact" value={state.emergencyContact || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Visual Tire Inspection</FormLabel>
                      <RadioGroup row name="visualTire" value={state.visualTire || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Spare Tire</FormLabel>
                      <RadioGroup row name="spareTire" value={state.spareTire || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Leaks Under Vehicle</FormLabel>
                      <RadioGroup row name="leaks" value={state.leaks || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Horn, Windows, Wipers, Mirrors</FormLabel>
                      <RadioGroup row name="horn" value={state.horn || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Signal and Brake Lights</FormLabel>
                      <RadioGroup row name="signalLight" value={state.signalLight || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Interior and Dash Lights</FormLabel>
                      <RadioGroup row name="interiorLight" value={state.interiorLight || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Running Lights: Low and High Beam, Alarm Beacon</FormLabel>
                      <RadioGroup row name="runningLight" value={state.runningLight || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Dash Guages</FormLabel>
                      <RadioGroup row name="dashGuage" value={state.dashGuage || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Light Connections</FormLabel>
                      <RadioGroup row name="lightConnection" value={state.lightConnection || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Back-Up Alarm</FormLabel>
                      <RadioGroup row name="backupAlarm" value={state.backupAlarm || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Park Brake</FormLabel>
                      <RadioGroup row name="parkBrake" value={state.parkBrake || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Seat Belts</FormLabel>
                      <RadioGroup row name="seatBelt" value={state.seatBelt || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Brake Test</FormLabel>
                      <RadioGroup row name="brakeTest" value={state.brakeTest || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Under-hood Checks (Belts, Transmission Fluid, Coolant Level, Engine Oil Level, Power Steering Fluid Level)</FormLabel>
                      <RadioGroup row name="underHood" value={state.underHood || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
            )}

            {state.vehicleType === "Trailer" && (
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                  
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Trailer Brakes in Working Order</FormLabel>
                      <RadioGroup row name="trailerBrakes" value={state.trailerBrakes || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" />
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" />
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Right Tailer Hitch Attached and Secured to the Vehicle</FormLabel>
                      <RadioGroup row name="trailerHitch" value={state.trailerHitch || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Vehicle Lights in Working Order</FormLabel>
                      <RadioGroup row name="trailerLights" value={state.trailerLights || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Fire Extinguisher Fully Charged and Secured to the Trailer</FormLabel>
                      <RadioGroup row name="trailerFireExtinguisher" value={state.trailerFireExtinguisher || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" gutterBottom color="primary">
                      Are the Tollowing Tools/Equipment Stored in the Beamer Trailer Storage Box?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >8 - Ratchet Straps</FormLabel>
                      <RadioGroup row name="ratchetStraps" value={state.ratchetStraps || ""} onChange={handleChange}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes"/>
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >1 - 7/8 Wheel Wrench for Spare Tire</FormLabel>
                      <RadioGroup row name="wheelWrench1" value={state.wheelWrench1 || ""} onChange={handleChange}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes"/>
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >1 - 15/16 Wheel Wrench</FormLabel>
                      <RadioGroup row name="wheelWrench2" value={state.wheelWrench2 || ""} onChange={handleChange}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes"/>
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >1 - Hydraulic Jack</FormLabel>
                      <RadioGroup row name="hydralicJack" value={state.hydralicJack || ""} onChange={handleChange}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes"/>
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >12 - Softeners for Padding Straps</FormLabel>
                      <RadioGroup row name="softeners" value={state.softeners || ""} onChange={handleChange}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes"/>
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel>2 - Tire Chocks</FormLabel>
                      <RadioGroup row name="tireChocks" value={state.tireChocks || ""} onChange={handleChange}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes"/>
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  
                </Grid>
            )}
                
              <Grid item xs={12} sm={6}>
              <TextField
                    name="nextService"
                    fullWidth
                    variant="outlined"
                    label=" Next Service Due Date"
                    onChange={handleChange}
                    value={state.nextService}
                    type="date"     
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Comments/Rapairs Required"
                  onChange={handleChange}
                  multiline
                  value={state.comment || ""}
                  />
              </Grid>
   
            </Grid>
            <br />
            
            <Typography align="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSave}
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save"}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save & Submit"}
                </Button>
              </Typography>
              </form>
          </Box>
          </Paper>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateDailyInspectionModal;
