import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  Paper,
  Box,
  IconButton,
  FormControl,
  FormLabel,
  FormControlLabel,
  Button,
  ButtonGroup,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import clsx from "clsx";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import CloseIcon from "@material-ui/icons/Close";
import BuildIcon from '@material-ui/icons/Build';
import AddIcon from "@material-ui/icons/Add"
import { useDispatch, useSelector } from "react-redux";
import {getInspection, setInspectionDraft, getInspections, getInspectionsByUser} from "../../../actions/swagerAction";
import ServiceInspectionModal from "./ServiceInspection";
import Feedback from "../../../utils/Feedback";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  btnGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
  },
  
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 380,
      [theme.breakpoints.down("sm")]: {
          width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      },
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 350,
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
    color: "#4caf50",
  },
  paper3:{
    textAlign: "center",
    width: "fit-content",
    height: 50,
    display: "flex",
    justifyContent: "center",
    marginTop: 30
  },

}));

const INIT_STATE = {
    unitNumber: "",
    inspector: "",
    supervisor: undefined,
    date: "",
    location: undefined,
    hydraulicFluid: "1",
    bolts: "1",
    hydraulicLeaks: "1",
    hydraulicGuage: "1",
    pin: "1",
    equipment: "1",
    comment:"",
    service:{
      date:"",
      description:"",
      servicedBy: "",
      cost: ""
    },
    error: null,
    success: false,
    loading: false,
};

const ViewInspectionModal = ({ openViewInspection, handleCloseViewInspection, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, swager } = useSelector((state) => state.swager);
  const [openService, setOpenService] = useState(false);
  const [getId, setGetId] = useState("");

  const { profile, loading } = useSelector((state) => state.users);

    let isAdminGuard = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isSwagerAdmin = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("swager_admin"))
        : false;


useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
        setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
        }));
    }
    return () => {
        abortController.abort();
    };
    }, [error]);

  useEffect(() => {
    if (id){
      dispatch(getInspection(id));
    }
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      unitNumber: swager && swager.unitNumber,
      inspector: swager && swager.inspector,
      supervisor: swager && swager.supervisor,
      date: swager && swager.date,
      location: swager && swager.location.displayname,
      hydraulicFluid: swager && swager.hydraulicFluid,
      bolts: swager && swager.bolts,
      hydraulicLeaks: swager && swager.hydraulicLeaks,
      hydraulicGuage: swager && swager.hydraulicGuage,
      pin: swager && swager.pin,
      equipment: swager && swager.equipment,
      comment: swager && swager.comment ,
      service: swager && swager.service

    }));
  }, [swager]);

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleSetDraft = (e) => {
    e.preventDefault();
    dispatch(setInspectionDraft(id));
    setTimeout(() => {
      handleCloseViewInspection();
        dispatch(getInspections());
        dispatch(getInspectionsByUser());

        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Inspection set to draft successfully!",
          success: true,
        }));
    }, 1500);
  };

  const handleOpenService = (id) => {
    setGetId(id);
    setOpenService(true);
  };

  const handleCloseService = () => {
    setOpenService(false);
  };


  const printRef = React.useRef()
  const handleDownloadPdf = async () => {
    const element = printRef.current
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4', true);
    
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight()
    const imgWidth = canvas.width
    const imgHeight = canvas.height
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
    const imgX = (pdfWidth - imgWidth * ratio) / 2
    const imgY = (pdfHeight - imgHeight * ratio) / 2

    pdf.addImage(data, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    pdf.save(`Swager Press_${state.unitNumber}_${state.date?.split("T")[0]}.pdf`);
    };  

  return (
    <div>
      <ServiceInspectionModal {...{ openService, handleCloseService, id: getId }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openViewInspection}
        onClose={handleCloseViewInspection}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                View Swager Press Inspection
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseViewInspection}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        {(swager && (swager.status === "Submitted" || swager.status === "Serviced")) && (isAdminGuard || isSwagerAdmin) && (
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={8} sm={8}>
                <ButtonGroup className={classes.btnGroup} variant="outlined">
                    <Button
                        size="small"
                        className={classes.btnLink}
                        color="primary"
                        onClick={handleSetDraft}
                    >
                        Set to Draft
                    </Button>
                    <Button
                        size="small"
                        className={clsx(classes.btnLink, classes.statusDarkGreen)}
                        onClick={handleDownloadPdf}
                    >
                        Download as PDF
                    </Button>
                
                </ButtonGroup>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Paper variant="outlined" className={classes.paper3}>
                  <IconButton color="primary" onClick={() => handleOpenService(swager._id)}>
                    <BuildIcon/>
                    <Tooltip title="Record Services & Repairs">
                        <AddIcon />
                    </Tooltip>
                </IconButton>
                </Paper>
              </Grid>
          </Grid>
                
          )}
          <br/>   
          <Divider />

        <DialogContent className={classes.dialogContent}>
        <div ref={printRef}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>

            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="unitNumber"
                      fullWidth
                      variant="outlined"
                      label="Unit Number"
                      value={state.unitNumber || ""}
                      disabled
                    />
              </Grid>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Operator"
                      value={state.inspector || ""}
                      disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="supervisor"
                      fullWidth
                      variant="outlined"
                      label="Supervisor"
                      value={state.supervisor?.displayname || ""}
                      disabled
                    />
                </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                    name="date"
                    fullWidth
                    variant="outlined"
                    label="Date"
                    value={state.date?.split("T")[0] || ''}
                    disabled    
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    name="location"
                    fullWidth
                    variant="outlined"
                    label="Location"
                    value={state.location || ""}
                    disabled    
                />
              </Grid>

              <Grid item xs={12} sm={12}>
              < Typography variant="body2" gutterBottom color="primary">
                  1 - Ok;
                  2 - Requires Scheduled Maintenance;
                  3 - Requires Immediate Fix
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Hydraulic Fluid Level</FormLabel>
                  <RadioGroup row name="hydraulicFluid" value={state.hydraulicFluid || ""} >
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Bolts for Proper Torque</FormLabel>
                  <RadioGroup row name="bolts" value={state.bolts || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Hydraulics for Leaks/Damage</FormLabel>
                  <RadioGroup row name="hydraulicLeaks" value={state.hydraulicLeaks || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Hydraulic Guage</FormLabel>
                  <RadioGroup row name="hydraulicGuage" value={state.hydraulicGuage || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Pins for Alignment Issues</FormLabel>
                  <RadioGroup row name="pin" value={state.pin || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Equipment for Adequate Grease</FormLabel>
                  <RadioGroup row name="equipment" value={state.equipment || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
                
              
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Comments/Rapairs Required"
                  multiline
                  value={state.comment || ""}
                  disabled
                  />
              </Grid>

            </Grid>

            {swager && swager.service && (
              <Grid>
                <br/>
                <Divider/>
                <Typography variant="overline" className={classes.title}>
                  Services/Repairs Completed
                </Typography>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="serviceDate"
                        fullWidth
                        variant="outlined"
                        label="Service Date"
                        value={state.service?.date?.split("T")[0] || ""}
                        type="date"
                        disabled
                        size="small"     
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="description"
                      fullWidth
                      variant="outlined"
                      label="Description"
                      multiline
                      value={state.service?.description || ""}
                      disabled
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="servicedBy"
                        fullWidth
                        variant="outlined"
                        label="Serviced By"
                        value={state.service?.servicedBy || ""}
                        disabled
                        size="small"    
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="cost"
                      fullWidth
                      variant="outlined"
                      label="Cost"
                      value={state.service?.cost || ""}
                      disabled
                      size="small"
                    />
                  </Grid>
                  </Grid>
                </Grid>
            )}
            
          </Box>
          </Paper>
          </div>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewInspectionModal;
