import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  Paper,
  Box,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {updateMove, submitMove, getMovesByUser, getMove, getSuppliers, getStores, getItems} from "../../../actions/inventoryAction"
import {getLocations} from "../../../actions/usersAction";
import Feedback from "../../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },

  btn2:{
    marginBottom: 30,
    marginLeft: 10
  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl2: {
    textAlign: "left",
    minWidth: 400,
    [theme.breakpoints.down("sm")]: {
      minWidth: 340,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },

  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },

}));

const INIT_STATE = {
    item: undefined,
    moveType: "",
    date: "",
    quantity: 0,
    supplier: undefined,
    source: undefined,
    destination: undefined,
    PONumber: "",
    shape: "",
    grade: "",
    dimension: "",
    heatNumber: "",
    charpyTemp: "",
    location: undefined,
    note: ""
};

const EditMoveModal = ({ openEditMove, handleCloseEditMove, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { move, suppliers, stores, items, error, loading } = useSelector((state) => state.inventory);
  const { profile, locations } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getMovesByUser());
    dispatch(getLocations());
    dispatch(getSuppliers())
    dispatch(getStores())
    dispatch(getItems())
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    if(id){
      dispatch(getMove(id))
    };
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
        ...prev,
        item: move && move.item,
        moveType: move && move.moveType,
        date: move && move.date,
        quantity: move && move.quantity,
        supplier: move && move.supplier,
        source: move && move.source,
        destination: move && move.destination,
        PONumber: move && move.PONumber,
        shape: move && move.shape,
        grade: move && move.grade,
        dimension: move && move.dimension,
        heatNumber: move && move.heatNumber,
        charpyTemp: move && move.charpyTemp,
        location: move && move.location?._id,
        note: move && move.note

    }));
  }, [move]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    
  };

  const handleSave = (e) => {
    e.preventDefault();
    let data = {
        item: state.item,
        moveType: state.moveType,
        date: state.date,
        quantity: state.quantity,
        supplier: state.supplier,
        source: state.source,
        destination: state.destination,
        PONumber: state.PONumber,
        shape: state.shape,
        grade: state.grade,
        dimension: state.dimension,
        heatNumber: state.heatNumber,
        charpyTemp: state.charpyTemp,
        location: state.location,
        note: state.note,
        id
    };
    dispatch(updateMove(data));

    setTimeout(() => {
      handleCloseEditMove();
      dispatch(getMovesByUser());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Item Move Saved Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        item: state.item,
        moveType: state.moveType,
        date: state.date,
        quantity: state.quantity,
        supplier: state.supplier,
        source: state.source,
        destination: state.destination,
        PONumber: state.PONumber,
        shape: state.shape,
        grade: state.grade,
        dimension: state.dimension,
        heatNumber: state.heatNumber,
        charpyTemp: state.charpyTemp,
        location: state.location,
        note: state.note,
        path: "inventory/move/view",
        id
    };
    dispatch(submitMove(data));
    setTimeout(() => {
      handleCloseEditMove()
        dispatch(getMovesByUser());
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Item Move Submitted Successfully.",
          success: true,
        }));
    }, 1500);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.item || !state.moveType || !state.quantity

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openEditMove}
        onClose={handleCloseEditMove}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit Item Move
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseEditMove}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
          <form onSubmit={handleSubmit}>
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={12} sm={6}>
                  <TextField
                      name="initiator"
                      fullWidth
                      variant="outlined"
                      label="Initiator"
                      value={profile?.displayname || ""}
                      disabled
                      required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="date"
                      fullWidth
                      variant="outlined"
                      label="Date"
                      onChange={handleChange}
                      value={state.date?.split("T")[0] || ''}
                      required
                      type="date"   
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                    <FormControl variant="outlined" className={classes.formControl2}>
                        <InputLabel id="location">
                            Location
                        </InputLabel>
                        <Select
                            labelId="location"
                            id="location"
                            fullWidth
                            value={state.location || ''}
                            name="location"
                            onChange={handleChange}
                            required
                        >
                        <MenuItem value={null}>
                            <em>None</em>
                        </MenuItem>
                        {locations &&
                            locations.map((location) => (
                            <MenuItem key={location._id} value={location._id}>
                                {location.displayname}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>

                    <FormControl className={classes.formControl2}>
                        <Autocomplete 
                            id="item"
                            options={items}
                            getOptionSelected={(option, value) => option.name === state.item?.name}
                            getOptionLabel={(option) => option.displayname || ''} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, item: value }))}
                            value={state.item || ''}
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Item" required/>} 
                            
                        />
                    </FormControl>
                        
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className={classes.formControl2}>
                        <InputLabel id="demo-simple-select-outlined-label">
                            Move Type
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            fullWidth
                            value={state.moveType || ""}
                            name="moveType"
                            onChange={handleChange}
                            variant="outlined"
                        >
                            <MenuItem value="Addition">Addition</MenuItem>
                            <MenuItem value="Removal">Removal</MenuItem>
                            <MenuItem value="Shrinkage">Shrinkage</MenuItem>
                            <MenuItem value="Hold">Hold</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {state.moveType && state.moveType == "Addition" && (
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl2}>
                        <Autocomplete 
                            id="supplier"
                            options={suppliers}
                            getOptionSelected={(option, value) => option.name === state.supplier?.name}
                            getOptionLabel={(option) => option.name || ''} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, supplier: value }))}
                            value={state.supplier || ''}
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Supplier" />} 
                        />
                        
                    </FormControl>
                </Grid>
                )}
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl2}>
                        <Autocomplete 
                            id="source"
                            options={stores}
                            getOptionSelected={(option, value) => option.name === state.source?.name}
                            getOptionLabel={(option) => option.name || ''} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, source: value }))}
                            value={state.source}
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Source" required={state.moveType && (state.moveType == "Removal" || state.moveType == "Shrinkage") }/>} 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl2}>
                        <Autocomplete 
                            id="destination"
                            options={stores}
                            getOptionSelected={(option, value) => option.name === state.destination?.name}
                            getOptionLabel={(option) => option.name || ''}
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, destination: value }))}
                            value={state.destination}
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Destination" required={state.moveType && (state.moveType == "Addition") }/>} 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="quantity"
                        fullWidth
                        variant="outlined"
                        label="Quantity"
                        onChange={handleChange}
                        value={state.quantity || 0}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{state.item?.uom?.name}</InputAdornment>,
                        }}
                        type="number"
                    />
                </Grid>
                {/* <Grid item xs={2} sm={1}>
                <Typography variant="body2">
                    {state.item?.uom?.name || ""}
                 </Typography>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="PONumber"
                        fullWidth
                        variant="outlined"
                        label="PO Number"
                        onChange={handleChange}
                        value={state.PONumber || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="shape"
                        fullWidth
                        variant="outlined"
                        label="Shape"
                        onChange={handleChange}
                        value={state.shape || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="grade"
                        fullWidth
                        variant="outlined"
                        label="Grade"
                        onChange={handleChange}
                        value={state.grade || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="dimension"
                        fullWidth
                        variant="outlined"
                        label="Dimension"
                        onChange={handleChange}
                        value={state.dimension || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="heatNumber"
                        fullWidth
                        variant="outlined"
                        label="Heat Number"
                        onChange={handleChange}
                        value={state.heatNumber || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="charpyTemp"
                        fullWidth
                        variant="outlined"
                        label="Charpy Test Temperature"
                        onChange={handleChange}
                        value={state.charpyTemp || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="note"
                        multiline
                        fullWidth
                        variant="outlined"
                        label="Comment"
                        onChange={handleChange}
                        value={state.note || ""}
                        />
                </Grid>
   
            </Grid>

            <br />
          
            <Typography align="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSave}
                  className={classes.btn2}
                  type="submit"
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save"}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                //   onClick={handleSubmit}
                  type="submit"
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save & Submit"}
                </Button>
              </Typography>
            </form>
          </Box>
          </Paper>
          
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditMoveModal;
