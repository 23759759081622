import {
    CREATE_CRANE,
    CREATE_CRANE_REQUEST,
    CREATE_CRANE_FAIL,
    SUBMIT_CRANE,
    SUBMIT_CRANE_REQUEST,
    SUBMIT_CRANE_FAIL,
    UPDATE_CRANE,
    UPDATE_CRANE_REQUEST,
    UPDATE_CRANE_FAIL,
    SET_CRANE_DRAFT,
    SET_CRANE_DRAFT_REQUEST,
    SET_CRANE_DRAFT_FAIL,
    SET_CRANE_SERVICED,
    SET_CRANE_SERVICED_REQUEST,
    SET_CRANE_SERVICED_FAIL,
    GET_CRANE,
    GET_CRANE_REQUEST,
    GET_CRANE_FAIL,
    GET_CRANES,
    GET_CRANES_REQUEST,
    GET_CRANES_FAIL,
    GET_CRANES_BY_USER,
    GET_CRANES_BY_USER_REQUEST,
    GET_CRANES_BY_USER_FAIL,
    GET_CRANES_BY_LOCATION,
    GET_CRANES_BY_LOCATION_REQUEST,
    GET_CRANES_BY_LOCATION_FAIL,
    DELETE_CRANE,
    DELETE_CRANE_REQUEST,
    DELETE_CRANE_FAIL,

} from "../types/craneTypes"

const initState = {
    crane: null,
    cranes: [],
    cranesByUser: [],
    error: null,
    loading: false,
  };

  const craneReducer = (state = initState, action) => {
    switch (action.type) {
        case CREATE_CRANE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_CRANE:
            return {
                ...state,
                crane: action.payload.data,
                loading: false,
            };
        case CREATE_CRANE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UPDATE_CRANE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_CRANE:
            return {
                ...state,
                crane: action.payload.data,
                loading: false
            }
        case UPDATE_CRANE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_CRANE_DRAFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_CRANE_DRAFT:
            return {
                ...state,
                crane: action.payload.data,
                loading: false
            }
        case SET_CRANE_DRAFT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_CRANE_SERVICED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_CRANE_SERVICED:
            return {
                ...state,
                crane: action.payload.data,
                loading: false
            }
        case SET_CRANE_SERVICED_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SUBMIT_CRANE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SUBMIT_CRANE: 
            return {
                ...state,
                crane: action.payload.data,
                loading: false
            }
        case SUBMIT_CRANE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case GET_CRANES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CRANES:
            return {
                ...state,
                loading: false,
                cranes: action.payload.data
            };
        case GET_CRANES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_CRANE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CRANE:
            return {
                ...state,
                loading: false,
                crane: action.payload.data,
            };
        case GET_CRANE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_CRANES_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CRANES_BY_USER:
            return {
                ...state,
                loading: false,
                cranesByUser: action.payload.data
            };
        case GET_CRANES_BY_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_CRANES_BY_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CRANES_BY_LOCATION:
            return {
                ...state,
                loading: false,
                cranes: action.payload.data
            };
        case GET_CRANES_BY_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        case DELETE_CRANE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_CRANE:
            return {
                ...state,
                loading: false,
                crane: action.payload,
            };
        case DELETE_CRANE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
    };

    export default craneReducer;

