import axios from "axios";
import {
    CREATE_SWAGER,
    CREATE_SWAGER_REQUEST,
    CREATE_SWAGER_FAIL,
    SUBMIT_SWAGER,
    SUBMIT_SWAGER_REQUEST,
    SUBMIT_SWAGER_FAIL,
    UPDATE_SWAGER,
    UPDATE_SWAGER_REQUEST,
    UPDATE_SWAGER_FAIL,
    SET_SWAGER_DRAFT,
    SET_SWAGER_DRAFT_REQUEST,
    SET_SWAGER_DRAFT_FAIL,
    SET_SWAGER_SERVICED,
    SET_SWAGER_SERVICED_REQUEST,
    SET_SWAGER_SERVICED_FAIL,
    GET_SWAGER,
    GET_SWAGER_REQUEST,
    GET_SWAGER_FAIL,
    GET_SWAGERS,
    GET_SWAGERS_REQUEST,
    GET_SWAGERS_FAIL,
    GET_SWAGERS_BY_USER,
    GET_SWAGERS_BY_USER_REQUEST,
    GET_SWAGERS_BY_USER_FAIL,
    GET_SWAGERS_BY_LOCATION,
    GET_SWAGERS_BY_LOCATION_REQUEST,
    GET_SWAGERS_BY_LOCATION_FAIL,
    DELETE_SWAGER,
    DELETE_SWAGER_REQUEST,
    DELETE_SWAGER_FAIL,

} from "../types/swagerTypes"

const token = localStorage.getItem("token:data");
export const createInspection = (swager) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_SWAGER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/swager/inspection`, swager, config);
        dispatch({
            type: CREATE_SWAGER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_SWAGER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const submitInspection = (swager) => async (dispatch) => {
    
    try {
        dispatch({
            type: SUBMIT_SWAGER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/swager/inspection/submit`, swager, config);
        dispatch({
            type: SUBMIT_SWAGER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUBMIT_SWAGER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const updateInspection = (swager) => async (dispatch) => {
    
    try {
        dispatch({
            type: UPDATE_SWAGER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/swager/inspection/${swager.id}`, swager, config);
        dispatch({
            type: UPDATE_SWAGER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_SWAGER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setInspectionDraft = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_SWAGER_DRAFT_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/swager/inspection/draft/${id}`, null, config);
        dispatch({
            type: SET_SWAGER_DRAFT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_SWAGER_DRAFT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setInspectionServiced = (service) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_SWAGER_SERVICED_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/swager/inspection/serviced/${service.id}`, service, config);
        dispatch({
            type: SET_SWAGER_SERVICED,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_SWAGER_SERVICED_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}


export const getInspections = () => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_SWAGERS_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/swager/inspections`, config);
        dispatch({
            type: GET_SWAGERS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SWAGERS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
                });
    }
}

export const getInspection = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_SWAGER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/swager/inspection/${id}`, config);
        dispatch({
            type: GET_SWAGER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SWAGER_FAIL,
            payload:
                error.response && error.response.data?.error
                ? error.response.data?.error
                : error.request
                ? error.request.data?.error
                : error.message,
        });
    }
}

export const getInspectionsByUser = () => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_SWAGERS_BY_USER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/swager/myinspections/user`, config);
        dispatch({
            type: GET_SWAGERS_BY_USER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SWAGERS_BY_USER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const getInspectionsByLocation = (id) => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_SWAGERS_BY_LOCATION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/swager/inspections/location/${id}`, config);
        dispatch({
            type: GET_SWAGERS_BY_LOCATION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SWAGERS_BY_LOCATION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const deleteInspection = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_SWAGER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/swager/inspection/${id}`, config);
        dispatch({
            type: DELETE_SWAGER,
        });
    } catch (error) {
        dispatch({
            type: DELETE_SWAGER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

