import React, { useEffect, useState } from "react";
import {
    makeStyles,
    lighten,
    Tooltip,
    Grid,
    Typography,
    TextField,
    Paper,
    Box,
    IconButton,
    FormControl,
    FormLabel,
    FormControlLabel,
    Button,
    ButtonGroup,
    Radio,
    RadioGroup,
  } from "@material-ui/core";

import clsx from "clsx";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import LaunchIcon from '@material-ui/icons/Launch';

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {getInspection, setDailyInspectionDraft, getInspections, getInspectionsByUser} from "../../actions/vehicleAction"
import EditFleetModal from "../Modals/Vehicle/EditFleetModal";
import Feedback from "../../utils/Feedback"

const useStyles = makeStyles((theme) => ({

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
    
      title: {
        // color: "#707070",
        padding: theme.spacing(1.5),
        color: lighten(theme.palette.common.black, 0.75),
    
        display: "block",
        fontSize: 14,
        fontWeight: 900,
      },
      
      title2: {
        fontSize: 13,
        marginBottom: 10,
        fontWeight: "bold",
    
        },
    
      time: {
        // marginLeft: "auto",
        marginTop: 10,
    
        // width: 120,
        width: "fit-content",
        height: 40,
        padding: theme.spacing(1),
        // textAlign: "center",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      icon: {
        marginRight: 5,
      },
      btnLink: {
        padding: theme.spacing(1.5),
        marginTop: -90,
        width: 200,
        [theme.breakpoints.down("xs")]: {
          width: "40%",
        },
      },
      btn: {
        marginLeft: 20,
        marginBottom: 30,
      },
    
      btnGroup: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
    
      },
      dialog: {
        position: "relative",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          height: "100%",
        },
      },
      formControl: {
          minWidth: 380,
          [theme.breakpoints.down("sm")]: {
              width: "100%",
          },
          [theme.breakpoints.down("xs")]: {
              minWidth: "100%",
          },
      },
      formcontrol2: {
        textAlign: "left",
        minWidth: 350,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
        },
      },
      paper: {
          padding: theme.spacing(4),
          textAlign: "center",
          color: theme.palette.text.secondary,
          marginLeft: 20,
      },
      [theme.breakpoints.down("sm")]: {
          width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      },
      
      typo : {
        fontWeight: "bold",
        fontSize: 13,
      },
      statusDarkGreen: {
        borderColor: "#4caf50",
        color: "#4caf50",
      },
}));

const INIT_STATE = {
    inspector: "",
    supervisor: undefined,
    vehicle: "",
    date: moment().format('YYYY-MM-DD'),
    location: undefined,
    vehicleType: "",
    mileage: "",
    visualDamage: "",
    fireExtinguisher: "",
    emergencyContact: "",
    visualTire: "",
    spareTire: "",
    leaks: "",
    horn: "",
    signalLight: "",
    interiorLight: "",
    runningLight: "",
    dashGuage: "",
    lightConnection: "",
    underHood: "",
    backupAlarm: "",
    parkBrake: "",
    seatBelt: "",
    brakeTest: "",
    trailerBrakes: "",
    trailerHitch: "",
    trailerLights: "",
    trailerFireExtinguisher: "",
    ratchetStraps: "",
    wheelWrench1: "",
    wheelWrench2: "",
    hydralicJack: "",
    softeners: "",
    tireChocks: "",
    fuel: "",
    comment: "",
    nextService: "", 
    error: null,
    success: false,
    loading: false,
};

const VehicleDailyInspectionView = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });


  const {error, inspection } = useSelector((state) => state.vehicle);
  const { profile, loading } = useSelector((state) => state.users);

  const [openEdit, setOpenEdit] = useState(false);
  const [getId, setGetId] = useState("");

    let isAdminGuard = profile
    ? (profile &&
        // profile.role && profile.role.name === "admin")
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isVehicleAdmin = profile
    ? (profile &&
        // profile.role && profile.role.name === "vehicle_admin")
        profile.role && profile.role.map((role) => role.name).includes("vehicle_admin"))
        : false;

    let isVehicleManager = profile
    ? (profile &&
        // profile.role && profile.role.name === "vehicle_manager")
        profile.role && profile.role.map((role) => role.name).includes("vehicle_manager"))
        : false;

    let isVehicleSupervisor = profile
    ? (profile &&
        // profile.role && profile.role.name === "vehicle_supervisor")
        profile.role && profile.role.map((role) => role.name).includes("vehicle_supervisor"))
        : false;

    useEffect(() => {
        if (id){
            dispatch(getInspection(id));
        }
        }, [dispatch, id]);
    
    useEffect(() => {
        const abortController = new AbortController();
        if (error && error !== null) {
            setFeed((prev) => ({
            loading: false,
            open: true,
            message: error,
            success: false,
            }));
        }
        return () => {
            abortController.abort();
        };
    }, [error]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            inspector: inspection && inspection.inspector,
            supervisor: inspection && inspection.supervisor,
            vehicle: inspection && inspection.vehicle,
            date: inspection && inspection.date,
            location: inspection && inspection.location?.displayname,
            vehicleType: inspection && inspection.vehicleType,
            mileage: inspection && inspection.mileage,
            visualDamage: inspection && inspection.visualDamage,
            fireExtinguisher: inspection && inspection.fireExtinguisher,
            emergencyContact: inspection && inspection.emergencyContact,
            visualTire: inspection && inspection.visualTire,
            spareTire: inspection && inspection.spareTire,
            leaks: inspection && inspection.leaks,
            horn: inspection && inspection.horn,
            signalLight: inspection && inspection.signalLight,
            interiorLight: inspection && inspection.interiorLight,
            runningLight: inspection && inspection.runningLight,
            dashGuage: inspection && inspection.dashGuage,
            lightConnection: inspection && inspection.lightConnection,
            underHood: inspection && inspection.underHood,
            backupAlarm: inspection && inspection.backupAlarm,
            parkBrake: inspection && inspection.parkBrake,
            seatBelt: inspection && inspection.seatBelt,
            brakeTest: inspection && inspection.brakeTest,
            trailerBrakes: inspection && inspection.trailerBrakes,
            trailerHitch: inspection && inspection.trailerHitch,
            trailerLights: inspection && inspection.trailerLights,
            trailerFireExtinguisher: inspection && inspection.trailerFireExtinguisher,
            ratchetStraps: inspection && inspection.ratchetStraps,
            wheelWrench1: inspection && inspection.wheelWrench1,
            wheelWrench2: inspection && inspection.wheelWrench2,
            hydralicJack: inspection && inspection.hydralicJack,
            softeners: inspection && inspection.softeners,
            tireChocks: inspection && inspection.tireChocks,
            fuel: inspection && inspection.fuel,
            comment: inspection && inspection.comment,
            nextService: inspection && inspection.nextService,
    
        }));
        }, [inspection]);

    const handleSetDraft = (e) => {
        e.preventDefault();
        dispatch(setDailyInspectionDraft(id));
        setTimeout(() => {
            dispatch(getInspections());
            dispatch(getInspectionsByUser());
    
            setFeed((prev) => ({
                loading: false,
                open: true,
                message: "Inspection set to draft successfully!",
                success: true,
            }));
        }, 1500);
        };
    
    const handleOpenEdit = (id) => {
        setGetId(id);
        setOpenEdit(true);
        };

        const handleCloseEdit = () => {
        setOpenEdit(false);
        };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
        };
        
    const printRef = React.useRef()
    const handleDownloadPdf = async () => {
      const element = printRef.current
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL('image/png');
  
      const pdf = new jsPDF('p', 'mm', 'a4', true);
      
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      const imgX = (pdfWidth - imgWidth * ratio) / 2
      const imgY = 20
  
      pdf.addImage(data, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save(`${inspection && inspection.inspectionType}_${state.vehicle?.name}_${state.date?.split("T")[0]}.pdf`);
      }; 

  return (
    <div className={classes.root}>
    <EditFleetModal {...{ openEdit, handleCloseEdit, id: getId }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Typography variant="overline" gutterBottom className={classes.title}>
        View Vehicle Daily Inspection
      </Typography>
        <Button
            color="primary"
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ChevronLeftIcon />}
            className={classes.btn}
        >
            Back
        </Button>
        {(inspection && inspection.status === "Submitted") && (isAdminGuard || isVehicleAdmin || isVehicleManager || isVehicleSupervisor) && (
                <ButtonGroup className={classes.btnGroup} variant="outlined">
                    
                    <Button
                        size="small"
                        className={classes.btnLink}
                        color="primary"
                        onClick={handleSetDraft}
                    >
                        Set to Draft
                    </Button>
                    <Button
                        size="small"
                        className={clsx(classes.btnLink, classes.statusDarkGreen)}
                        onClick={handleDownloadPdf}
                    >
                        Download as PDF
                    </Button>
                
                </ButtonGroup>
            )}
            <br/>
      {/* <Divider/> */}
      <div ref={printRef}>
      <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>

            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Inspector"
                      value={state.inspector || ""}
                      disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="supervisor"
                      fullWidth
                      variant="outlined"
                      label="Supervisor"
                      value={state.supervisor?.displayname || ""}
                      disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="vehicleType"
                      fullWidth
                      variant="outlined"
                      label="Type"
                      value={state.vehicleType || ""}
                      disabled
                    />
                </Grid>

            {isAdminGuard || isVehicleAdmin || isVehicleManager || isVehicleSupervisor ? (
                <Grid item xs={10} sm={5}>
                  <TextField
                    name="vehicle"
                    fullWidth
                    variant="outlined"
                    label="Vehicle"
                    value={state.vehicle?.displayname || ""}
                    disabled
                  ></TextField>
                </Grid>
                
              ) : (
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="vehicle"
                    fullWidth
                    variant="outlined"
                    label="Vehicle"
                    value={state.vehicle?.displayname || ""}
                    disabled
                  ></TextField>
                </Grid>
              )}
                
              {(isAdminGuard || isVehicleAdmin || isVehicleManager || isVehicleSupervisor) && (
                <Grid item xs={2} sm={1}>
                  <Tooltip title="View Vehicle">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handleOpenEdit(state.vehicle?._id);
                      }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <TextField
                    name="date"
                    fullWidth
                    variant="outlined"
                    label="Date"
                    value={state.date?.split("T")[0] || ''}
                    disabled    
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    name="location"
                    fullWidth
                    variant="outlined"
                    label="Location"
                    value={state.location || ""}
                    disabled    
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                    name="mileage"
                    fullWidth
                    variant="outlined"
                    label="Mileage"
                    value={state.mileage || ""}
                    disabled
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    name="fuel"
                    fullWidth
                    variant="outlined"
                    label="Fuel Level"
                    value={state.fuel || ""}
                    disabled
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body2" gutterBottom color="primary">
                  1 - Ok;
                  2 - Requires Scheduled Maintenance;
                  3 - Requires Immediate Fix
                </Typography>
              </Grid>
              {(state.vehicleType === "Vehicle" || state.vehicleType === undefined) && (
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Walk Around Visual Damage Inspection</FormLabel>
                      <RadioGroup row aria-label="visualDamage" name="visualDamage" value={state.visualDamage || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                      </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Fire Extinguisher</FormLabel>
                    <RadioGroup row name="fireExtinguisher" value={state.fireExtinguisher || ""}>
                      <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                      <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                      <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Emergency Contact Information</FormLabel>
                    <RadioGroup row name="emergencyContact" value={state.emergencyContact || ""}>
                      <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                      <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                      <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Visual Tire Inspection</FormLabel>
                    <RadioGroup row name="visualTire" value={state.visualTire || ""}>
                      <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                      <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                      <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Spare Tire</FormLabel>
                    <RadioGroup row name="spareTire" value={state.spareTire || ""}>
                      <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                      <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                      <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Leaks Under Vehicle</FormLabel>
                    <RadioGroup row name="leaks" value={state.leaks || ""}>
                      <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                      <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                      <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Horn, Windows, Wipers, Mirrors</FormLabel>
                    <RadioGroup row name="horn" value={state.horn || ""}>
                      <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                      <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                      <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Signal and Brake Lights</FormLabel>
                    <RadioGroup row name="signalLight" value={state.signalLight || ""}>
                      <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                      <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                      <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Interior and Dash Lights</FormLabel>
                    <RadioGroup row name="interiorLight" value={state.interiorLight || ""}>
                      <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                      <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                      <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Running Lights: Low and High Beam, Alarm Beacon</FormLabel>
                    <RadioGroup row name="runningLight" value={state.runningLight || ""}>
                      <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                      <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                      <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Dash Guages</FormLabel>
                    <RadioGroup row name="dashGuage" value={state.dashGuage || ""} >
                      <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                      <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                      <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Light Connections</FormLabel>
                    <RadioGroup row name="lightConnection" value={state.lightConnection || ""}>
                      <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                      <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                      <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Back-Up Alarm</FormLabel>
                      <RadioGroup row name="backupAlarm" value={state.backupAlarm || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Park Brake</FormLabel>
                      <RadioGroup row name="parkBrake" value={state.parkBrake || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Seat Belts</FormLabel>
                      <RadioGroup row name="seatBelt" value={state.seatBelt || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Brake Test</FormLabel>
                      <RadioGroup row name="brakeTest" value={state.brakeTest || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Under-hood Checks (Belts, Transmission Fluid, Coolant Level, Engine Oil Level, Power Steering Fluid Level)</FormLabel>
                      <RadioGroup row name="underHood" value={state.underHood || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
              </Grid>
            )}
              {state.vehicleType === "Trailer" && (
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                  
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Trailer Brakes in Working Order</FormLabel>
                      <RadioGroup row name="trailerBrakes" value={state.trailerBrakes || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Right Tailer Hitch Attached and Secured to the Vehicle</FormLabel>
                      <RadioGroup row name="trailerHitch" value={state.trailerHitch || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Vehicle Lights in Working Order</FormLabel>
                      <RadioGroup row name="trailerLights" value={state.trailerLights || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >Fire Extinguisher Fully Charged and Secured to the Trailer</FormLabel>
                      <RadioGroup row name="trailerFireExtinguisher" value={state.trailerFireExtinguisher || ""}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" gutterBottom color="primary">
                      Are the Tollowing Tools/Equipment Stored in the Beamer Trailer Storage Box?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >8 - Ratchet Straps</FormLabel>
                      <RadioGroup row name="ratchetStraps" value={state.ratchetStraps || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled/>
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >1 - 7/8 Wheel Wrench for Spare Tire</FormLabel>
                      <RadioGroup row name="wheelWrench1" value={state.wheelWrench1 || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled/>
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >1 - 15/16 Wheel Wrench</FormLabel>
                      <RadioGroup row name="wheelWrench2" value={state.wheelWrench2 || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled/>
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >1 - Hydraulic Jack</FormLabel>
                      <RadioGroup row name="hydralicJack" value={state.hydralicJack || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled/>
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel >12 - Softeners for Padding Straps</FormLabel>
                      <RadioGroup row name="softeners" value={state.softeners || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled/>
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                      <FormLabel>2 - Tire Chocks</FormLabel>
                      <RadioGroup row name="tireChocks" value={state.tireChocks || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled/>
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  
                </Grid>
            )}
              
              <Grid item xs={12} sm={6}>
              <TextField
                    name="nextService"
                    fullWidth
                    variant="outlined"
                    label=" Next Service Due Date"
                    disabled
                    value={state.nextService?.split("T")[0] || ''}
                    type="date"     
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Comments/Rapairs Required"
                  multiline
                  value={state.comment || ""}
                  disabled
                  />
              </Grid>

            </Grid>
               
          </Box>
          </Paper>
          </div>
    </div>
  );
};

export default VehicleDailyInspectionView;