import axios from "axios";
import {
    CREATE_EVENT,
    CREATE_EVENT_REQUEST,
    CREATE_EVENT_FAIL,
    GET_EVENT,
    GET_EVENT_REQUEST,
    GET_EVENT_FAIL,
    GET_EVENTS,
    GET_EVENTS_REQUEST,
    GET_EVENTS_FAIL,
    UPDATE_EVENT,
    UPDATE_EVENT_REQUEST,
    UPDATE_EVENT_FAIL,
    DELETE_EVENT,
    DELETE_EVENT_REQUEST,
    DELETE_EVENT_FAIL,

    CREATE_CAUSE,
    CREATE_CAUSE_REQUEST,
    CREATE_CAUSE_FAIL,
    GET_CAUSE,
    GET_CAUSE_REQUEST,
    GET_CAUSE_FAIL,
    GET_CAUSES_REQUEST,
    GET_CAUSES,
    GET_CAUSES_FAIL,
    UPDATE_CAUSE,
    UPDATE_CAUSE_REQUEST,
    UPDATE_CAUSE_FAIL,
    DELETE_CAUSE,
    DELETE_CAUSE_REQUEST,
    DELETE_CAUSE_FAIL,

    CREATE_CONTACT,
    CREATE_CONTACT_REQUEST,
    CREATE_CONTACT_FAIL,
    UPDATE_CONTACT,
    UPDATE_CONTACT_REQUEST,
    UPDATE_CONTACT_FAIL,
    GET_CONTACTS,
    GET_CONTACTS_REQUEST,
    GET_CONTACTS_FAIL,
    GET_CONTACT,
    GET_CONTACT_REQUEST,
    GET_CONTACT_FAIL,
    DELETE_CONTACT,
    DELETE_CONTACT_REQUEST,
    DELETE_CONTACT_FAIL,

    CREATE_INCIDENT,
    CREATE_INCIDENT_REQUEST,
    CREATE_INCIDENT_FAIL,
    GET_INCIDENT,
    GET_INCIDENT_REQUEST,
    GET_INCIDENT_FAIL,
    GET_INCIDENTS,
    GET_INCIDENTS_REQUEST,
    GET_INCIDENTS_FAIL,
    GET_INCIDENTS_BY_USER,
    GET_INCIDENTS_BY_USER_REQUEST,
    GET_INCIDENTS_BY_USER_FAIL,
    UPDATE_INCIDENT,
    UPDATE_INCIDENT_REQUEST,
    UPDATE_INCIDENT_FAIL,
    SUBMIT_INCIDENT,
    SUBMIT_INCIDENT_REQUEST,
    SUBMIT_INCIDENT_FAIL,
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_REQUEST,
    UPLOAD_DOCUMENT_FAIL,
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_FAIL,
    DOWNLOAD_DOCUMENT,
    DOWNLOAD_DOCUMENT_REQUEST,
    DOWNLOAD_DOCUMENT_FAIL,
    SET_INCIDENT_DRAFT,
    SET_INCIDENT_DRAFT_REQUEST,
    SET_INCIDENT_DRAFT_FAIL,
    SET_INCIDENT_CLOSED,
    SET_INCIDENT_CLOSED_REQUEST,
    SET_INCIDENT_CLOSED_FAIL,
    SET_INCIDENT_OPEN,
    SET_INCIDENT_OPEN_REQUEST,
    SET_INCIDENT_OPEN_FAIL,
    DELETE_INCIDENT,
    DELETE_INCIDENT_REQUEST,
    DELETE_INCIDENT_FAIL,

} from '../types/incidentTypes';

const token = localStorage.getItem("token:data");

export const createEvent = (event) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_EVENT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/incident/event`, event, config);
        dispatch({
            type: CREATE_EVENT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_EVENT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getEvents = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_EVENTS_REQUEST,
            });
            
            const URL = `/api/v1/incident/event`;
            const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_EVENTS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_EVENTS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }   
};


export const getEvent = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_EVENT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/incident/event/${id}`, config);
        dispatch({
            type: GET_EVENT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_EVENT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const updateEvent = (event) => async (dispatch) => {
   
    try {
        dispatch({
            type: UPDATE_EVENT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/incident/event/${event.id}`, event, config);
        dispatch({
            type: UPDATE_EVENT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_EVENT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                    ? error.request.data.error
                    : error.message,
        });
    }
}

export const deleteEvent = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_EVENT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/incident/event/${id}`, config);
        dispatch({
            type: DELETE_EVENT,
        });
    } catch (error) {
        dispatch({
            type: DELETE_EVENT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const createCause = (cause) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_CAUSE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/incident/cause`, cause, config);
        dispatch({
            type: CREATE_CAUSE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_CAUSE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getCauses = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_CAUSES_REQUEST,
            });
            
            const URL = `/api/v1/incident/cause`;
            const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_CAUSES,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_CAUSES_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }   
};


export const getCause = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_CAUSE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/incident/cause/${id}`, config);
        dispatch({
            type: GET_CAUSE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_CAUSE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const updateCause = (cause) => async (dispatch) => {
   
    try {
        dispatch({
            type: UPDATE_CAUSE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/incident/cause/${cause.id}`, cause, config);
        dispatch({
            type: UPDATE_CAUSE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_CAUSE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                    ? error.request.data.error
                    : error.message,
        });
    }
}

export const deleteCause = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_CAUSE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/incident/cause/${id}`, config);
        dispatch({
            type: DELETE_CAUSE,
        });
    } catch (error) {
        dispatch({
            type: DELETE_CAUSE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const createContact = (contact) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_CONTACT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/incident/contact`, contact, config);
        dispatch({
            type: CREATE_CONTACT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_CONTACT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getContacts = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_CONTACTS_REQUEST,
            });
            
            const URL = `/api/v1/incident/contact`;
            const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_CONTACTS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_CONTACTS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }   
};


export const getContact = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_CONTACT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/incident/contact/${id}`, config);
        dispatch({
            type: GET_CONTACT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_CONTACT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const updateContact = (contact) => async (dispatch) => {
   
    try {
        dispatch({
            type: UPDATE_CONTACT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/incident/contact/${contact.id}`, contact, config);
        dispatch({
            type: UPDATE_CONTACT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_CONTACT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                    ? error.request.data.error
                    : error.message,
        });
    }
}

export const deleteContact = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_CONTACT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/incident/contact/${id}`, config);
        dispatch({
            type: DELETE_CONTACT,
        });
    } catch (error) {
        dispatch({
            type: DELETE_CONTACT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const createIncident = (report) => async (dispatch) => {
        
    try {
        dispatch({
            type: CREATE_INCIDENT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/incident/report`, report, config);
        dispatch({
            type: CREATE_INCIDENT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_INCIDENT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const submitIncident = (report) => async (dispatch) => {
    
    try {
        dispatch({
            type: SUBMIT_INCIDENT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/incident/report/submit`, report, config);
        dispatch({
            type: SUBMIT_INCIDENT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUBMIT_INCIDENT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const updateIncident = (report) => async (dispatch) => {
    
    try {
        dispatch({
            type: UPDATE_INCIDENT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/incident/report/${report.id}`, report, config);
        dispatch({
            type: UPDATE_INCIDENT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_INCIDENT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setIncidentDraft = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_INCIDENT_DRAFT_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/incident/report/draft/${id}`, null, config);
        dispatch({
            type: SET_INCIDENT_DRAFT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_INCIDENT_DRAFT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setIncidentClosed = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_INCIDENT_CLOSED_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/incident/report/close/${id}`, null, config);
        dispatch({
            type: SET_INCIDENT_CLOSED,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_INCIDENT_CLOSED_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setIncidentOpen = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_INCIDENT_OPEN_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/incident/report/open/${id}`, null, config);
        dispatch({
            type: SET_INCIDENT_OPEN,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_INCIDENT_OPEN_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}

export const uploadDocument = (formData, id) => async (dispatch) => {
    try {
      dispatch({
        type: UPLOAD_DOCUMENT_REQUEST,
      });
      const URL = `/api/v1/incident/doc/upload/${id}`;
      const { data } = await axios.put(URL, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: UPLOAD_DOCUMENT,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: UPLOAD_DOCUMENT_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error 
            : error.request
            ? error.request.data.error
            : error.message,
      });
    }
  };

  export const deleteDocument = (doc) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_DOCUMENT_REQUEST,
      });
      const URL = `/api/v1/incident/doc/delete/${doc.id}`;
      const { data } = await axios.put(URL, doc, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: DELETE_DOCUMENT,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_DOCUMENT_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error 
            : error.request
            ? error.request.data.error
            : error.message,
      });
    }
  };

export const generateReport = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DOWNLOAD_DOCUMENT_REQUEST,
        });
        const URL = `/api/v1/incident/report/pdf/${id}`;
        const { data } = await axios.put(URL, null, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: DOWNLOAD_DOCUMENT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DOWNLOAD_DOCUMENT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const getIncident = (id) => async (dispatch) => {

    try {
        dispatch({
            type: GET_INCIDENT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/incident/report/${id}`, config);
        dispatch({
            type: GET_INCIDENT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_INCIDENT_FAIL,
            payload:
                error.response && error.response.data?.error
                ? error.response.data?.error
                : error.request
                ? error.request.data?.error
                : error.message,
        });
    }
}



export const getIncidents = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_INCIDENTS_REQUEST,
            });
            
            const URL = `/api/v1/incident/reports`;
            const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_INCIDENTS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_INCIDENTS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }   
}

export const getIncidentsByUser = () => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_INCIDENTS_BY_USER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/incident/myreports/user`, config);
        dispatch({
            type: GET_INCIDENTS_BY_USER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_INCIDENTS_BY_USER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const deleteIncident = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_INCIDENT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/incident/report/${id}`, config);
        dispatch({
            type: DELETE_INCIDENT,
        });
    } catch (error) {
        dispatch({
            type: DELETE_INCIDENT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}