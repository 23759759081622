import React from "react";
import {
  Button,
  darken,
  makeStyles,
  TextField,
  IconButton,
  Backdrop,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Divider,
  Toolbar,
  alpha,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../actions/usersAction";
import Feedback from "../../utils/Feedback";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    // background: theme.palette.background.paper,
    minHeight: "100vh",
    height: "100%",
    // background: "linear-gradient(to right, #6A82FB, #FC5C7D)",
    background: "#071e4a",
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  lightBg: {
    background:
      "repeating-linear-gradient(45deg, rgb(255,255,255) 0px, rgb(255,255,255) 10px,transparent 10px, transparent 11px),repeating-linear-gradient(135deg, rgb(255,255,255) 0px, rgb(255,255,255) 10px,transparent 10px, transparent 11px),linear-gradient(90deg, hsl(256,7%,84%),hsl(256,7%,84%))",

    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    // height: "100%",
    borderRadius: 5,
    margin: "auto",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    position: "relative",
  },
  gridContainer: {
    margin: "0 auto",
    // width: "80%",
  },
  gradientBg: {
    minHeight: "100vh",
    background: "url(/img/stars3.jpg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#000",
  },
  centered: {
    // margin: "auto",
    textAlign: "center",
  },
  form: {
    width: "100%",
  },
  logo: {
    height: 100,
  },
  textField: {
    marginTop: 15,
    marginBottom: 15,
  },
  welcomeText: {
    color: darken(theme.palette.background.paper, 0.1),
    fontWeight: 900,
    margin: "auto",
    fontFamily: "Rubik",
  },
  welcomeText2: {
    color: theme.palette.background.paper,
    fontWeight: 900,
    margin: "auto",
    position: "relative",

    "&:after": {
      position: "absolute",
      content: "''",
      width: 250,
      height: 2,
      marginTop: 13,
      marginLeft: 5,
      background:
        "repeating-linear-gradient(135deg, rgba(86, 86, 86, 0.02) 0px, rgba(86, 86, 86, 0.02) 22px,rgba(202, 202, 202, 0.02) 22px, rgba(202, 202, 202, 0.02) 67px,rgba(247, 247, 247, 0.02) 67px, rgba(247, 247, 247, 0.02) 113px,rgba(135, 135, 135, 0.02) 113px, rgba(135, 135, 135, 0.02) 132px,rgba(157, 157, 157, 0.02) 132px, rgba(157, 157, 157, 0.02) 153px,rgba(53, 53, 53, 0.02) 153px, rgba(53, 53, 53, 0.02) 171px,rgba(17, 17, 17, 0.02) 171px, rgba(17, 17, 17, 0.02) 181px,rgba(179, 179, 179, 0.02) 181px, rgba(179, 179, 179, 0.02) 220px),repeating-linear-gradient(135deg, rgba(58, 58, 58, 0.02) 0px, rgba(58, 58, 58, 0.02) 41px,rgba(198, 198, 198, 0.02) 41px, rgba(198, 198, 198, 0.02) 60px,rgba(176, 176, 176, 0.02) 60px, rgba(176, 176, 176, 0.02) 99px,rgba(173, 173, 173, 0.02) 99px, rgba(173, 173, 173, 0.02) 146px,rgba(164, 164, 164, 0.02) 146px, rgba(164, 164, 164, 0.02) 167px,rgba(179, 179, 179, 0.02) 167px, rgba(179, 179, 179, 0.02) 205px,rgba(228, 228, 228, 0.02) 205px, rgba(228, 228, 228, 0.02) 230px,rgba(23, 23, 23, 0.02) 230px, rgba(23, 23, 23, 0.02) 241px),repeating-linear-gradient(135deg, rgba(190, 190, 190, 0.02) 0px, rgba(190, 190, 190, 0.02) 15px,rgba(74, 74, 74, 0.02) 15px, rgba(74, 74, 74, 0.02) 45px,rgba(98, 98, 98, 0.02) 45px, rgba(98, 98, 98, 0.02) 71px,rgba(43, 43, 43, 0.02) 71px, rgba(43, 43, 43, 0.02) 95px,rgba(131, 131, 131, 0.02) 95px, rgba(131, 131, 131, 0.02) 118px,rgba(21, 21, 21, 0.02) 118px, rgba(21, 21, 21, 0.02) 130px,rgba(77, 77, 77, 0.02) 130px, rgba(77, 77, 77, 0.02) 167px,rgba(231, 231, 231, 0.02) 167px, rgba(231, 231, 231, 0.02) 189px),linear-gradient(90deg, rgb(251, 251, 251),rgb(250, 250, 250));",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  marginAuto: {
    margin: "160px auto",
    height: "100%",
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    position: "relative",
    width: 300,

  },

  title: {
    fontFamily: "Sigmar One, Quicksand",
    color: darken(theme.palette.background.paper, 0.6),

    fontWeight: 900,
    fontSize: 35,
    background:
      "repeating-linear-gradient(90deg, hsla(57,0%,42%,0.09) 0px, hsla(57,0%,42%,0.09) 1px,transparent 1px, transparent 60px),repeating-linear-gradient(0deg, hsla(57,0%,42%,0.09) 0px, hsla(57,0%,42%,0.09) 1px,transparent 1px, transparent 60px),repeating-linear-gradient(0deg, hsla(57,0%,42%,0.09) 0px, hsla(57,0%,42%,0.09) 1px,transparent 1px, transparent 10px),repeating-linear-gradient(90deg, hsla(57,0%,42%,0.09) 0px, hsla(57,0%,42%,0.09) 1px,transparent 1px, transparent 10px),linear-gradient(90deg, rgb(20,20,20),rgb(20,20,20))",
  },
  title2: {
    // position: "absolute",

    letterSpacing: 1.5,
    lineHeight: 2,
    fontSize: 18,
    // color: "#fff",

    display: "block",
    fontFamily: "Carter One",
  },
  logoTitle: {
    fontFamily: "Sansita Swashed",
    color: darken(theme.palette.background.paper, 0.4),
    fontWeight: 900,
  },
  loginText: {
    fontWeight: 500,
    fontSize: 25,
    marginTop: -100,
    [theme.breakpoints.down("sm")]: {
      marginTop: -50,
    }
  },

  cardContent: {
    padding: theme.spacing(6),
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  cardMedia: {
    backgroundImage:
      "repeating-linear-gradient(358deg, rgb(0,0,0) 0px, rgb(0,0,0) 27px,transparent 27px, transparent 29px),repeating-linear-gradient(280deg, rgb(0,0,0) 0px, rgb(0,0,0) 27px,transparent 27px, transparent 29px),repeating-linear-gradient(337deg, rgb(0,0,0) 0px, rgb(0,0,0) 27px,transparent 27px, transparent 29px),linear-gradient(90deg, rgb(225, 16, 162),rgb(169, 105, 71))",
    objectFit: "cover",
    objectPosition: "50% 65%",

    [theme.breakpoints.down("sm")]: {
      objectPosition: "50% 60%",
    },
  },
  avatarSquare: {
    width: 120,
    height: 60,
    position: "absolute",
    top: -100,
    right: -40,
    marginTop: -50,
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 40,
      top: -50,
      right: -20,
      marginTop: -25,
    }
  },
  divider: {
    height: 2,
    background: alpha(theme.palette.warning.light, 0.9),
  },
  divider2: {
    height: 3,
    background: theme.palette.warning.main,
  },
}));

const INIT_VALUES = {
  email: "",
  password: "",
  showPassword: false,
};

const LoginComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [values, setValues] = React.useState(INIT_VALUES);

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { loading, error, user } = useSelector((state) => state.users);

  React.useEffect(() => {
    const abortController = new AbortController();
    const token = localStorage.getItem("token:data");
    if (token) {
      history.push("/dashboard");
    }
    return () => {
      abortController.abort();
    };
  }, [history]);

  React.useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  React.useEffect(() => {
    if (user && user !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: `You've Successfully logged in to your account.`,
        success: true,
      }));
      setValues(INIT_VALUES);
      setTimeout(() => {
        history.push("/dashboard");
        window.location.reload();
      }, 2000);
    }
  }, [user, history]);

  const handleChange = (e) => {
    e.persist();
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClickShowPassword = () => {
    setValues((prev) => ({
      ...prev,
      showPassword: !prev.showPassword,
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let data = {
      email: values.email,
      password: values.password,
    };

    dispatch(loginUser(data));
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const doCheck = () => !values.email || !values.password;

  return (
    <div className={classes.root}>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Card className={classes.lightBg}>
        <CardMedia
          component="img"
          alt="Stream"
          height="140"
          image="/img/FAMILY_BG.svg"
          title="Stream"
          className={classes.cardMedia}
        />
       
        <Divider className={classes.divider2} />
        <Divider className={classes.divider} />
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.title2}
            variant="overline"
            align="center"
          >
          <Toolbar>
            <img src="/img/dfbarnes-no-bg.png" alt="" className={classes.avatarSquare}/>
          </Toolbar>
          </Typography>
          <Typography
            variant="overline"
            component="h1"
            align="center"
            className={classes.loginText}
          >
            Login
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Email"
              name="email"
              type="email"
              className={classes.textField}
              onChange={handleChange}
              value={values.email}
              label="Email"
            />
            <TextField
              variant="outlined"
              fullWidth
              name="password"
              label="Password"
              placeholder="Password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              className={classes.textField}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography align="center">
              <Button
                className={classes.btn}
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                disabled={doCheck() || loading}
                endIcon={
                  loading ? <CircularProgress size={20} color="primary" /> : null
                }
              >
                {loading ? "Loading..." : "Login"}
              </Button>
            </Typography>
            <Typography
                component="h2"
                align="center"
                >
                <Link to="/" className={classes.link}>
                  Forgot Password? Reset Here
                </Link>
            </Typography>
          </form>
        </CardContent>
      </Card>

      <Backdrop className={classes.backdrop} open={loading ? loading : false}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default LoginComponent;
