import React from "react";
import {
  ImageList,
  makeStyles,
  Paper,
  Typography,
  ImageListItem,
  Button,
  lighten

} from "@material-ui/core";

import ButtonIcon from "@material-ui/core/Button";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import TramIcon from '@material-ui/icons/Tram';
import RvHookupIcon from '@material-ui/icons/RvHookup';
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import GolfCourseIcon from '@material-ui/icons/GolfCourse';
import LocalMoviesIcon from '@material-ui/icons/LocalMovies';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import KingBedIcon from '@material-ui/icons/KingBed'

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {

    color: lighten(theme.palette.common.black, 0.75),
    display: "block",
    fontSize: 14,
    fontWeight: 900,
  },

  btn: {
    marginBottom: 30,
  },
  paper: {
    padding: theme.spacing(2),
    // height: 120,
  },
  
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  icon: {
    width: 50,
    height: 50,
    fill: "#bdbdbd",
  },

    toolbox: {
        width: 80,
        height: 80,
        fill: "#34495E",
    },

    vehicle: {
        width: 80,
        height: 80,
        fill: "#515A5A",
    },
    awp: {
      width: 80,
      height: 80,
      fill: "#674C02",
    },
    forklift: {
      width: 80,
      height: 80,
      fill: "#556B2F",
    },
    harness: {
      width: 80,
      height: 80,
      fill: "#56020E",
    },
    crane: {
      width: 80,
      height: 80,
      fill: "#B35512",
    },
    scaffold: {
      width: 80,
      height: 80,
      fill: "556B2F",
    },
    swager: {
      width: 80,
      height: 80,
      fill: "#5F9EA0",
    },
    testbed: {
      width: 80,
      height: 80,
      fill: "#800080",
    },
  imageListItem: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(-2),
    },
  },
  imageList: { 
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(1),

    },
    },


}));
const Sign = () => {
  const classes = useStyles();

  const history = useHistory();

  const route = (path) => {
    history.push(path);
  };
  const { profile } = useSelector((state) => state.users);

  let isAdminGuard = profile
    ? (profile &&
        // profile.role && profile.role.name === "admin")
        profile.role && profile.role.map((role) => role.name).includes("admin"))
     : false;

  let isToolboxAdmin = profile
    ? (profile &&
        // profile.role && profile.role.name === "toolbox_admin")
        profile.role && profile.role.map((role) => role.name).includes("toolbox_admin"))
      : false;

  let isToolboxSupervisor = profile
    ? (profile &&
        // profile.role && profile.role.name === "toolbox_supervisor")
        profile.role && profile.role.map((role) => role.name).includes("toolbox_supervisor"))
      : false;
  
  let isVehicleOperator = profile
    ? (profile &&
        // profile.role && profile.role.name === "vehicle_operator")
        profile.role && profile.role.map((role) => role.name).includes("vehicle_operator"))
      : false;

  let isVehicleAdmin = profile
  ? (profile &&
      // profile.role && profile.role.name === "vehicle_admin")
      profile.role && profile.role.map((role) => role.name).includes("vehicle_admin"))
  : false;

  let isVehicleSupervisor = profile
  ? (profile &&
      profile.role && profile.role.map((role) => role.name).includes("vehicle_supervisor"))
  : false;

  let isVehicleManager = profile
  ? (profile &&
      profile.role && profile.role.map((role) => role.name).includes("vehicle_manager"))
  : false;

  let isAWPOperator = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("awp_operator"))
      : false;

  let isAWPAdmin = profile
  ? (profile &&
      profile.role && profile.role.map((role) => role.name).includes("awp_admin"))
  : false;

  let isForkliftOperator = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("forklift_operator"))
      : false;

  let isForkliftAdmin = profile
  ? (profile &&
      profile.role && profile.role.map((role) => role.name).includes("forklift_admin"))
  : false;

  let isCraneOperator = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("crane_operator"))
      : false;

  let isCraneAdmin = profile
  ? (profile &&
      profile.role && profile.role.map((role) => role.name).includes("crane_admin"))
  : false;

  let isHarnessOperator = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("harness_operator"))
      : false;

  let isHarnessAdmin = profile
  ? (profile &&
      profile.role && profile.role.map((role) => role.name).includes("harness_admin"))
  : false;

  let isScaffoldOperator = profile
  ? (profile &&
      profile.role && profile.role.map((role) => role.name).includes("scaffold_operator"))
    : false;

  let isScaffoldAdmin = profile
  ? (profile &&
      profile.role && profile.role.map((role) => role.name).includes("scaffold_admin"))
  : false;

  let isSwagerOperator = profile
  ? (profile &&
      profile.role && profile.role.map((role) => role.name).includes("swager_operator"))
    : false;

  let isSwagerAdmin = profile
  ? (profile &&
      profile.role && profile.role.map((role) => role.name).includes("swager_admin"))
  : false;

  let isTestbedOperator = profile
  ? (profile &&
      profile.role && profile.role.map((role) => role.name).includes("testbed_operator"))
    : false;

  let isTestbedAdmin = profile
  ? (profile &&
      profile.role && profile.role.map((role) => role.name).includes("testbed_admin"))
  : false;


  return (
    <div className={classes.root}>
        <Typography variant="overline" gutterBottom className={classes.title}>
            Signs & Inspections
        </Typography>
        <Button
            color="primary"
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ChevronLeftIcon />}
            className={classes.btn}
        >
            Back
        </Button>
      <Paper className={classes.paper}>
        
        <div>
            <ImageList rowHeight={160}  cols={4}>
                {(isAdminGuard || isToolboxAdmin || isToolboxSupervisor) && (
                <ImageListItem className={classes.imageListItem}>
                    <ButtonIcon onClick={() => route("/toolbox/sign")}>
                        <AssignmentIcon className={classes.toolbox} />
                    </ButtonIcon>
                    <Typography variant="subtitle2">
                        Toolbox Sign
                    </Typography>

                </ImageListItem>
                )}
            
                {(isAdminGuard || isVehicleOperator || isVehicleAdmin || isVehicleSupervisor || isVehicleManager) && (
                <ImageListItem className={classes.imageListItem}>
                    <ButtonIcon onClick={() => route("/vehicle/inspection")}>
                        <LocalShippingIcon className={classes.vehicle} />
                    </ButtonIcon>
                    <Typography variant="subtitle2">
                        Vehicle Inspection
                    </Typography>

                </ImageListItem>
                 )}

              {(isAdminGuard || isAWPOperator || isAWPAdmin) && (
                <ImageListItem className={classes.imageListItem}>
                    <ButtonIcon onClick={() => route("/awp/inspection")}>
                        <TramIcon className={classes.awp} />
                    </ButtonIcon>
                    <Typography variant="subtitle2">
                        Aerial Work Platform Inspection
                    </Typography>

                </ImageListItem>
                 )}

              {(isAdminGuard || isForkliftOperator || isForkliftAdmin) && (
                <ImageListItem className={classes.imageListItem}>
                    <ButtonIcon onClick={() => route("/forklift/inspection")}>
                        <RvHookupIcon className={classes.forklift} />
                    </ButtonIcon>
                    <Typography variant="subtitle2">
                        Forklift/Zoom Boom Inspection
                    </Typography>

                </ImageListItem>
                 )}
              
              {(isAdminGuard || isHarnessOperator || isHarnessAdmin) && (
                <ImageListItem className={classes.imageListItem}>
                    <ButtonIcon onClick={() => route("/harness/inspection")}>
                        <DeviceHubIcon className={classes.harness} />
                    </ButtonIcon>
                    <Typography variant="subtitle2">
                        Harness & Lanyard Inspection
                    </Typography>

                </ImageListItem>
                 )}

                {(isAdminGuard || isCraneOperator || isCraneAdmin) && (
                  <ImageListItem className={classes.imageListItem}>
                      <ButtonIcon onClick={() => route("/crane/inspection")}>
                          <GolfCourseIcon className={classes.crane} />
                      </ButtonIcon>
                      <Typography variant="subtitle2">
                          Overhead (Bridge) Crane Inspection
                      </Typography>

                  </ImageListItem>
                )}
                
                {(isAdminGuard || isScaffoldOperator || isScaffoldAdmin) && (
                  <ImageListItem className={classes.imageListItem}>
                      <ButtonIcon onClick={() => route("/scaffold/inspection")}>
                          <LocalMoviesIcon className={classes.scaffold} />
                      </ButtonIcon>
                      <Typography variant="subtitle2">
                          Scaffold Inspection
                      </Typography>

                  </ImageListItem>
                )}

                {(isAdminGuard || isSwagerOperator || isSwagerAdmin) && (
                  <ImageListItem className={classes.imageListItem}>
                      <ButtonIcon onClick={() => route("/swager/inspection")}>
                          <HourglassFullIcon className={classes.swager} />
                      </ButtonIcon>
                      <Typography variant="subtitle2">
                          Swager Press Inspection
                      </Typography>

                  </ImageListItem>
                )}

                {(isAdminGuard || isTestbedOperator || isTestbedAdmin) && (
                  <ImageListItem className={classes.imageListItem}>
                      <ButtonIcon onClick={() => route("/testbed/inspection")}>
                          <KingBedIcon className={classes.testbed} />
                      </ButtonIcon>
                      <Typography variant="subtitle2">
                          Test Bed Inspection
                      </Typography>

                  </ImageListItem>
                )}

            </ImageList>
            
        </div>
      </Paper>
     
    </div>
  );
};

export default Sign;
