import axios from "axios";
import {
    CREATE_UOM,
    CREATE_UOM_REQUEST,
    CREATE_UOM_FAIL,
    GET_UOM,
    GET_UOM_REQUEST,
    GET_UOM_FAIL,
    GET_UOMS,
    GET_UOMS_REQUEST,
    GET_UOMS_FAIL,
    UPDATE_UOM,
    UPDATE_UOM_REQUEST,
    UPDATE_UOM_FAIL,
    DELETE_UOM,
    DELETE_UOM_REQUEST,
    DELETE_UOM_FAIL,
    ARCHIVE_UOM,
    ARCHIVE_UOM_REQUEST,
    ARCHIVE_UOM_FAIL,

    CREATE_CATEGORY,
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_FAIL,
    GET_CATEGORY,
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_FAIL,
    GET_CATEGORIES,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_FAIL,
    UPDATE_CATEGORY,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_FAIL,
    DELETE_CATEGORY,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_FAIL,
    ARCHIVE_CATEGORY,
    ARCHIVE_CATEGORY_REQUEST,
    ARCHIVE_CATEGORY_FAIL,

    CREATE_STORE,
    CREATE_STORE_REQUEST,
    CREATE_STORE_FAIL,
    GET_STORE,
    GET_STORE_REQUEST,
    GET_STORE_FAIL,
    GET_STORES,
    GET_STORES_REQUEST,
    GET_STORES_FAIL,
    UPDATE_STORE,
    UPDATE_STORE_REQUEST,
    UPDATE_STORE_FAIL,
    DELETE_STORE,
    DELETE_STORE_REQUEST,
    DELETE_STORE_FAIL,
    ARCHIVE_STORE,
    ARCHIVE_STORE_REQUEST,
    ARCHIVE_STORE_FAIL,

    CREATE_SUPPLIER,
    CREATE_SUPPLIER_REQUEST,
    CREATE_SUPPLIER_FAIL,
    GET_SUPPLIER,
    GET_SUPPLIER_REQUEST,
    GET_SUPPLIER_FAIL,
    GET_SUPPLIERS,
    GET_SUPPLIERS_REQUEST,
    GET_SUPPLIERS_FAIL,
    UPDATE_SUPPLIER,
    UPDATE_SUPPLIER_REQUEST,
    UPDATE_SUPPLIER_FAIL,
    DELETE_SUPPLIER,
    DELETE_SUPPLIER_REQUEST,
    DELETE_SUPPLIER_FAIL,
    ARCHIVE_SUPPLIER,
    ARCHIVE_SUPPLIER_REQUEST,
    ARCHIVE_SUPPLIER_FAIL,

    CREATE_ITEM,
    CREATE_ITEM_REQUEST,
    CREATE_ITEM_FAIL,
    GET_ITEM,
    GET_ITEM_REQUEST,
    GET_ITEM_FAIL,
    GET_ITEMS,
    GET_ITEMS_REQUEST,
    GET_ITEMS_FAIL,
    UPDATE_ITEM,
    UPDATE_ITEM_REQUEST,
    UPDATE_ITEM_FAIL,
    DELETE_ITEM,
    DELETE_ITEM_REQUEST,
    DELETE_ITEM_FAIL,
    ARCHIVE_ITEM,
    ARCHIVE_ITEM_REQUEST,
    ARCHIVE_ITEM_FAIL,

    CREATE_QUANT,
    CREATE_QUANT_REQUEST,
    CREATE_QUANT_FAIL,
    GET_QUANT,
    GET_QUANT_REQUEST,
    GET_QUANT_FAIL,
    GET_QUANTS,
    GET_QUANTS_REQUEST,
    GET_QUANTS_FAIL,
    UPDATE_QUANT,
    UPDATE_QUANT_REQUEST,
    UPDATE_QUANT_FAIL,

    CREATE_MOVE,
    CREATE_MOVE_REQUEST,
    CREATE_MOVE_FAIL,
    UPDATE_MOVE,
    UPDATE_MOVE_REQUEST,
    UPDATE_MOVE_FAIL,
    SUBMIT_MOVE,
    SUBMIT_MOVE_REQUEST,
    SUBMIT_MOVE_FAIL,
    DECLINE_MOVE,
    DECLINE_MOVE_REQUEST,
    DECLINE_MOVE_FAIL,
    APPROVE_MOVE,
    APPROVE_MOVE_REQUEST,
    APPROVE_MOVE_FAIL,
    GET_MOVE,
    GET_MOVE_REQUEST,
    GET_MOVE_FAIL,
    GET_MOVES,
    GET_MOVES_REQUEST,
    GET_MOVES_FAIL,
    GET_MOVE_BY_USER,
    GET_MOVE_BY_USER_REQUEST,
    GET_MOVE_BY_USER_FAIL,
    GET_MOVE_BY_LOCATION,
    GET_MOVE_BY_LOCATION_REQUEST,
    GET_MOVE_BY_LOCATION_FAIL,
    GET_MOVE_BY_ITEM,
    GET_MOVE_BY_ITEM_REQUEST,
    GET_MOVE_BY_ITEM_FAIL,
    DELETE_MOVE,
    DELETE_MOVE_REQUEST,
    DELETE_MOVE_FAIL,



} from '../types/inventoryTypes';

const token = localStorage.getItem("token:data");

export const createUOM = (uom) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_UOM_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/inventory/uom`, uom, config);
        dispatch({
            type: CREATE_UOM,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_UOM_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getUOMs = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_UOMS_REQUEST,
            });
            
            const URL = `/api/v1/inventory/uom`;
            const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_UOMS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_UOMS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }   
};


export const getUOM = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_UOM_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/inventory/uom/${id}`, config);
        dispatch({
            type: GET_UOM,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_UOM_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const updateUOM = (uom) => async (dispatch) => {
   
    try {
        dispatch({
            type: UPDATE_UOM_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/inventory/uom/${uom.id}`, uom, config);
        dispatch({
            type: UPDATE_UOM,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_UOM_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                    ? error.request.data.error
                    : error.message,
        });
    }
}

export const deleteUOM = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_UOM_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/inventory/uom/${id}`, config);
        dispatch({
            type: DELETE_UOM,
        });
    } catch (error) {
        dispatch({
            type: DELETE_UOM_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const archiveUOM = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ARCHIVE_UOM_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.put(`/api/v1/inventory/uom/archive/${id}`, null, config);
        dispatch({
            type: ARCHIVE_UOM,
        });
    } catch (error) {
        dispatch({
            type: ARCHIVE_UOM_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const createCategory = (category) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_CATEGORY_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/inventory/category`, category, config);
        dispatch({
            type: CREATE_CATEGORY,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_CATEGORY_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getCategories = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_CATEGORIES_REQUEST,
            });
            
            const URL = `/api/v1/inventory/category`;
            const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_CATEGORIES,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_CATEGORIES_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }   
};


export const getCategory = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_CATEGORY_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/inventory/category/${id}`, config);
        dispatch({
            type: GET_CATEGORY,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_CATEGORY_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const updateCategory = (category) => async (dispatch) => {
   
    try {
        dispatch({
            type: UPDATE_CATEGORY_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/inventory/category/${category.id}`, category, config);
        dispatch({
            type: UPDATE_CATEGORY,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_CATEGORY_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                    ? error.request.data.error
                    : error.message,
        });
    }
}

export const deleteCategory = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_CATEGORY_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/inventory/category/${id}`, config);
        dispatch({
            type: DELETE_CATEGORY,
        });
    } catch (error) {
        dispatch({
            type: DELETE_CATEGORY_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const archiveCategory = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ARCHIVE_CATEGORY_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.put(`/api/v1/inventory/category/archive/${id}`, null, config);
        dispatch({
            type: ARCHIVE_CATEGORY,
        });
    } catch (error) {
        dispatch({
            type: ARCHIVE_CATEGORY_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const createStore = (store) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_STORE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/inventory/store`, store, config);
        dispatch({
            type: CREATE_STORE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_STORE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getStores = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_STORES_REQUEST,
            });
            
            const URL = `/api/v1/inventory/store`;
            const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_STORES,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_STORES_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }   
};


export const getStore = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_STORE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/inventory/store/${id}`, config);
        dispatch({
            type: GET_STORE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_STORE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const updateStore = (store) => async (dispatch) => {
   
    try {
        dispatch({
            type: UPDATE_STORE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/inventory/store/${store.id}`, store, config);
        dispatch({
            type: UPDATE_STORE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_STORE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                    ? error.request.data.error
                    : error.message,
        });
    }
}

export const deleteStore = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_STORE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/inventory/store/${id}`, config);
        dispatch({
            type: DELETE_STORE,
        });
    } catch (error) {
        dispatch({
            type: DELETE_STORE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const archiveStore = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ARCHIVE_STORE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.put(`/api/v1/inventory/store/archive/${id}`, null, config);
        dispatch({
            type: ARCHIVE_STORE,
        });
    } catch (error) {
        dispatch({
            type: ARCHIVE_STORE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const createSupplier = (supplier) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_SUPPLIER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/inventory/supplier`, supplier, config);
        dispatch({
            type: CREATE_SUPPLIER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_SUPPLIER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getSuppliers = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_SUPPLIERS_REQUEST,
            });
            
            const URL = `/api/v1/inventory/supplier`;
            const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_SUPPLIERS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SUPPLIERS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }   
};


export const getSupplier = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_SUPPLIER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/inventory/supplier/${id}`, config);
        dispatch({
            type: GET_SUPPLIER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SUPPLIER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const updateSupplier = (supplier) => async (dispatch) => {
   
    try {
        dispatch({
            type: UPDATE_SUPPLIER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/inventory/supplier/${supplier.id}`, supplier, config);
        dispatch({
            type: UPDATE_SUPPLIER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_SUPPLIER_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                    ? error.request.data.error
                    : error.message,
        });
    }
}

export const deleteSupplier = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_SUPPLIER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/inventory/supplier/${id}`, config);
        dispatch({
            type: DELETE_SUPPLIER,
        });
    } catch (error) {
        dispatch({
            type: DELETE_SUPPLIER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const archiveSupplier = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ARCHIVE_SUPPLIER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.put(`/api/v1/inventory/supplier/archive/${id}`, null, config);
        dispatch({
            type: ARCHIVE_SUPPLIER,
        });
    } catch (error) {
        dispatch({
            type: ARCHIVE_SUPPLIER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const createItem = (item) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_ITEM_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/inventory/item`, item, config);
        dispatch({
            type: CREATE_ITEM,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_ITEM_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getItems = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_ITEMS_REQUEST,
            });
            
            const URL = `/api/v1/inventory/item`;
            const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_ITEMS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_ITEMS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }   
};


export const getItem = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_ITEM_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/inventory/item/${id}`, config);
        dispatch({
            type: GET_ITEM,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_ITEM_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const updateItem = (item) => async (dispatch) => {
   
    try {
        dispatch({
            type: UPDATE_ITEM_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/inventory/item/${item.id}`, item, config);
        dispatch({
            type: UPDATE_ITEM,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_ITEM_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                    ? error.request.data.error
                    : error.message,
        });
    }
}

export const deleteItem = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_ITEM_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/inventory/item/${id}`, config);
        dispatch({
            type: DELETE_ITEM,
        });
    } catch (error) {
        dispatch({
            type: DELETE_ITEM_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const archiveItem = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ARCHIVE_ITEM_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.put(`/api/v1/inventory/item/archive/${id}`, null, config);
        dispatch({
            type: ARCHIVE_ITEM,
        });
    } catch (error) {
        dispatch({
            type: ARCHIVE_ITEM_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const createQuant = (quant) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_QUANT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/inventory/quant`, quant, config);
        dispatch({
            type: CREATE_QUANT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_QUANT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getQuants = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_QUANTS_REQUEST,
            });
            
            const URL = `/api/v1/inventory/quant`;
            const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_QUANTS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_QUANTS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }   
};


export const getQuant = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_QUANT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/inventory/quant/${id}`, config);
        dispatch({
            type: GET_QUANT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_QUANT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const updateQuant = (quant) => async (dispatch) => {
   
    try {
        dispatch({
            type: UPDATE_QUANT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/inventory/quant/${quant.id}`, quant, config);
        dispatch({
            type: UPDATE_QUANT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_QUANT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                    ? error.request.data.error
                    : error.message,
        });
    }
}

export const createMove = (move) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_MOVE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/inventory/move`, move, config);
        dispatch({
            type: CREATE_MOVE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_MOVE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const updateMove = (move) => async (dispatch) => {
   
    try {
        dispatch({
            type: UPDATE_MOVE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/inventory/move/${move.id}`, move, config);
        dispatch({
            type: UPDATE_MOVE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_MOVE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                    ? error.request.data.error
                    : error.message,
        });
    }
}

export const submitMove = (move) => async (dispatch) => {
    
    try {
        dispatch({
            type: SUBMIT_MOVE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/inventory/move/submit`, move, config);
        dispatch({
            type: SUBMIT_MOVE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUBMIT_MOVE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const declineMove = (move) => async (dispatch) => {
    console.log(move);
    try {
        dispatch({
            type: DECLINE_MOVE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/inventory/move/decline/${move.id}`, move, config);
        dispatch({
            type: DECLINE_MOVE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DECLINE_MOVE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}

export const approveMove = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: APPROVE_MOVE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/inventory/move/approve/${id}`, null, config);
        dispatch({
            type: APPROVE_MOVE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: APPROVE_MOVE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getMoves = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_MOVES_REQUEST,
            });
            
            const URL = `/api/v1/inventory/moves`;
            const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_MOVES,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_MOVES_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }   
};


export const getMove = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_MOVE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/inventory/move/${id}`, config);
        dispatch({
            type: GET_MOVE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_MOVE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getMovesByUser = () => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_MOVE_BY_USER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/inventory/moves/user`, config);
        dispatch({
            type: GET_MOVE_BY_USER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_MOVE_BY_USER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const getMovesByLocation = (id) => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_MOVE_BY_LOCATION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/inventory/moves/location/${id}`, config);
        dispatch({
            type: GET_MOVE_BY_LOCATION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_MOVE_BY_LOCATION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getMovesByItem = (id) => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_MOVE_BY_ITEM_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/inventory/moves/item/${id}`, config);
        dispatch({
            type: GET_MOVE_BY_ITEM,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_MOVE_BY_ITEM_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const deleteMove = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_MOVE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/inventory/move/${id}`, config);
        dispatch({
            type: DELETE_MOVE,
        });
    } catch (error) {
        dispatch({
            type: DELETE_MOVE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}