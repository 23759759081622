import {
    CREATE_FLEET,
    CREATE_FLEET_REQUEST,
    CREATE_FLEET_FAIL,
    GET_FLEET,
    GET_FLEET_REQUEST,
    GET_FLEET_FAIL,
    GET_FLEET_BY_SUPERVISOR,
    GET_FLEET_BY_SUPERVISOR_REQUEST,
    GET_FLEET_BY_SUPERVISOR_FAIL,
    GET_FLEET_BY_MANAGER,
    GET_FLEET_BY_MANAGER_REQUEST,
    GET_FLEET_BY_MANAGER_FAIL,
    GET_FLEET_BY_LOCATION,
    GET_FLEET_BY_LOCATION_REQUEST,
    GET_FLEET_BY_LOCATION_FAIL,
    GET_FLEETS,
    GET_FLEETS_REQUEST,
    GET_FLEETS_FAIL,
    GET_ARCHIVED_FLEETS,
    GET_ARCHIVED_FLEETS_REQUEST,
    GET_ARCHIVED_FLEETS_FAIL,
    UPDATE_FLEET,
    UPDATE_FLEET_REQUEST,
    UPDATE_FLEET_FAIL,
    FLEET_LOST,
    FLEET_LOST_REQUEST,
    FLEET_LOST_FAIL,
    FLEET_OUT_OF_SERVICE,
    FLEET_OUT_OF_SERVICE_REQUEST,
    FLEET_OUT_OF_SERVICE_FAIL,
    FLEET_RESTORE,
    FLEET_RESTORE_REQUEST,
    FLEET_RESTORE_FAIL,
    CREATE_SERVICE_REQUEST,
    CREATE_SERVICE,
    CREATE_SERVICE_FAIL,
    DELETE_SERVICE_REQUEST,
    DELETE_SERVICE,
    DELETE_SERVICE_FAIL,
    DELETE_FLEET,
    DELETE_FLEET_REQUEST,
    DELETE_FLEET_FAIL,
    ARCHIVE_FLEET,
    ARCHIVE_FLEET_REQUEST,
    ARCHIVE_FLEET_FAIL,

    CREATE_DAILYINSPECTION,
    CREATE_DAILYINSPECTION_REQUEST,
    CREATE_DAILYINSPECTION_FAIL,
    SUBMIT_DAILYINSPECTION,
    SUBMIT_DAILYINSPECTION_REQUEST,
    SUBMIT_DAILYINSPECTION_FAIL,
    UPDATE_DAILYINSPECTION,
    UPDATE_DAILYINSPECTION_REQUEST,
    UPDATE_DAILYINSPECTION_FAIL,
    SET_DAILYINSPECTION_DRAFT,
    SET_DAILYINSPECTION_DRAFT_REQUEST,
    SET_DAILYINSPECTION_DRAFT_FAIL,
    CREATE_WEEKLYINSPECTION,
    CREATE_WEEKLYINSPECTION_REQUEST,
    CREATE_WEEKLYINSPECTION_FAIL,
    SUBMIT_WEEKLYINSPECTION,
    SUBMIT_WEEKLYINSPECTION_REQUEST,
    SUBMIT_WEEKLYINSPECTION_FAIL,
    UPDATE_WEEKLYINSPECTION,
    UPDATE_WEEKLYINSPECTION_REQUEST,
    UPDATE_WEEKLYINSPECTION_FAIL,
    SET_WEEKLYINSPECTION_DRAFT,
    SET_WEEKLYINSPECTION_DRAFT_REQUEST,
    SET_WEEKLYINSPECTION_DRAFT_FAIL,
    GET_INSPECTION,
    GET_INSPECTION_REQUEST,
    GET_INSPECTION_FAIL,
    GET_INSPECTIONS,
    GET_INSPECTIONS_REQUEST,
    GET_INSPECTIONS_FAIL,
    GET_INSPECTIONS_BY_USER,
    GET_INSPECTIONS_BY_USER_REQUEST,
    GET_INSPECTIONS_BY_USER_FAIL,
    GET_INSPECTIONS_BY_VEHICLE,
    GET_INSPECTIONS_BY_VEHICLE_REQUEST,
    GET_INSPECTIONS_BY_VEHICLE_FAIL,
    GET_INSPECTIONS_BY_LOCATION,
    GET_INSPECTIONS_BY_LOCATION_REQUEST,
    GET_INSPECTIONS_BY_LOCATION_FAIL,
    DELETE_INSPECTION,
    DELETE_INSPECTION_REQUEST,
    DELETE_INSPECTION_FAIL,




} from "../types/vehicleTypes"

const initState = {
    fleet: null,
    fleets: [],
    locFleets: [],
    inspection: null,
    inspections: [],
    inspectionsByUser: [],
    inspectionsByVehicle: [],
    inspectionsByLocation: [],
    error: null,
    loading: false,
  };

  const vehicleReducer = (state = initState, action) => {
    switch (action.type) {
        case CREATE_FLEET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_FLEET:
            return {
                ...state,
                fleet: action.payload,
                loading: false,
            };
        case CREATE_FLEET_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case GET_FLEET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_FLEET:
            return {
                ...state,
                fleet: action.payload.data,
                loading: false,
            };
        case GET_FLEET_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case GET_FLEET_BY_SUPERVISOR_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_FLEET_BY_SUPERVISOR:
            return {
                ...state,
                fleets: action.payload.data,
                loading: false,
            };
        case GET_FLEET_BY_SUPERVISOR_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case GET_FLEET_BY_MANAGER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_FLEET_BY_MANAGER:
            return {
                ...state,
                fleets: action.payload.data,
                loading: false,
            };  
        case GET_FLEET_BY_MANAGER_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case GET_FLEET_BY_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_FLEET_BY_LOCATION:
            return {
                ...state,
                locFleets: action.payload.data,
                loading: false,
            };
        case GET_FLEET_BY_LOCATION_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case GET_FLEETS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_FLEETS:
            return {
                ...state,
                fleets: action.payload.data,
                loading: false,
            };
        case GET_FLEETS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case GET_ARCHIVED_FLEETS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_ARCHIVED_FLEETS:
            return {
                ...state,
                fleets: action.payload.data,
                loading: false,
            };
        case GET_ARCHIVED_FLEETS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UPDATE_FLEET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_FLEET:
            return {
                ...state,
                fleet: action.payload.data,
                loading: false,
            };
        case UPDATE_FLEET_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case FLEET_LOST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FLEET_LOST:
            return {
                ...state,
                fleet: action.payload.data,
                loading: false,
            };
        case FLEET_LOST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case FLEET_OUT_OF_SERVICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FLEET_OUT_OF_SERVICE:
            return {
                ...state,
                fleet: action.payload.data,
                loading: false,
            };
        case FLEET_OUT_OF_SERVICE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case FLEET_RESTORE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FLEET_RESTORE:
            return {
                ...state,
                fleet: action.payload.data,
                loading: false,
            };
        case FLEET_RESTORE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
            
        case CREATE_SERVICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_SERVICE:
            return {
                ...state,
                fleet: action.payload.data,
                loading: false,
            };
        case CREATE_SERVICE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case DELETE_SERVICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_SERVICE:
            return {
                ...state,
                fleet: action.payload.data,
                loading: false,
            };
        case DELETE_SERVICE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case DELETE_FLEET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_FLEET:
            return {
                ...state,
                fleet: action.payload,
                loading: false,
            };
        case DELETE_FLEET_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case ARCHIVE_FLEET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ARCHIVE_FLEET:
            return {
                ...state,
                fleet: action.payload,
                loading: false,
            };
        case ARCHIVE_FLEET_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CREATE_DAILYINSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_DAILYINSPECTION:
            return {
                ...state,
                inspection: action.payload.data,
                loading: false,
            };
        case CREATE_DAILYINSPECTION_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UPDATE_DAILYINSPECTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_DAILYINSPECTION:
            return {
                ...state,
                inspection: action.payload.data,
                loading: false
            }
        case UPDATE_DAILYINSPECTION_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_DAILYINSPECTION_DRAFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_DAILYINSPECTION_DRAFT:
            return {
                ...state,
                inspection: action.payload.data,
                loading: false
            }
        case SET_DAILYINSPECTION_DRAFT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SUBMIT_DAILYINSPECTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SUBMIT_DAILYINSPECTION: 
            return {
                ...state,
                inspection: action.payload.data,
                loading: false
            }
        case SUBMIT_DAILYINSPECTION_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case CREATE_WEEKLYINSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_WEEKLYINSPECTION:
            return {
                ...state,
                inspection: action.payload.data,
                loading: false,
            };
        case CREATE_WEEKLYINSPECTION_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UPDATE_WEEKLYINSPECTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_WEEKLYINSPECTION:
            return {
                ...state,
                inspection: action.payload.data,
                loading: false
            }
        case UPDATE_WEEKLYINSPECTION_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_WEEKLYINSPECTION_DRAFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_WEEKLYINSPECTION_DRAFT:
            return {
                ...state,
                inspection: action.payload.data,
                loading: false
            }
        case SET_WEEKLYINSPECTION_DRAFT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SUBMIT_WEEKLYINSPECTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SUBMIT_WEEKLYINSPECTION: 
            return {
                ...state,
                inspection: action.payload.data,
                loading: false
            }
        case SUBMIT_WEEKLYINSPECTION_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case GET_INSPECTIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_INSPECTIONS:
            return {
                ...state,
                loading: false,
                inspections: action.payload.data
            };
        case GET_INSPECTIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_INSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_INSPECTION:
            return {
                ...state,
                loading: false,
                inspection: action.payload.data,
            };
        case GET_INSPECTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_INSPECTIONS_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_INSPECTIONS_BY_USER:
            return {
                ...state,
                loading: false,
                inspectionsByUser: action.payload.data
            };
        case GET_INSPECTIONS_BY_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_INSPECTIONS_BY_VEHICLE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_INSPECTIONS_BY_VEHICLE:
            return {
                ...state,
                loading: false,
                inspectionsByVehicle: action.payload.data
            };
        case GET_INSPECTIONS_BY_VEHICLE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_INSPECTIONS_BY_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_INSPECTIONS_BY_LOCATION:
            return {
                ...state,
                loading: false,
                inspections: action.payload.data
            };
        case GET_INSPECTIONS_BY_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case DELETE_INSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_INSPECTION:
            return {
                ...state,
                loading: false,
                inspection: action.payload,
            };
        case DELETE_INSPECTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
    };

    export default vehicleReducer;

