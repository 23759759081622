module.exports = {
    CREATE_AWP: "CREATE_AWP",
    CREATE_AWP_REQUEST: "CREATE_AWP_REQUEST",
    CREATE_AWP_FAIL: "CREATE_AWP_FAIL",

    SUBMIT_AWP: "SUBMIT_AWP",
    SUBMIT_AWP_REQUEST: "SUBMIT_AWP_REQUEST",
    SUBMIT_AWP_FAIL: "SUBMIT_AWP_FAIL",

    UPDATE_AWP: "UPDATE_AWP",
    UPDATE_AWP_REQUEST: "UPDATE_AWP_REQUEST",
    UPDATE_AWP_FAIL: "UPDATE_AWP_FAIL",

    SET_AWP_DRAFT: "SET_AWP_DRAFT",
    SET_AWP_DRAFT_REQUEST: "SET_AWP_DRAFT_REQUEST",
    SET_AWP_DRAFT_FAIL: "SET_AWP_DRAFT_FAIL",

    SET_AWP_SERVICED: "SET_AWP_SERVICED",
    SET_AWP_SERVICED_REQUEST: "SET_AWP_SERVICED_REQUEST",
    SET_AWP_SERVICED_FAIL: "SET_AWP_SERVICED_FAIL",

    GET_AWPS: "GET_AWPS",
    GET_AWPS_REQUEST: "GET_AWPS_REQUEST",
    GET_AWPS_FAIL: "GET_AWPS_FAIL",

    GET_AWP: "GET_AWP",
    GET_AWP_REQUEST: "GET_AWP_REQUEST",
    GET_AWP_FAIL: "GET_AWP_FAIL",

    GET_AWPS_BY_USER: "GET_AWPS_BY_USER",
    GET_AWPS_BY_USER_REQUEST: "GET_AWPS_BY_USER_REQUEST",
    GET_AWPS_BY_USER_FAIL: "GET_AWPS_BY_USER_FAIL",

    GET_AWPS_BY_LOCATION: "GET_AWPS_BY_LOCATION",
    GET_AWPS_BY_LOCATION_REQUEST: "GET_AWPS_BY_LOCATION_REQUEST",
    GET_AWPS_BY_LOCATION_FAIL: "GET_AWPS_BY_LOCATION_FAIL",

    DELETE_AWP: "DELETE_AWP",
    DELETE_AWP_REQUEST: "DELETE_AWP_REQUEST",
    DELETE_AWP_FAIL: "DELETE_AWP_FAIL",
}