import {
    CREATE_SCAFFOLD,
    CREATE_SCAFFOLD_REQUEST,
    CREATE_SCAFFOLD_FAIL,
    SUBMIT_SCAFFOLD,
    SUBMIT_SCAFFOLD_REQUEST,
    SUBMIT_SCAFFOLD_FAIL,
    UPDATE_SCAFFOLD,
    UPDATE_SCAFFOLD_REQUEST,
    UPDATE_SCAFFOLD_FAIL,
    SET_SCAFFOLD_DRAFT,
    SET_SCAFFOLD_DRAFT_REQUEST,
    SET_SCAFFOLD_DRAFT_FAIL,
    GET_SCAFFOLD,
    GET_SCAFFOLD_REQUEST,
    GET_SCAFFOLD_FAIL,
    GET_SCAFFOLDS,
    GET_SCAFFOLDS_REQUEST,
    GET_SCAFFOLDS_FAIL,
    GET_SCAFFOLD_BY_USER,
    GET_SCAFFOLD_BY_USER_REQUEST,
    GET_SCAFFOLD_BY_USER_FAIL,
    GET_SCAFFOLD_BY_LOCATION,
    GET_SCAFFOLD_BY_LOCATION_REQUEST,
    GET_SCAFFOLD_BY_LOCATION_FAIL,
    DELETE_SCAFFOLD,
    DELETE_SCAFFOLD_REQUEST,
    DELETE_SCAFFOLD_FAIL,

} from "../types/scaffoldTypes"

const initState = {
    scaffold: null,
    scaffolds: [],
    scaffoldByUser: [],
    error: null,
    loading: false,
  };

  const scaffoldReducer = (state = initState, action) => {
    switch (action.type) {
        case CREATE_SCAFFOLD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_SCAFFOLD:
            return {
                ...state,
                scaffold: action.payload.data,
                loading: false,
            };
        case CREATE_SCAFFOLD_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UPDATE_SCAFFOLD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_SCAFFOLD:
            return {
                ...state,
                scaffold: action.payload.data,
                loading: false
            }
        case UPDATE_SCAFFOLD_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_SCAFFOLD_DRAFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_SCAFFOLD_DRAFT:
            return {
                ...state,
                scaffold: action.payload.data,
                loading: false
            }
        case SET_SCAFFOLD_DRAFT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SUBMIT_SCAFFOLD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SUBMIT_SCAFFOLD: 
            return {
                ...state,
                scaffold: action.payload.data,
                loading: false
            }
        case SUBMIT_SCAFFOLD_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case GET_SCAFFOLDS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SCAFFOLDS:
            return {
                ...state,
                loading: false,
                scaffolds: action.payload.data
            };
        case GET_SCAFFOLDS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_SCAFFOLD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SCAFFOLD:
            return {
                ...state,
                loading: false,
                scaffold: action.payload.data,
            };
        case GET_SCAFFOLD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_SCAFFOLD_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SCAFFOLD_BY_USER:
            return {
                ...state,
                loading: false,
                scaffoldByUser: action.payload.data
            };
        case GET_SCAFFOLD_BY_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_SCAFFOLD_BY_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SCAFFOLD_BY_LOCATION:
            return {
                ...state,
                loading: false,
                scaffolds: action.payload.data
            };
        case GET_SCAFFOLD_BY_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        case DELETE_SCAFFOLD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_SCAFFOLD:
            return {
                ...state,
                loading: false,
                scaffold: action.payload,
            };
        case DELETE_SCAFFOLD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
    };

    export default scaffoldReducer;

