const {
    UPLOAD_TOOLBOX_DOCUMENT,
    UPLOAD_TOOLBOX_DOCUMENT_REQUEST,
    UPLOAD_TOOLBOX_DOCUMENT_FAIL,
    UPLOAD_SAFETY_DOCUMENT,
    UPLOAD_SAFETY_DOCUMENT_REQUEST,
    UPLOAD_SAFETY_DOCUMENT_FAIL,
    UPLOAD_QUALITY_DOCUMENT,
    UPLOAD_QUALITY_DOCUMENT_REQUEST,
    UPLOAD_QUALITY_DOCUMENT_FAIL,
    UPLOAD_EMPLOYEE_DOCUMENT,
    UPLOAD_EMPLOYEE_DOCUMENT_REQUEST,
    UPLOAD_EMPLOYEE_DOCUMENT_FAIL,
    UPLOAD_HSE_DOCUMENT,
    UPLOAD_HSE_DOCUMENT_REQUEST,
    UPLOAD_HSE_DOCUMENT_FAIL,
    GET_DOCUMENTS_BY_TYPE,
    GET_DOCUMENTS_BY_TYPE_REQUEST,
    GET_DOCUMENTS_BY_TYPE_FAIL,
    GET_DOCUMENTS_BY_SUBFOLDER,
    GET_DOCUMENTS_BY_SUBFOLDER_REQUEST,
    GET_DOCUMENTS_BY_SUBFOLDER_FAIL,
    GET_DOCUMENTS_BY_LOCATION,
    GET_DOCUMENTS_BY_LOCATION_REQUEST,
    GET_DOCUMENTS_BY_LOCATION_FAIL,
    GET_DOCUMENTS,
    GET_DOCUMENTS_REQUEST,
    GET_DOCUMENTS_FAIL,
    GET_DOCUMENT,
    GET_DOCUMENT_REQUEST,
    GET_DOCUMENT_FAIL,
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_FAIL,
} = require("../types/documentTypes");

const initState = {
    documents: [],
    document: null,
    error: null,
    loading: false,
};

const documentReducer =  (state = initState, action) => {
    switch (action.type) {
        case GET_DOCUMENTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_DOCUMENTS:
            return {
                ...state,
                loading: false,
                documents: action.payload.data,
            };
        case GET_DOCUMENTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case GET_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_DOCUMENT:
            return {
                ...state,
                loading: false,
                document: action.payload.data,
            };
        case GET_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_DOCUMENTS_BY_TYPE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_DOCUMENTS_BY_TYPE:
            return {
                ...state,
                loading: false,
                documents: action.payload.data,
            };
        case GET_DOCUMENTS_BY_TYPE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_DOCUMENTS_BY_SUBFOLDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_DOCUMENTS_BY_SUBFOLDER:
            return {
                ...state,
                loading: false,
                documents: action.payload.data,
            };
        case GET_DOCUMENTS_BY_SUBFOLDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_DOCUMENTS_BY_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_DOCUMENTS_BY_LOCATION:
            return {
                ...state,
                loading: false,
                documents: action.payload.data,
            };
        case GET_DOCUMENTS_BY_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPLOAD_TOOLBOX_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPLOAD_TOOLBOX_DOCUMENT:
            return {
                ...state,
                loading: false,
                document: action.payload.doc,
            };
        case UPLOAD_TOOLBOX_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case UPLOAD_SAFETY_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPLOAD_SAFETY_DOCUMENT:
            return {
                ...state,
                loading: false,
                document: action.payload.doc,
            };
        case UPLOAD_SAFETY_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case UPLOAD_QUALITY_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UPLOAD_QUALITY_DOCUMENT:
            return {
                ...state,
                loading: false,
                document: action.payload.doc,
            };
        case UPLOAD_QUALITY_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPLOAD_EMPLOYEE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPLOAD_EMPLOYEE_DOCUMENT:
            return {
                ...state,
                loading: false,
                document: action.payload.doc,
            };
        case UPLOAD_EMPLOYEE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case UPLOAD_HSE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPLOAD_HSE_DOCUMENT:
            return {
                ...state,
                loading: false,
                document: action.payload.doc,
            };
        case UPLOAD_HSE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
    
        case DELETE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_DOCUMENT:
            return {
                ...state,
                loading: false,
                document: action.payload.data,
            };
        case DELETE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default documentReducer;

