import {
    CREATE_UOM,
    CREATE_UOM_REQUEST,
    CREATE_UOM_FAIL,
    GET_UOM,
    GET_UOM_REQUEST,
    GET_UOM_FAIL,
    GET_UOMS,
    GET_UOMS_REQUEST,
    GET_UOMS_FAIL,
    UPDATE_UOM,
    UPDATE_UOM_REQUEST,
    UPDATE_UOM_FAIL,
    DELETE_UOM,
    DELETE_UOM_REQUEST,
    DELETE_UOM_FAIL,
    ARCHIVE_UOM,
    ARCHIVE_UOM_REQUEST,
    ARCHIVE_UOM_FAIL,

    CREATE_CATEGORY,
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_FAIL,
    GET_CATEGORY,
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_FAIL,
    GET_CATEGORIES,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_FAIL,
    UPDATE_CATEGORY,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_FAIL,
    DELETE_CATEGORY,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_FAIL,
    ARCHIVE_CATEGORY,
    ARCHIVE_CATEGORY_REQUEST,
    ARCHIVE_CATEGORY_FAIL,

    CREATE_STORE,
    CREATE_STORE_REQUEST,
    CREATE_STORE_FAIL,
    GET_STORE,
    GET_STORE_REQUEST,
    GET_STORE_FAIL,
    GET_STORES,
    GET_STORES_REQUEST,
    GET_STORES_FAIL,
    UPDATE_STORE,
    UPDATE_STORE_REQUEST,
    UPDATE_STORE_FAIL,
    DELETE_STORE,
    DELETE_STORE_REQUEST,
    DELETE_STORE_FAIL,
    ARCHIVE_STORE,
    ARCHIVE_STORE_REQUEST,
    ARCHIVE_STORE_FAIL,

    CREATE_SUPPLIER,
    CREATE_SUPPLIER_REQUEST,
    CREATE_SUPPLIER_FAIL,
    GET_SUPPLIER,
    GET_SUPPLIER_REQUEST,
    GET_SUPPLIER_FAIL,
    GET_SUPPLIERS,
    GET_SUPPLIERS_REQUEST,
    GET_SUPPLIERS_FAIL,
    UPDATE_SUPPLIER,
    UPDATE_SUPPLIER_REQUEST,
    UPDATE_SUPPLIER_FAIL,
    DELETE_SUPPLIER,
    DELETE_SUPPLIER_REQUEST,
    DELETE_SUPPLIER_FAIL,
    ARCHIVE_SUPPLIER,
    ARCHIVE_SUPPLIER_REQUEST,
    ARCHIVE_SUPPLIER_FAIL,

    CREATE_ITEM,
    CREATE_ITEM_REQUEST,
    CREATE_ITEM_FAIL,
    GET_ITEM,
    GET_ITEM_REQUEST,
    GET_ITEM_FAIL,
    GET_ITEMS,
    GET_ITEMS_REQUEST,
    GET_ITEMS_FAIL,
    UPDATE_ITEM,
    UPDATE_ITEM_REQUEST,
    UPDATE_ITEM_FAIL,
    DELETE_ITEM,
    DELETE_ITEM_REQUEST,
    DELETE_ITEM_FAIL,
    ARCHIVE_ITEM,
    ARCHIVE_ITEM_REQUEST,
    ARCHIVE_ITEM_FAIL,

    CREATE_QUANT,
    CREATE_QUANT_REQUEST,
    CREATE_QUANT_FAIL,
    GET_QUANT,
    GET_QUANT_REQUEST,
    GET_QUANT_FAIL,
    GET_QUANTS,
    GET_QUANTS_REQUEST,
    GET_QUANTS_FAIL,
    UPDATE_QUANT,
    UPDATE_QUANT_REQUEST,
    UPDATE_QUANT_FAIL,

    CREATE_MOVE,
    CREATE_MOVE_REQUEST,
    CREATE_MOVE_FAIL,
    SUBMIT_MOVE,
    SUBMIT_MOVE_REQUEST,
    SUBMIT_MOVE_FAIL,
    APPROVE_MOVE,
    APPROVE_MOVE_REQUEST,
    APPROVE_MOVE_FAIL,
    DECLINE_MOVE,
    DECLINE_MOVE_REQUEST,
    DECLINE_MOVE_FAIL,
    GET_MOVE,
    GET_MOVE_REQUEST,
    GET_MOVE_FAIL,
    GET_MOVES,
    GET_MOVES_REQUEST,
    GET_MOVES_FAIL,
    GET_MOVE_BY_USER,
    GET_MOVE_BY_USER_REQUEST,
    GET_MOVE_BY_USER_FAIL,
    GET_MOVE_BY_LOCATION,
    GET_MOVE_BY_LOCATION_REQUEST,
    GET_MOVE_BY_LOCATION_FAIL,
    GET_MOVE_BY_ITEM,
    GET_MOVE_BY_ITEM_REQUEST,
    GET_MOVE_BY_ITEM_FAIL,
    UPDATE_MOVE,
    UPDATE_MOVE_REQUEST,
    UPDATE_MOVE_FAIL,
    DELETE_MOVE,
    DELETE_MOVE_REQUEST,
    DELETE_MOVE_FAIL,

} 

from '../types/inventoryTypes';

const initState = {
    uom: null,
    uoms: [],
    category: null,
    categories: [],
    store: null,
    stores: [],
    supplier: null,
    suppliers: [],
    item: null,
    items: [],
    quant: null,
    quants: [],
    move: null,
    moves: [],
    moveByUser: [],
    moveByItem: [],
    error: null,
    loading: false,
  };

const inventoryReducer = (state = initState, action) => {
    switch (action.type) {

        case CREATE_UOM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_UOM:
            return {
                ...state,
                loading: false,
                uom: action.payload,
            };
        case CREATE_UOM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_UOM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_UOM:
            return {
                ...state,
                loading: false,
                uom: action.payload.data,
            };
        case GET_UOM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_UOMS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_UOMS:
            return {
                ...state,
                loading: false,
                uoms: action.payload.data
            };
        case GET_UOMS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_UOM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_UOM:
            return {
                ...state,
                loading: false,
                uom: action.payload.data,
            };
        case UPDATE_UOM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DELETE_UOM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_UOM:
            return {
                ...state,
                loading: false,
                uom: action.payload,
            };
        case DELETE_UOM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case ARCHIVE_UOM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ARCHIVE_UOM:
            return {
                ...state,
                loading: false,
                uom: action.payload,
            };
        case ARCHIVE_UOM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CREATE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_CATEGORY:
            return {
                ...state,
                loading: false,
                category: action.payload,
            };
        case CREATE_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CATEGORY:
            return {
                ...state,
                loading: false,
                category: action.payload.data,
            };
        case GET_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CATEGORIES:
            return {
                ...state,
                loading: false,
                categories: action.payload.data
            };
        case GET_CATEGORIES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_CATEGORY:
            return {
                ...state,
                loading: false,
                category: action.payload.data,
            };
        case UPDATE_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DELETE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_CATEGORY:
            return {
                ...state,
                loading: false,
                category: action.payload,
            };
        case DELETE_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case ARCHIVE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ARCHIVE_CATEGORY:
            return {
                ...state,
                loading: false,
                category: action.payload,
            };
        case ARCHIVE_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case CREATE_STORE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_STORE:
            return {
                ...state,
                loading: false,
                store: action.payload,
            };
        case CREATE_STORE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_STORE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_STORE:
            return {
                ...state,
                loading: false,
                store: action.payload.data,
            };
        case GET_STORE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_STORES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_STORES:
            return {
                ...state,
                loading: false,
                stores: action.payload.data
            };
        case GET_STORES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_STORE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_STORE:
            return {
                ...state,
                loading: false,
                store: action.payload.data,
            };
        case UPDATE_STORE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DELETE_STORE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_STORE:
            return {
                ...state,
                loading: false,
                store: action.payload,
            };
        case DELETE_STORE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case ARCHIVE_STORE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ARCHIVE_STORE:
            return {
                ...state,
                loading: false,
                store: action.payload,
            };
        case ARCHIVE_STORE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
    
        case CREATE_SUPPLIER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_SUPPLIER:
            return {
                ...state,
                loading: false,
                supplier: action.payload,
            };
        case CREATE_SUPPLIER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_SUPPLIER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SUPPLIER:
            return {
                ...state,
                loading: false,
                supplier: action.payload.data,
            };
        case GET_SUPPLIER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_SUPPLIERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SUPPLIERS:
            return {
                ...state,
                loading: false,
                suppliers: action.payload.data
            };
        case GET_SUPPLIERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_SUPPLIER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_SUPPLIER:
            return {
                ...state,
                loading: false,
                supplier: action.payload.data,
            };
        case UPDATE_SUPPLIER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DELETE_SUPPLIER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_SUPPLIER:
            return {
                ...state,
                loading: false,
                supplier: action.payload,
            };
        case DELETE_SUPPLIER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case ARCHIVE_SUPPLIER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ARCHIVE_SUPPLIER:
            return {
                ...state,
                loading: false,
                supplier: action.payload,
            };
        case ARCHIVE_SUPPLIER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
            
        case CREATE_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_ITEM:
            return {
                ...state,
                loading: false,
                item: action.payload,
            };
        case CREATE_ITEM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_ITEM:
            return {
                ...state,
                loading: false,
                item: action.payload.data,
            };
        case GET_ITEM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_ITEMS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_ITEMS:
            return {
                ...state,
                loading: false,
                items: action.payload.data
            };
        case GET_ITEMS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_ITEM:
            return {
                ...state,
                loading: false,
                item: action.payload.data,
            };
        case UPDATE_ITEM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DELETE_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_ITEM:
            return {
                ...state,
                loading: false,
                item: action.payload,
            };
        case DELETE_ITEM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case ARCHIVE_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ARCHIVE_ITEM:
            return {
                ...state,
                loading: false,
                item: action.payload,
            };
        case ARCHIVE_ITEM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };   
        
        case CREATE_QUANT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_QUANT:
            return {
                ...state,
                loading: false,
                quant: action.payload,
            };
        case CREATE_QUANT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_QUANT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_QUANT:
            return {
                ...state,
                loading: false,
                quant: action.payload.data,
            };
        case GET_QUANT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_QUANTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_QUANTS:
            return {
                ...state,
                loading: false,
                quants: action.payload.data
            };
        case GET_QUANTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_QUANT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_QUANT:
            return {
                ...state,
                loading: false,
                quant: action.payload.data,
            };
        case UPDATE_QUANT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CREATE_MOVE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_MOVE:
            return {
                ...state,
                loading: false,
                move: action.payload,
            };
        case CREATE_MOVE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case UPDATE_MOVE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_MOVE:
            return {
                ...state,
                loading: false,
                move: action.payload.data,
            };
        case UPDATE_MOVE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case SUBMIT_MOVE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SUBMIT_MOVE: 
            return {
                ...state,
                move: action.payload.data,
                loading: false
            }
        case SUBMIT_MOVE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case APPROVE_MOVE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case APPROVE_MOVE: 
            return {
                ...state,
                move: action.payload.data,
                loading: false
            }
        case APPROVE_MOVE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case DECLINE_MOVE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DECLINE_MOVE: 
            return {
                ...state,
                move: action.payload.data,
                loading: false
            }
        case DECLINE_MOVE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case GET_MOVE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_MOVE:
            return {
                ...state,
                loading: false,
                move: action.payload.data,
            };
        case GET_MOVE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_MOVES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_MOVES:
            return {
                ...state,
                loading: false,
                moves: action.payload.data
            };
        case GET_MOVES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case GET_MOVE_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_MOVE_BY_USER:
            return {
                ...state,
                loading: false,
                moveByUser: action.payload.data
            };
        case GET_MOVE_BY_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_MOVE_BY_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_MOVE_BY_LOCATION:
            return {
                ...state,
                loading: false,
                moves: action.payload.data
            };
        case GET_MOVE_BY_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_MOVE_BY_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_MOVE_BY_ITEM:
            return {
                ...state,
                loading: false,
                moveByItem: action.payload.data
            };
        case GET_MOVE_BY_ITEM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DELETE_MOVE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_MOVE:
            return {
                ...state,
                loading: false,
                move: action.payload,
            };
        case DELETE_MOVE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
        
    }    
}

export default inventoryReducer;