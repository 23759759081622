module.exports = {
    CREATE_CRANE: "CREATE_CRANE",
    CREATE_CRANE_REQUEST: "CREATE_CRANE_REQUEST",
    CREATE_CRANE_FAIL: "CREATE_CRANE_FAIL",

    SUBMIT_CRANE: "SUBMIT_CRANE",
    SUBMIT_CRANE_REQUEST: "SUBMIT_CRANE_REQUEST",
    SUBMIT_CRANE_FAIL: "SUBMIT_CRANE_FAIL",

    UPDATE_CRANE: "UPDATE_CRANE",
    UPDATE_CRANE_REQUEST: "UPDATE_CRANE_REQUEST",
    UPDATE_CRANE_FAIL: "UPDATE_CRANE_FAIL",

    SET_CRANE_DRAFT: "SET_CRANE_DRAFT",
    SET_CRANE_DRAFT_REQUEST: "SET_CRANE_DRAFT_REQUEST",
    SET_CRANE_DRAFT_FAIL: "SET_CRANE_DRAFT_FAIL",

    SET_CRANE_SERVICED: "SET_CRANE_SERVICED",
    SET_CRANE_SERVICED_REQUEST: "SET_CRANE_SERVICED_REQUEST",
    SET_CRANE_SERVICED_FAIL: "SET_CRANE_SERVICED_FAIL",

    GET_CRANES: "GET_CRANES",
    GET_CRANES_REQUEST: "GET_CRANES_REQUEST",
    GET_CRANES_FAIL: "GET_CRANES_FAIL",

    GET_CRANE: "GET_CRANE",
    GET_CRANE_REQUEST: "GET_CRANE_REQUEST",
    GET_CRANE_FAIL: "GET_CRANE_FAIL",

    GET_CRANES_BY_USER: "GET_CRANES_BY_USER",
    GET_CRANES_BY_USER_REQUEST: "GET_CRANES_BY_USER_REQUEST",
    GET_CRANES_BY_USER_FAIL: "GET_CRANES_BY_USER_FAIL",

    GET_CRANES_BY_LOCATION: "GET_CRANES_BY_LOCATION",
    GET_CRANES_BY_LOCATION_REQUEST: "GET_CRANES_BY_LOCATION_REQUEST",
    GET_CRANES_BY_LOCATION_FAIL: "GET_CRANES_BY_LOCATION_FAIL",

    DELETE_CRANE: "DELETE_CRANE",
    DELETE_CRANE_REQUEST: "DELETE_CRANE_REQUEST",
    DELETE_CRANE_FAIL: "DELETE_CRANE_FAIL",
}