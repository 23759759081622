import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogContent,
    makeStyles,
    Tooltip,
    Button,
    Grid,
    Typography,
    DialogTitle,
    Divider,
    TextField,
    FormControl,
    IconButton,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { createFleet, getFleets} from "../../../actions/vehicleAction"
import { getUsers, getLocations } from "../../../actions/usersAction";
import Feedback from "../../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },
  dialog: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
},
formControl: {
    minWidth: 410,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    },
},
formcontrol2: {
    textAlign: "left",
    minWidth: 410,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    },
    },
paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
},
[theme.breakpoints.down("sm")]: {
    width: "100%",
},
[theme.breakpoints.down("xs")]: {
    minWidth: "100%",
},
fab: {
    right: theme.spacing(-1),
},

}));

const INIT_STATE = {
    name: "",
    supervisor: undefined,
    manager: undefined,
    year: "",
    driver: "",
    registeredTo: "",
    serialNo: "",
    licencePlate: "",
    expiryDate: "",
    gvwrGreater: false,
    gvwr: "",
    inspectionExpiry: "",
    tireSize: "",
    tracker: false,
    trackerId: "",
    mileage: "",
    lastService: "",
    nextService: "",
    location: undefined,
    comment: ""
};

const CreateFleetModal = ({ openCreate, handleCloseCreate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, loading } = useSelector((state) => state.vehicle);
    const { users, locations} = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getLocations());
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));

  };


  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        name: state.name,
        supervisor: state.supervisor,
        manager: state.manager,
        year: state.year,
        driver: state.driver,
        registeredTo: state.registeredTo,
        serialNo: state.serialNo,
        licencePlate: state.licencePlate,
        expiryDate: state.expiryDate,
        gvwrGreater: state.gvwrGreater,
        gvwr: state.gvwr,
        inspectionExpiry:state.inspectionExpiry,
        tireSize: state.tireSize,
        tracker: state.tracker,
        trackerId: state.trackerId,
        mileage: state.mileage,
        lastService: state.lastService,
        nextService: state.nextService,
        location: state.location,
        comment: state.comment
    };
    dispatch(createFleet(data));

    setTimeout(() => {
      handleCloseCreate();
      dispatch(getFleets());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Fleet Created Successfully.",
        success: true,
      }));
    }, 1500);
  };


  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () => !state.name 

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openCreate}
        onClose={handleCloseCreate}
        fullWidth
        maxWidth= "md"
        className={classes.dialog}

      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Fleet
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseCreate}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  fullWidth
                  variant="outlined"
                  label="Trade Name"
                  onChange={handleChange}
                  value={state.name}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                    <TextField
                        name="year"
                        fullWidth
                        variant="outlined"
                        label="Year"
                        onChange={handleChange}
                        value={state.year}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <Autocomplete 
                            id="supervisor"
                            options={users}
                            getOptionSelected={(option, value) => option._id === value._id}
                            getOptionLabel={(option) => option.displayname} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, supervisor: value }))} 
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Supervisor"/>} 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <Autocomplete 
                            id="manager"
                            options={users}
                            getOptionSelected={(option, value) => option._id === value._id}
                            getOptionLabel={(option) => option.displayname} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, manager: value }))} 
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Manager" />} 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="driver"
                        fullWidth
                        variant="outlined"
                        label="Driver"
                        onChange={handleChange}
                        value={state.driver}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="registeredTo"
                        fullWidth
                        variant="outlined"
                        label="Registered To"
                        onChange={handleChange}
                        value={state.registeredTo}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                    Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={state.location || ""}
                      name="location"
                      onChange={handleChange}
                      label="Location"
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {locations &&
                        locations.map((location) => (
                          <MenuItem key={location._id} value={location._id}>
                            {location.displayname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="serialNo"
                        fullWidth
                        variant="outlined"
                        label="Serial No"
                        onChange={handleChange}
                        value={state.serialNo}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="licencePlate"
                        fullWidth
                        variant="outlined"
                        label="Licence Plate"
                        onChange={handleChange}
                        value={state.licencePlate}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                      name="expiryDate"
                      fullWidth
                      variant="outlined"
                      label=" Expiry Date"
                      onChange={handleChange}
                      value={state.expiryDate}
                      type="date"     
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="tireSize"
                        fullWidth
                        variant="outlined"
                        label="Tire Size"
                        onChange={handleChange}
                        value={state.tireSize}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="mileage"
                        fullWidth
                        variant="outlined"
                        label="Mileage"
                        onChange={handleChange}
                        value={state.mileage}
                    />
                </Grid>

                <Grid item xs={10} sm={5} md={5} >
                    <Typography variant="subtitle2" align="left">
                        Tracker Installed?
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.tracker || false}
                            name="tracker"
                            color="primary"
                            onChange={(e, value) => {
                                e.persist()
                                setState((prevState) =>({ ...prevState, tracker: value }))
                              }}
                        />
                        }
                    />        
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="trackerId"
                        fullWidth
                        variant="outlined"
                        label="Tracker ID"
                        onChange={handleChange}
                        value={state.trackerId}
                        disabled = {!state.tracker}
                    />
                </Grid>
                <Grid item xs={10} sm={5} md={5} >
                    <Typography variant="subtitle2" align="left">
                        GVWR Greater Than 4500 KG?
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.gvwrGreater || false}
                            name="gvwrGreater"
                            color="primary"
                            onChange={(e, value) => {
                                e.persist()
                                setState((prevState) =>({ ...prevState, gvwrGreater: value }))
                              }}
                        />
                        }
                    />        
                </Grid>
                {!state.gvwrGreater && (
                  <Grid item xs={12} sm={6}>
                      <TextField
                          name="gvwr"
                          fullWidth
                          variant="outlined"
                          label="GVWR"
                          onChange={handleChange}
                          value={state.gvwr}
                      />
                  </Grid>
                )}
                {state.gvwrGreater && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="inspectionExpiry"
                        fullWidth
                        variant="outlined"
                        label="Commercial Inspection Expiry"
                        onChange={handleChange}
                        value={state.inspectionExpiry}
                        type="date"     
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                <TextField
                      name="lastService"
                      fullWidth
                      variant="outlined"
                      label=" Last Service Date"
                      onChange={handleChange}
                      value={state.lastService}
                      type="date"     
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                      name="nextService"
                      fullWidth
                      variant="outlined"
                      label=" Next Service Due Date"
                      onChange={handleChange}
                      value={state.nextService}
                      type="date"     
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        name="comment"
                        fullWidth
                        multiline
                        variant="outlined"
                        label="Comment"
                        onChange={handleChange}
                        value={state.comment}
                    />
                </Grid>

            </Grid>
            <Typography align="center">
              <Button
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                type="submit"
                className={classes.btn}
                disabled={isValid() || loading}
              >
                {loading ? "Loading..." : "Save"}
              </Button>
            </Typography>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateFleetModal;
