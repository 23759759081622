import {
    CREATE_EVENT,
    CREATE_EVENT_REQUEST,
    CREATE_EVENT_FAIL,
    GET_EVENT,
    GET_EVENT_REQUEST,
    GET_EVENT_FAIL,
    GET_EVENTS,
    GET_EVENTS_REQUEST,
    GET_EVENTS_FAIL,
    UPDATE_EVENT,
    UPDATE_EVENT_REQUEST,
    UPDATE_EVENT_FAIL,
    DELETE_EVENT,
    DELETE_EVENT_REQUEST,
    DELETE_EVENT_FAIL,

    CREATE_CAUSE,
    CREATE_CAUSE_REQUEST,
    CREATE_CAUSE_FAIL,
    GET_CAUSE,
    GET_CAUSE_REQUEST,
    GET_CAUSE_FAIL,
    GET_CAUSES_REQUEST,
    GET_CAUSES,
    GET_CAUSES_FAIL,
    UPDATE_CAUSE,
    UPDATE_CAUSE_REQUEST,
    UPDATE_CAUSE_FAIL,
    DELETE_CAUSE,
    DELETE_CAUSE_REQUEST,
    DELETE_CAUSE_FAIL,

    CREATE_CONTACT,
    CREATE_CONTACT_REQUEST,
    CREATE_CONTACT_FAIL,
    UPDATE_CONTACT,
    UPDATE_CONTACT_REQUEST,
    UPDATE_CONTACT_FAIL,
    GET_CONTACTS,
    GET_CONTACTS_REQUEST,
    GET_CONTACTS_FAIL,
    GET_CONTACT,
    GET_CONTACT_REQUEST,
    GET_CONTACT_FAIL,
    DELETE_CONTACT,
    DELETE_CONTACT_REQUEST,
    DELETE_CONTACT_FAIL,

    CREATE_INCIDENT,
    CREATE_INCIDENT_REQUEST,
    CREATE_INCIDENT_FAIL,
    GET_INCIDENT,
    GET_INCIDENT_REQUEST,
    GET_INCIDENT_FAIL,
    GET_INCIDENTS,
    GET_INCIDENTS_REQUEST,
    GET_INCIDENTS_FAIL,
    GET_INCIDENTS_BY_USER,
    GET_INCIDENTS_BY_USER_REQUEST,
    GET_INCIDENTS_BY_USER_FAIL,
    UPDATE_INCIDENT,
    UPDATE_INCIDENT_REQUEST,
    UPDATE_INCIDENT_FAIL,
    SUBMIT_INCIDENT,
    SUBMIT_INCIDENT_REQUEST,
    SUBMIT_INCIDENT_FAIL,
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_REQUEST,
    UPLOAD_DOCUMENT_FAIL,
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_FAIL,
    DOWNLOAD_DOCUMENT,
    DOWNLOAD_DOCUMENT_REQUEST,
    DOWNLOAD_DOCUMENT_FAIL,
    SET_INCIDENT_DRAFT,
    SET_INCIDENT_DRAFT_REQUEST,
    SET_INCIDENT_DRAFT_FAIL,
    SET_INCIDENT_CLOSED,
    SET_INCIDENT_CLOSED_REQUEST,
    SET_INCIDENT_CLOSED_FAIL,
    SET_INCIDENT_OPEN,
    SET_INCIDENT_OPEN_REQUEST,
    SET_INCIDENT_OPEN_FAIL,
    DELETE_INCIDENT,
    DELETE_INCIDENT_REQUEST,
    DELETE_INCIDENT_FAIL,


} from "../types/incidentTypes";

const initState = {
    event: null,
    events: [],
    cause: null,
    causes: [],
    contact: null,
    contacts: [],
    incident: null,
    incidents: [],
    incidentsByUser: [],
    error: null,
    loading: false,
  };


  const incidentReducer = (state = initState, action) => {
    switch (action.type) {

        case CREATE_EVENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_EVENT:
            return {
                ...state,
                loading: false,
                event: action.payload,
            };
        case CREATE_EVENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case GET_EVENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_EVENT:
            return {
                ...state,
                loading: false,
                event: action.payload.data,
            };
        case GET_EVENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case GET_EVENTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_EVENTS:
            return {
                ...state,
                loading: false,
                events: action.payload.data
            };
        case GET_EVENTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case UPDATE_EVENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_EVENT:
            return {
                ...state,
                loading: false,
                event: action.payload.data,
            };
        case UPDATE_EVENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DELETE_EVENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_EVENT:
            return {
                ...state,
                loading: false,
                event: action.payload,
            };
        case DELETE_EVENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CREATE_CAUSE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_CAUSE:
            return {
                ...state,
                loading: false,
                cause: action.payload,
            };
        case CREATE_CAUSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case GET_CAUSE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CAUSE:
            return {
                ...state,
                loading: false,
                cause: action.payload.data,
            };
        case GET_CAUSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case GET_CAUSES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CAUSES:
            return {
                ...state,
                loading: false,
                causes: action.payload.data
            };
        case GET_CAUSES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case UPDATE_CAUSE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_CAUSE:
            return {
                ...state,
                loading: false,
                cause: action.payload.data,
            };
        case UPDATE_CAUSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DELETE_CAUSE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_CAUSE:
            return {
                ...state,
                loading: false,
                cause: action.payload,
            };
        case DELETE_CAUSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CREATE_CONTACT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_CONTACT:
            return {
                ...state,
                loading: false,
                contact: action.payload,
            };
        case CREATE_CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case GET_CONTACT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CONTACT:
            return {
                ...state,
                loading: false,
                contact: action.payload.data,
            };
        case GET_CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case GET_CONTACTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CONTACTS:
            return {
                ...state,
                loading: false,
                contacts: action.payload.data
            };
        case GET_CONTACTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case UPDATE_CONTACT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_CONTACT:
            return {
                ...state,
                loading: false,
                contact: action.payload.data,
            };
        case UPDATE_CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DELETE_CONTACT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_CONTACT:
            return {
                ...state,
                loading: false,
                contact: action.payload,
            };
        case DELETE_CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CREATE_INCIDENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_INCIDENT:
            return {
                ...state,
                loading: false,
                incident: action.payload,
            };
        case CREATE_INCIDENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_INCIDENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_INCIDENT:
            return {
                ...state,
                loading: false,
                incident: action.payload.data,
            };
        case GET_INCIDENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_INCIDENTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_INCIDENTS:
            return {
                ...state,
                loading: false,
                incidents: action.payload.data
            };
        case GET_INCIDENTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_INCIDENTS_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_INCIDENTS_BY_USER:
            return {
                ...state,
                loading: false,
                incidentsByUser: action.payload.data
            };
        case GET_INCIDENTS_BY_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_INCIDENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_INCIDENT:
            return {
                ...state,
                loading: false,
                incident: action.payload.data,
            };
        case UPDATE_INCIDENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SUBMIT_INCIDENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SUBMIT_INCIDENT:
            return {
                ...state,
                loading: false,
                incident: action.payload.data,
            };
        case SUBMIT_INCIDENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPLOAD_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPLOAD_DOCUMENT:
            return {
                ...state,
                loading: false,
                incident: action.payload.data,
            };
        case UPLOAD_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case DELETE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_DOCUMENT:
            return {
                ...state,
                loading: false,
                incident: action.payload.data,
            };
        case DELETE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DOWNLOAD_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DOWNLOAD_DOCUMENT:
            return {
                ...state,
                loading: false,
                incident: action.payload.data,
            };
        case DOWNLOAD_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
            
        case SET_INCIDENT_DRAFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_INCIDENT_DRAFT:
            return {
                ...state,
                loading: false,
                incident: action.payload.data,
            };
        case SET_INCIDENT_DRAFT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SET_INCIDENT_CLOSED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_INCIDENT_CLOSED:
            return {
                ...state,
                loading: false,
                incident: action.payload.data,
            };
        case SET_INCIDENT_CLOSED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case SET_INCIDENT_OPEN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_INCIDENT_OPEN:
            return {
                ...state,
                loading: false,
                incident: action.payload.data,
            };
        case SET_INCIDENT_OPEN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DELETE_INCIDENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_INCIDENT:
            return {
                ...state,
                loading: false,
                incident: action.payload,
            };
        case DELETE_INCIDENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        

        default:
            return state;
        
    }    
}


export default incidentReducer;
