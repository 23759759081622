import React from "react";
import {
  ImageList,
  makeStyles,
  Paper,
  Typography,
  ImageListItem,
  Button,
  lighten

} from "@material-ui/core";

import ButtonIcon from "@material-ui/core/Button";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ReportIcon from '@material-ui/icons/Report';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {

    color: lighten(theme.palette.common.black, 0.75),
    display: "block",
    fontSize: 14,
    fontWeight: 900,
  },

  btn: {
    marginBottom: 30,
  },
  paper: {
    padding: theme.spacing(2),
    // height: 120,
  },
  
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  icon: {
    width: 50,
    height: 50,
    fill: "#bdbdbd",
  },

incident: {
    width: 80,
    height: 80,
    fill: "#F4A460",
},

hazard: {
    width: 80,
    height: 80,
    fill: "#FF7F50",
},

  imageListItem: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(-2),
    },
  },
  imageList: { 
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(1),

    },
    },


}));
const Report = () => {
  const classes = useStyles();

  const history = useHistory();

  const route = (path) => {
    history.push(path);
  };
  const { profile } = useSelector((state) => state.users);

  let isAdminGuard = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("admin"))
     : false;

  let isIncidentAdmin = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("incident_admin"))
        : false;

  let isIncidentUser = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("incident_user"))
      : false;

  

  return (
    <div className={classes.root}>
        <Typography variant="overline" gutterBottom className={classes.title}>
            Reporting
        </Typography>
        <Button
            color="primary"
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ChevronLeftIcon />}
            className={classes.btn}
        >
            Back
        </Button>
      <Paper className={classes.paper}>
        
        <div>
            <ImageList rowHeight={160}  cols={4}>
                {(isAdminGuard || isIncidentAdmin || isIncidentUser) && (
                <ImageListItem className={classes.imageListItem}>
                    <ButtonIcon onClick={() => route("/incident/report")}>
                        <ReportIcon className={classes.incident} />
                    </ButtonIcon>
                    <Typography variant="subtitle2">
                        Incident Report
                    </Typography>

                </ImageListItem>

                
                )}   

                {(isAdminGuard || isIncidentAdmin || isIncidentUser) && (
                <ImageListItem className={classes.imageListItem}>
                    <ButtonIcon>
                        <ReportProblemIcon className={classes.hazard} />
                    </ButtonIcon>
                    <Typography variant="subtitle2">
                        Hazard Report
                    </Typography>

                </ImageListItem>

                
                )}        

            </ImageList>

            
        </div>
      </Paper>
     
    </div>
  );
};

export default Report;
