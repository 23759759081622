import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  Paper,
  Box,
  IconButton,
  ButtonGroup,
  InputAdornment
} from "@material-ui/core";

import clsx from "clsx";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';


import CloseIcon from "@material-ui/icons/Close";
import LaunchIcon from '@material-ui/icons/Launch';

import { useDispatch, useSelector } from "react-redux";
import {approveMove, declineMove, getMovesByUser, getMove, getItem, getMoves,} from "../../../actions/inventoryAction"
import EditItemModal from "../Item/EditItemModal";
import {getLocations} from "../../../actions/usersAction";
import Feedback from "../../../utils/Feedback";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },

  btn2:{
    marginBottom: 30,
    marginLeft: 10
  },

  btnGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
  },

  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl2: {
    textAlign: "left",
    minWidth: 400,
    [theme.breakpoints.down("sm")]: {
      minWidth: 340,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },

  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  statusDarkGreen: {
    color: "#4caf50",
    borderColor: "#4caf50",
  },
  statusGreen: {
    color: "#1de9b6",
    borderColor: "#1de9b6",
  },
  statusBlue: {
    color: "#2196f3",
    borderColor: "#2196f3",
  },
  statusOrange: {
    color: "#ff9800",
    borderColor: "#ff9800",
  },
  statusRed: {
    color: "#ef5350",
    borderColor: "#ef5350",
  },

  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },

}));

const INIT_STATE = {
    item: undefined,
    moveType: "",
    date: "",
    quantity: 0,
    supplier: undefined,
    source: undefined,
    destination: undefined,
    PONumber: "",
    shape: "",
    grade: "",
    dimension: "",
    heatNumber: "",
    charpyTemp: "",
    location: undefined,
    note: ""
};

const ViewMoveModal = ({ openViewMove, handleCloseViewMove, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { move, error, loading } = useSelector((state) => state.inventory);
  const { profile } = useSelector((state) => state.users);

  const [openEdit, setOpenEdit] = useState(false);
  const [getId, setGetId] = useState("");


  useEffect(() => {
    dispatch(getMovesByUser());
    dispatch(getLocations());
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    if(id){
      dispatch(getMove(id))
    };
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
        ...prev,
        item: move && move.item?.displayname,
        moveType: move && move.moveType,
        date: move && move.date,
        quantity: move && move.quantity,
        supplier: move && move.supplier?.name,
        source: move && move.source?.name,
        destination: move && move.destination?.name,
        PONumber: move && move.PONumber,
        shape: move && move.shape,
        grade: move && move.grade,
        dimension: move && move.dimension,
        heatNumber: move && move.heatNumber,
        charpyTemp: move && move.charpyTemp,
        location: move && move.location?.displayname,
        note: move && move.note

    }));
  }, [move]);


  let isAdminGuard = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isInventoryAdmin = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("inventory_admin"))
        : false;
    let isInventoryUser = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("inventory_user"))
        : false;

    const printRef = React.useRef()
    const handleDownloadPdf = async () => {
        const element = printRef.current
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');
    
        const pdf = new jsPDF('p', 'mm', 'a4', true);
    
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight()
        const imgWidth = canvas.width
        const imgHeight = canvas.height
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
        const imgX = (pdfWidth - imgWidth * ratio) / 2
        const imgY = 20

        pdf.addImage(data, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
        pdf.save(`Move_${state.item}_${state.date?.split("T")[0]}.pdf`);
        };  

  const handleApprove = (e) => {
    e.preventDefault();
    dispatch(approveMove(id));
    dispatch(getItem(move.item?._id))

    setTimeout(() => {
      handleCloseViewMove()
      dispatch(getMovesByUser());
      dispatch(getMoves())
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Item Move Approved Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleDecline = (e) => {
    e.preventDefault();
    let data = {
        path: "inventory/move/view",
        id
    };
    dispatch(declineMove(data));
    dispatch(getItem(move.item?._id))

    setTimeout(() => {
      handleCloseViewMove()
      dispatch(getMovesByUser());
      dispatch(getMoves())
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Item Move Declined Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleOpenEdit = (id) => {
    setGetId(id);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
        <EditItemModal {...{ openEdit, handleCloseEdit, id: getId }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openViewMove}
        onClose={handleCloseViewMove}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                View Item Move
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseViewMove}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
          
          <ButtonGroup className={classes.btnGroup} variant="outlined">
          {(move && move.status === "Submitted") && (isAdminGuard || isInventoryAdmin) && (
              <Button
                  size="small"
                  className={clsx(classes.btnLink, classes.statusBlue)}
                  color="primary"
                  onClick={handleApprove}
              >
                  Approve
              </Button>
            )}
            {(move && move.status === "Submitted") && (isAdminGuard || isInventoryAdmin) && (
              <Button
                  size="small"
                  className={clsx(classes.btnLink, classes.statusRed)}
                  color="secondary"
                  onClick={handleDecline}
              >
                  Decline
              </Button>
            )}
              {((move && move.status === "Submitted") || (move && move.status === "Approved")) && (isAdminGuard || isInventoryAdmin || isInventoryUser) && (
                <Button
                    size="small"
                    className={clsx(classes.btnLink, classes.statusDarkGreen)}
                    onClick={handleDownloadPdf}
                >
                    Download as PDF
                </Button>
              )}
          
          </ButtonGroup>
      
          
      <br/>
      <Divider />
        
      <DialogContent className={classes.dialogContent}>
        <div ref={printRef}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={12} sm={6}>
                  <TextField
                      name="initiator"
                      fullWidth
                      variant="outlined"
                      label="Initiator"
                      value={profile?.displayname || ""}
                      disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="date"
                      fullWidth
                      variant="outlined"
                      label="Date"
                      value={state.date?.split("T")[0] || ''}
                      disabled  
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="location"
                      fullWidth
                      variant="outlined"
                      label="Location"
                      value={state.location || ''}
                      disabled  
                  />
                </Grid>
                
                <Grid item xs={10} sm={5}>
                    <TextField
                        name="item"
                        fullWidth
                        variant="outlined"
                        label="Item"
                        value={state.item || ''}
                        disabled  
                    />
                </Grid>
              
                <Grid item xs={2} sm={1}>
                    <Tooltip title="View Item">
                        <IconButton
                        color="primary"
                        onClick={() => {
                            handleOpenEdit(move.item?._id);
                        }}
                        >
                        <LaunchIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            
                
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="item"
                      fullWidth
                      variant="outlined"
                      label="Item"
                      value={state.item || ''}
                      disabled  
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="moveType"
                      fullWidth
                      variant="outlined"
                      label="Move Type"
                      value={state.moveType || ''}
                      disabled  
                  />
                </Grid>

                {state.moveType && state.moveType === "Addition" && (
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="supplier"
                            fullWidth
                            variant="outlined"
                            label="Supplier"
                            value={state.supplier || ''}
                            disabled  
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="source"
                      fullWidth
                      variant="outlined"
                      label="Source"
                      value={state.source || ''}
                      disabled  
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="destination"
                      fullWidth
                      variant="outlined"
                      label="Destination"
                      value={state.destination || ''}
                      disabled  
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="quantity"
                        fullWidth
                        variant="outlined"
                        label="Quantity"
                        value={state.quantity || 0}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{move?.uom?.name}</InputAdornment>,
                        }}
                        disabled
                    />
                </Grid>
                {/* <Grid item xs={2} sm={1}>
                <Typography variant="body2">
                    {move?.uom?.name || ""}
                 </Typography>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="PONumber"
                        fullWidth
                        variant="outlined"
                        label="PO Number"
                        disabled
                        value={state.PONumber || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="shape"
                        fullWidth
                        variant="outlined"
                        label="Shape"
                        disabled
                        value={state.shape || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="grade"
                        fullWidth
                        variant="outlined"
                        label="Grade"
                        disabled
                        value={state.grade || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="dimension"
                        fullWidth
                        variant="outlined"
                        label="Dimension"
                        disabled
                        value={state.dimension || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="heatNumber"
                        fullWidth
                        variant="outlined"
                        label="Heat Number"
                        disabled
                        value={state.heatNumber || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="charpyTemp"
                        fullWidth
                        variant="outlined"
                        label="Charpy Test Temperature"
                        disabled
                        value={state.charpyTemp || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="note"
                        multiline
                        fullWidth
                        variant="outlined"
                        label="Comment"
                        disabled
                        value={state.note || ""}
                        />
                </Grid>
   
            </Grid>
  
          </Box>
          </Paper>
        </div>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewMoveModal;
