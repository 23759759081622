import React from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";
import { setUserPassword, getUsers, getUser } from "../../../actions/usersAction";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../../utils/Feedback";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(4
      ),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 20,
    marginBottom: 30,
  },
  typo: {
    lineHeight: 1,
    textTransform: "uppercase",
    fontWeight: 600,
  },
  dialog: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",

    },
  },

}));

const INIT_STATE = {
    password: "",
  };

const SetPasswordModal = ({ openPassword, handleClosePassword, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const [state, setState] = React.useState(INIT_STATE);
  const { loading, error } = useSelector((state) => state.users);

  React.useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);



  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      password: state.password,
      id,
    };
    dispatch(setUserPassword(data));

    setTimeout(() => {
      handleClosePassword();
      dispatch(getUsers());
      dispatch(getUser(id))
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "User Password Set Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.password

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openPassword}
        onClose={handleClosePassword} 
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        className={classes.dialog}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Set User Password
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleClosePassword}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
        <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12} md={12} sm={12}>
                <TextField
                  name="password"
                  fullWidth
                  variant="outlined"
                  label="Password"
                  onChange={handleChange}
                  value={state.password || ""}
                  required
                />
              </Grid>
              
            </Grid>
            <Typography align='center'>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                endIcon={<UpdateIcon />}
                className={classes.btn}
                disabled={isValid() || loading}
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
            </Typography> 
          </form>
                        
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SetPasswordModal;
