module.exports = {
    CREATE_HARNESS: "CREATE_HARNESS",
    CREATE_HARNESS_REQUEST: "CREATE_HARNESS_REQUEST",
    CREATE_HARNESS_FAIL: "CREATE_HARNESS_FAIL",

    SUBMIT_HARNESS: "SUBMIT_HARNESS",
    SUBMIT_HARNESS_REQUEST: "SUBMIT_HARNESS_REQUEST",
    SUBMIT_HARNESS_FAIL: "SUBMIT_HARNESS_FAIL",

    UPDATE_HARNESS: "UPDATE_HARNESS",
    UPDATE_HARNESS_REQUEST: "UPDATE_HARNESS_REQUEST",
    UPDATE_HARNESS_FAIL: "UPDATE_HARNESS_FAIL",

    SET_HARNESS_DRAFT: "SET_HARNESS_DRAFT",
    SET_HARNESS_DRAFT_REQUEST: "SET_HARNESS_DRAFT_REQUEST",
    SET_HARNESS_DRAFT_FAIL: "SET_HARNESS_DRAFT_FAIL",

   
    GET_HARNESSES: "GET_HARNESSES",
    GET_HARNESSES_REQUEST: "GET_HARNESSES_REQUEST",
    GET_HARNESSES_FAIL: "GET_HARNESSES_FAIL",

    GET_HARNESS: "GET_HARNESS",
    GET_HARNESS_REQUEST: "GET_HARNESS_REQUEST",
    GET_HARNESS_FAIL: "GET_HARNESS_FAIL",

    GET_HARNESS_BY_USER: "GET_HARNESS_BY_USER",
    GET_HARNESS_BY_USER_REQUEST: "GET_HARNESS_BY_USER_REQUEST",
    GET_HARNESS_BY_USER_FAIL: "GET_HARNESS_BY_USER_FAIL",

    GET_HARNESS_BY_LOCATION: "GET_HARNESS_BY_LOCATION",
    GET_HARNESS_BY_LOCATION_REQUEST: "GET_HARNESS_BY_LOCATION_REQUEST",
    GET_HARNESS_BY_LOCATION_FAIL: "GET_HARNESS_BY_LOCATION_FAIL",

    DELETE_HARNESS: "DELETE_HARNESS",
    DELETE_HARNESS_REQUEST: "DELETE_HARNESS_REQUEST",
    DELETE_HARNESS_FAIL: "DELETE_HARNESS_FAIL",
}