import axios from "axios";

import {
    GET_DOCUMENTS_REQUEST,
    GET_DOCUMENTS,
    GET_DOCUMENTS_FAIL,
    GET_DOCUMENT_REQUEST,
    GET_DOCUMENT,
    GET_DOCUMENT_FAIL,
    UPLOAD_TOOLBOX_DOCUMENT_REQUEST,
    UPLOAD_TOOLBOX_DOCUMENT,
    UPLOAD_TOOLBOX_DOCUMENT_FAIL,
    UPLOAD_SAFETY_DOCUMENT_REQUEST,
    UPLOAD_SAFETY_DOCUMENT,
    UPLOAD_SAFETY_DOCUMENT_FAIL,
    UPLOAD_QUALITY_DOCUMENT_REQUEST,
    UPLOAD_QUALITY_DOCUMENT,
    UPLOAD_QUALITY_DOCUMENT_FAIL,
    UPLOAD_EMPLOYEE_DOCUMENT_REQUEST,
    UPLOAD_EMPLOYEE_DOCUMENT,
    UPLOAD_EMPLOYEE_DOCUMENT_FAIL,
    UPLOAD_HSE_DOCUMENT_REQUEST,
    UPLOAD_HSE_DOCUMENT,
    UPLOAD_HSE_DOCUMENT_FAIL,
    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_FAIL,
    GET_DOCUMENTS_BY_TYPE_REQUEST,
    GET_DOCUMENTS_BY_TYPE,
    GET_DOCUMENTS_BY_TYPE_FAIL,
    GET_DOCUMENTS_BY_SUBFOLDER_REQUEST,
    GET_DOCUMENTS_BY_SUBFOLDER,
    GET_DOCUMENTS_BY_SUBFOLDER_FAIL,
    GET_DOCUMENTS_BY_LOCATION_REQUEST,
    GET_DOCUMENTS_BY_LOCATION,
    GET_DOCUMENTS_BY_LOCATION_FAIL,

} from "../types/documentTypes";

const token = localStorage.getItem("token:data");

export const getDocuments = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_DOCUMENTS_REQUEST,
            });
            
            const URL = `/api/v1/document`;
            const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_DOCUMENTS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_DOCUMENTS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }   
};

export const getDocumentsByType = (type) => async (dispatch) => {
    try {
        dispatch({
            type: GET_DOCUMENTS_BY_TYPE_REQUEST,
            });
        const URL = `/api/v1/document/type/${type}`;
        const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_DOCUMENTS_BY_TYPE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_DOCUMENTS_BY_TYPE_FAIL,
            payload:
                error.response && error.response.data.error
                  ? error.response.data.error
                  : error.request
                  ? error.request.data.error
                  : error.message,
        });
    }
};

export const getDocumentsBySubfolder = (type, subFolder) => async (dispatch) => {
    try {
        dispatch({
            type: GET_DOCUMENTS_BY_SUBFOLDER_REQUEST,
            });
        const URL = `/api/v1/document/subfolder/${type}/${subFolder}`;
        const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_DOCUMENTS_BY_SUBFOLDER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_DOCUMENTS_BY_SUBFOLDER_FAIL,
            payload:
                error.response && error.response.data.error
                  ? error.response.data.error
                  : error.request
                  ? error.request.data.error
                  : error.message,
        });
    }
};

export const getDocumentsByLocation = (location) => async (dispatch) => {
    try {
        dispatch({
            type: GET_DOCUMENTS_BY_LOCATION_REQUEST,
            });
        const URL = `/api/v1/document/location/${location}`;
        const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_DOCUMENTS_BY_LOCATION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_DOCUMENTS_BY_LOCATION_FAIL,
            payload:
                error.response && error.response.data.error
                  ? error.response.data.error
                  : error.request
                  ? error.request.data.error
                  : error.message,
        });
    }
};


export const getDocument = (id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_DOCUMENT_REQUEST,
      });
      const URL = `/api/v1/document/${id}`;
      const { data } = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: GET_DOCUMENT,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_DOCUMENT_FAIL,
        payload:
          error.response && error.response.data.error
  
            ? error.response.data.error
            : error.request
            ? error.request.data.error
            : error.message,
      });
    }
  };
    
  
export const uploadToolboxDocument = (document) => async (dispatch) => {
    try {
        dispatch({
            type: UPLOAD_TOOLBOX_DOCUMENT_REQUEST,
        });
        const URL = `/api/v1/document/toolbox/upload`;
        const { data } = await axios.post(URL, document, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: UPLOAD_TOOLBOX_DOCUMENT,
            payload: data.doc
        });
    } catch (error) {
        dispatch({
            type: UPLOAD_TOOLBOX_DOCUMENT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const uploadSafetyDocument = (document) => async (dispatch) => {
    try {
        dispatch({
            type: UPLOAD_SAFETY_DOCUMENT_REQUEST,
        });
        const URL = `/api/v1/document/safety/upload`;
        const { data } = await axios.post(URL, document, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: UPLOAD_SAFETY_DOCUMENT,
            payload: data.doc
        });
    } catch (error) {
        dispatch({
            type: UPLOAD_SAFETY_DOCUMENT_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.request
                ? error.request.data.message
                : error.message,
        });
    }
}

export const uploadQualityDocument = (document) => async (dispatch) => {
    try {
        dispatch({
            type: UPLOAD_QUALITY_DOCUMENT_REQUEST,
        });
        const URL = `/api/v1/document/quality/upload`;
        const { data } = await axios.post(URL, document, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: UPLOAD_QUALITY_DOCUMENT,
            payload: data.doc
        });
    } catch (error) {
        dispatch({
            type: UPLOAD_QUALITY_DOCUMENT_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message   
                : error.request
                ? error.request.data.message
                : error.message,
        });
    }
}

export const uploadEmployeeDocument = (document) => async (dispatch) => {
    try {
        dispatch({
            type: UPLOAD_EMPLOYEE_DOCUMENT_REQUEST,
        });
        const URL = `/api/v1/document/employee/upload`;
        const { data } = await axios.post(URL, document, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: UPLOAD_EMPLOYEE_DOCUMENT,
            payload: data.doc
        });
    } catch (error) {
        dispatch({
            type: UPLOAD_EMPLOYEE_DOCUMENT_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.request
                ? error.request.data.message
                : error.message,
        });
    }
}

export const uploadHSEDocument = (document, subFolder) => async (dispatch) => {
    try {
        dispatch({
            type: UPLOAD_HSE_DOCUMENT_REQUEST,
        });
        const URL = `/api/v1/document/hse/upload/${subFolder}`;
        const { data } = await axios.post(URL, document, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: UPLOAD_HSE_DOCUMENT,
            payload: data.doc
        });
    } catch (error) {
        dispatch({
            type: UPLOAD_HSE_DOCUMENT_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.request
                ? error.request.data.message
                : error.message,
        });
    }
}

export const deleteDocument = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_DOCUMENT_REQUEST,
        });
        const URL = `/api/v1/document/${id}`;
        const { data } = await axios.delete(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: DELETE_DOCUMENT,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: DELETE_DOCUMENT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
                });
    }
}

  
