import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  Paper,
  Box,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { createSignout, submitSignout, getSignsByUser, getCrewBySupervisor, getSign, getSignoutByUser} from "../../../actions/toolboxAction";
import {getLocations} from "../../../actions/usersAction";
import EditSignOutModal from "./EditSignOutModal";
import Feedback from "../../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },

  btn2:{
    marginBottom: 30,
    marginLeft: 10
  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 370,
      [theme.breakpoints.down("sm")]: {
        minWidth: 300,
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      },
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 370,
    [theme.breakpoints.down("sm")]: {
      minWidth: 300,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },
  sign : {
    width: 60,
    height: 30,
    [theme.breakpoints.down("sm")]: {
      width: 60,
      height: 30,
    },
    [theme.breakpoints.down("xs")]: {
      width: 40,
      height: 20,
    },
  }


}));

const INIT_STATE = {
    crew: "",
    date: moment().format('YYYY-MM-DD'),
    location: undefined,
    crewInjured: { injured:false, firstAid:false, comment: "" },
    nearMissIncident: { nearMiss:false, comment: "" },
    cleanUp: { clean:false, tools:false, comment: "" },
    workPlanProblem: "",
    tomorrowTool: "",
    tomorrowPlan: "",
    shiftConcern: "",
    crewMembers: [{ name: "", absent: false, signature: "", time: "" }],
};

const CreateSignOutModal = ({ openCreateSignOut, handleCloseCreateSignOut }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, loading, myCrews, sign, signsByUser, signoutByUser } = useSelector((state) => state.toolbox);
  const { profile, locations} = useSelector((state) => state.users);
  const [openEditSignOut, setOpenEditSignOut] = useState(false);
  const [getId, setGetId] = useState("");
  const [actionType, setActionType] = useState("Save");

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getSignsByUser());
    dispatch(getSignoutByUser());
    }, [dispatch]);

  useEffect(() => {
    dispatch(getCrewBySupervisor(profile._id));
  }, [profile._id, dispatch]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      crew: sign && sign.crew,
      location: sign && sign.location,
      crewMembers: sign && sign.crewMembers?.filter(item => item.absent === false).concat(sign && sign.addMembers).map(item => ({...item, signature: "", time: ""}))
    }));
  }, [profile, sign]); 

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    
  };

  const handleOpenEditSignOut = (id) => {
    setGetId(id);
    setOpenEditSignOut(true);
  };

  const handleCloseEditSignOut = () => {
    setOpenEditSignOut(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setActionType("Save");
    let data = {
      crew: state.crew?._id,
      date: state.date,
      location: state.location?._id,
      crewInjured: state.crewInjured,
      nearMissIncident: state.nearMissIncident,
      cleanUp: state.cleanUp,
      workPlanProblem: state.workPlanProblem,
      tomorrowTool: state.tomorrowTool,
      tomorrowPlan: state.tomorrowPlan,
      shiftConcern: state.shiftConcern,
      crewMembers: [...state.crewMembers],
    };
    dispatch(createSignout(data))

    setTimeout(() => {
      handleCloseCreateSignOut()
      dispatch(getSignsByUser());
      dispatch(getSignoutByUser());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Toolbox Saved Successfully.",
        success: true,
      }));
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setActionType("Submit");
    let data = {
      crew: state.crew?._id,
      date: state.date,
      location: state.location._id,
      crewInjured: state.crewInjured,
      nearMissIncident: state.nearMissIncident,
      cleanUp: state.cleanUp,
      workPlanProblem: state.workPlanProblem,
      tomorrowTool: state.tomorrowTool,
      tomorrowPlan: state.tomorrowPlan,
      shiftConcern: state.shiftConcern,
      crewMembers: [...state.crewMembers],

    };
    dispatch(submitSignout(data));
    setTimeout(() => {
      handleCloseCreateSignOut()
      dispatch(getSignsByUser());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Toolbox Signed Off Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
    actionType === "Save" ? handleOpenEditSignOut(signoutByUser[0]._id) : handleCloseCreateSignOut();
  };

  const isValid = () =>
    !state.crew || !state.date || !state.location || !state.crewMembers.length;

  return (
    <div>
      <EditSignOutModal {...{ openEditSignOut, handleCloseEditSignOut, id: getId }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
          duration={1500}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
          duration={1500}
        />
      )}
      <Dialog
        open={openCreateSignOut}
        onClose={handleCloseCreateSignOut}
        fullWidth
        maxWidth="md"
        className={classes.dialog}
      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Toolbox Sign Off
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseCreateSignOut}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
        
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="supervisor"
                      fullWidth
                      variant="outlined"
                      label="Supervisor"
                      value={profile?.displayname || ""}
                      disabled
                      required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                {/* <FormControl className={classes.formControl}>
                    <Autocomplete 
                        id="crew"
                        options={myCrews}
                        getOptionSelected={(option, value) => option._id === value._id}
                        getOptionLabel={(option) => option.name} 
                        onChange={(e, value) => {
                          e.persist();
                          setState((prevState) => (
                            { ...prevState, crew: value, crewMembers:value?.members.map((item, index) => (
                            { name: item.name, absent: false })
                          
                          )}))
                        }}
                        value={state.crew || null}
                        renderInput={(params) => 
                        <TextField {...params} variant="outlined" label="Crew Name" />}
                          
                    />

                </FormControl> */}
                  <TextField
                      name="crew"
                      fullWidth
                      variant="outlined"
                      label="Crew Name"
                      value={state.crew?.name || ""}
                      disabled
                      required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
              
                <TextField
                    name="date"
                    fullWidth
                    variant="outlined"
                    label="Date"
                    onChange={handleChange}
                    value={state.date || moment().format('YYYY-MM-DD')}
                    required
                    type="date"
                    
                />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <TextField
                      name="location"
                      fullWidth
                      variant="outlined"
                      label="Job Location"
                      value={state.location?.displayname || ""}
                      disabled
                      required
                    />
                  
                  {/* <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                    Job Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={state.location || ""}
                      name="location"
                      onChange={handleChange}
                      label="Location"
                      required
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {locations &&
                        locations.map((location) => (
                          <MenuItem key={location._id} value={location._id}>
                            {location.displayname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}

                </Grid>
            </Grid>
            <br />
            
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Was anyone injured today?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.crewInjured.injured || false}
                        name="injured"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, crewInjured: { ...prevState.crewInjured, injured: value }}))
                        }}
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Did anyone go to First Aid today?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.crewInjured.firstAid || false}
                        name="firstAid"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, crewInjured: { ...prevState.crewInjured, firstAid: value }}))
                        }}
                      />
                    }
                />        
              </Grid>
              
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="If Yes, please explain (include who/what)"
                  onChange={(e) => {
                    e.persist()
                    setState((prevState) => ({ ...prevState, crewInjured: { ...prevState.crewInjured, comment: e.target.value }}))
                  }}
                  multiline
                  value={state.crewInjured.comment || ""}
                />
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Did a near miss incident occur today?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.nearMissIncident.nearMiss || false}
                        name="nearMiss"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, nearMissIncident: { ...prevState.nearMissIncident, nearMiss: value }}))
                        }}
                      />
                    }
                />        
              </Grid>
              
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="If yes, describe briefly"
                  onChange={(e) => {
                    e.persist()
                    setState((prevState) => ({ ...prevState, nearMissIncident: { ...prevState.nearMissIncident, comment: e.target.value }}))
                  }}
                  multiline
                  value={state.nearMissIncident.comment || ""}
                />
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Has the work area clean up been done?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.cleanUp.clean || false}
                        name="clean"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, cleanUp: { ...prevState.cleanUp, clean: value }}))
                        }}
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Have tools/equipment been put away?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.cleanUp.tools || false}
                        name="tools"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, cleanUp: { ...prevState.cleanUp, tools: value }}))
                        }}
                      />
                    }
                />        
              </Grid>
              
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="If Yes, please comment"
                  onChange={(e) => {
                    e.persist()
                    setState((prevState) => ({ ...prevState, cleanUp: { ...prevState.cleanUp, comment: e.target.value }}))
                  }}
                  multiline
                  value={state.cleanUp.comment || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} >
              <Typography variant="subtitle2" align="left">
                What problems did we have with today’s work plan?
              </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="workPlanProblem"
                  fullWidth
                  variant="outlined"
                  label="List Issues"
                  onChange={handleChange}
                  multiline
                  value={state.workPlanProblem || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} >
              <Typography variant="subtitle2" align="left">
                What tools, equipment or manpower do we need for tomorrow’s work plan?
              </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="tomorrowTool"
                  fullWidth
                  variant="outlined"
                  label="List Requirements"
                  onChange={handleChange}
                  multiline
                  value={state.tomorrowTool || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} >
              <Typography variant="subtitle2" align="left">
                What is tomorrow’s work plan?
              </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="tomorrowPlan"
                  fullWidth
                  variant="outlined"
                  label="Briefly describe"
                  onChange={handleChange}
                  multiline
                  value={state.tomorrowPlan || ""}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  name="shiftConcern"
                  fullWidth
                  variant="outlined"
                  label="End of Shift Concerns"
                  onChange={handleChange}
                  multiline
                  value={state.shiftConcern || ""}
                />
              </Grid>

              <Paper className={classes.paper} variant="outlined" square>
                    <Typography variant="body1">
                        Crew Members
                    </Typography>
                    <br />
                    <Grid container spacing={3}>
                      {state.crewMembers?.map((current, i) => (
                        i % 2 === 0 ? (
                        <Grid container item xs={12} sm={6} md={6} justifyContent="center" alignItems="center" key={i}>
                          <Grid container alignContent="center" spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    label="Name"
                                    value={current.name}
                                    required
                                    size="small"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                              <img src={current.signature} alt="signature" className={classes.sign}/>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                                <TextField
                                    name="time"
                                    fullWidth
                                    variant="outlined"
                                    label="Time"
                                    value={current.time}
                                    required
                                    size="small"
                                    disabled
                                />
                            </Grid>
                            
                          </Grid>
                        </Grid>
                        ) : (
                        <Grid  container item xs={12} sm={6} md={6} justifyContent="center" alignItems="center" key={i}>
                          <Grid container alignContent="center" spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    label="Name"
                                    value={current.name}
                                    required
                                    size="small"
                                    disabled
                                />
                            </Grid>
                            
                            <Grid item xs={3} sm={3} md={3}>
                              <img src={current.signature} alt="signature" className={classes.sign}/>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                                <TextField
                                    name="time"
                                    fullWidth
                                    variant="outlined"
                                    label="Time"
                                    value={current.time}
                                    required
                                    size="small"
                                    disabled
                                />
                            </Grid>
                            
                          </Grid>
                        </Grid>
                        )
                      ))}
                    </Grid>
                </Paper>
              
            </Grid>
            <br />
        
            <Typography align="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSave}
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save & Sign"}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save & Submit"}
                </Button>
              </Typography>
          </Box>
          </Paper>


        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateSignOutModal;
