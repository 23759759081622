import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  Paper,
  Box,
  IconButton,
  FormControl,
  FormLabel,
  FormControlLabel,
  Button,
  ButtonGroup,
  Radio,
  RadioGroup,
  Checkbox
} from "@material-ui/core";

import clsx from "clsx";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {getInspection, setInspectionDraft, getInspections, getInspectionsByUser} from "../../../actions/scaffoldAction";
import Feedback from "../../../utils/Feedback";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  btnGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
  },
  
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 380,
      [theme.breakpoints.down("sm")]: {
          width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      },
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 350,
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
    color: "#4caf50",
  },
  paper3:{
    textAlign: "center",
    width: "fit-content",
    height: 50,
    display: "flex",
    justifyContent: "center",
    marginTop: 30
  },
  green: {
    color: "#4caf50",
    fontWeight: 500,
    },
  yellow: {
    color: "#FFD700",
    fontWeight: 500
  },
  red: {
    color: "#ef5350",
    fontWeight: 500,
    },
  orange: {
    color: "#F5B041",
    fontWeight: 500,
    },

}));

const INIT_STATE = {
    unitNumber: "",
    inspector: "",
    supervisor: undefined,
    date: "",
    location: undefined,
    colour: "",
    elevation: "",
    height:"",
    length:"",
    width:"",
    loadWeight:"",
    specialLoad:"",
    hoarding:false,
    system:false,
    tube:false,
    bridge:false,
    hanger:false,
    roller:false,
    tower:false,
    cantelever:false,
    frames:false,
    erect:false,
    dismantle:false,
    addition:false,
    modification:false,
    mudSills: "Yes",
    longitudinalBracing: "Yes",
    internalBracing: "Yes",
    horizontalBracing: "Yes",
    checkClamps: "Yes",
    allClamps: "Yes",
    allConnections: "Yes",
    scaffoldSquare: "Yes",
    topRail: "Yes",
    midRail: "Yes",
    toeBoard: "Yes",
    ladder: "Yes",
    ladderSecurely: "Yes",
    ladder3: "Yes",
    ladderCage: "Yes",
    safeAccess: "Yes",
    safeAccessOther: "Yes",
    gate: "Yes",
    crawl: "Yes",
    basePlates: "Yes",
    deckLevel: "Yes",
    deckFully: "Yes",
    transoms: "Yes",
    correctPlank: "Yes",
    rollingScaffold: "Yes",
    anySpan: "Yes",
    screwjack: "Yes",
    noTube: "Yes",
    tieIns: "Yes",
    comment: "",
    error: null,
    success: false,
    loading: false,
};

const ViewInspectionModal = ({ openViewInspection, handleCloseViewInspection, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, scaffold } = useSelector((state) => state.scaffold);
  const [getId, setGetId] = useState("");

  const { profile, loading } = useSelector((state) => state.users);

    let isAdminGuard = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isScaffoldAdmin = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("scaffold_admin"))
        : false;


useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
        setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
        }));
    }
    return () => {
        abortController.abort();
    };
    }, [error]);

  useEffect(() => {
    if (id){
      dispatch(getInspection(id));
    }
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      unitNumber: scaffold && scaffold.unitNumber,
        inspector: scaffold && scaffold.inspector,
        supervisor: scaffold && scaffold.supervisor,
        date: scaffold && scaffold.date,
        location: scaffold && scaffold.location.displayname,
        colour: scaffold && scaffold.colour,
        elevation: scaffold && scaffold.elevation,
        height:scaffold && scaffold.height,
        length:scaffold && scaffold.length,
        width:scaffold && scaffold.width,
        loadWeight:scaffold && scaffold.loadWeight,
        specialLoad:scaffold && scaffold.specialLoad,
        hoarding:scaffold && scaffold.hoarding,
        system:scaffold && scaffold.system,
        tube:scaffold && scaffold.tube,
        bridge:scaffold && scaffold.bridge,
        hanger:scaffold && scaffold.hanger,
        roller:scaffold && scaffold.roller,
        tower:scaffold && scaffold.tower,
        cantelever:scaffold && scaffold.cantelever,
        frames:scaffold && scaffold.frames,
        erect:scaffold && scaffold.erect,
        dismantle:scaffold && scaffold.dismantle,
        addition:scaffold && scaffold.addition,
        modification:scaffold && scaffold.modification,
        mudSills: scaffold && scaffold.mudSills,
        longitudinalBracing: scaffold && scaffold.longitudinalBracing,
        internalBracing: scaffold && scaffold.internalBracing,
        horizontalBracing: scaffold && scaffold.horizontalBracing,
        checkClamps: scaffold && scaffold.checkClamps,
        allClamps: scaffold && scaffold.allClamps,
        allConnections: scaffold && scaffold.allConnections,
        scaffoldSquare: scaffold && scaffold.scaffoldSquare,
        topRail: scaffold && scaffold.topRail,
        midRail: scaffold && scaffold.midRail,
        toeBoard: scaffold && scaffold.toeBoard,
        ladder: scaffold && scaffold.ladder,
        ladderSecurely: scaffold && scaffold.ladderSecurely,
        ladder3: scaffold && scaffold.ladder3,
        ladderCage: scaffold && scaffold.ladderCage,
        safeAccess: scaffold && scaffold.safeAccess,
        safeAccessOther: scaffold && scaffold.safeAccessOther,
        gate: scaffold && scaffold.gate,
        crawl: scaffold && scaffold.crawl,
        basePlates: scaffold && scaffold.basePlates,
        deckLevel: scaffold && scaffold.deckLevel,
        deckFully: scaffold && scaffold.deckFully,
        transoms: scaffold && scaffold.transoms,
        correctPlank: scaffold && scaffold.correctPlank,
        rollingScaffold: scaffold && scaffold.rollingScaffold,
        anySpan: scaffold && scaffold.anySpan,
        screwjack: scaffold && scaffold.screwjack,
        noTube: scaffold && scaffold.noTube,
        tieIns: scaffold && scaffold.tieIns,
        comment: scaffold && scaffold.comment,

    }));
  }, [scaffold]);

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleSetDraft = (e) => {
    e.preventDefault();
    dispatch(setInspectionDraft(id));
    setTimeout(() => {
      handleCloseViewInspection();
        dispatch(getInspections());
        dispatch(getInspectionsByUser());

        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Inspection set to draft successfully!",
          success: true,
        }));
    }, 1500);
  };

  const printRef = React.useRef()
  const handleDownloadPdf = async () => {
    const element = printRef.current
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4', true);
    
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight()
    const imgWidth = canvas.width
    const imgHeight = canvas.height
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
    const imgX = (pdfWidth - imgWidth * ratio) / 2
    const imgY = (pdfHeight - imgHeight * ratio) / 2

    pdf.addImage(data, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    pdf.save(`Scaffold_${state.unitNumber}_${state.date?.split("T")[0]}.pdf`);
    };  

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openViewInspection}
        onClose={handleCloseViewInspection}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                View Scaffold Inspection
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseViewInspection}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        {(scaffold && scaffold.status === "Submitted" ) && (isAdminGuard || isScaffoldAdmin) && (
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={12}>
                <ButtonGroup className={classes.btnGroup} variant="outlined">
                    <Button
                        size="small"
                        className={classes.btnLink}
                        color="primary"
                        onClick={handleSetDraft}
                    >
                        Set to Draft
                    </Button>
                    <Button
                        size="small"
                        className={clsx(classes.btnLink, classes.statusDarkGreen)}
                        onClick={handleDownloadPdf}
                    >
                        Download as PDF
                    </Button>
                
                </ButtonGroup>
              </Grid>
          </Grid>
                
          )}
          <br/>   
          <Divider />

        <DialogContent className={classes.dialogContent}>
        <div ref={printRef}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>

            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="unitNumber"
                      fullWidth
                      variant="outlined"
                      label="Unit Number"
                      value={state.unitNumber || ""}
                      disabled
                    />
              </Grid>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Operator"
                      value={state.inspector || ""}
                      disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="supervisor"
                      fullWidth
                      variant="outlined"
                      label="Supervisor"
                      value={state.supervisor?.displayname || ""}
                      disabled
                    />
                </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                    name="date"
                    fullWidth
                    variant="outlined"
                    label="Date"
                    value={state.date?.split("T")[0] || ''}
                    disabled    
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    name="location"
                    fullWidth
                    variant="outlined"
                    label="Location"
                    value={state.location || ""}
                    disabled    
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="elevation"
                      fullWidth
                      variant="outlined"
                      label="Elevation"
                      value={state.elevation}
                      disabled
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel > Tag Colour</FormLabel>
                        <RadioGroup row name="colour" value={state.colour || ""}>
                            <FormControlLabel value="Green" control={<Radio color="primary"/>} label="Green" disabled/>
                            <FormControlLabel value="Yellow" control={<Radio color="primary"/>} label="Yellow" disabled/>
                            <FormControlLabel value="Red" control={<Radio color="primary"/>} label="Red" disabled/>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                      name="height"
                      fullWidth
                      variant="outlined"
                      label="Total Height"
                      value={state.height}
                      disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="width"
                      fullWidth
                      variant="outlined"
                      label="Total Width"
                      value={state.width}
                      disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="length"
                      fullWidth
                      variant="outlined"
                      label="Total Length"
                      value={state.length}
                      disabled
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                      name="loadWeight"
                      fullWidth
                      variant="outlined"
                      label="Load Weight"
                      value={state.loadWeight}
                      disabled
                    />
                </Grid>
                {state && state.loadWeight === "Special" && (
                    <Grid item xs={12} sm={6}>
                    <TextField
                        name="specialLoad"
                        fullWidth
                        variant="outlined"
                        label="Special Load"
                        value={state.specialLoad}
                        disabled
                        />
                    </Grid>
                )}
            </Grid>

            <Grid item xs={12} sm={12}>
                <br/>
            </Grid>

            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                
                <Grid item xs={4} sm={3} md={3} >
                    <Typography  align="left">
                        Hoarding
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.hoarding || false}
                            name="hoarding"
                            color="primary"
                            disabled
                        />
                        }
                    />        
                </Grid>

                <Grid item xs={4} sm={3} md={3} >
                    <Typography  align="left">
                        System
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.system || false}
                            name="system"
                            color="primary"
                            disabled
                        />
                        }
                    />        
                </Grid>

                <Grid item xs={4} sm={3} md={3} >
                    <Typography  align="left">
                        Tube/Clamp
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.tube || false}
                            name="tube"
                            color="primary"
                            disabled
                        />
                        }
                    />        
                </Grid>
                <Grid item xs={4} sm={3} md={3} >
                    <Typography  align="left">
                        Bridge
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.bridge || false}
                            name="bridge"
                            color="primary"
                            disabled
                        />
                        }
                    />        
                </Grid>
                <Grid item xs={4} sm={3} md={3} >
                    <Typography  align="left">
                        Hanger
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.hanger || false}
                            name="hanger"
                            color="primary"
                           disabled
                        />
                        }
                    />        
                </Grid>
                <Grid item xs={4} sm={3} md={3} >
                    <Typography  align="left">
                        Roller
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.roller || false}
                            name="roller"
                            color="primary"
                            disabled
                        />
                        }
                    />        
                </Grid>
                <Grid item xs={4} sm={3} md={3} >
                    <Typography  align="left">
                        Tower
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.tower || false}
                            name="tower"
                            color="primary"
                            disabled
                        />
                        }
                    />        
                </Grid>
                <Grid item xs={4} sm={3} md={3} >
                    <Typography  align="left">
                        Canterlever
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.cantelever || false}
                            name="cantelever"
                            color="primary"
                            disabled
                        />
                        }
                    />        
                </Grid>
                <Grid item xs={4} sm={3} md={3} >
                    <Typography  align="left">
                        Frames
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.frames || false}
                            name="frames"
                            color="primary"
                            disabled
                        />
                        }
                    />        
                </Grid>
                <Grid item xs={4} sm={3} md={3} >
                    <Typography  align="left">
                        Erect
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.erect || false}
                            name="erect"
                            color="primary"
                           disabled
                        />
                        }
                    />        
                </Grid>
                <Grid item xs={4} sm={3} md={3} >
                    <Typography  align="left">
                        Dismantle
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.dismantle || false}
                            name="dismantle"
                            color="primary"
                            disabled
                        />
                        }
                    />        
                </Grid>
                <Grid item xs={4} sm={3} md={3} >
                    <Typography  align="left">
                        Addition
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.addition || false}
                            name="addition"
                            color="primary"
                            disabled
                        />
                        }
                    />        
                </Grid>
                <Grid item xs={4} sm={3} md={3} >
                    <Typography  align="left">
                        Modification
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.modification || false}
                            name="modification"
                            color="primary"
                            disabled
                        />
                        }
                    />        
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} >
                <br/>
            </Grid>
            
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
        
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Mud Sills in Place and Secure</FormLabel>
                    <RadioGroup row name="mudSills" value={state.mudSills || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Longitudinal Bracing in Place</FormLabel>
                    <RadioGroup row name="longitudinalBracing" value={state.longitudinalBracing || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Internal Bracing in Place</FormLabel>
                    <RadioGroup row name="internalBracing" value={state.internalBracing || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Horizontal Bracing in Place</FormLabel>
                    <RadioGroup row name="horizontalBracing" value={state.horizontalBracing || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Check Clamps in Place in All Areas</FormLabel>
                    <RadioGroup row name="checkClamps" value={state.checkClamps || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >All Clamps Tight</FormLabel>
                    <RadioGroup row name="allClamps" value={state.allClamps || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >All Connections Tight</FormLabel>
                    <RadioGroup row name="allConnections" value={state.allConnections || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Scaffold Square, Straight and Plumb in all Directions</FormLabel>
                    <RadioGroup row name="scaffoldSquare" value={state.scaffoldSquare || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Top Rail in Place and Secured</FormLabel>
                    <RadioGroup row name="topRail" value={state.topRail || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Mid Rail in Place and Secured</FormLabel>
                    <RadioGroup row name="midRail" value={state.midRail || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Toe Board in Place and Secured</FormLabel>
                    <RadioGroup row name="toeBoard" value={state.toeBoard || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Ladder in Place (Broken at 24")</FormLabel>
                    <RadioGroup row name="ladder" value={state.ladder || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Ladder Securely Installed</FormLabel>
                    <RadioGroup row name="ladderSecurely" value={state.ladderSecurely || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Ladder 3" Over Deck</FormLabel>
                    <RadioGroup row name="ladder3" value={state.ladder3 || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Ladder Cage (Deck Over 24")</FormLabel>
                    <RadioGroup row name="ladderCage" value={state.ladderCage || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Safe Access On/Off Ladder</FormLabel>
                    <RadioGroup row name="safeAccess" value={state.safeAccess || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Safe Access Other than Ladder</FormLabel>
                    <RadioGroup row name="safeAccessOther" value={state.safeAccessOther || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Gate in Place</FormLabel>
                    <RadioGroup row name="gate" value={state.gate || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Crawl On</FormLabel>
                    <RadioGroup row name="crawl" value={state.crawl || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Base Plates Tightened and Secured</FormLabel>
                    <RadioGroup row name="basePlates" value={state.basePlates || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Deck Level (No Tripping Hazard)</FormLabel>
                    <RadioGroup row name="deckLevel" value={state.deckLevel || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Deck Fully Planked and Secured</FormLabel>
                    <RadioGroup row name="deckFully" value={state.deckFully || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Transoms Installed Where Necessary</FormLabel>
                    <RadioGroup row name="transoms" value={state.transoms || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Correct Plank Overlap (6"-12")</FormLabel>
                    <RadioGroup row name="correctPlank" value={state.correctPlank || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Rolling Scaffold Brakes in Place (3:1 Ratio)</FormLabel>
                    <RadioGroup row name="rollingScaffold" value={state.rollingScaffold || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Any Span Greater Than 10" or 3m Picked Up</FormLabel>
                    <RadioGroup row name="anySpan" value={state.anySpan || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Screwjack Adjustments Must Not Exceed 12"</FormLabel>
                    <RadioGroup row name="screwjack" value={state.screwjack || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >No Tube or Members Overextended</FormLabel>
                    <RadioGroup row name="noTube" value={state.noTube || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Tie Ins Adequate and Secure</FormLabel>
                    <RadioGroup row name="tieIns" value={state.tieIns || ""}>
                        <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" disabled />
                        <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    name="comment"
                    fullWidth
                    variant="outlined"
                    label="Comments"
                    disabled
                    multiline
                    value={state.comment || ""}
                    />
                </Grid>
   
            </Grid>
                     
          </Box>
          </Paper>
          </div>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewInspectionModal;
