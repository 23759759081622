import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  Paper,
  Box,
  IconButton,
  FormControlLabel,
  Checkbox,
  Button,
  ButtonGroup
} from "@material-ui/core";
import clsx from "clsx";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import ButtonIcon from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import LaunchIcon from '@material-ui/icons/Launch';
import { useDispatch, useSelector } from "react-redux";
import {getSign, getSigns, getSignsByUser, setSignoutDraft, generateSignout} from "../../../actions/toolboxAction";
import EditCrewModal from "../Crew/EditCrewModal";
import Feedback from "../../../utils/Feedback";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 20,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  btnGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
      },

  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 380,
      [theme.breakpoints.down("sm")]: {
          width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      },
  },
  divider: {
    height: 40,
    margin: 4,
    width: 2,
  },
  
  status:{
    width: "fit-content",
    height: 50,
    padding:10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginLeft: 20
  },
  iconButton: {
    paddingLeft: 50,
    paddingRight: 50,
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },

  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },

  statusDarkGreen: {
    borderColor: "#4caf50",
    color: "#4caf50",
  },

  sign : {
    width: 60,
    height: 30,
    [theme.breakpoints.down("sm")]: {
      width: 60,
      height: 30,
    },
    [theme.breakpoints.down("xs")]: {
      width: 40,
      height: 20,
    },
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },

}));

const INIT_STATE = {
    crew: {},
    date: moment().format('YYYY-MM-DD'),
    location: "",
    crewInjured: { injured:false, firstAid:false, comment: "" },
    nearMissIncident: { nearMiss:false, comment: "" },
    cleanUp: { clean:false, tools:false, comment: "" },
    workPlanProblem: "",
    tomorrowTool: "",
    tomorrowPlan: "",
    shiftConcern: "",
    supervisor: "",
    crewMembers: [{ name: "", absent: false, signature: "", time: "" }],
    
};

const ViewSignOutModal = ({ openViewSignOut, handleCloseViewSignOut, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, loading, sign } = useSelector((state) => state.toolbox);
  const { profile } = useSelector((state) => state.users);

  const [openEdit, setOpenEdit] = useState(false);
  const [getId, setGetId] = useState("");

  let isAdminGuard = profile
  ? (profile &&
      // profile.role && profile.role.name === "admin")
      profile.role && profile.role.map((role) => role.name).includes("admin"))
  : false;

  let isToolboxAdmin = profile
  ? (profile &&
      // profile.role && profile.role.name === "toolbox_admin")
      profile.role && profile.role.map((role) => role.name).includes("toolbox_admin"))
      : false;

  let isToolboxSupervisor = profile
  ? (profile &&
      // profile.role && profile.role.name === "toolbox_supervisor")
      profile.role && profile.role.map((role) => role.name).includes("toolbox_supervisor"))
      : false;


useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
        setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
        }));
    }
    return () => {
        abortController.abort();
    };
    }, [error]);

  useEffect(() => {
    if (id){
      dispatch(getSign(id));
    }
    
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
      ...prev,
        crew: sign && sign.crew,
        date: sign && sign.date,
        location: sign && sign.location?.displayname,
        crewInjured: sign && sign.crewInjured,
        nearMissIncident: sign && sign.nearMissIncident,
        cleanUp: sign && sign.cleanUp,
        workPlanProblem: sign && sign.workPlanProblem,
        tomorrowTool: sign && sign.tomorrowTool,
        tomorrowPlan: sign && sign.tomorrowPlan,
        shiftConcern: sign && sign.shiftConcern,
        supervisor: sign && sign.createdBy,
        crewMembers: sign && sign.crewMembers

    }));
  }, [sign]);


  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleSetDraft = (e) => {
    e.preventDefault();
    dispatch(setSignoutDraft(id));
    setTimeout(() => {
      handleCloseViewSignOut();
        dispatch(getSigns());
        dispatch(getSignsByUser());
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Toolbox set to draft successfully!",
          success: true,
        }));
      }, 1500);
  };

  const handleGenerateReport = (e) => {
    e.preventDefault();
    dispatch(generateSignout(id));
    setTimeout(() => {
        dispatch(getSign(id));
        dispatch(getSignsByUser());
      
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Signout Report Generated Successfully!",
          success: true,
        }));
    }, 1500);
  };

  const handleOpenEdit = (id) => {
    setGetId(id);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const printRef = React.useRef()
  const handleDownloadPdf = async () => {
    const element = printRef.current
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4', true);
    
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight()
    const imgWidth = canvas.width
    const imgHeight = canvas.height
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
    const imgX = (pdfWidth - imgWidth * ratio) / 2
    const imgY = (pdfHeight - imgHeight * ratio) / 2

    pdf.addImage(data, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    pdf.save(`${sign && sign.toolBoxType}_${state.crew?.name}_${state.date?.split("T")[0]}.pdf`);
    };

  return (
    <div>
      <EditCrewModal {...{ openEdit, handleCloseEdit, id: getId }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openViewSignOut}
        onClose={handleCloseViewSignOut}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                View Toolbox Sign Off
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseViewSignOut}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          {(sign && sign.status === "Submitted") && (isAdminGuard || isToolboxAdmin) && (
            <Grid item xs={5} sm={6}>
              <ButtonGroup className={classes.btnGroup} variant="outlined">
                  <Button
                      size="small"
                      className={classes.btnLink}
                      color="primary"
                      onClick={handleSetDraft}
                  >
                      Set to Draft
                  </Button>
                  {/* <Button
                      size="small"
                      className={clsx(classes.btnLink, classes.statusDarkGreen)}
                      onClick={handleDownloadPdf}
                  >
                      Download as PDF
                  </Button> */}
              
              </ButtonGroup>
            </Grid>
          )}
          {(sign && (sign.status === "Submitted")) && (
            <Grid item xs={7} sm={6}>
              <Paper variant="outlined" className={clsx(classes.status, classes.statusOrange)}
              >
                  <Typography variant="overline">
                      <ButtonIcon onClick={handleGenerateReport} color="default" size="small">
                          Generate PDF
                      </ButtonIcon>
                  </Typography>
                  <Divider orientation="vertical" className={classes.divider}/>
                  {sign && sign.reportPDF ? 
                    <ButtonIcon onClick={() => window.open(sign.reportPDF, "_blank", "noopener, noreferrer")} className={classes.file}>
                        <i className="fas fa-2x fa-file-pdf" ></i>
                    </ButtonIcon> : "No PDF"}
                    
              </Paper>
              </Grid>
          )}
        </Grid>

        <br/>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
        <div ref={printRef}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
          
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
                  <TextField
                      name="supervisor"
                      fullWidth
                      variant="outlined"
                      label="Supervisor"
                      value={state.supervisor?.displayname || ""}
                      disabled
                    />
                </Grid>
              
                {isAdminGuard || isToolboxAdmin ? (
                <Grid item xs={10} sm={5}>
                  <TextField
                    name="crew"
                    fullWidth
                    variant="outlined"
                    label="Crew"
                    value={state.crew?.name || ""}
                    disabled
                  ></TextField>
                </Grid>
                
              ) : (
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="crew"
                    fullWidth
                    variant="outlined"
                    label="Crew"
                    value={state.crew?.name || ""}
                    disabled
                  ></TextField>
                </Grid>
              )}
                
              {(isAdminGuard || isToolboxAdmin) && (
                <Grid item xs={2} sm={1}>
                  <Tooltip title="View Crew">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handleOpenEdit(state.crew?._id);
                      }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
              
                <TextField
                    name="date"
                    fullWidth
                    variant="outlined"
                    label="Date"
                    value={state.date?.split("T")[0] || ''}
                    disabled    
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                    name="location"
                    fullWidth
                    variant="outlined"
                    label="Job Location"
                    value={state.location || ""}
                    disabled    
                />
              </Grid>
            </Grid>
            <br />
    
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Was anyone injured today?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.crewInjured?.injured || false}
                        name="injured"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Did anyone go to First Aid today?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.crewInjured?.firstAid || false}
                        name="firstAid"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>
              
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="If Yes, please explain (include who/what)"
                  multiline
                  value={state.crewInjured?.comment || ""}
                  disabled
                />
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Did a near miss incident occur today?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.nearMissIncident?.nearMiss || false}
                        name="nearMiss"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>
              
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="If yes, describe briefly"
                  multiline
                  value={state.nearMissIncident?.comment || ""}
                  disabled
                />
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Has the work area clean up been done?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.cleanUp?.clean || false}
                        name="clean"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Have tools/equipment been put away?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.cleanUp?.tools || false}
                        name="tools"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>
              
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="If Yes, please comment"
                  multiline
                  value={state.cleanUp?.comment || ""}
                    disabled
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} >
              <Typography variant="subtitle2" align="left">
                What problems did we have with today’s work plan?
              </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="workPlanProblem"
                  fullWidth
                  variant="outlined"
                  label="List Issues"
                  multiline
                  value={state.workPlanProblem || ""}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} >
              <Typography variant="subtitle2" align="left">
                What tools, equipment or manpower do we need for tomorrow’s work plan?
              </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="tomorrowTool"
                  fullWidth
                  variant="outlined"
                  label="List Requirements"
                  multiline
                  value={state.tomorrowTool || ""}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} >
              <Typography variant="subtitle2" align="left">
                What is tomorrow’s work plan?
              </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="tomorrowPlan"
                  fullWidth
                  variant="outlined"
                  label="Briefly describe"
                  multiline
                  value={state.tomorrowPlan || ""}
                    disabled
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  name="shiftConcern"
                  fullWidth
                  variant="outlined"
                  label="End of Shift Concerns"
                  multiline
                  value={state.shiftConcern || ""}
                    disabled
                />
              </Grid> 
              <Paper className={classes.paper} variant="outlined" square>
                    <Typography variant="body1">
                        Crew Members
                    </Typography>
                    <br />
                    <Grid container spacing={3}>
                      {state.crewMembers?.map((current, i) => (
                        i % 2 === 0 ? (
                        <Grid container item xs={12} sm={6} md={6} justifyContent="center" alignItems="center" key={i}>
                          <Grid container alignContent="center" spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    label="Name"
                                    value={current.name}
                                    size="small"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                              <img src={current.signature} alt="signature" className={classes.sign}/>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                                <TextField
                                    name="time"
                                    fullWidth
                                    variant="outlined"
                                    label="Time"
                                    value={current.time}
                                    size="small"
                                    disabled
                                />
                            </Grid>
                            
                          </Grid>
                        </Grid>
                        ) : (
                        <Grid  container item xs={12} sm={6} md={6} justifyContent="center" alignItems="center" key={i}>
                          <Grid container alignContent="center" spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    label="Name"
                                    value={current.name}
                                    size="small"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                              <img src={current.signature} alt="signature" className={classes.sign} />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                                <TextField
                                    name="time"
                                    fullWidth
                                    variant="outlined"
                                    label="Time"
                                    value={current.time}
                                    disabled
                                    size="small"
                                />
                            </Grid>  
                            
                          </Grid>
                        </Grid>
                        )
                      ))}
                    </Grid>
                </Paper>
              
          </Grid>
          </Box>
        
          </Paper>
        </div>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewSignOutModal;
