import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  InputAdornment
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";

import { useDispatch, useSelector } from "react-redux";
import { getItem, getItems, getQuant, updateQuant, getStores } from "../../../actions/inventoryAction";

import Feedback from "../../../utils/Feedback";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    color: theme.palette.primary.main,
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dialog: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
    minWidth: 420,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    },
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 420,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },
paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
},
[theme.breakpoints.down("sm")]: {
    width: "100%",
},
[theme.breakpoints.down("xs")]: {
    minWidth: "100%",
},

fab: {
    right: theme.spacing(-1),
},
status:{
    width: "fit-content",
    height: 50,
    padding:10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginRight: -30
  },

  divider: {
    height: 40,
    margin: 4,
    width: 2,
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    borderColor: "#ef5350",
  },

}));

const INIT_STATE = {
    item: undefined,
    store: undefined,
    quantity: 0,
    error: null,
    success: false,
    loading: false,
};

const EditQuantModal = ({ openEdit, handleCloseEdit, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { quant, item, error, loading } = useSelector((state) => state.inventory);

  useEffect(() => {
    dispatch(getQuant(id));
  }, [dispatch, id]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);


  useEffect(() => {
    setState((prev) => ({
      ...prev,
        item: quant && quant.item?._id,
        store: quant && quant.store?._id,
        quantity: quant && quant.quantity

    }));
  }, [quant]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        item: state.item,
        store: state.store,
        quantity: +state.quantity,
        id
    
    };
    dispatch(updateQuant(data));

    setTimeout(() => {
      handleCloseEdit();
      dispatch(getItem(quant.item?._id))
      dispatch(getItems())
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Quantity Updated Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () => !state.store

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth maxWidth="md" className={classes.dialog}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit Quantity
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseEdit}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
      
        <Divider/>
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleSubmit}>
        
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                  name="store"
                  fullWidth
                  variant="outlined"
                  label="Store"
                  onChange={handleChange}
                  value={quant?.store?.name || ""}
                  disabled
                />
              </Grid>
                
              <Grid item xs={12} sm={6}>
                <TextField
                  name="quantity"
                  fullWidth
                  variant="outlined"
                  label="Quantity"
                  onChange={handleChange}
                  value={state.quantity || 0}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{item?.uom?.name}</InputAdornment>,
                  }}
                  type="number"
                />
              </Grid>
                
            </Grid>
            <Typography align='center'>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                endIcon={<UpdateIcon />}
                className={classes.btn}
                disabled={isValid() || loading}
              >
                {loading ? "Loading..." : "Update"}
              </Button>
            </Typography> 
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditQuantModal;
