module.exports = {
    CREATE_SCAFFOLD: "CREATE_SCAFFOLD",
    CREATE_SCAFFOLD_REQUEST: "CREATE_SCAFFOLD_REQUEST",
    CREATE_SCAFFOLD_FAIL: "CREATE_SCAFFOLD_FAIL",

    SUBMIT_SCAFFOLD: "SUBMIT_SCAFFOLD",
    SUBMIT_SCAFFOLD_REQUEST: "SUBMIT_SCAFFOLD_REQUEST",
    SUBMIT_SCAFFOLD_FAIL: "SUBMIT_SCAFFOLD_FAIL",

    UPDATE_SCAFFOLD: "UPDATE_SCAFFOLD",
    UPDATE_SCAFFOLD_REQUEST: "UPDATE_SCAFFOLD_REQUEST",
    UPDATE_SCAFFOLD_FAIL: "UPDATE_SCAFFOLD_FAIL",

    SET_SCAFFOLD_DRAFT: "SET_SCAFFOLD_DRAFT",
    SET_SCAFFOLD_DRAFT_REQUEST: "SET_SCAFFOLD_DRAFT_REQUEST",
    SET_SCAFFOLD_DRAFT_FAIL: "SET_SCAFFOLD_DRAFT_FAIL",

   
    GET_SCAFFOLDS: "GET_SCAFFOLDS",
    GET_SCAFFOLDS_REQUEST: "GET_SCAFFOLDS_REQUEST",
    GET_SCAFFOLDS_FAIL: "GET_SCAFFOLDS_FAIL",

    GET_SCAFFOLD: "GET_SCAFFOLD",
    GET_SCAFFOLD_REQUEST: "GET_SCAFFOLD_REQUEST",
    GET_SCAFFOLD_FAIL: "GET_SCAFFOLD_FAIL",

    GET_SCAFFOLD_BY_USER: "GET_SCAFFOLD_BY_USER",
    GET_SCAFFOLD_BY_USER_REQUEST: "GET_SCAFFOLD_BY_USER_REQUEST",
    GET_SCAFFOLD_BY_USER_FAIL: "GET_SCAFFOLD_BY_USER_FAIL",

    GET_SCAFFOLD_BY_LOCATION: "GET_SCAFFOLD_BY_LOCATION",
    GET_SCAFFOLD_BY_LOCATION_REQUEST: "GET_SCAFFOLD_BY_LOCATION_REQUEST",
    GET_SCAFFOLD_BY_LOCATION_FAIL: "GET_SCAFFOLD_BY_LOCATION_FAIL",

    DELETE_SCAFFOLD: "DELETE_SCAFFOLD",
    DELETE_SCAFFOLD_REQUEST: "DELETE_SCAFFOLD_REQUEST",
    DELETE_SCAFFOLD_FAIL: "DELETE_SCAFFOLD_FAIL",
}