module.exports = {
    CREATE_EVENT: "CREATE_EVENT",
    CREATE_EVENT_REQUEST: "CREATE_EVENT_REQUEST",
    CREATE_EVENT_FAIL: "CREATE_EVENT_FAIL",

    GET_EVENT: "GET_EVENT",
    GET_EVENT_REQUEST: "GET_EVENT_REQUEST",
    GET_EVENT_FAIL: "GET_EVENT_FAIL",

    GET_EVENTS: "GET_EVENTS",
    GET_EVENTS_REQUEST: "GET_EVENTS_REQUEST",
    GET_EVENTS_FAIL: "GET_EVENTS_FAIL",

    UPDATE_EVENT: "UPDATE_EVENT",
    UPDATE_EVENT_REQUEST: "UPDATE_EVENT_REQUEST",
    UPDATE_EVENT_FAIL: "UPDATE_EVENT_FAIL",

    DELETE_EVENT: "DELETE_EVENT",
    DELETE_EVENT_REQUEST: "DELETE_EVENT_REQUEST",
    DELETE_EVENT_FAIL: "DELETE_EVENT_FAIL",


    CREATE_CAUSE: "CREATE_CAUSE",
    CREATE_CAUSE_REQUEST: "CREATE_CAUSE_REQUEST",
    CREATE_CAUSE_FAIL: "CREATE_CAUSE_FAIL",

    GET_CAUSE: "GET_CAUSE",
    GET_CAUSE_REQUEST: "GET_CAUSE_REQUEST",
    GET_CAUSE_FAIL: "GET_CAUSE_FAIL",

    GET_CAUSES: "GET_CAUSES",
    GET_CAUSES_REQUEST: "GET_CAUSES_REQUEST",
    GET_CAUSES_FAIL: "GET_CAUSES_FAIL",

    UPDATE_CAUSE: "UPDATE_CAUSE",
    UPDATE_CAUSE_REQUEST: "UPDATE_CAUSE_REQUEST",
    UPDATE_CAUSE_FAIL: "UPDATE_CAUSE_FAIL",

    DELETE_CAUSE: "DELETE_CAUSE",
    DELETE_CAUSE_REQUEST: "DELETE_CAUSE_REQUEST",
    DELETE_CAUSE_FAIL: "DELETE_CAUSE_FAIL",

    CREATE_CONTACT: "CREATE_CONTACT",
    CREATE_CONTACT_REQUEST: "CREATE_CONTACT_REQUEST",
    CREATE_CONTACT_FAIL: "CREATE_CONTACT_FAIL",

    UPDATE_CONTACT: "UPDATE_CONTACT",
    UPDATE_CONTACT_REQUEST: "UPDATE_CONTACT_REQUEST",
    UPDATE_CONTACT_FAIL: "UPDATE_CONTACT_FAIL",

    GET_CONTACTS: "GET_CONTACTS",
    GET_CONTACTS_REQUEST: "GET_CONTACTS_REQUEST",
    GET_CONTACTS_FAIL: "GET_CONTACTS_FAIL",

    GET_CONTACT: "GET_CONTACT",
    GET_CONTACT_REQUEST: "GET_CONTACT_REQUEST",
    GET_CONTACT_FAIL: "GET_CONTACT_FAIL",

    DELETE_CONTACT: "DELETE_CONTACT",
    DELETE_CONTACT_REQUEST: "DELETE_CONTACT_REQUEST",
    DELETE_CONTACT_FAIL: "DELETE_CONTACT_FAIL",

    CREATE_INCIDENT: "CREATE_INCIDENT",
    CREATE_INCIDENT_REQUEST: "CREATE_INCIDENT_REQUEST",
    CREATE_INCIDENT_FAIL: "CREATE_INCIDENT_FAIL",

    SUBMIT_INCIDENT: "SUBMIT_INCIDENT",
    SUBMIT_INCIDENT_REQUEST: "SUBMIT_INCIDENT_REQUEST",
    SUBMIT_INCIDENT_FAIL: "SUBMIT_INCIDENT_FAIL",

    UPDATE_INCIDENT: "UPDATE_INCIDENT",
    UPDATE_INCIDENT_REQUEST: "UPDATE_INCIDENT_REQUEST",
    UPDATE_INCIDENT_FAIL: "UPDATE_INCIDENT_FAIL",

    UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
    UPLOAD_DOCUMENT_REQUEST: "UPLOAD_DOCUMENT_REQUEST",
    UPLOAD_DOCUMENT_FAIL: "UPLOAD_DOCUMENT_FAIL",

    DELETE_DOCUMENT: "DELETE_DOCUMENT",
    DELETE_DOCUMENT_REQUEST: "DELETE_DOCUMENT_REQUEST",
    DELETE_DOCUMENT_FAIL: "DELETE_DOCUMENT_FAIL",

    DOWNLOAD_DOCUMENT: "DOWNLOAD_DOCUMENT",
    DOWNLOAD_DOCUMENT_REQUEST: "DOWNLOAD_DOCUMENT_REQUEST",
    DOWNLOAD_DOCUMENT_FAIL: "DOWNLOAD_DOCUMENT_FAIL",

    SET_INCIDENT_DRAFT: "SET_INCIDENT_DRAFT",
    SET_INCIDENT_DRAFT_REQUEST: "SET_INCIDENT_DRAFT_REQUEST",
    SET_INCIDENT_DRAFT_FAIL: "SET_INCIDENT_DRAFT_FAIL",

    SET_INCIDENT_CLOSED: "SET_INCIDENT_CLOSED",
    SET_INCIDENT_CLOSED_REQUEST: "SET_INCIDENT_CLOSED_REQUEST",
    SET_INCIDENT_CLOSED_FAIL: "SET_INCIDENT_CLOSED_FAIL",

    SET_INCIDENT_OPEN: "SET_INCIDENT_OPEN",
    SET_INCIDENT_OPEN_REQUEST: "SET_INCIDENT_OPEN_REQUEST",
    SET_INCIDENT_OPEN_FAIL: "SET_INCIDENT_OPEN_FAIL",

    GET_INCIDENTS: "GET_INCIDENTS",
    GET_INCIDENTS_REQUEST: "GET_INCIDENTS_REQUEST",
    GET_INCIDENTS_FAIL: "GET_INCIDENTS_FAIL",

    GET_INCIDENT: "GET_INCIDENT",
    GET_INCIDENT_REQUEST: "GET_INCIDENT_REQUEST",
    GET_INCIDENT_FAIL: "GET_INCIDENT_FAIL",

    GET_INCIDENTS_BY_USER: "GET_INCIDENTS_BY_USER",
    GET_INCIDENTS_BY_USER_REQUEST: "GET_INCIDENTS_BY_USER_REQUEST",
    GET_INCIDENTS_BY_USER_FAIL: "GET_INCIDENTS_BY_USER_FAIL",

    DELETE_INCIDENT: "DELETE_INCIDENT",
    DELETE_INCIDENT_REQUEST: "DELETE_INCIDENT_REQUEST",
    DELETE_INCIDENT_FAIL: "DELETE_INCIDENT_FAIL",
   
}