import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  ImageList,
  ImageListItem, 
  lighten,
  IconButton,
  Backdrop,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import clsx from "clsx";

import TablePagination from "@material-ui/core/TablePagination";
import { TablePaginationActions } from "../../utils/TablePaginationAction";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Search from "../../utils/Search";

import Feedback from "../../utils/Feedback";
import ButtonIcon from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import {uploadEmployeeDocument, getDocumentsByType } from "../../actions/documentAction";
import DeleteEmployeeDocumentModal from "../Modals/Document/DeleteEmployeeDocumentModal";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),

    // width: "100%",
    overflow: "hidden",
  },
  title: {
    color: lighten(theme.palette.common.black, 0.75),
    display: "block",
    fontSize: 15,
    fontWeight: 900,
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  btn2: {
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 350,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.65rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
    background: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
    background: "#1de9b6",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
    background: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  info: {
    padding: theme.spacing(4),
    marginBottom: 30,
  },
  avatar: {
    margin: "auto",
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: 30,
  },
  link: {
    color: theme.palette.type === "light" ? "#333" : "#fff",
  },

  delete: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(-1),
        marginTop: theme.spacing(-3),
    }
  },

  file: {
    color: theme.palette.type === "light" ? "#330C03" : "#fff",
    marginLeft: theme.spacing(-1),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(-1),
        marginTop: theme.spacing(-2),
    }
    },

  imageListItem: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(-2),
    },
  },

  typo: { 
    textJustify: "center",
    textAlign: "left",
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        textAlign: "left",
        opacity: 1,
    }
  },

  nameTypo: {
    textJustify: "center",
    textAlign: "left",
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
        fontSize: 10,
        textAlign: "left",
        opacity: 1,
    }
    },


  imageList: { 
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(1),

    },
    },

}));

const EmployeeDocument = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [file, setFile] = React.useState(undefined)
  const [search, setSearch] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const [getId, setGetId] = useState("");

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { documents, error, loading } = useSelector((state) => state.documents);

  useEffect(() => {
    dispatch(getDocumentsByType("Employee"));
  }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };


  let filteredRows = () =>
  documents === undefined
    ? []
    : documents.filter((row) => {
        if (search !== "") {
          return (
            row.filename?.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            moment(row.createdAt).format("DD/MM/YYYY").toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            row.createdBy?.displayname.toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
        } else {
          return row;
        }
      });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };
  const history = useHistory();

  const route = (path) => {
    history.push(path);
  };

  React.useEffect(() => {
    const upload = async () => {
      if (file) {
        const formData = new FormData();
        formData.append("doc", file)
  
        dispatch(uploadEmployeeDocument(formData));
        setTimeout(() => {
          setFile(undefined);
          if (error === null) {
            setFeed((prev) => ({
              loading: false,
              open: true,
              message: "Document uploaded successfully",
              success: true,
            }));
          }
          dispatch(getDocumentsByType("Employee"));
        }, 1500);
      }
    }
    upload();
  }, [file, dispatch, error]);
  
  
  const handleUploadDocument = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);

  };

  const handleOpenDelete = (id) => {
    setGetId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return (
    <div className={classes.root}>
    <DeleteEmployeeDocumentModal {...{ openDelete, handleCloseDelete, id: getId }} />
      {feed.success === false && (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Typography className={classes.title} variant="overline">
        Employee Documents
      </Typography>
      <Grid container alignItems="center" spacing={3}>
        <Grid item>
            <label htmlFor="photo">
                <ButtonIcon
                className={classes.btn2}
                type="file"
                color="primary"
                size="large"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                component="span"
                >
                Upload
                </ButtonIcon>
            </label>

            <input 
                type="file"
                id="photo"
                name="photo"
                accept="file/*"
                onChange={handleUploadDocument}
                hidden
              />

        </Grid>
        <Grid item>
          <Search
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>
     
      {documents.length === 0 ? (
            <Typography className={classes.typo} variant="h6">
                No Documents Found
            </Typography>
        ) : (
      <Paper>
        <div>
            <ImageList cols={6} rowHeight={160}>
                
                {filteredRows().map((item) => (
                    <ImageListItem key={item._id} className={classes.imageListItem}>
                    
                        <ButtonIcon onClick={() => item.file && item.file.split(".").pop() === "pdf"? route(`/document-view/${item._id}`): window.open(item.file, "_blank", "noopener, noreferrer")} className={classes.file}>
                            {item.file && item.file.split(".").pop() === "pdf"? <i className="fas fa-5x fa-file-pdf" ></i>:                        
                            (item.file && item.file.split(".").pop() === "ppt") || (item.file && item.file.split(".").pop() === "pptx")? <i className="fas fa-5x fa-file-powerpoint"></i>:
                            item.file && item.file.split(".").pop() === "pptx"? <i className="fas fa-5x fa-file-powerpoint"></i>:
                            (item.file && item.file.split(".").pop() ==="png") || (item.file && item.file.split(".").pop() ==="jpg") || (item.file && item.file.split(".").pop() ==="jpeg")? <i className="fas fa-5x fa-file-image"></i>:
                            <i className="fas fa-5x fa-file"></i>}
                        </ButtonIcon>

                        <IconButton 
                        onClick={() => handleOpenDelete(item._id)} className={classes.delete}>
                            <DeleteIcon />
                        </IconButton
                        >
                            
                        <Tooltip title={item.filename}>    
                          <Typography variant="subtitle2" noWrap className={classes.typo}>
                              {item.filename}
                            
                          </Typography>
                        </Tooltip>
                        
                        <Typography variant="body2" noWrap className={classes.typo}>
                          {item.createdAt?.split("T")[0]}
                        </Typography>
                        <Typography variant="caption" noWrap className={classes.nameTypo}>
                            {item.createdBy.displayname}
                        </Typography>

                    </ImageListItem>
                ))}
            </ImageList>
                        
        </div>
         
        <TablePagination
          component="div"
          rowsPerPageOptions={[20, 50, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
      />
        </Paper>
    )}
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        </div>
  );
};

export default EmployeeDocument;
