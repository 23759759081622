module.exports = {
    CREATE_CREW: "CREATE_CREW",
    CREATE_CREW_REQUEST: "CREATE_CREW_REQUEST",
    CREATE_CREW_FAIL: "CREATE_CREW_FAIL",

    GET_CREW: "GET_CREW",
    GET_CREW_REQUEST: "GET_CREW_REQUEST",
    GET_CREW_FAIL: "GET_CREW_FAIL",

    GET_CREW_BY_SUPERVISOR: "GET_CREW_BY_SUPERVISOR",
    GET_CREW_BY_SUPERVISOR_REQUEST: "GET_CREW_BY_SUPERVISOR_REQUEST",
    GET_CREW_BY_SUPERVISOR_FAIL: "GET_CREW_BY_SUPERVISOR_FAIL",

    GET_CREWS: "GET_CREWS",
    GET_CREWS_REQUEST: "GET_CREWS_REQUEST",
    GET_CREWS_FAIL: "GET_CREWS_FAIL",

    UPDATE_CREW: "UPDATE_CREW",
    UPDATE_CREW_REQUEST: "UPDATE_CREW_REQUEST",
    UPDATE_CREW_FAIL: "UPDATE_CREW_FAIL",

    DELETE_CREW: "DELETE_CREW",
    DELETE_CREW_REQUEST: "DELETE_CREW_REQUEST",
    DELETE_CREW_FAIL: "DELETE_CREW_FAIL",

    ARCHIVE_CREW: "ARCHIVE_CREW",
    ARCHIVE_CREW_REQUEST: "ARCHIVE_CREW_REQUEST",
    ARCHIVE_CREW_FAIL: "ARCHIVE_CREW_FAIL",

    CREATE_SIGNIN: "CREATE_SIGNIN",
    CREATE_SIGNIN_REQUEST: "CREATE_SIGNIN_REQUEST",
    CREATE_SIGNIN_FAIL: "CREATE_SIGNIN_FAIL",

    SUBMIT_SIGNIN: "SUBMIT_SIGNIN",
    SUBMIT_SIGNIN_REQUEST: "SUBMIT_SIGNIN_REQUEST",
    SUBMIT_SIGNIN_FAIL: "SUBMIT_SIGNIN_FAIL",

    UPDATE_SIGNIN: "UPDATE_SIGNIN",
    UPDATE_SIGNIN_REQUEST: "UPDATE_SIGNIN_REQUEST",
    UPDATE_SIGNIN_FAIL: "UPDATE_SIGNIN_FAIL",

    SET_SIGNIN_DRAFT: "SET_SIGNIN_DRAFT",
    SET_SIGNIN_DRAFT_REQUEST: "SET_SIGNIN_DRAFT_REQUEST",
    SET_SIGNIN_DRAFT_FAIL: "SET_SIGNIN_DRAFT_FAIL",

    CREATE_SIGNOUT: "CREATE_SIGNOUT",
    CREATE_SIGNOUT_REQUEST: "CREATE_SIGNOUT_REQUEST",
    CREATE_SIGNOUT_FAIL: "CREATE_SIGNOUT_FAIL",

    SUBMIT_SIGNOUT: "SUBMIT_SIGNOUT",
    SUBMIT_SIGNOUT_REQUEST: "SUBMIT_SIGNOUT_REQUEST",
    SUBMIT_SIGNOUT_FAIL: "SUBMIT_SIGNOUT_FAIL",

    UPDATE_SIGNOUT: "UPDATE_SIGNOUT",
    UPDATE_SIGNOUT_REQUEST: "UPDATE_SIGNOUT_REQUEST",
    UPDATE_SIGNOUT_FAIL: "UPDATE_SIGNOUT_FAIL",

    SET_SIGNOUT_DRAFT: "SET_SIGNOUT_DRAFT",
    SET_SIGNOUT_DRAFT_REQUEST: "SET_SIGNOUT_DRAFT_REQUEST",
    SET_SIGNOUT_DRAFT_FAIL: "SET_SIGNOUT_DRAFT_FAIL",

    GET_SIGNS: "GET_SIGNS",
    GET_SIGNS_REQUEST: "GET_SIGNS_REQUEST",
    GET_SIGNS_FAIL: "GET_SIGNS_FAIL",

    GET_SIGN: "GET_SIGN",
    GET_SIGN_REQUEST: "GET_SIGN_REQUEST",
    GET_SIGN_FAIL: "GET_SIGN_FAIL",

    GET_SIGNS_BY_USER: "GET_SIGNS_BY_USER",
    GET_SIGNS_BY_USER_REQUEST: "GET_SIGNS_BY_USER_REQUEST",
    GET_SIGNS_BY_USER_FAIL: "GET_SIGNS_BY_USER_FAIL",

    GET_SIGNIN_BY_USER: "GET_SIGNIN_BY_USER",
    GET_SIGNIN_BY_USER_REQUEST: "GET_SIGNIN_BY_USER_REQUEST",
    GET_SIGNIN_BY_USER_FAIL: "GET_SIGNIN_BY_USER_FAIL",

    GET_SIGNOUT_BY_USER: "GET_SIGNOUT_BY_USER",
    GET_SIGNOUT_BY_USER_REQUEST: "GET_SIGNOUT_BY_USER_REQUEST",
    GET_SIGNOUT_BY_USER_FAIL: "GET_SIGNOUT_BY_USER_FAIL",

    GET_SIGNS_BY_LOCATION: "GET_SIGNS_BY_LOCATION",
    GET_SIGNS_BY_LOCATION_REQUEST: "GET_SIGNS_BY_LOCATION_REQUEST",
    GET_SIGNS_BY_LOCATION_FAIL: "GET_SIGNS_BY_LOCATION_FAIL",

    DELETE_SIGN: "DELETE_SIGN",
    DELETE_SIGN_REQUEST: "DELETE_SIGN_REQUEST",
    DELETE_SIGN_FAIL: "DELETE_SIGN_FAIL",

    DOWNLOAD_SIGNIN: "DOWNLOAD_SIGNIN",
    DOWNLOAD_SIGNIN_REQUEST: "DOWNLOAD_SIGNIN_REQUEST",
    DOWNLOAD_SIGNIN_FAIL: "DOWNLOAD_SIGNIN_FAIL",

    DOWNLOAD_SIGNOUT: "DOWNLOAD_SIGNOUT",
    DOWNLOAD_SIGNOUT_REQUEST: "DOWNLOAD_SIGNOUT_REQUEST",
    DOWNLOAD_SIGNOUT_FAIL: "DOWNLOAD_SIGNOUT_FAIL",

}