import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  Paper,
  Box,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {createInspection, submitInspection, getInspectionsByUser} from "../../../actions/forkliftAction"
import { getLocations, getUsers } from "../../../actions/usersAction";
import Feedback from "../../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },

  btn2:{
    marginBottom: 30,
    marginLeft: 10
  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 400,
      [theme.breakpoints.down("sm")]: {
          minWidth:330,
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      }
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 400,
    [theme.breakpoints.down("sm")]: {
        minWidth:330,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },
  green: {
    color: "#4caf50",
    fontWeight: 500,
    },  
  red: {
    color: "#ef5350",
    fontWeight: 500,
    },
  orange: {
    color: "#F5B041",
    fontWeight: 500,
    },


}));

const INIT_STATE = {
    unitNumber: "",
    inspector: "",
    supervisor: undefined,
    date: moment().format('YYYY-MM-DD'),
    location: undefined,
    hour: "",
    visualDamage:"1",
    reason:"",
    fireExtinguisher: "1",
    safetyDecals: "1",
    quickDisconnect: "1",
    fenders: "1",
    greasePoint: "1",
    driveTrain: "1",
    hydraulicLines: "1",
    hydraulicOil:"1",
    engineOil:"1",
    coolant:"1",
    airFilter: "1",
    belt: "1",
    battery:"1",
    powerLockout: "1",
    brakeFluid: "1",
    visualLeaks: "1",
    windows: "1",
    mirrors: "1",
    seats: "1",
    cleanliness: "1",
    controlLevers: "1",
    steeringBrakes: "1",
    parkBrakes: "1",
    backupAlarms: "1",
    guages:"1",
    lights: "1",
    dashLights: "1",
    horn: "1",
    operationOverhaul: "1",
    forks: "1",
    outriggers: "1",
    tires: "1",
    wheel: "1",
    inspectSafety: "1",
    inspectTow: "1",
    inspectReceiver: "1",
    comment:"",
    fuel:"",
    nextService:""
    
};


const CreateInspectionModal = ({ openCreateInspection, handleCloseCreateInspection }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, loading } = useSelector((state) => state.forklift);
  const { profile, locations, users } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getInspectionsByUser());
    dispatch(getUsers());
    dispatch(getLocations());
    }, [dispatch]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      location: profile && profile.location?._id,
    }));
  }, [profile]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    
  };


  const handleSave = (e) => {
    e.preventDefault();
    let data = {
        unitNumber: state.unitNumber,
        inspector: state.inspector,
        supervisor: state.supervisor,
        date: state.date,
        location: state.location,
        hour: state.hour,
        visualDamage:state.visualDamage,
        reason:state.reason,
        fireExtinguisher: state.fireExtinguisher,
        safetyDecals: state.safetyDecals,
        quickDisconnect: state.quickDisconnect,
        fenders: state.fenders,
        greasePoint: state.greasePoint,
        driveTrain: state.driveTrain,
        hydraulicLines: state.hydraulicLines,
        hydraulicOil:state.hydraulicOil,
        engineOil:state.engineOil,
        coolant:state.coolant,
        airFilter: state.airFilter,
        belt: state.belt,
        battery:state.battery,
        powerLockout: state.powerLockout,
        brakeFluid: state.brakeFluid,
        visualLeaks: state.visualLeaks,
        windows: state.windows,
        mirrors: state.mirrors,
        seats: state.seats,
        cleanliness: state.cleanliness,
        controlLevers: state.controlLevers,
        steeringBrakes: state.steeringBrakes,
        parkBrakes: state.parkBrakes,
        backupAlarms: state.backupAlarms,
        guages:state.guages,
        lights: state.lights,
        dashLights: state.dashLights,
        horn: state.horn,
        operationOverhaul: state.operationOverhaul,
        forks: state.forks,
        outriggers: state.outriggers,
        tires: state.tires,
        wheel: state.wheel,
        inspectSafety: state.inspectSafety,
        inspectTow: state.inspectTow,
        inspectReceiver: state.inspectReceiver,
        comment:state.comment,
        fuel:state.fuel,
        nextService:state.nextService
    };
    dispatch(createInspection(data));

    setTimeout(() => {
      handleCloseCreateInspection();
      dispatch(getInspectionsByUser());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Forklift Inspection Saved Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        unitNumber: state.unitNumber,
        inspector: state.inspector,
        supervisor: state.supervisor,
        date: state.date,
        location: state.location,
        hour: state.hour,
        visualDamage:state.visualDamage,
        reason:state.reason,
        fireExtinguisher: state.fireExtinguisher,
        safetyDecals: state.safetyDecals,
        quickDisconnect: state.quickDisconnect,
        fenders: state.fenders,
        greasePoint: state.greasePoint,
        driveTrain: state.driveTrain,
        hydraulicLines: state.hydraulicLines,
        hydraulicOil:state.hydraulicOil,
        engineOil:state.engineOil,
        coolant:state.coolant,
        airFilter: state.airFilter,
        belt: state.belt,
        battery:state.battery,
        powerLockout: state.powerLockout,
        brakeFluid: state.brakeFluid,
        visualLeaks: state.visualLeaks,
        windows: state.windows,
        mirrors: state.mirrors,
        seats: state.seats,
        cleanliness: state.cleanliness,
        controlLevers: state.controlLevers,
        steeringBrakes: state.steeringBrakes,
        parkBrakes: state.parkBrakes,
        backupAlarms: state.backupAlarms,
        guages:state.guages,
        lights: state.lights,
        dashLights: state.dashLights,
        horn: state.horn,
        operationOverhaul: state.operationOverhaul,
        forks: state.forks,
        outriggers: state.outriggers,
        tires: state.tires,
        wheel: state.wheel,
        inspectSafety: state.inspectSafety,
        inspectTow: state.inspectTow,
        inspectReceiver: state.inspectReceiver,
        comment:state.comment,
        fuel:state.fuel,
        nextService:state.nextService,
        path: "forklift/inspection/view"
    };
    dispatch(submitInspection(data));
    setTimeout(() => {
      handleCloseCreateInspection()
      dispatch(getInspectionsByUser());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Forklift Inspection Submitted Successfully.",
        success: true,
      }));
    }, 1500);
  };


  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.unitNumber || !state.date || !state.location

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openCreateInspection}
        onClose={handleCloseCreateInspection}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Forklift Inspection
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseCreateInspection}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <form onSubmit={handleSubmit}>
        
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="unitNumber"
                    fullWidth
                    variant="outlined"
                    label="Unit Number"
                    onChange={handleChange}
                    value={state.unitNumber}
                    required
                />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Operator"
                      onChange={handleChange}
                      value={state.inspector}
                      required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <Autocomplete 
                            id="supervisor"
                            options={users}
                            getOptionSelected={(option, value) => option._id === value._id}
                            getOptionLabel={(option) => option.displayname} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, supervisor: value }))} 
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Supervisor" />} 
                        />
                    </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="date"
                      fullWidth
                      variant="outlined"
                      label="Date"
                      onChange={handleChange}
                      value={state.date || moment().format('YYYY-MM-DD')}
                      required
                      type="date"   
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                    Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={state.location || ""}
                      name="location"
                      onChange={handleChange}
                      label="Location"
                      required
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {locations &&
                        locations.map((location) => (
                          <MenuItem key={location._id} value={location._id}>
                            {location.displayname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                        Fuel Level
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        fullWidth
                        value={state.fuel || ""}
                        name="fuel"
                        onChange={handleChange}
                        label="Fuel Level"
                        variant="outlined"
                    >
                        <MenuItem value="Empty">Empty</MenuItem>
                        <MenuItem value="1/4">1/4</MenuItem>
                        <MenuItem value="1/2">1/2</MenuItem>
                        <MenuItem value="3/4">3/4</MenuItem>
                        <MenuItem value="Full">Full</MenuItem>
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    name="hour"
                    fullWidth
                    variant="outlined"
                    label="Hours"
                    onChange={handleChange}
                    value={state.hour}
                    type="number"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body2" gutterBottom color="primary">
                  1 - Ok;
                  2 - Requires Scheduled Maintenance;
                  3 - Requires Immediate Fix
                </Typography>
                </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Walk Around Visual Damage Inspection</FormLabel>
                  <RadioGroup row aria-label="visualDamage" name="visualDamage" value={state.visualDamage || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" />
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" />
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {(state.visualDamage === "2" || state.visualDamage === "3") && (
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="reason"
                  fullWidth
                  variant="outlined"
                  label="Briefly describe the damage"
                  onChange={handleChange}
                  multiline
                  value={state.reason || ""}
                  />
              </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Fire Extinguisher</FormLabel>
                  <RadioGroup row name="fireExtinguisher" value={state.fireExtinguisher || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Safety Decals</FormLabel>
                  <RadioGroup row name="safetyDecals" value={state.safetyDecals || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Quick Disconnect Assembly</FormLabel>
                  <RadioGroup row name="quickDisconnect" value={state.quickDisconnect || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Fenders</FormLabel>
                  <RadioGroup row name="fenders" value={state.fenders || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Grease Points</FormLabel>
                  <RadioGroup row name="greasePoint" value={state.greasePoint || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Drive Train - Drive Shafts</FormLabel>
                  <RadioGroup row name="driveTrain" value={state.driveTrain || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Hydraulic Lines</FormLabel>
                  <RadioGroup row name="hydraulicLines" value={state.hydraulicLines || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Engine Oil Level</FormLabel>
                  <RadioGroup row name="engineOil" value={state.engineOil || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Coolant Level</FormLabel>
                  <RadioGroup row name="coolant" value={state.coolant || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Air Filter</FormLabel>
                  <RadioGroup row name="airFilter" value={state.airFilter || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Belt</FormLabel>
                  <RadioGroup row name="belt" value={state.belt || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Battery Connections - Clean & Dry</FormLabel>
                  <RadioGroup row name="battery" value={state.battery || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Power Lockout</FormLabel>
                  <RadioGroup row name="powerLockout" value={state.powerLockout || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Brake Fluid Level - if equipped</FormLabel>
                  <RadioGroup row name="brakeFluid" value={state.brakeFluid || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Visual Leaks</FormLabel>
                  <RadioGroup row name="visualLeaks" value={state.visualLeaks || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Windows & Wipers</FormLabel>
                  <RadioGroup row name="windows" value={state.windows || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Mirrors</FormLabel>
                  <RadioGroup row name="mirrors" value={state.mirrors || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Seats & Seatbelts</FormLabel>
                  <RadioGroup row name="seats" value={state.seats || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Cleanliness</FormLabel>
                  <RadioGroup row name="cleanliness" value={state.cleanliness || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Control Levers</FormLabel>
                  <RadioGroup row name="controlLevers" value={state.controlLevers || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Steering Brakes</FormLabel>
                  <RadioGroup row name="steeringBrakes" value={state.steeringBrakes || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Park Brakes</FormLabel>
                  <RadioGroup row name="parkBrakes" value={state.parkBrakes || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Back Up Alarms</FormLabel>
                  <RadioGroup row name="backupAlarms" value={state.backupAlarms || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Guages</FormLabel>
                  <RadioGroup row name="guages" value={state.guages || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Lights</FormLabel>
                  <RadioGroup row name="lights" value={state.lights || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Dash Lights</FormLabel>
                  <RadioGroup row name="dashLights" value={state.dashLights || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Horn</FormLabel>
                  <RadioGroup row name="horn" value={state.horn || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Operation Overhaul</FormLabel>
                  <RadioGroup row name="operationOverhaul" value={state.operationOverhaul || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Forks - Bent or Cracked</FormLabel>
                  <RadioGroup row name="forks" value={state.forks || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Outriggers</FormLabel>
                  <RadioGroup row name="outriggers" value={state.outriggers || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Tires</FormLabel>
                  <RadioGroup row name="tires" value={state.tires || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Wheel Lug Nuts</FormLabel>
                  <RadioGroup row name="wheel" value={state.wheel || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Inspect Safety Hitch</FormLabel>
                  <RadioGroup row name="inspectSafety" value={state.inspectSafety || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Inspect Tow Bar</FormLabel>
                  <RadioGroup row name="inspectTow" value={state.inspectTow || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Inspect Receiver, Coupler & Pin</FormLabel>
                  <RadioGroup row name="inspectReceiver" value={state.inspectReceiver || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
                
              <Grid item xs={12} sm={6}>
              <TextField
                    name="nextService"
                    fullWidth
                    variant="outlined"
                    label=" Next Service Due Date"
                    onChange={handleChange}
                    value={state.nextService}
                    type="date"     
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Comments/Rapairs Required"
                  onChange={handleChange}
                  multiline
                  value={state.comment || ""}
                  />
              </Grid>
   
            </Grid>
            <br />
            
            <Typography align="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSave}
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save"}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save & Submit"}
                </Button>
              </Typography>
              </form>
          </Box>
          </Paper>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateInspectionModal;
