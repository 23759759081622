import {
    CREATE_FORKLIFT,
    CREATE_FORKLIFT_REQUEST,
    CREATE_FORKLIFT_FAIL,
    SUBMIT_FORKLIFT,
    SUBMIT_FORKLIFT_REQUEST,
    SUBMIT_FORKLIFT_FAIL,
    UPDATE_FORKLIFT,
    UPDATE_FORKLIFT_REQUEST,
    UPDATE_FORKLIFT_FAIL,
    SET_FORKLIFT_DRAFT,
    SET_FORKLIFT_DRAFT_REQUEST,
    SET_FORKLIFT_DRAFT_FAIL,
    SET_FORKLIFT_SERVICED,
    SET_FORKLIFT_SERVICED_REQUEST,
    SET_FORKLIFT_SERVICED_FAIL,
    GET_FORKLIFT,
    GET_FORKLIFT_REQUEST,
    GET_FORKLIFT_FAIL,
    GET_FORKLIFTS,
    GET_FORKLIFTS_REQUEST,
    GET_FORKLIFTS_FAIL,
    GET_FORKLIFTS_BY_USER,
    GET_FORKLIFTS_BY_USER_REQUEST,
    GET_FORKLIFTS_BY_USER_FAIL,
    GET_FORKLIFTS_BY_LOCATION,
    GET_FORKLIFTS_BY_LOCATION_REQUEST,
    GET_FORKLIFTS_BY_LOCATION_FAIL,
    DELETE_FORKLIFT,
    DELETE_FORKLIFT_REQUEST,
    DELETE_FORKLIFT_FAIL,

} from "../types/forkliftTypes"

const initState = {
    forklift: null,
    forklifts: [],
    forkliftsByUser: [],
    error: null,
    loading: false,
  };

  const forkliftReducer = (state = initState, action) => {
    switch (action.type) {
        case CREATE_FORKLIFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_FORKLIFT:
            return {
                ...state,
                forklift: action.payload.data,
                loading: false,
            };
        case CREATE_FORKLIFT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UPDATE_FORKLIFT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_FORKLIFT:
            return {
                ...state,
                forklift: action.payload.data,
                loading: false
            }
        case UPDATE_FORKLIFT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_FORKLIFT_DRAFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_FORKLIFT_DRAFT:
            return {
                ...state,
                forklift: action.payload.data,
                loading: false
            }
        case SET_FORKLIFT_DRAFT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_FORKLIFT_SERVICED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_FORKLIFT_SERVICED:
            return {
                ...state,
                forklift: action.payload.data,
                loading: false
            }
        case SET_FORKLIFT_SERVICED_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SUBMIT_FORKLIFT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SUBMIT_FORKLIFT: 
            return {
                ...state,
                forklift: action.payload.data,
                loading: false
            }
        case SUBMIT_FORKLIFT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case GET_FORKLIFTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_FORKLIFTS:
            return {
                ...state,
                loading: false,
                forklifts: action.payload.data
            };
        case GET_FORKLIFTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_FORKLIFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_FORKLIFT:
            return {
                ...state,
                loading: false,
                forklift: action.payload.data,
            };
        case GET_FORKLIFT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_FORKLIFTS_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_FORKLIFTS_BY_USER:
            return {
                ...state,
                loading: false,
                forkliftsByUser: action.payload.data
            };
        case GET_FORKLIFTS_BY_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_FORKLIFTS_BY_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_FORKLIFTS_BY_LOCATION:
            return {
                ...state,
                loading: false,
                forklifts: action.payload.data
            };
        case GET_FORKLIFTS_BY_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        case DELETE_FORKLIFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_FORKLIFT:
            return {
                ...state,
                loading: false,
                forklift: action.payload,
            };
        case DELETE_FORKLIFT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
    };

    export default forkliftReducer;

