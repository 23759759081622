import {
    CREATE_HARNESS,
    CREATE_HARNESS_REQUEST,
    CREATE_HARNESS_FAIL,
    SUBMIT_HARNESS,
    SUBMIT_HARNESS_REQUEST,
    SUBMIT_HARNESS_FAIL,
    UPDATE_HARNESS,
    UPDATE_HARNESS_REQUEST,
    UPDATE_HARNESS_FAIL,
    SET_HARNESS_DRAFT,
    SET_HARNESS_DRAFT_REQUEST,
    SET_HARNESS_DRAFT_FAIL,
    GET_HARNESS,
    GET_HARNESS_REQUEST,
    GET_HARNESS_FAIL,
    GET_HARNESSES,
    GET_HARNESSES_REQUEST,
    GET_HARNESSES_FAIL,
    GET_HARNESS_BY_USER,
    GET_HARNESS_BY_USER_REQUEST,
    GET_HARNESS_BY_USER_FAIL,
    GET_HARNESS_BY_LOCATION,
    GET_HARNESS_BY_LOCATION_REQUEST,
    GET_HARNESS_BY_LOCATION_FAIL,
    DELETE_HARNESS,
    DELETE_HARNESS_REQUEST,
    DELETE_HARNESS_FAIL,

} from "../types/harnessTypes"

const initState = {
    harness: null,
    harnesses: [],
    harnessByUser: [],
    error: null,
    loading: false,
  };

  const harnessReducer = (state = initState, action) => {
    switch (action.type) {
        case CREATE_HARNESS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_HARNESS:
            return {
                ...state,
                harness: action.payload.data,
                loading: false,
            };
        case CREATE_HARNESS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UPDATE_HARNESS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_HARNESS:
            return {
                ...state,
                harness: action.payload.data,
                loading: false
            }
        case UPDATE_HARNESS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_HARNESS_DRAFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_HARNESS_DRAFT:
            return {
                ...state,
                harness: action.payload.data,
                loading: false
            }
        case SET_HARNESS_DRAFT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SUBMIT_HARNESS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SUBMIT_HARNESS: 
            return {
                ...state,
                harness: action.payload.data,
                loading: false
            }
        case SUBMIT_HARNESS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case GET_HARNESSES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_HARNESSES:
            return {
                ...state,
                loading: false,
                harnesses: action.payload.data
            };
        case GET_HARNESSES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_HARNESS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_HARNESS:
            return {
                ...state,
                loading: false,
                harness: action.payload.data,
            };
        case GET_HARNESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_HARNESS_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_HARNESS_BY_USER:
            return {
                ...state,
                loading: false,
                harnessByUser: action.payload.data
            };
        case GET_HARNESS_BY_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_HARNESS_BY_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_HARNESS_BY_LOCATION:
            return {
                ...state,
                loading: false,
                harnesses: action.payload.data
            };
        case GET_HARNESS_BY_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        case DELETE_HARNESS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_HARNESS:
            return {
                ...state,
                loading: false,
                harness: action.payload,
            };
        case DELETE_HARNESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
    };

    export default harnessReducer;

