module.exports = {
    CREATE_UOM: "CREATE_UOM",
    CREATE_UOM_REQUEST: "CREATE_UOM_REQUEST",
    CREATE_UOM_FAIL: "CREATE_UOM_FAIL",

    GET_UOM: "GET_UOM",
    GET_UOM_REQUEST: "GET_UOM_REQUEST",
    GET_UOM_FAIL: "GET_UOM_FAIL",

    GET_UOMS: "GET_UOMS",
    GET_UOMS_REQUEST: "GET_UOMS_REQUEST",
    GET_UOMS_FAIL: "GET_UOMS_FAIL",

    UPDATE_UOM: "UPDATE_UOM",
    UPDATE_UOM_REQUEST: "UPDATE_UOM_REQUEST",
    UPDATE_UOM_FAIL: "UPDATE_UOM_FAIL",

    DELETE_UOM: "DELETE_UOM",
    DELETE_UOM_REQUEST: "DELETE_UOM_REQUEST",
    DELETE_UOM_FAIL: "DELETE_UOM_FAIL",

    ARCHIVE_UOM: "ARCHIVE_UOM",
    ARCHIVE_UOM_REQUEST: "ARCHIVE_UOM_REQUEST",
    ARCHIVE_UOM_FAIL: "ARCHIVE_UOM_FAIL",

    CREATE_CATEGORY: "CREATE_CATEGORY",
    CREATE_CATEGORY_REQUEST: "CREATE_CATEGORY_REQUEST",
    CREATE_CATEGORY_FAIL: "CREATE_CATEGORY_FAIL",

    GET_CATEGORY: "GET_CATEGORY",
    GET_CATEGORY_REQUEST: "GET_CATEGORY_REQUEST",
    GET_CATEGORY_FAIL: "GET_CATEGORY_FAIL",

    GET_CATEGORIES: "GET_CATEGORIES",
    GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
    GET_CATEGORIES_FAIL: "GET_CATEGORIES_FAIL",

    UPDATE_CATEGORY: "UPDATE_CATEGORY",
    UPDATE_CATEGORY_REQUEST: "UPDATE_CATEGORY_REQUEST",
    UPDATE_CATEGORY_FAIL: "UPDATE_CATEGORY_FAIL",

    DELETE_CATEGORY: "DELETE_CATEGORY",
    DELETE_CATEGORY_REQUEST: "DELETE_CATEGORY_REQUEST",
    DELETE_CATEGORY_FAIL: "DELETE_CATEGORY_FAIL",

    ARCHIVE_CATEGORY: "ARCHIVE_CATEGORY",
    ARCHIVE_CATEGORY_REQUEST: "ARCHIVE_CATEGORY_REQUEST",
    ARCHIVE_CATEGORY_FAIL: "ARCHIVE_CATEGORY_FAIL",

    CREATE_STORE: "CREATE_STORE",
    CREATE_STORE_REQUEST: "CREATE_STORE_REQUEST",
    CREATE_STORE_FAIL: "CREATE_STORE_FAIL",

    UPDATE_STORE: "UPDATE_STORE",
    UPDATE_STORE_REQUEST: "UPDATE_STORE_REQUEST",
    UPDATE_STORE_FAIL: "UPDATE_STORE_FAIL",

    GET_STORES: "GET_STORES",
    GET_STORES_REQUEST: "GET_STORES_REQUEST",
    GET_STORES_FAIL: "GET_STORES_FAIL",

    GET_STORE: "GET_STORE",
    GET_STORE_REQUEST: "GET_STORE_REQUEST",
    GET_STORE_FAIL: "GET_STORE_FAIL",

    ARCHIVE_STORE: "ARCHIVE_STORE",
    ARCHIVE_STORE_REQUEST: "ARCHIVE_STORE_REQUEST",
    ARCHIVE_STORE_FAIL: "ARCHIVE_STORE_FAIL",

    DELETE_STORE: "DELETE_STORE",
    DELETE_STORE_REQUEST: "DELETE_STORE_REQUEST",
    DELETE_STORE_FAIL: "DELETE_STORE_FAIL",

    CREATE_SUPPLIER: "CREATE_SUPPLIER",
    CREATE_SUPPLIER_REQUEST: "CREATE_SUPPLIER_REQUEST",
    CREATE_SUPPLIER_FAIL: "CREATE_SUPPLIER_FAIL",

    UPDATE_SUPPLIER: "UPDATE_SUPPLIER",
    UPDATE_SUPPLIER_REQUEST: "UPDATE_SUPPLIER_REQUEST",
    UPDATE_SUPPLIER_FAIL: "UPDATE_SUPPLIER_FAIL",

    GET_SUPPLIERS: "GET_SUPPLIERS",
    GET_SUPPLIERS_REQUEST: "GET_SUPPLIERS_REQUEST",
    GET_SUPPLIERS_FAIL: "GET_SUPPLIERS_FAIL",

    GET_SUPPLIER: "GET_SUPPLIER",
    GET_SUPPLIER_REQUEST: "GET_SUPPLIER_REQUEST",
    GET_SUPPLIER_FAIL: "GET_SUPPLIER_FAIL",

    ARCHIVE_SUPPLIER: "ARCHIVE_SUPPLIER",
    ARCHIVE_SUPPLIER_REQUEST: "ARCHIVE_SUPPLIER_REQUEST",
    ARCHIVE_SUPPLIER_FAIL: "ARCHIVE_SUPPLIER_FAIL",

    DELETE_SUPPLIER: "DELETE_SUPPLIER",
    DELETE_SUPPLIER_REQUEST: "DELETE_SUPPLIER_REQUEST",
    DELETE_SUPPLIER_FAIL: "DELETE_SUPPLIER_FAIL",

    CREATE_ITEM: "CREATE_ITEM",
    CREATE_ITEM_REQUEST: "CREATE_ITEM_REQUEST",
    CREATE_ITEM_FAIL: "CREATE_ITEM_FAIL",

    UPDATE_ITEM: "UPDATE_ITEM",
    UPDATE_ITEM_REQUEST: "UPDATE_ITEM_REQUEST",
    UPDATE_ITEM_FAIL: "UPDATE_ITEM_FAIL",

    GET_ITEMS: "GET_ITEMS",
    GET_ITEMS_REQUEST: "GET_ITEMS_REQUEST",
    GET_ITEMS_FAIL: "GET_ITEMS_FAIL",

    GET_ITEM: "GET_ITEM",
    GET_ITEM_REQUEST: "GET_ITEM_REQUEST",
    GET_ITEM_FAIL: "GET_ITEM_FAIL",

    ARCHIVE_ITEM: "ARCHIVE_ITEM",
    ARCHIVE_ITEM_REQUEST: "ARCHIVE_ITEM_REQUEST",
    ARCHIVE_ITEM_FAIL: "ARCHIVE_ITEM_FAIL",

    DELETE_ITEM: "DELETE_ITEM",
    DELETE_ITEM_REQUEST: "DELETE_ITEM_REQUEST",
    DELETE_ITEM_FAIL: "DELETE_ITEM_FAIL",

    CREATE_QUANT: "CREATE_QUANT",
    CREATE_QUANT_REQUEST: "CREATE_QUANT_REQUEST",
    CREATE_QUANT_FAIL: "CREATE_QUANT_FAIL",

    UPDATE_QUANT: "UPDATE_QUANT",
    UPDATE_QUANT_REQUEST: "UPDATE_QUANT_REQUEST",
    UPDATE_QUANT_FAIL: "UPDATE_QUANT_FAIL",

    GET_QUANTS: "GET_QUANTS",
    GET_QUANTS_REQUEST: "GET_QUANTS_REQUEST",
    GET_QUANTS_FAIL: "GET_QUANTS_FAIL",

    GET_QUANT: "GET_QUANT",
    GET_QUANT_REQUEST: "GET_QUANT_REQUEST",
    GET_QUANT_FAIL: "GET_QUANT_FAIL",

    CREATE_MOVE: "CREATE_MOVE",
    CREATE_MOVE_REQUEST: "CREATE_MOVE_REQUEST",
    CREATE_MOVE_FAIL: "CREATE_MOVE_FAIL",

    UPDATE_MOVE: "UPDATE_MOVE",
    UPDATE_MOVE_REQUEST: "UPDATE_MOVE_REQUEST",
    UPDATE_MOVE_FAIL: "UPDATE_MOVE_FAIL",

    SUBMIT_MOVE: "SUBMIT_MOVE",
    SUBMIT_MOVE_REQUEST: "SUBMIT_MOVE_REQUEST",
    SUBMIT_MOVE_FAIL: "SUBMIT_MOVE_FAIL",

    DECLINE_MOVE: "DECLINE_MOVE",
    DECLINE_MOVE_REQUEST: "DECLINE_MOVE_REQUEST",
    DECLINE_MOVE_FAIL: "DECLINE_MOVE_FAIL",

    APPROVE_MOVE: "APPROVE_MOVE",
    APPROVE_MOVE_REQUEST: "APPROVE_MOVE_REQUEST",
    APPROVE_MOVE_FAIL: "APPROVE_MOVE_FAIL",

    GET_MOVES: "GET_MOVES",
    GET_MOVES_REQUEST: "GET_MOVES_REQUEST",
    GET_MOVES_FAIL: "GET_MOVES_FAIL",

    GET_MOVE: "GET_MOVE",
    GET_MOVE_REQUEST: "GET_MOVE_REQUEST",
    GET_MOVE_FAIL: "GET_MOVE_FAIL",

    DELETE_MOVE: "DELETE_MOVE",
    DELETE_MOVE_REQUEST: "DELETE_MOVE_REQUEST",
    DELETE_MOVE_FAIL: "DELETE_MOVE_FAIL",

    GET_MOVE_BY_USER : "GET_MOVE_BY_USER",
    GET_MOVE_BY_USER_REQUEST: "GET_MOVE_BY_USER_REQUEST",
    GET_MOVE_BY_USER_FAIL: "GET_MOVE_BY_USER_FAIL",

    GET_MOVE_BY_LOCATION: "GET_MOVE_BY_LOCATION",
    GET_MOVE_BY_LOCATION_REQUEST: "GET_MOVE_BY_LOCATION_REQUEST",
    GET_MOVE_BY_LOCATION_FAIL: "GET_MOVE_BY_LOCATION_FAIL",

    GET_MOVE_BY_ITEM: "GET_MOVE_BY_ITEM",
    GET_MOVE_BY_ITEM_REQUEST: "GET_MOVE_BY_ITEM_REQUEST",
    GET_MOVE_BY_ITEM_FAIL: "GET_MOVE_BY_ITEM_FAIL",

}