import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Backdrop,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  IconButton,
  CircularProgress,
  Divider,
  Chip,
  Avatar
} from "@material-ui/core";
import clsx from "clsx";


import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import CloseIcon from "@material-ui/icons/Close";

import { useDispatch, useSelector } from "react-redux";
import { TablePaginationActions } from "../../../utils/TablePaginationAction";
import Search from "../../../utils/Search";
import Feedback from "../../../utils/Feedback";
import { getMovesByItem } from "../../../actions/inventoryAction";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),

    // width: "100%",
    overflow: "hidden",
  },
  title: {
    fontSize: 13,
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginBottom: 30,
  },
  btn2: {
    marginBottom: 30,
    marginLeft: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 350,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.8rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  info: {
    padding: theme.spacing(4),
    marginBottom: 30,
  },
  typo: {
    lineHeight: 1,
    fontWeight: 900,
  },
  avatar: {
    margin: "auto",
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: 30,
  },
  
  link: {
    color: theme.palette.type === "light" ? "#333" : "#fff",
  },
}));

const ItemMoveModal = ({ openAllMove, handleCloseAllMove, id }) => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, loading, moveByItem } = useSelector((state) => state.inventory);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

    useEffect(() => {
        if (id){
          dispatch(getMovesByItem(id))
        }
        
    }, [dispatch, id]);
    

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  let filteredRows = () =>
    moveByItem === undefined
      ? []
      : moveByItem?.filter((row) => {
          if (search !== "") {
            return (
                row.date?.split("T")[0].toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                row.item?.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                row.moveType.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                row.source?.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                row.destination?.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                row.status?.toLowerCase().indexOf(search.toLowerCase()) !== -1  

            );
          } else {
            return row;
          }
        });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, Array(50) - page * rowsPerPage);

  return (
    <div>

      {feed.success === false && (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog open={openAllMove} onClose={handleCloseAllMove} fullWidth maxWidth="md" className={classes.dialog}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                All Item Moves
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseAllMove}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
        <Grid container alignItems="center" spacing={3}>
            
            <Grid item>
            <Search
                updateSearch={updateSearch}
                placeholder="Search..."
                search={search}
                ResetSearch={ResetSearch}
            />
            </Grid>
        </Grid>
        
        <Paper>
        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
              <TableCell>Initiator</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row, i) => {
                return (
                    <TableRow hover key={row._id}>

                    <TableCell component="th" scope="row">
                            <Chip
                                avatar={
                                <Avatar
                                    alt={row.createdBy?.photo}
                                    src={row.createdBy?.photo}
                                />
                                }
                                label={row.createdBy?.displayname}
                                className={classes.chip}
                            />
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.date?.split("T")[0]}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Typography variant="body2" noWrap>
                            {row.item?.name}
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.moveType}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.quantity} {row.item?.uom?.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        ${row.value}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.source?.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.destination?.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Chip
                            label={row.status}
                            variant="outlined"
                            className={row.status === "Draft" ? clsx(classes.chip): row.status === "Declined" ? clsx(classes.chip, classes.statusRed) : clsx(classes.chip, classes.statusDarkGreen)}
                        />

                    </TableCell>

                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{
                      fontWeight: 900,
                      color: "#616161",
                      textAlign: "center",
                    }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
          
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ItemMoveModal;
