import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  Paper,
  Fab
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../actions/usersAction";
import {getCrews, getCrew, updateCrew} from "../../../actions/toolboxAction";

import Feedback from "../../../utils/Feedback";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    color: theme.palette.primary.main,
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dialog: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
    minWidth: 420,
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    },
},
paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
},
[theme.breakpoints.down("sm")]: {
    width: "100%",
},
[theme.breakpoints.down("xs")]: {
    minWidth: "100%",
},

fab: {
    right: theme.spacing(-1),
},

}));

const INIT_STATE = {
    name: "",
    supervisors: [],
    members: [],
    error: null,
    success: false,
    loading: false,
};

const EditCrewModal = ({ openEdit, handleCloseEdit, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { crew, error, loading } = useSelector((state) => state.toolbox);
  const { users, profile } = useSelector((state) => state.users);

  let isAdminGuard = profile
    ? (profile &&
        // profile.role && profile.role.name === "admin")
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isToolboxAdmin = profile
    ? (profile &&
        // profile.role && profile.role.name === "toolbox_admin")
        profile.role && profile.role.map((role) => role.name).includes("toolbox_admin"))
        : false;

    let isToolboxSupervisor = profile
    ? (profile &&
        // profile.role && profile.role.name === "toolbox_supervisor")
        profile.role && profile.role.map((role) => role.name).includes("toolbox_supervisor"))
        : false;

  useEffect(() => {
    dispatch(getUsers());
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    if (isAdminGuard || isToolboxAdmin) {
      dispatch(getCrew(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
        name: crew && crew.name,
        supervisors: crew && crew.supervisors,
        members: crew && crew.members,
    }));
  }, [crew]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      name: state.name,
      supervisors: state.supervisors,
      members: state.members,
      id,
    };
    dispatch(updateCrew(data));
    setTimeout(() => {
      handleCloseEdit();
      dispatch(getCrews());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Crew Updated Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleChangeMembers = (index) => (e) => {
    e.persist();
    const { name, value } = e.target;
    const list = [...state.members];
    list[index][name] = value;
    setState((prevState) => ({ ...prevState, members: list }));
};


  const handleAddMembers = (e) => {
    e.persist();
    const data = {
        name: '',
        phone: '',
        employeeNo: '',
        trade: ''
    }
    setState((prevState) => ({ ...prevState, members: [...prevState.members, data] }));
  };

  const handleRemoveMembers = (index) => (e) => {
    e.persist();
    const list = [...state.members];
    list.splice(index, 1);
    setState((prevState) => ({ ...prevState, members: list }));
};

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.name

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth maxWidth="md" className={classes.dialog}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit Crew Data
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseEdit}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="name"
                  fullWidth
                  variant="outlined"
                  label="Crew Name"
                  onChange={handleChange}
                  value={state.name || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                    <Autocomplete 
                        multiple 
                        id="supervisors"
                        value={state.supervisors || []}    
                        fullWidth
                        options={users} 
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.displayname} 
                        onChange={(e, value) => setState((prevState) => ({ ...prevState, supervisors: value }))} 
                        renderInput={(params) => 
                        <TextField {...params} variant="outlined" label="Supervisors" />} 
                    />
                </FormControl>
              </Grid>
              {state.members && state.members.length > 0 && (
                    <Paper className={classes.paper} variant="outlined" square>
                        <Typography variant="body1">
                            Crew Members
                        </Typography>
                        {state.members.map((item, index) => (
                        <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                            <Grid item xs={6} sm={3} md={3}>
                                <TextField
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    label="Name"
                                    onChange={handleChangeMembers(index)}
                                    value={item.name || ""}
                                    required
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3} md={3}>
                                <TextField
                                    name="employeeNo"
                                    fullWidth
                                    variant="outlined"
                                    label="Employee No"
                                    onChange={handleChangeMembers(index)}
                                    value={item.employeeNo || ""}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={5} sm={3} md={3}>
                                <TextField
                                    name="trade"
                                    fullWidth
                                    variant="outlined"
                                    label="Trade"
                                    onChange={handleChangeMembers(index)}
                                    value={item.trade || ""}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={5} sm={2} md={2}>
                                <TextField
                                    name="phone"
                                    fullWidth
                                    variant="outlined"
                                    label="Phone"
                                    onChange={handleChangeMembers(index)}
                                    value={item.phone || ""}
                                    size="small"
                                />
                            </Grid>
                            
                            
                            <Grid item xs={2} sm={1} md={1}>
                                <IconButton color="secondary" onClick={handleRemoveMembers(index)}>
                                    <CloseIcon />
                                </IconButton>
                        </Grid>

                        </Grid>
                        ))}

                    </Paper>
                )}
                <Typography variant="overline" align="center" className={classes.title2}>
                    Add Crew Members
                </Typography>
                    <Fab color="primary" aria-label="add" size="medium" className={classes.fab} onClick={handleAddMembers}>
                        <AddIcon />
                    </Fab>
              
            </Grid>
            <Typography align='center'>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                endIcon={<UpdateIcon />}
                className={classes.btn}
                disabled={isValid() || loading}
              >
                {loading ? "Loading..." : "Update"}
              </Button>
            </Typography> 
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditCrewModal;
