import {
    CREATE_TESTBED,
    CREATE_TESTBED_REQUEST,
    CREATE_TESTBED_FAIL,
    SUBMIT_TESTBED,
    SUBMIT_TESTBED_REQUEST,
    SUBMIT_TESTBED_FAIL,
    UPDATE_TESTBED,
    UPDATE_TESTBED_REQUEST,
    UPDATE_TESTBED_FAIL,
    SET_TESTBED_DRAFT,
    SET_TESTBED_DRAFT_REQUEST,
    SET_TESTBED_DRAFT_FAIL,
    SET_TESTBED_SERVICED,
    SET_TESTBED_SERVICED_REQUEST,
    SET_TESTBED_SERVICED_FAIL,
    GET_TESTBED,
    GET_TESTBED_REQUEST,
    GET_TESTBED_FAIL,
    GET_TESTBEDS,
    GET_TESTBEDS_REQUEST,
    GET_TESTBEDS_FAIL,
    GET_TESTBEDS_BY_USER,
    GET_TESTBEDS_BY_USER_REQUEST,
    GET_TESTBEDS_BY_USER_FAIL,
    GET_TESTBEDS_BY_LOCATION,
    GET_TESTBEDS_BY_LOCATION_REQUEST,
    GET_TESTBEDS_BY_LOCATION_FAIL,
    DELETE_TESTBED,
    DELETE_TESTBED_REQUEST,
    DELETE_TESTBED_FAIL,

} from "../types/testbedTypes"

const initState = {
    testbed: null,
    testbeds: [],
    testbedsByUser: [],
    error: null,
    loading: false,
  };

  const testbedReducer = (state = initState, action) => {
    switch (action.type) {
        case CREATE_TESTBED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_TESTBED:
            return {
                ...state,
                testbed: action.payload.data,
                loading: false,
            };
        case CREATE_TESTBED_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UPDATE_TESTBED_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_TESTBED:
            return {
                ...state,
                testbed: action.payload.data,
                loading: false
            }
        case UPDATE_TESTBED_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_TESTBED_DRAFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_TESTBED_DRAFT:
            return {
                ...state,
                testbed: action.payload.data,
                loading: false
            }
        case SET_TESTBED_DRAFT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_TESTBED_SERVICED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_TESTBED_SERVICED:
            return {
                ...state,
                testbed: action.payload.data,
                loading: false
            }
        case SET_TESTBED_SERVICED_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SUBMIT_TESTBED_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SUBMIT_TESTBED: 
            return {
                ...state,
                testbed: action.payload.data,
                loading: false
            }
        case SUBMIT_TESTBED_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case GET_TESTBEDS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_TESTBEDS:
            return {
                ...state,
                loading: false,
                testbeds: action.payload.data
            };
        case GET_TESTBEDS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_TESTBED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_TESTBED:
            return {
                ...state,
                loading: false,
                testbed: action.payload.data,
            };
        case GET_TESTBED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_TESTBEDS_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_TESTBEDS_BY_USER:
            return {
                ...state,
                loading: false,
                testbedsByUser: action.payload.data
            };
        case GET_TESTBEDS_BY_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_TESTBEDS_BY_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_TESTBEDS_BY_LOCATION:
            return {
                ...state,
                loading: false,
                testbeds: action.payload.data
            };
        case GET_TESTBEDS_BY_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        case DELETE_TESTBED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_TESTBED:
            return {
                ...state,
                loading: false,
                testbed: action.payload,
            };
        case DELETE_TESTBED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
    };

    export default testbedReducer;

