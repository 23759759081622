import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const ToolboxGuard = ({ children, ...rest }) => {
  const token = localStorage.getItem("token:data");

  const { profile } = useSelector((state) => state.users);

  let isVehicleAdminGuard = profile
    // ? (profile.role && profile.role.name === "vehicle_admin") || (profile.role && profile.role.name === "admin") || (profile.role && profile.role.name === "vehicle_manager") || (profile.role && profile.role.name === "vehicle_supervisor")
    ? (profile.role && 
        ((profile.role.map((role) => role.name).includes("admin")) || (profile.role.map((role) => role.name).includes("vehicle_admin")) || (profile.role.map((role) => role.name).includes("vehicle_manager")) || (profile.role.map((role) => role.name).includes("vehicle_supervisor")))
      )
    : false;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token && isVehicleAdminGuard ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
};

export default ToolboxGuard;
