import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { createStore, getStores} from "../../../actions/inventoryAction";
import { getLocations, getUsers } from "../../../actions/usersAction";
import Feedback from "../../../utils/Feedback";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },
  dialog: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl2: {
    textAlign: "left",
    minWidth: 420,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },
    
paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
},
[theme.breakpoints.down("sm")]: {
    width: "100%",
},
[theme.breakpoints.down("xs")]: {
    minWidth: "100%",
},
fab: {
    right: theme.spacing(-1),
},

}));

const INIT_STATE = {
    name: "",
    tpye: "",
    location: undefined,
    manager: undefined,
};

const CreateStoreModal = ({ openCreate, handleCloseCreate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, loading, stores } = useSelector((state) => state.inventory);
  const { profile, locations, users } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getUsers());
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));

  };


  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        name: state.name,
        type: state.type,
        location: state.location,
        manager: state.manager,
    };
    dispatch(createStore(data));

    setTimeout(() => {
      handleCloseCreate();
      dispatch(getStores());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Store Created Successfully.",
        success: true,
      }));
    }, 1500);
  };


  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () => !state.name

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openCreate}
        onClose={handleCloseCreate}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Store
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseCreate}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  fullWidth
                  variant="outlined"
                  label="Name"
                  onChange={handleChange}
                  value={state.name || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                        Store Type
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        fullWidth
                        value={state.type || ""}
                        name="type"
                        onChange={handleChange}
                        variant="outlined"
                    >
                        <MenuItem value="Internal">Internal</MenuItem>
                        <MenuItem value="External">External</MenuItem>
                        <MenuItem value="Vendor">Vendor</MenuItem>
                        <MenuItem value="Shrinkage">Shrinkage</MenuItem>
                    </Select>
                </FormControl>

              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl2}>
                    <InputLabel id="location">
                        Location
                    </InputLabel>
                    <Select
                    labelId="location"
                    id="location"
                    fullWidth
                    value={state.location || ""}
                    name="location"
                    onChange={handleChange}
                    >
                    <MenuItem value={null}>
                        <em>None</em>
                    </MenuItem>
                    {locations &&
                        locations.map((location) => (
                        <MenuItem key={location._id} value={location._id}>
                            {location.displayname}
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl2}>
                    <InputLabel id="manager">
                        Manager
                    </InputLabel>
                    <Select
                    labelId="manager"
                    id="manager"
                    fullWidth
                    value={state.manager || ""}
                    name="manager"
                    onChange={handleChange}
                    >
                    <MenuItem value={null}>
                        <em>None</em>
                    </MenuItem>
                    {users &&
                        users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                            {user.displayname}
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>
              </Grid>
                
            </Grid>
            <Typography align="center">
              <Button
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                type="submit"
                className={classes.btn}
                disabled={isValid() || loading}
              >
                {loading ? "Loading..." : "Save"}
              </Button>
            </Typography>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateStoreModal;
