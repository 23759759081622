import React, { useEffect } from "react";

import { makeStyles, Typography, Button, lighten } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import Viewer, { Worker, SpecialZoomLevel } from "@phuocng/react-pdf-viewer";
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css'



// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';

// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';


import { useHistory, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getDocument } from "../../actions/documentAction";

const useStyles = makeStyles((theme) => ({

  
  root: {
    // textAlign: "center",
    padding: theme.spacing(3),
  },
  title: {
    // color: "#707070",

    color: lighten(theme.palette.common.black, 0.75),

    display: "block",
    fontSize: 14,
    fontWeight: 900,
  },
  btn: {
    marginBottom: 30,
  },
}));

const DocumentView = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  
  
  useEffect(() => {
    dispatch(getDocument(id));
  }, [dispatch, id]);

  const { document } = useSelector((state) => state.documents || {});
  
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className={classes.root}>
      <Typography variant="overline" gutterBottom className={classes.title}>
        View Document
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={() => history.goBack()}
        startIcon={<ChevronLeftIcon />}
        className={classes.btn}
      >
        Back
      </Button>

      <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
      <div
        style={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          height: '950px',
          overflow: 'auto',}}
      >
        <Viewer 
        fileUrl={document?.file || "https://res.cloudinary.com/dfbarnes/image/upload/v1685120965/documents/default_lmrf2u.pdf"}
        defaultScale={SpecialZoomLevel.PageFit}
        />
  
      </div>
   
      </Worker>

      

    </div>
  );
};

export default DocumentView;