import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  DialogActions,
  ButtonGroup,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../../utils/Feedback";
import SignatureCanvas from 'react-signature-canvas'
import { useRef } from 'react'
import { updateIncident} from "../../../actions/incidentAction";


const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 20,
    marginBottom: 30,
  },
  typo: {
    lineHeight: 1,
    textTransform: "uppercase",
    fontWeight: 600,
  },
  dialog: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",

    },
  },
  container: {
    width: 400,
    height: 300,
    top: "10%",
    left: "10%"
  },
  sigPadContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F5F5F5",
    margin: "auto"
  },
  sigCanvas: {
    width: "100%",
    height: "100%"
  }

}));

const INIT_STATE = {
    reviews: [{ name: "", position: "", signature: "", date: "" }],
};

const IncidentSignature = ({ openSignature, handleCloseSignature, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const { loading, error, incident } = useSelector((state) => state.incident);
  const sigCanvas = useRef()
  const [imageURL, setImageURL] = useState(null)


  React.useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
        reviews: incident && incident.reviews
    }));
  }, [incident]);

  useEffect(() => {
    let data = {
        reviews: incident && incident.reviews?.map((review, index) => 
                     index === id ? {...review, signature: imageURL, date: new Date()} : review),
        signature: imageURL,
        id: incident?._id
    };
    if (imageURL){
        dispatch(updateIncident(data));
    }
   
  }, [imageURL, dispatch]);

  const handleCreate = (e) => {
    e.preventDefault();
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setImageURL(URL);
    handleCloseSignature();
    
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openSignature}
        onClose={handleCloseSignature}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Signature
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseSignature}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.container}>
            <div className={classes.sigPadContainer}>
                <SignatureCanvas
                    canvasProps={{className:classes.sigCanvas}}
                    ref={sigCanvas}
                />           
            </div>
        </DialogContent>
        <DialogActions>
          <ButtonGroup variant="contained">
            <Button color="default" onClick={() => sigCanvas.current.clear()}>
              Clear
            </Button>
            <Button
              color="primary"
              onClick={handleCreate}
              disabled={loading}
            >
              {loading ? "Loading..." : "Sign"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IncidentSignature;
