import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
  Tooltip,
  Button,
  ButtonGroup,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Paper,
  Badge,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import BuildIcon from '@material-ui/icons/Build';
import ListAltIcon from "@material-ui/icons/ListAlt";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";
import AddIcon from "@material-ui/icons/Add"
import { Autocomplete} from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, getLocations } from "../../../actions/usersAction";
import {getFleets, getFleet, updateFleet, fleetLost, fleetOutOfService, fleetRestore, getFleetByManager, getFleetBySupervisor, getInspectionsByVehicle} from "../../../actions/vehicleAction";
import AllServiceModal from "./ServiceFleetModalAll";
import AllInspectionModal from "./AllVehicleInspectionModal";
import ServiceFleetModal from "./ServiceFleetModal";

import Feedback from "../../../utils/Feedback";
import clsx from "clsx";

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 3,
      top: 3,
      padding: '0 4px',
    },
  }))(Badge);

const useStyles = makeStyles((theme) => ({

  root: {
    padding: theme.spacing(0),
    overflow: "hidden",
  },

  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },
  btnLink: {
    width: "40%",
    height: 50,
    padding:8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // margin: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  btnGroup: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
      },

  },
  dialog: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
    minWidth: 410,
    [theme.breakpoints.down("sm")]: {
        minWidth:350
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    },
},
formcontrol2: {
    textAlign: "left",
    minWidth: 410,
    [theme.breakpoints.down("sm")]: {
        minWidth:350
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },
  divider: {
    height: 40,
    margin: 4,
    width: 2,
  },
  divider2: {
    height: 32
  },
  status:{
    width: "fit-content",
    height: 50,
    padding:8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
  iconButton: {
    paddingLeft: 50,
    paddingRight: 50,
  },

paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
},
paper2:{
    padding: theme.spacing(2),
    textAlign: "center",
    width: "fit-content",
    height: 50,
    display: "flex",
    justifyContent: "center",
    margin: "auto",
},
paper3:{
  // padding: theme.spacing(1),
  textAlign: "center",
  width: "fit-content",
  height: 50,
  display: "flex",
  justifyContent: "center",
},

fab: {
    right: theme.spacing(-1),
},
statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    borderColor: "#ef5350",
  },


}));

const INIT_STATE = {
    name: "",
    supervisor: undefined,
    manager: undefined,
    year: "",
    driver: "",
    registeredTo: "",
    serialNo: "",
    licencePlate: "",
    expiryDate: "",
    tireSize: "",
    tracker: false,
    trackerId: "",
    mileage: "",
    gvwrGreater: false,
    gvwr: "",
    inspectionExpiry: "",
    lastService: "",
    nextService: "",
    location: undefined,
    comment: "",
    error: null,
    success: false,
    loading: false,
};

const EditFleetModal = ({ openEdit, handleCloseEdit, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { fleet, error, loading, inspectionsByVehicle } = useSelector((state) => state.vehicle);
  const { users, profile, locations } = useSelector((state) => state.users);
  
  const [openAllService, setOpenAllService] = useState(false);
  const [openAllInspection, setOpenAllInspection] = useState(false);
  const [openService, setOpenService] = useState(false);
  const [getId, setGetId] = useState("");

  let isAdminGuard = profile
    ? (profile &&
        // profile.role && profile.role.name === "admin")
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isVehicleAdmin = profile
    ? (profile &&
        // profile.role && profile.role.name === "vehicle_admin")
        profile.role && profile.role.map((role) => role.name).includes("vehicle_admin"))
        : false;

    let isVehicleManager = profile
    ? (profile &&
        // profile.role && profile.role.name === "vehicle_manager")
        profile.role && profile.role.map((role) => role.name).includes("vehicle_manager"))
        : false;
    let isVehicleSupervisor = profile
    ? (profile &&
        // profile.role && profile.role.name === "vehicle_supervisor")
        profile.role && profile.role.map((role) => role.name).includes("vehicle_supervisor"))
        : false;


  useEffect(() => {
    dispatch(getUsers());
    dispatch(getLocations());
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    if (isAdminGuard || isVehicleAdmin || isVehicleManager || isVehicleSupervisor) {
      if (id){
        dispatch(getFleet(id));
        dispatch(getInspectionsByVehicle(id))
      }
      
    }
  }, [dispatch, id, isAdminGuard, isVehicleAdmin, isVehicleManager, isVehicleSupervisor]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
        name: fleet && fleet.name,
        supervisor: fleet && fleet.supervisor,
        manager: fleet && fleet.manager,
        year: fleet && fleet.year,
        driver: fleet && fleet.driver,
        registeredTo: fleet && fleet.registeredTo,
        serialNo: fleet && fleet.serialNo,
        licencePlate: fleet && fleet.licencePlate,
        expiryDate: fleet && fleet.expiryDate,
        tireSize: fleet && fleet.tireSize,
        tracker: fleet && fleet.tracker,
        trackerId: fleet && fleet.trackerId,
        mileage: fleet && fleet.mileage,
        gvwrGreater: fleet && fleet.gvwrGreater,
        gvwr: fleet && fleet.gvwr,
        inspectionExpiry: fleet && fleet.inspectionExpiry,
        lastService: fleet && fleet.lastService,
        nextService: fleet && fleet.nextService,
        location: fleet && fleet.location?._id,
        comment: fleet && fleet.comment

    }));
  }, [dispatch,fleet]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        name: state.name,
        supervisor: state.supervisor,
        manager: state.manager,
        year: state.year,
        driver: state.driver,
        registeredTo: state.registeredTo,
        serialNo: state.serialNo,
        licencePlate: state.licencePlate,
        expiryDate: state.expiryDate,
        tireSize: state.tireSize,
        tracker: state.tracker,
        trackerId: state.trackerId,
        mileage: state.mileage,
        gvwrGreater: state.gvwrGreater,
        gvwr: state.gvwr,
        inspectionExpiry: state.inspectionExpiry,
        lastService: state.lastService,
        nextService: state.nextService,
        location: state.location,
        comment: state.comment,
        id
    };
    dispatch(updateFleet(data));
    setTimeout(() => {
      handleCloseEdit();
        if (isVehicleManager){
            dispatch(getFleetByManager(profile._id))
        }
        else if (isVehicleSupervisor){
            dispatch(getFleetBySupervisor(profile._id))
        }
        else{
            dispatch(getFleets());
        }
        setFeed((prev) => ({
            loading: false,
            open: true,
            message: "Fleet Updated Successfully.",
            success: true,
        }));
        }, 1500);
  };

  const handleFleetLost = () => {
    dispatch(fleetLost(id));
    setTimeout(() => {
      handleCloseEdit();
      dispatch(getFleets());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Fleet Marked As Lost Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleFleetOutOfService = () => {
    dispatch(fleetOutOfService(id));
    setTimeout(() => {
      handleCloseEdit();
      dispatch(getFleets());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Fleet Marked As Out of Service Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleFleetRestore = () => {
    dispatch(fleetRestore(id));
    setTimeout(() => {
      handleCloseEdit();
      dispatch(getFleets());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Fleet Restored Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleOpenAllService = (id) => {
    setGetId(id);
    setOpenAllService(true);
  };

  const handleCloseAllService = () => {
    setOpenAllService(false);
  };

  const handleOpenAllInspection = (id) => {
    setGetId(id);
    setOpenAllInspection(true);
  };

  const handleCloseAllInspection = () => {
    setOpenAllInspection(false);
  };

  const handleOpenService = (id) => {
    setGetId(id);
    setOpenService(true);
  };

  const handleCloseService = () => {
    setOpenService(false);
  };

  const isValid = () =>
    !state.name

  return (
    <div>
        <AllServiceModal {...{ openAllService, handleCloseAllService, id: getId }} />
        <AllInspectionModal {...{ openAllInspection, handleCloseAllInspection, id: getId }} />
        <ServiceFleetModal {...{ openService, handleCloseService, id: getId }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth maxWidth="md" className={classes.dialog}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit Fleet Data
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseEdit}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <br/>
   
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={8} sm={3}>
                <Paper variant="outlined" className={classes.paper2}>
                
                    <IconButton color="primary" onClick={() => handleOpenAllInspection(fleet._id)}>
                        <StyledBadge badgeContent={ inspectionsByVehicle && inspectionsByVehicle.length} color="primary" max={99} overlap="rectangular">
                            <Tooltip title="All Inspections">
                                <ListAltIcon />
                            </Tooltip>
                        </StyledBadge>
                    </IconButton>
                    <Divider orientation="vertical" className={classes.divider2}/>
                    <IconButton color="primary" onClick={() => handleOpenAllService(fleet._id)}>
                        <StyledBadge badgeContent={fleet && fleet.serviceHistory?.length} color="primary" max={99} overlap="rectangular">
                            <Tooltip title="All Services & Repairs">
                                <BuildIcon />
                            </Tooltip>
                        </StyledBadge>
                    </IconButton>
                </Paper>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Paper variant="outlined" className={classes.paper3}>
                <IconButton color="primary" onClick={() => handleOpenService(fleet._id)}>
                  <BuildIcon/>
                  <Tooltip title="Record Services & Repairs">
                      <AddIcon />
                  </Tooltip>
                </IconButton>
              </Paper>
            </Grid>
            
            <Grid item xs={6} sm={4}>
              <ButtonGroup className={classes.btnGroup} variant="outlined">
                {fleet && fleet.status !== "Lost" &&
                 <Tooltip title="Mark As Lost">
                  <Button
                      size="small"
                      className={classes.btnLink}
                      color="default"
                      onClick={handleFleetLost}
                  >
                      Lost
                  </Button>
                </Tooltip>
                }
                 {fleet && fleet.status !== "Out of Service" &&
                 <Tooltip title="Mark As Out of Service">
                  <Button
                      size="small"
                      className={classes.btnLink}
                      color="secondary"
                      onClick={handleFleetOutOfService}
                  >
                      Out of Service
                  </Button>
                </Tooltip>
                } 
                {fleet && (fleet.status === "Out of Service" || fleet.status === "Lost" || fleet.active === false) &&
                 <Tooltip title="Restore Fleet">
                  <Button
                      size="small"
                      className={classes.btnLink}
                      color="primary"
                      onClick={handleFleetRestore}
                  >
                      Restore
                  </Button>
                </Tooltip>
                } 
                </ButtonGroup>
              </Grid>

              <Grid item xs={6} sm={3}>
                <Paper variant="outlined" className={fleet && fleet.status === "Ok" ? clsx(classes.status, classes.statusDarkGreen) : 
                    fleet && fleet.status === "Service Required" ? clsx(classes.status, classes.statusOrange) :
                    fleet && fleet.status === "Repair Required" ? clsx(classes.status, classes.statusOrange) :
                    fleet && fleet.status === "Out of Service" ? clsx(classes.status, classes.statusRed) :
                    classes.status}
                >
                    <Typography variant="overline">
                        Status
                    </Typography>
                    <Divider orientation="vertical" className={classes.divider}/>
                    {fleet && fleet.status}
                </Paper>
            </Grid>
        </Grid>

        <br/>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  fullWidth
                  variant="outlined"
                  label="Trade Name"
                  onChange={handleChange}
                  value={state.name || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                    <TextField
                        name="year"
                        fullWidth
                        variant="outlined"
                        label="Year"
                        onChange={handleChange}
                        value={state.year || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <Autocomplete 
                            id="supervisor"
                            options={users}
                            getOptionSelected={(option, value) => option.displayname === state.supervisor?.displayname}
                            getOptionLabel={(option) => option.displayname || ''} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, supervisor: value }))}
                            value={state.supervisor || ''}
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Supervisor" />} 
                        />
                    </FormControl>
                    
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <Autocomplete 
                            id="manager"
                            options={users}
                            getOptionSelected={(option, value) => option.displayname === state.manager?.displayname}
                            getOptionLabel={(option) => option.displayname || ''} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, manager: value }))}
                            value={state.manager || ''}
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Manager" />} 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="driver"
                        fullWidth
                        variant="outlined"
                        label="Driver"
                        onChange={handleChange}
                        value={state.driver || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="registeredTo"
                        fullWidth
                        variant="outlined"
                        label="Registered To"
                        onChange={handleChange}
                        value={state.registeredTo || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                    Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={state.location || ''}
                      name="location"
                      onChange={handleChange}
                      label="Location"
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {locations &&
                        locations.map((location) => (
                          <MenuItem key={location._id} value={location._id}>
                            {location.displayname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="serialNo"
                        fullWidth
                        variant="outlined"
                        label="Serial No"
                        onChange={handleChange}
                        value={state.serialNo || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="licencePlate"
                        fullWidth
                        variant="outlined"
                        label="Licence Plate"
                        onChange={handleChange}
                        value={state.licencePlate || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                      name="expiryDate"
                      fullWidth
                      variant="outlined"
                      label=" Expiry Date"
                      onChange={handleChange}
                      value={state.expiryDate?.split("T")[0] || ''}
                      type="date"     
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="tireSize"
                        fullWidth
                        variant="outlined"
                        label="Tire Size"
                        onChange={handleChange}
                        value={state.tireSize || ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="mileage"
                        fullWidth
                        variant="outlined"
                        label="Mileage"
                        onChange={handleChange}
                        value={state.mileage || ""}
                    />
                </Grid>

                <Grid item xs={10} sm={5}>
                    <Typography variant="subtitle2" align="left">
                        Tracker Installed?
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.tracker || false}
                            name="tracker"
                            color="primary"
                            onChange={(e, value) => {
                                e.persist()
                                setState((prevState) =>({ ...prevState, tracker: value }))
                              }}
                        />
                        }
                    />        
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="trackerId"
                        fullWidth
                        variant="outlined"
                        label="Tracker ID"
                        onChange={handleChange}
                        value={state.trackerId || ""}
                        disabled = {!state.tracker}
                    />
                </Grid>
                <Grid item xs={10} sm={5} md={5} >
                    <Typography variant="subtitle2" align="left">
                        GVWR Greater Than 4500 KG?
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1} >
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={state.gvwrGreater || false}
                            name="gvwrGreater"
                            color="primary"
                            onChange={(e, value) => {
                                e.persist()
                                setState((prevState) =>({ ...prevState, gvwrGreater: value }))
                              }}
                        />
                        }
                    />        
                </Grid>
                {!state.gvwrGreater && (
                  <Grid item xs={12} sm={6}>
                      <TextField
                          name="gvwr"
                          fullWidth
                          variant="outlined"
                          label="GVWR"
                          onChange={handleChange}
                          value={state.gvwr || ""}
                      />
                  </Grid>
                )}
                {state.gvwrGreater && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="inspectionExpiry"
                        fullWidth
                        variant="outlined"
                        label="Commercial Inspection Expiry"
                        onChange={handleChange}
                        value={state.inspectionExpiry?.split("T")[0] || ''}
                        type="date"     
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                <TextField
                      name="lastService"
                      fullWidth
                      variant="outlined"
                      label=" Last Service Date"
                      onChange={handleChange}
                      value={state.lastService?.split("T")[0] || ''}
                      type="date"     
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                      name="nextService"
                      fullWidth
                      variant="outlined"
                      label=" Next Service Due Date"
                      onChange={handleChange}
                      value={state.nextService?.split("T")[0] || ''}
                      type="date"     
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        name="comment"
                        fullWidth
                        multiline
                        variant="outlined"
                        label="Comment"
                        onChange={handleChange}
                        value={state.comment || ""}
                    />
                </Grid>
              
            </Grid>
            <Typography align='center'>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                endIcon={<UpdateIcon />}
                className={classes.btn}
                disabled={isValid() || loading}
              >
                {loading ? "Loading..." : "Update"}
              </Button>
            </Typography> 
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditFleetModal;
