import React from "react";
import {
  ImageList,
  makeStyles,
  Paper,
  Typography,
  ImageListItem,
  Button,
  lighten

} from "@material-ui/core";

import ButtonIcon from "@material-ui/core/Button";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {

    color: lighten(theme.palette.common.black, 0.75),
    display: "block",
    fontSize: 14,
    fontWeight: 900,
  },

  btn: {
    marginBottom: 30,
  },
  paper: {
    padding: theme.spacing(2),
    // height: 120,
  },
  
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  icon: {
    width: 50,
    height: 50,
    fill: "#bdbdbd",
  },

    inventory: {
        width: 80,
        height: 80,
        fill: "#34495E",
    },

    vehicle: {
        width: 80,
        height: 80,
        fill: "#515A5A",
    },
    awp: {
      width: 80,
      height: 80,
      fill: "#674C02",
    },
    forklift: {
      width: 80,
      height: 80,
      fill: "#7F8904",
    },
    harness: {
      width: 80,
      height: 80,
      fill: "#56020E",
    },

  imageListItem: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(-2),
    },
  },
  imageList: { 
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(1),

    },
    },


}));
const Quality = () => {
  const classes = useStyles();

  const history = useHistory();

  const route = (path) => {
    history.push(path);
  };
  const { profile } = useSelector((state) => state.users);

  let isAdminGuard = profile
    ? (profile &&
        // profile.role && profile.role.name === "admin")
        profile.role && profile.role.map((role) => role.name).includes("admin"))
     : false;

  let isInventoryAdmin = profile
    ? (profile &&
        // profile.role && profile.role.name === "toolbox_admin")
        profile.role && profile.role.map((role) => role.name).includes("inventory_admin"))
      : false;

  let isInventoryUser = profile
    ? (profile &&
        // profile.role && profile.role.name === "toolbox_supervisor")
        profile.role && profile.role.map((role) => role.name).includes("inventory_user"))
      : false;


  return (
    <div className={classes.root}>
        <Typography variant="overline" gutterBottom className={classes.title}>
            Quality Processes
        </Typography>
        <Button
            color="primary"
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ChevronLeftIcon />}
            className={classes.btn}
        >
            Back
        </Button>
      <Paper className={classes.paper}>
        
        <div>
            <ImageList rowHeight={160}  cols={4}>
                {(isAdminGuard || isInventoryAdmin || isInventoryUser) && (
                <ImageListItem className={classes.imageListItem}>
                    <ButtonIcon onClick={() => route("/inventory/move")}>
                        <SyncAltIcon className={classes.inventory} />
                    </ButtonIcon>
                    <Typography variant="subtitle2">
                        Inventory Move
                    </Typography>

                </ImageListItem>
                )}
            
                

            </ImageList>
            
        </div>
      </Paper>
     
    </div>
  );
};

export default Quality;
