module.exports = {
    CREATE_FLEET: "CREATE_FLEET",
    CREATE_FLEET_REQUEST: "CREATE_FLEET_REQUEST",
    CREATE_FLEET_FAIL: "CREATE_FLEET_FAIL",

    GET_FLEET: "GET_FLEET",
    GET_FLEET_REQUEST: "GET_FLEET_REQUEST",
    GET_FLEET_FAIL: "GET_FLEET_FAIL",

    GET_FLEET_BY_SUPERVISOR: "GET_FLEET_BY_SUPERVISOR",
    GET_FLEET_BY_SUPERVISOR_REQUEST: "GET_FLEET_BY_SUPERVISOR_REQUEST",
    GET_FLEET_BY_SUPERVISOR_FAIL: "GET_FLEET_BY_SUPERVISOR_FAIL",

    GET_FLEET_BY_MANAGER: "GET_FLEET_BY_MANAGER",
    GET_FLEET_BY_MANAGER_REQUEST: "GET_FLEET_BY_MANAGER_REQUEST",
    GET_FLEET_BY_MANAGER_FAIL: "GET_FLEET_BY_MANAGER_FAIL",

    GET_FLEET_BY_LOCATION: "GET_FLEET_BY_LOCATION",
    GET_FLEET_BY_LOCATION_REQUEST: "GET_FLEET_BY_LOCATION_REQUEST",
    GET_FLEET_BY_LOCATION_FAIL: "GET_FLEET_BY_LOCATION_FAIL",

    GET_FLEETS: "GET_FLEETS",
    GET_FLEETS_REQUEST: "GET_FLEETS_REQUEST",
    GET_FLEETS_FAIL: "GET_FLEETS_FAIL",

    GET_ARCHIVED_FLEETS: "GET_ARCHIVED_FLEETS",
    GET_ARCHIVED_FLEETS_REQUEST: "GET_ARCHIVED_FLEETS_REQUEST",
    GET_ARCHIVED_FLEETS_FAIL: "GET_ARCHIVED_FLEETS_FAIL",

    UPDATE_FLEET: "UPDATE_FLEET",
    UPDATE_FLEET_REQUEST: "UPDATE_FLEET_REQUEST",
    UPDATE_FLEET_FAIL: "UPDATE_FLEET_FAIL",

    FLEET_LOST: "FLEET_LOST",
    FLEET_LOST_REQUEST: "FLEET_LOST_REQUEST",
    FLEET_LOST_FAIL: "FLEET_LOST_FAIL",

    FLEET_OUT_OF_SERVICE: "FLEET_OUT_OF_SERVICE",
    FLEET_OUT_OF_SERVICE_REQUEST: "FLEET_OUT_OF_SERVICE_REQUEST",
    FLEET_OUT_OF_SERVICE_FAIL: "FLEET_OUT_OF_SERVICE_FAIL",

    FLEET_RESTORE: "FLEET_RESTORE",
    FLEET_RESTORE_REQUEST: "FLEET_RESTORE_REQUEST",
    FLEET_RESTORE_FAIL: "FLEET_RESTORE_FAIL",

    CREATE_SERVICE: "CREATE_SERVICE",
    CREATE_SERVICE_REQUEST: "CREATE_SERVICE_REQUEST",
    CREATE_SERVICE_FAIL: "CREATE_SERVICE_FAIL",

    DELETE_SERVICE: "DELETE_SERVICE",
    DELETE_SERVICE_REQUEST: "DELETE_SERVICE_REQUEST",
    DELETE_SERVICE_FAIL: "DELETE_SERVICE_FAIL",

    DELETE_FLEET: "DELETE_FLEET",
    DELETE_FLEET_REQUEST: "DELETE_FLEET_REQUEST",
    DELETE_FLEET_FAIL: "DELETE_FLEET_FAIL",

    ARCHIVE_FLEET: "ARCHIVE_FLEET",
    ARCHIVE_FLEET_REQUEST: "ARCHIVE_FLEET_REQUEST",
    ARCHIVE_FLEET_FAIL: "ARCHIVE_FLEET_FAIL",

    CREATE_DAILYINSPECTION: "CREATE_DAILYINSPECTION",
    CREATE_DAILYINSPECTION_REQUEST: "CREATE_DAILYINSPECTION_REQUEST",
    CREATE_DAILYINSPECTION_FAIL: "CREATE_DAILYINSPECTION_FAIL",

    SUBMIT_DAILYINSPECTION: "SUBMIT_DAILYINSPECTION",
    SUBMIT_DAILYINSPECTION_REQUEST: "SUBMIT_DAILYINSPECTION_REQUEST",
    SUBMIT_DAILYINSPECTION_FAIL: "SUBMIT_DAILYINSPECTION_FAIL",

    UPDATE_DAILYINSPECTION: "UPDATE_DAILYINSPECTION",
    UPDATE_DAILYINSPECTION_REQUEST: "UPDATE_DAILYINSPECTION_REQUEST",
    UPDATE_DAILYINSPECTION_FAIL: "UPDATE_DAILYINSPECTION_FAIL",

    SET_DAILYINSPECTION_DRAFT: "SET_DAILYINSPECTION_DRAFT",
    SET_DAILYINSPECTION_DRAFT_REQUEST: "SET_DAILYINSPECTION_DRAFT_REQUEST",
    SET_DAILYINSPECTION_DRAFT_FAIL: "SET_DAILYINSPECTION_DRAFT_FAIL",

    CREATE_WEEKLYINSPECTION: "CREATE_WEEKLYINSPECTION",
    CREATE_WEEKLYINSPECTION_REQUEST: "CREATE_WEEKLYINSPECTION_REQUEST",
    CREATE_WEEKLYINSPECTION_FAIL: "CREATE_WEEKLYINSPECTION_FAIL",

    SUBMIT_WEEKLYINSPECTION: "SUBMIT_WEEKLYINSPECTION",
    SUBMIT_WEEKLYINSPECTION_REQUEST: "SUBMIT_WEEKLYINSPECTION_REQUEST",
    SUBMIT_WEEKLYINSPECTION_FAIL: "SUBMIT_WEEKLYINSPECTION_FAIL",

    UPDATE_WEEKLYINSPECTION: "UPDATE_WEEKLYINSPECTION",
    UPDATE_WEEKLYINSPECTION_REQUEST: "UPDATE_WEEKLYINSPECTION_REQUEST",
    UPDATE_WEEKLYINSPECTION_FAIL: "UPDATE_WEEKLYINSPECTION_FAIL",

    SET_WEEKLYINSPECTION_DRAFT: "SET_WEEKLYINSPECTION_DRAFT",
    SET_WEEKLYINSPECTION_DRAFT_REQUEST: "SET_WEEKLYINSPECTION_DRAFT_REQUEST",
    SET_WEEKLYINSPECTION_DRAFT_FAIL: "SET_WEEKLYINSPECTION_DRAFT_FAIL",

    GET_INSPECTIONS: "GET_INSPECTIONS",
    GET_INSPECTIONS_REQUEST: "GET_INSPECTIONS_REQUEST",
    GET_INSPECTIONS_FAIL: "GET_INSPECTIONS_FAIL",

    GET_INSPECTION: "GET_INSPECTION",
    GET_INSPECTION_REQUEST: "GET_INSPECTION_REQUEST",
    GET_INSPECTION_FAIL: "GET_INSPECTION_FAIL",

    GET_INSPECTIONS_BY_USER: "GET_INSPECTIONS_BY_USER",
    GET_INSPECTIONS_BY_USER_REQUEST: "GET_INSPECTIONS_BY_USER_REQUEST",
    GET_INSPECTIONS_BY_USER_FAIL: "GET_INSPECTIONS_BY_USER_FAIL",

    GET_INSPECTIONS_BY_VEHICLE: "GET_INSPECTIONS_BY_VEHICLE",
    GET_INSPECTIONS_BY_VEHICLE_REQUEST: "GET_INSPECTIONS_BY_VEHICLE_REQUEST",
    GET_INSPECTIONS_BY_VEHICLE_FAIL: "GET_INSPECTIONS_BY_VEHICLE_FAIL",

    GET_INSPECTIONS_BY_LOCATION: "GET_INSPECTIONS_BY_LOCATION",
    GET_INSPECTIONS_BY_LOCATION_REQUEST: "GET_INSPECTIONS_BY_LOCATION_REQUEST",
    GET_INSPECTIONS_BY_LOCATION_FAIL: "GET_INSPECTIONS_BY_LOCATION_FAIL",

    DELETE_INSPECTION: "DELETE_INSPECTION",
    DELETE_INSPECTION_REQUEST: "DELETE_INSPECTION_REQUEST",
    DELETE_INSPECTION_FAIL: "DELETE_INSPECTION_FAIL",
}