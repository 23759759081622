import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {};

//OLD WAY TO CONFIGURE STORE

// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension"

// const middleware = [thunk];
// const middleWareEnhancer = applyMiddleware(...middleware);

// const composedEnhancers = composeWithDevTools({
//   trace: true,
// });

// const store = createStore(
//   rootReducer,
//   initialState,
//   composedEnhancers(middleWareEnhancer)
// );

//NEW WAY TO CONFIGURE STORE

const store = configureStore({
  initialState,
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false
  }).concat(thunk),
  devTools: true
});

export default store;
