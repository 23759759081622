import axios from "axios";
import {
    CREATE_TESTBED,
    CREATE_TESTBED_REQUEST,
    CREATE_TESTBED_FAIL,
    SUBMIT_TESTBED,
    SUBMIT_TESTBED_REQUEST,
    SUBMIT_TESTBED_FAIL,
    UPDATE_TESTBED,
    UPDATE_TESTBED_REQUEST,
    UPDATE_TESTBED_FAIL,
    SET_TESTBED_DRAFT,
    SET_TESTBED_DRAFT_REQUEST,
    SET_TESTBED_DRAFT_FAIL,
    SET_TESTBED_SERVICED,
    SET_TESTBED_SERVICED_REQUEST,
    SET_TESTBED_SERVICED_FAIL,
    GET_TESTBED,
    GET_TESTBED_REQUEST,
    GET_TESTBED_FAIL,
    GET_TESTBEDS,
    GET_TESTBEDS_REQUEST,
    GET_TESTBEDS_FAIL,
    GET_TESTBEDS_BY_USER,
    GET_TESTBEDS_BY_USER_REQUEST,
    GET_TESTBEDS_BY_USER_FAIL,
    GET_TESTBEDS_BY_LOCATION,
    GET_TESTBEDS_BY_LOCATION_REQUEST,
    GET_TESTBEDS_BY_LOCATION_FAIL,
    DELETE_TESTBED,
    DELETE_TESTBED_REQUEST,
    DELETE_TESTBED_FAIL,

} from "../types/testbedTypes"

const token = localStorage.getItem("token:data");
export const createInspection = (testbed) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_TESTBED_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/testbed/inspection`, testbed, config);
        dispatch({
            type: CREATE_TESTBED,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_TESTBED_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const submitInspection = (testbed) => async (dispatch) => {
    
    try {
        dispatch({
            type: SUBMIT_TESTBED_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/testbed/inspection/submit`, testbed, config);
        dispatch({
            type: SUBMIT_TESTBED,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUBMIT_TESTBED_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const updateInspection = (testbed) => async (dispatch) => {
    
    try {
        dispatch({
            type: UPDATE_TESTBED_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/testbed/inspection/${testbed.id}`, testbed, config);
        dispatch({
            type: UPDATE_TESTBED,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_TESTBED_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setInspectionDraft = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_TESTBED_DRAFT_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/testbed/inspection/draft/${id}`, null, config);
        dispatch({
            type: SET_TESTBED_DRAFT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_TESTBED_DRAFT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setInspectionServiced = (service) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_TESTBED_SERVICED_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/testbed/inspection/serviced/${service.id}`, service, config);
        dispatch({
            type: SET_TESTBED_SERVICED,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_TESTBED_SERVICED_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}


export const getInspections = () => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_TESTBEDS_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/testbed/inspections`, config);
        dispatch({
            type: GET_TESTBEDS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_TESTBEDS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
                });
    }
}

export const getInspection = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_TESTBED_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/testbed/inspection/${id}`, config);
        dispatch({
            type: GET_TESTBED,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_TESTBED_FAIL,
            payload:
                error.response && error.response.data?.error
                ? error.response.data?.error
                : error.request
                ? error.request.data?.error
                : error.message,
        });
    }
}

export const getInspectionsByUser = () => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_TESTBEDS_BY_USER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/testbed/myinspections/user`, config);
        dispatch({
            type: GET_TESTBEDS_BY_USER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_TESTBEDS_BY_USER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const getInspectionsByLocation = (id) => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_TESTBEDS_BY_LOCATION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/testbed/inspections/location/${id}`, config);
        dispatch({
            type: GET_TESTBEDS_BY_LOCATION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_TESTBEDS_BY_LOCATION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const deleteInspection = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_TESTBED_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/testbed/inspection/${id}`, config);
        dispatch({
            type: DELETE_TESTBED,
        });
    } catch (error) {
        dispatch({
            type: DELETE_TESTBED_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

