import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Badge,

} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";
import ListAltIcon from "@material-ui/icons/ListAlt";

import { useDispatch, useSelector } from "react-redux";
import { getStore, getStores, updateStore } from "../../../actions/inventoryAction";
import { getLocations, getUsers } from "../../../actions/usersAction";
import AllItemModal from "./AllItemModal";

import Feedback from "../../../utils/Feedback";

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 3,
      top: 3,
      padding: '0 4px',
    },
  }))(Badge);

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    color: theme.palette.primary.main,
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dialog: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl2: {
    textAlign: "left",
    minWidth: 420,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },
  
paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
},
paper3:{
    padding: theme.spacing(1),
    textAlign: "center",
    width: "fit-content",
    height: 50,
    display: "flex",
    justifyContent: "center",
  },
[theme.breakpoints.down("sm")]: {
    width: "100%",
},
[theme.breakpoints.down("xs")]: {
    minWidth: "100%",
},

fab: {
    right: theme.spacing(-1),
},

}));

const INIT_STATE = {
    name: "",
    type: "",
    location: undefined,
    manager: undefined,
    error: null,
    success: false,
    loading: false,
};

const EditStoreModal = ({ openEdit, handleCloseEdit, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { store, stores, error, loading } = useSelector((state) => state.inventory);
  const { profile, locations, users } = useSelector((state) => state.users);
  const [openAllItem, setOpenAllItem] = useState(false);
  const [getId, setGetId] = useState("");

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getUsers());
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    dispatch(getStore(id));
  }, [dispatch, id]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
        name: store && store.name,
        type: store && store.type,
        location: store && store.location?._id,
        manager: store && store.manager?._id,
    }));
  }, [store]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      name: state.name,
      type: state.type,
      location: state.location,
      manager: state.manager,
      id,
    };
    dispatch(updateStore(data));
    setTimeout(() => {
      handleCloseEdit();
     
      dispatch(getStores());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Store Updated Successfully.",
        success: true,
      }));
    }, 1500);
  };


  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleOpenAllItem = (id) => {
    setGetId(id);
    setOpenAllItem(true);
  };

  const handleCloseAllItem = () => {
    setOpenAllItem(false);
  };

  const isValid = () => !state.name

  return (
    <div>
      <AllItemModal {...{ openAllItem, handleCloseAllItem, id: getId }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth maxWidth="md" className={classes.dialog}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit Store
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseEdit}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <br/>

        <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={10} sm={10}>
                <Paper variant="outlined" className={classes.paper3}>
                    <IconButton color="primary" onClick={() => handleOpenAllItem(store._id)}>
                        <StyledBadge badgeContent={store && store.currentStock?.length} color="primary" max={99} overlap="rectangular">
                            <Tooltip title="Current Stock">
                                <ListAltIcon />
                            </Tooltip>
                        </StyledBadge>
                    </IconButton>
                </Paper>
            </Grid>
        </Grid>

        <br/>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  fullWidth
                  variant="outlined"
                  label="Name"
                  onChange={handleChange}
                  value={state.name || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                        Store Type
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        fullWidth
                        value={state.type || ""}
                        name="type"
                        onChange={handleChange}
                        variant="outlined"
                    >
                        <MenuItem value="Internal">Internal</MenuItem>
                        <MenuItem value="External">External</MenuItem>
                        <MenuItem value="Vendor">Vendor</MenuItem>
                        <MenuItem value="Shrinkage">Shrinkage</MenuItem>
                    </Select>
                </FormControl>

              </Grid>
              <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.formControl2}>
                <InputLabel id="location">
                    Location
                </InputLabel>
                <Select
                  labelId="location"
                  name="location"
                  fullWidth
                  value={state.location || ""}
                  onChange={handleChange}
                >
                  <MenuItem value={null}>
                    <em>None</em>
                  </MenuItem>
                  {locations &&
                    locations.map((location) => (
                      <MenuItem key={location._id} value={location._id}>
                        {location.displayname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl2}>
                    <InputLabel id="manager">
                        Manager
                    </InputLabel>
                    <Select
                    labelId="manager"
                    id="manager"
                    fullWidth
                    value={state.manager || ""}
                    name="manager"
                    onChange={handleChange}
                    >
                    <MenuItem value={null}>
                        <em>None</em>
                    </MenuItem>
                    {users &&
                        users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                            {user.displayname}
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>
              </Grid>
              
            </Grid>
            <Typography align='center'>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                endIcon={<UpdateIcon />}
                className={classes.btn}
                disabled={isValid() || loading}
              >
                {loading ? "Loading..." : "Update"}
              </Button>
            </Typography> 
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditStoreModal;
