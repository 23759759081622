import {
    CREATE_SWAGER,
    CREATE_SWAGER_REQUEST,
    CREATE_SWAGER_FAIL,
    SUBMIT_SWAGER,
    SUBMIT_SWAGER_REQUEST,
    SUBMIT_SWAGER_FAIL,
    UPDATE_SWAGER,
    UPDATE_SWAGER_REQUEST,
    UPDATE_SWAGER_FAIL,
    SET_SWAGER_DRAFT,
    SET_SWAGER_DRAFT_REQUEST,
    SET_SWAGER_DRAFT_FAIL,
    SET_SWAGER_SERVICED,
    SET_SWAGER_SERVICED_REQUEST,
    SET_SWAGER_SERVICED_FAIL,
    GET_SWAGER,
    GET_SWAGER_REQUEST,
    GET_SWAGER_FAIL,
    GET_SWAGERS,
    GET_SWAGERS_REQUEST,
    GET_SWAGERS_FAIL,
    GET_SWAGERS_BY_USER,
    GET_SWAGERS_BY_USER_REQUEST,
    GET_SWAGERS_BY_USER_FAIL,
    GET_SWAGERS_BY_LOCATION,
    GET_SWAGERS_BY_LOCATION_REQUEST,
    GET_SWAGERS_BY_LOCATION_FAIL,
    DELETE_SWAGER,
    DELETE_SWAGER_REQUEST,
    DELETE_SWAGER_FAIL,

} from "../types/swagerTypes"

const initState = {
    swager: null,
    swagers: [],
    swagersByUser: [],
    error: null,
    loading: false,
  };

  const swagerReducer = (state = initState, action) => {
    switch (action.type) {
        case CREATE_SWAGER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_SWAGER:
            return {
                ...state,
                swager: action.payload.data,
                loading: false,
            };
        case CREATE_SWAGER_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UPDATE_SWAGER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_SWAGER:
            return {
                ...state,
                swager: action.payload.data,
                loading: false
            }
        case UPDATE_SWAGER_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_SWAGER_DRAFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_SWAGER_DRAFT:
            return {
                ...state,
                swager: action.payload.data,
                loading: false
            }
        case SET_SWAGER_DRAFT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SET_SWAGER_SERVICED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_SWAGER_SERVICED:
            return {
                ...state,
                swager: action.payload.data,
                loading: false
            }
        case SET_SWAGER_SERVICED_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case SUBMIT_SWAGER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SUBMIT_SWAGER: 
            return {
                ...state,
                swager: action.payload.data,
                loading: false
            }
        case SUBMIT_SWAGER_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case GET_SWAGERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SWAGERS:
            return {
                ...state,
                loading: false,
                swagers: action.payload.data
            };
        case GET_SWAGERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_SWAGER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SWAGER:
            return {
                ...state,
                loading: false,
                swager: action.payload.data,
            };
        case GET_SWAGER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_SWAGERS_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SWAGERS_BY_USER:
            return {
                ...state,
                loading: false,
                swagersByUser: action.payload.data
            };
        case GET_SWAGERS_BY_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_SWAGERS_BY_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SWAGERS_BY_LOCATION:
            return {
                ...state,
                loading: false,
                swagers: action.payload.data
            };
        case GET_SWAGERS_BY_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        case DELETE_SWAGER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_SWAGER:
            return {
                ...state,
                loading: false,
                swager: action.payload,
            };
        case DELETE_SWAGER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
    };

    export default swagerReducer;

