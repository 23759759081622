import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  Paper,
  Tab,
  Box,
  IconButton,
  FormControlLabel,
  Checkbox,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import clsx from "clsx";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import {TabContext, TabList, TabPanel} from '@material-ui/lab';
import CloseIcon from "@material-ui/icons/Close";
import LaunchIcon from '@material-ui/icons/Launch';
import ButtonIcon from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import {getSign, setSigninDraft, getSigns, getSignsByUser, generateSignin} from "../../../actions/toolboxAction";
import CreateSignOutModal from "./CreateSignOutModal";
import EditCrewModal from "../Crew/EditCrewModal";
import Feedback from "../../../utils/Feedback";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    // marginBottom: 10,
    marginLeft: 40,
    marginTop: 30,
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 20,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  

  btnGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
      },

  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 380,
      [theme.breakpoints.down("sm")]: {
          width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      },
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 370,
    [theme.breakpoints.down("sm")]: {
      minWidth: 300,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },
  divider: {
    height: 40,
    margin: 4,
    width: 2,
  },
  
  status:{
    width: "fit-content",
    height: 50,
    padding:10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginLeft: 20
  },
  iconButton: {
    paddingLeft: 50,
    paddingRight: 50,
  },

  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },

  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },

  statusDarkGreen: {
    borderColor: "#4caf50",
    color: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  sign : {
    width: 60,
    height: 30,
    [theme.breakpoints.down("sm")]: {
      width: 60,
      height: 30,
    },
    [theme.breakpoints.down("xs")]: {
      width: 40,
      height: 20,
    },
  }

}));

const INIT_STATE = {
    crew: {},
    date: moment().format('YYYY-MM-DD'),
    topic: "",
    location: "",
    crewMembers: [{ name: "", absent: false, signature: "", time: "" }],
    addMembers: [{ name: "", absent: false, signature: "", time: "" }],
    crewFit: {},
    crewModified: {},
    checkIn: false,
    safetyBulletin: false,
    weatherConcern: {},
    requireMentor: {},
    returnFromVacation: {},
    workPermit: false,
    ergonomicConcern: false,
    crewRotation: false,
    awkwardPosition: false,
    privateIssue: false,
    requireMSDS: {},
    JHACard: false,
    stretchCompleted: false,
    comment: "",
    supervisor: "",
    error: null,
    success: false,
    loading: false,
};

const ViewSignInModal = ({ openViewSignIn, handleCloseViewSignIn, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, sign } = useSelector((state) => state.toolbox);
  const [val, setVal] = useState('1');

  const [openEdit, setOpenEdit] = useState(false);
  const [getId, setGetId] = useState("");
  const [openCreateSignOut, setOpenCreateSignOut] = useState(false);

  const { profile, loading } = useSelector((state) => state.users);

    let isAdminGuard = profile
    ? (profile &&
        // profile.role && profile.role.name === "admin")
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isToolboxAdmin = profile
    ? (profile &&
        // profile.role && profile.role.name === "toolbox_admin")
        profile.role && profile.role.map((role) => role.name).includes("toolbox_admin"))
        : false;

    let isToolboxViewer = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("toolbox_viewer"))
        : false;

useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
        setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
        }));
    }
    return () => {
        abortController.abort();
    };
    }, [error]);

  useEffect(() => {
    if (id){
      dispatch(getSign(id))
    }
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
      ...prev,
        crew: sign && sign.crew,
        date: sign && sign.date,
        topic: sign && sign.topic,
        location: sign && sign.location?.displayname,
        crewMembers: sign && sign.crewMembers,
        addMembers: sign && sign.addMembers,
        crewFit: sign && sign.crewFit,
        crewModified: sign && sign.crewModified,
        checkIn: sign && sign.checkIn,
        safetyBulletin: sign && sign.safetyBulletin,
        weatherConcern: sign && sign.weatherConcern,
        requireMentor: sign && sign.requireMentor,
        returnFromVacation: sign && sign.returnFromVacation,
        workPermit: sign && sign.workPermit,
        ergonomicConcern: sign && sign.ergonomicConcern,
        crewRotation: sign && sign.crewRotation,
        awkwardPosition: sign && sign.awkwardPosition,
        privateIssue: sign && sign.privateIssue,
        requireMSDS: sign && sign.requireMSDS,
        JHACard: sign && sign.JHACard,
        stretchCompleted: sign && sign.stretchCompleted,
        comment: sign && sign.comment,
        supervisor: sign && sign.createdBy,

    }));
  }, [sign]);
   
  const handleTabChange = (event, newVal) => {
    setVal(newVal);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleSetDraft = (e) => {
    e.preventDefault();
    dispatch(setSigninDraft(id));
    setTimeout(() => {
      handleCloseViewSignIn();
        dispatch(getSigns());
        dispatch(getSignsByUser())

        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Toolbox set to draft successfully!",
          success: true,
        }));
    }, 1500);
  };

  const handleGenerateReport = (e) => {
    e.preventDefault();
    dispatch(generateSignin(id));
    setTimeout(() => {
        dispatch(getSign(id));
        dispatch(getSignsByUser());
      
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Signin Report Generated Successfully!",
          success: true,
        }));
    }, 1500);
  };

  const handleOpenEdit = (id) => {
    setGetId(id);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenCreateSignOut = () => {
    setOpenCreateSignOut(true);

  };

  const handleCloseCreateSignOut = () => {
    setOpenCreateSignOut(false);
    handleCloseViewSignIn();
    
  };

  const printRef = React.useRef()
  const handleDownloadPdf = async () => {
    const element = printRef.current
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4', true);
    
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight()
    const imgWidth = canvas.width
    const imgHeight = canvas.height
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
    const imgX = (pdfWidth - imgWidth * ratio) / 2
    const imgY = 20

    pdf.addImage(data, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    pdf.save(`${sign && sign.toolBoxType}_${state.crew?.name}_${state.date?.split("T")[0]}.pdf`);
  };


  return (
    <div>
      <EditCrewModal {...{ openEdit, handleCloseEdit, id: getId }} />
      <CreateSignOutModal {...{ openCreateSignOut, handleCloseCreateSignOut }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openViewSignIn}
        onClose={handleCloseViewSignIn}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                View Toolbox Sign On
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseViewSignIn}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={12} sm={3}>
            <Button
              variant="contained"
              className={classes.btn}
              color="secondary"
              onClick={handleOpenCreateSignOut}
            >
              Sign Off
            </Button>
          </Grid>
          {(sign && sign.status === "Submitted") && (isAdminGuard || isToolboxAdmin) && (
            <Grid item xs={5} sm={4}>
              <ButtonGroup className={classes.btnGroup} variant="outlined">
                  <Button
                      size="small"
                      className={classes.btnLink}
                      color="primary"
                      onClick={handleSetDraft}
                  >
                      Set to Draft
                  </Button>
                  {/* <Button
                      size="small"
                      className={clsx(classes.btnLink, classes.statusDarkGreen)}
                      onClick={handleDownloadPdf}
                  >
                      Download as PDF
                  </Button> */}   
              </ButtonGroup>
            </Grid>
          )}
          {(sign && (sign.status === "Submitted")) && (
            <Grid item xs={7} sm={5}>
              <Paper variant="outlined" className={clsx(classes.status, classes.statusOrange)}
              >
                  <Typography variant="overline">
                      <ButtonIcon onClick={handleGenerateReport} color="default" size="small">
                          Generate PDF
                      </ButtonIcon>
                  </Typography>
                  <Divider orientation="vertical" className={classes.divider}/>
                  {sign && sign.reportPDF ? 
                    <ButtonIcon onClick={() => window.open(sign.reportPDF, "_blank", "noopener, noreferrer")} className={classes.file}>
                        <i className="fas fa-2x fa-file-pdf" ></i>
                    </ButtonIcon> : "No PDF"}
                    
              </Paper>
            </Grid>
          )}
        </Grid>
            
        <br/>
        <Divider />

        <DialogContent className={classes.dialogContent}>
        <div ref={printRef}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={val}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} textColor="secondary" indicatorColor="secondary">
                  <Tab label="Crew Members" className={classes.tab} value="1"/>
                  <Tab label="Other Information"  className={classes.tab} value="2"/>
                </TabList>
              </Box>
            
            <TabPanel value="1">
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="supervisor"
                      fullWidth
                      variant="outlined"
                      label="Supervisor"
                      value={state.supervisor?.displayname || ""}
                      disabled
                      required
                    />
                </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                    name="date"
                    fullWidth
                    variant="outlined"
                    label="Date"
                    value={state.date?.split("T")[0] || ''}
                    disabled    
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                    name="topic"
                    fullWidth
                    variant="outlined"
                    label="Toolbox Talk Topic"
                    value={state.topic || ""}
                    disabled
                  ></TextField>
                </Grid>
          
              <Grid item xs={12} sm={6}>
                <TextField
                    name="location"
                    fullWidth
                    variant="outlined"
                    label="Job Location"
                    value={state.location || ""}
                    disabled
                />
              </Grid>
              {isAdminGuard || isToolboxAdmin ? (
                <Grid item xs={10} sm={5}>
                  <TextField
                    name="crew"
                    fullWidth
                    variant="outlined"
                    label="Crew"
                    value={state.crew?.name || ""}
                    disabled
                  ></TextField>
                </Grid>
                
              ) : (
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="crew"
                    fullWidth
                    variant="outlined"
                    label="Crew"
                    value={state.crew?.name || ""}
                    disabled
                  ></TextField>
                </Grid>
              )}
                
              {(isAdminGuard || isToolboxAdmin) && (
                <Grid item xs={2} sm={1}>
                  <Tooltip title="View Crew">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handleOpenEdit(state.crew?._id);
                      }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            <br />
                <Paper className={classes.paper} variant="outlined" square>
                    <Typography variant="body1">
                        Crew Members
                    </Typography>
                    <br />
                    <Grid container spacing={3}>
                    {state.crewMembers?.map((value, index) => (
                      <Grid container item xs={12} sm={12} md={12} justifyContent="center" alignItems="center" key={index}>
                        <Grid container alignContent="center" spacing={2}>
                          <Grid item xs={8} sm={4} md={4}>
                              <TextField
                                  name="name"
                                  fullWidth
                                  variant="outlined"
                                  label="Name"
                                  value={value.name}
                                  disabled
                                  size="small"
                              />
                          </Grid>
                          <Grid item xs={4} sm={2} md={2}>
                              <FormControlLabel
                                  control={
                                      <Checkbox
                                          checked={value.absent}
                                          name="absent"
                                          color="primary"
                                          disabled
                                      />
                                  }
                                  label="Absent?"
                                  labelPlacement="start"
                              />
                          </Grid>
                          <Grid item xs={5} sm={3} md={3}>
                              <img src={value.signature} alt="signature" className={classes.sign}/>
                          </Grid>
                          <Grid item xs={5} sm={3} md={3}>
                              <TextField
                                  name="time"
                                  fullWidth
                                  variant="outlined"
                                  label="Time"
                                  value={value.time}
                                  size="small"
                                  disabled
                              />
                          </Grid>  
                          
                        </Grid>
                      </Grid>
                          
                    ))}
                    </Grid>
                </Paper>
                    <br />
            
            {state.addMembers && state.addMembers.length > 0 && (
              <Paper className={classes.paper} variant="outlined" square>
                <Typography variant="overline" align="center" className={classes.title2}>
                    Additional Members
                </Typography>
                  {state.addMembers.map((item, index) => (
                    
                  <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                    <Grid item xs={6} sm={6} md={6}>
                      <TextField
                        name="name"
                        fullWidth
                        variant="outlined"
                        label="Name"
                        value={item.name}
                        disabled
                        size="small"
                      />
                    </Grid> 
                    <Grid item xs={3} sm={3} md={3}>
                              <img src={item.signature} alt="signature" className={classes.sign}/>
                          </Grid>
                          <Grid item xs={3} sm={3} md={3}>
                              <TextField
                                  name="time"
                                  fullWidth
                                  variant="outlined"
                                  label="Time"
                                  value={item.time}
                                  size="small"
                                  disabled
                              />
                          </Grid>                  

                  </Grid>
                  ))}

              </Paper>
            )}
          </TabPanel>
          <TabPanel value="2">
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                  Is Everyone Fit For Duty? (including injury sustained off site, drug/alcohol use, sleep deprivation, sickness, or medication)
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.crewFit?.fit || false}
                        name="fit"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>
              
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="names"
                  fullWidth
                  variant="outlined"
                  label="If No, List Names"
                  multiline
                  value={state.crewFit?.names || ""}
                  disabled
                />
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Any crew members on modified duty
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.crewModified?.modified || false}
                        name="modified"
                        color="primary"
                        disabled
                      />
                    }
                />     
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="names"
                  fullWidth
                  variant="outlined"
                  label="If Yes, List Names"
                  multiline
                  value={state.crewModified?.names || ""}
                  disabled
                />
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
              Are they required to check in with the Nurse?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkIn || false}
                        name="checkIn"
                        color="primary"
                        disabled
                      />
                    }
                />       
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
              Are there Safety Bulletins to be reviewed?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.safetyBulletin || false}
                        name="safetyBulletin"
                        color="primary"
                        disabled
                      />
                    }
                />       
              </Grid>

              <Grid item xs={10} sm={5} md={5}>
              <Typography variant="subtitle2" align="left">
                Are there any weather concerns?
              </Typography>
              </Grid>

              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.weatherConcern?.concern || false}
                        name="concern"
                        color="primary"
                        disabled
                      />
                    }

                />     
              </Grid>

              <Grid item xs={12} sm={6} md={6}> 
                <TextField
                  name="names"
                  fullWidth
                  variant="outlined"
                  label="If Yes, List Them"
                  multiline
                  value={state.weatherConcern?.names || ""}
                  disabled
                />
              </Grid>
              
              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Are there any employees, new or returning from Vacation?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.returnFromVacation?.vacation || false}
                        name="vacation"
                        color="primary"
                        disabled
                      />
                    }
                />      
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="names"
                  fullWidth
                  variant="outlined"
                  label="If Yes, List Names"
                  multiline
                  value={state.returnFromVacation?.names || ""}
                  disabled
                />
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                 Do any new workers require a mentor/buddy?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.requireMentor?.mentor || false}
                        name="mentor"
                        color="primary"
                        disabled
                      />
                    }
                />     
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="names"
                  fullWidth
                  variant="outlined"
                  label="If Yes, List Names"
                  multiline
                  value={state.requireMentor?.names || ""}
                  disabled
                />
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Are any MSDS required?
              </Typography>
              </Grid>    
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.requireMSDS?.msds || false}
                        name="msds"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="names"
                  fullWidth
                  variant="outlined"
                  label="If Yes, List Products"
                  multiline
                  value={state.requireMSDS?.names || ""}
                  disabled
                />
              </Grid>
              
              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Do we require a hazardous work permit for any of today's tasks?
              </Typography>
              </Grid>  
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.workPermit || false}
                        name="workPermit"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Ergonomic Safety Concerns?
              </Typography>
              </Grid>  
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.ergonomicConcern || false}
                        name="ergonomicConcern"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Crew rotation required for repetitive tasks?
              </Typography>
              </Grid>  
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.crewRotation || false}
                        name="crewRotation"
                        color="primary"
                        disabled
                      />
                    }
                />        
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Sustained or awkward positions?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.awkwardPosition || false}
                        name="awkwardPosition"
                        color="primary"
                        disabled
                      />
                    }
                />      
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Does everyone have JHA Cards?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.JHACard || false}
                        name="JHACard"
                        color="primary"
                        disabled
                      />
                    }
                />
                 
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Has the crew attended the Daily Tool Box Meeting and completed their stretches?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.stretchCompleted || false}
                        name="stretchCompleted"
                        color="primary"
                        disabled
                      />
                    }
                />         
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                  Does anyone wish to meet with the F/M in private regarding Safety Issues?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.privateIssue || false}
                        name="privateIssue"
                        color="primary"
                        disabled
                      />
                    }
                /> 
        
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Additional Comments or SWP Reviewed?"
                  multiline
                  value={state.comment || ""}
                  disabled
                />
              </Grid>
              
            </Grid>
            <br />
            <Typography align="center" variant="subtitle2">
              Note: Foremen, Supervisors and Operations are to be notified of injuries and incidents in their area and person is to be removed from permit during treatment.<br />
              It is our moral obligation to address At-Risk Behaviour immediately. If you are uncomfortable mentioning it to the person, tell your foreman at once. Don’t wait for your end of shift meetings
            </Typography>
            
          </TabPanel>
          </TabContext>
          </Box>
          </Paper>
          </div>


        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewSignInModal;
