import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  Paper,
  Box,
  IconButton,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {updateInspection, submitInspection, getInspectionsByUser, getInspection} from "../../../actions/harnessAction"
import {getLocations, getUsers} from "../../../actions/usersAction";
import Feedback from "../../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },

  btn2:{
    marginBottom: 30,
    marginLeft: 10
  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 400,
      [theme.breakpoints.down("sm")]: {
          minWidth:330
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      }
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 400,
    [theme.breakpoints.down("sm")]: {
       minWidth:330
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },

  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },

}));

const INIT_STATE = {
    unitNumber: "",
    inspector: "",
    supervisor: undefined,
    date: "",
    location: undefined,
    shoulderStrap1:"Good",
    stitching2:"Good",
    buckle3: "Good",
    adjustableRoller4: "Good",
    stitching5: "Good",
    stitching6: "Good",
    buckle7: "Good",
    stitching8: "Good",
    legStrap9: "Good",
    buckle10: "Good",
    buckle11:"Good",
    legStrap12:"Good",
    stitching13:"Good",
    buckle14: "Good",
    stitching15: "Good",
    stitching16:"Good",
    adjustableRoller17: "Good",
    buckle18: "Good",
    stitching19: "Good",
    shoulderStrap20: "Good",
    dorsalDring21: "Good",
    dringBack22: "Good",
    stitching23: "Good",
    stitching24: "Good",
    stitching25: "Good",
    stitching26: "Good",
    subPelvic27: "Good",
    beltKeepers28:"Good",
    stitchingEnd29: "Good",
    CSAProduct30: "Good",
    backStrap31: "Good",
    stitching32: "Good",
    stitching1L: "Good",
    attachmentPoint2L: "Good",
    shockAbsorber3L: "Good",
    dringSnap4L: "Good",
    comment:"",
    approve:""
};

const EditInspectionModal = ({ openEditInspection, handleCloseEditInspection, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { harness, error, loading } = useSelector((state) => state.harness);
  const { profile, locations, users } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getInspectionsByUser());
    dispatch(getLocations());
    dispatch(getUsers());
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    if (id){
      dispatch(getInspection(id));
    }
    
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      unitNumber: harness && harness.unitNumber,
      inspector: harness && harness.inspector,
      supervisor: harness && harness.supervisor,
      date: harness && harness.date,
      location: harness && harness.location._id,
      shoulderStrap1:harness && harness.shoulderStrap1,
      stitching2:harness && harness.stitching2,
      buckle3: harness && harness.buckle3,
      adjustableRoller4: harness && harness.adjustableRoller4,
      stitching5: harness && harness.stitching5,
      stitching6: harness && harness.stitching6,
      buckle7: harness && harness.buckle7,
      stitching8: harness && harness.stitching8,
      legStrap9: harness && harness.legStrap9,
      buckle10: harness && harness.buckle10,
      buckle11:harness && harness.buckle11,
      legStrap12:harness && harness.legStrap12,
      stitching13:harness && harness.stitching13,
      buckle14: harness && harness.buckle14,
      stitching15: harness && harness.stitching15,
      stitching16:harness && harness.stitching16,
      adjustableRoller17: harness && harness.adjustableRoller17,
      buckle18: harness && harness.buckle18,
      stitching19: harness && harness.stitching19,
      shoulderStrap20: harness && harness.shoulderStrap20,
      dorsalDring21: harness && harness.dorsalDring21,
      dringBack22: harness && harness.dringBack22,
      stitching23: harness && harness.stitching23,
      stitching24: harness && harness.stitching24,
      stitching25: harness && harness.stitching25,
      stitching26: harness && harness.stitching26,
      subPelvic27: harness && harness.subPelvic27,
      beltKeepers28:harness && harness.beltKeepers28,
      stitchingEnd29: harness && harness.stitchingEnd29,
      CSAProduct30: harness && harness.CSAProduct30,
      backStrap31: harness && harness.backStrap31,
      stitching32: harness && harness.stitching32,
      stitching1L: harness && harness.stitching1L,
      attachmentPoint2L: harness && harness.attachmentPoint2L,
      shockAbsorber3L: harness && harness.shockAbsorber3L,
      dringSnap4L: harness && harness.dringSnap4L,
      comment: harness && harness.comment,
      approve: harness && harness.approve

    }));
  }, [harness]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    
  };

  const handleSave = (e) => {
    e.preventDefault();
    let data = {
        unitNumber: state.unitNumber,
        inspector: state.inspector,
        supervisor: state.supervisor,
        date: state.date,
        location: state.location,
        shoulderStrap1:state.shoulderStrap1,
        stitching2:state.stitching2,
        buckle3: state.buckle3,
        adjustableRoller4: state.adjustableRoller4,
        stitching5: state.stitching5,
        stitching6: state.stitching6,
        buckle7: state.buckle7,
        stitching8: state.stitching8,
        legStrap9: state.legStrap9,
        buckle10: state.buckle10,
        buckle11:state.buckle11,
        legStrap12:state.legStrap12,
        stitching13:state.stitching13,
        buckle14: state.buckle14,
        stitching15: state.stitching15,
        stitching16:state.stitching16,
        adjustableRoller17: state.adjustableRoller17,
        buckle18: state.buckle18,
        stitching19: state.stitching19,
        shoulderStrap20: state.shoulderStrap20,
        dorsalDring21: state.dorsalDring21,
        dringBack22: state.dringBack22,
        stitching23: state.stitching23,
        stitching24: state.stitching24,
        stitching25: state.stitching25,
        stitching26: state.stitching26,
        subPelvic27: state.subPelvic27,
        beltKeepers28:state.beltKeepers28,
        stitchingEnd29: state.stitchingEnd29,
        CSAProduct30: state.CSAProduct30,
        backStrap31: state.backStrap31,
        stitching32: state.stitching32,
        stitching1L: state.stitching1L,
        attachmentPoint2L: state.attachmentPoint2L,
        shockAbsorber3L: state.shockAbsorber3L,
        dringSnap4L: state.dringSnap4L,
        comment: state.comment,
        approve: state.approve,
        id
    };
    dispatch(updateInspection(data));

    setTimeout(() => {
      handleCloseEditInspection();
      dispatch(getInspectionsByUser());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Inspection Saved Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        unitNumber: state.unitNumber,
        inspector: state.inspector,
        supervisor: state.supervisor,
        date: state.date,
        location: state.location,
        shoulderStrap1:state.shoulderStrap1,
        stitching2:state.stitching2,
        buckle3: state.buckle3,
        adjustableRoller4: state.adjustableRoller4,
        stitching5: state.stitching5,
        stitching6: state.stitching6,
        buckle7: state.buckle7,
        stitching8: state.stitching8,
        legStrap9: state.legStrap9,
        buckle10: state.buckle10,
        buckle11:state.buckle11,
        legStrap12:state.legStrap12,
        stitching13:state.stitching13,
        buckle14: state.buckle14,
        stitching15: state.stitching15,
        stitching16:state.stitching16,
        adjustableRoller17: state.adjustableRoller17,
        buckle18: state.buckle18,
        stitching19: state.stitching19,
        shoulderStrap20: state.shoulderStrap20,
        dorsalDring21: state.dorsalDring21,
        dringBack22: state.dringBack22,
        stitching23: state.stitching23,
        stitching24: state.stitching24,
        stitching25: state.stitching25,
        stitching26: state.stitching26,
        subPelvic27: state.subPelvic27,
        beltKeepers28:state.beltKeepers28,
        stitchingEnd29: state.stitchingEnd29,
        CSAProduct30: state.CSAProduct30,
        backStrap31: state.backStrap31,
        stitching32: state.stitching32,
        stitching1L: state.stitching1L,
        attachmentPoint2L: state.attachmentPoint2L,
        shockAbsorber3L: state.shockAbsorber3L,
        dringSnap4L: state.dringSnap4L,
        comment: state.comment,
        approve: state.approve,
        path: "harness/inspection/view",
        id
    };
    dispatch(submitInspection(data));
    setTimeout(() => {
      handleCloseEditInspection()
        dispatch(getInspectionsByUser());
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Inspection Submitted Successfully.",
          success: true,
        }));
    }, 1500);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.unitNumber || !state.location

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openEditInspection}
        onClose={handleCloseEditInspection}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit Harness/Lanyard Inspection
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseEditInspection}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
          <form onSubmit={handleSubmit}>
            
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="unitNumber"
                    fullWidth
                    variant="outlined"
                    label="Unit Number"
                    onChange={handleChange}
                    value={state.unitNumber || ""}
                    required
                />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Operator"
                      onChange={handleChange}
                      value={state.inspector || ""}
                      required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <Autocomplete 
                            id="supervisor"
                            options={users}
                            getOptionSelected={(option, value) => option.displayname === state.supervisor?.displayname}
                            getOptionLabel={(option) => option.displayname || ''} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, supervisor: value }))}
                            value={state.supervisor || ''}
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Supervisor" />} 
                        />
                    </FormControl>
                    
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="date"
                      fullWidth
                      variant="outlined"
                      label="Date"
                      onChange={handleChange}
                      value={state.date?.split("T")[0] || ''}
                      required
                      type="date"   
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                    Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={state.location || ""}
                      name="location"
                      onChange={handleChange}
                      label="Location"
                      required
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {locations &&
                        locations.map((location) => (
                          <MenuItem key={location._id} value={location._id}>
                            {location.displayname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                
              <Grid item xs={12} sm={12}>
              {/* <Typography variant="body2" gutterBottom color="primary">
                  1 - Ok;
                  2 - Requires Scheduled Maintenance;
                  3 - Requires Immediate Fix
                </Typography> */}
              </Grid>
              <Grid item xs={12} sm={4} container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="body1" gutterBottom color="primary">
                            HARNESS INSPECTION
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel > 1 - Shoulder Strap</FormLabel>
                        <RadioGroup row name="shoulderStrap1" value={state.shoulderStrap1 || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >2 - Stitching</FormLabel>
                        <RadioGroup row name="stitching2" value={state.stitching2 || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >3 - Buckle</FormLabel>
                        <RadioGroup row name="buckle3" value={state.buckle3 || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >4 - Adjustable Roller Buckle</FormLabel>
                        <RadioGroup row name="adjustableRoller4" value={state.adjustableRoller4 || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >5 - Stitching</FormLabel>
                        <RadioGroup row name="stitching5" value={state.stitching5 || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >6 - Stitching</FormLabel>
                        <RadioGroup row name="stitching6" value={state.stitching6 || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >7 - Buckle</FormLabel>
                        <RadioGroup row name="buckle7" value={state.buckle7 || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >8 - Stitching</FormLabel>
                        <RadioGroup row name="stitching8" value={state.stitching8 || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >9 - Leg Strap</FormLabel>
                        <RadioGroup row name="legStrap9" value={state.legStrap9 || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >10 - Buckle</FormLabel>
                        <RadioGroup row name="buckle10" value={state.buckle10 || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <img src="/img/harness-diagram.png" alt="" width="500"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >11 - Buckle</FormLabel>
                    <RadioGroup row name="buckle11" value={state.buckle11 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >12 - Leg Strap</FormLabel>
                    <RadioGroup row name="legStrap12" value={state.legStrap12 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >13 - Stitching</FormLabel>
                    <RadioGroup row name="stitching13" value={state.stitching13 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >14 - Buckle</FormLabel>
                    <RadioGroup row name="buckle14" value={state.buckle14 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >15 - Stitching</FormLabel>
                    <RadioGroup row name="stitching15" value={state.stitching15 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >16 - Stitching</FormLabel>
                    <RadioGroup row name="stitching16" value={state.stitching16 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >17 - Adjustable Roller Buckle</FormLabel>
                    <RadioGroup row name="adjustableRoller17" value={state.adjustableRoller17 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >18 - Buckle</FormLabel>
                    <RadioGroup row name="buckle18" value={state.buckle18 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >19 - Stitching</FormLabel>
                    <RadioGroup row name="stitching19" value={state.stitching19 || ""} onChange={handleChange}>
                    < FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >20 - Shoulder Strap</FormLabel>
                    <RadioGroup row name="shoulderStrap20" value={state.shoulderStrap20 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >21 - Dorsal D-Ring</FormLabel>
                    <RadioGroup row name="dorsalDring21" value={state.dorsalDring21 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >22 - D-Ring Back Pack</FormLabel>
                    <RadioGroup row name="dringBack22" value={state.dringBack22 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >23 - Stitching</FormLabel>
                    <RadioGroup row name="stitching23" value={state.stitching23 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >24 - Stitching</FormLabel>
                    <RadioGroup row name="stitching24" value={state.stitching24 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >25 - Stitching</FormLabel>
                    <RadioGroup row name="stitching25" value={state.stitching25 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >26 - Stitching</FormLabel>
                    <RadioGroup row name="stitching26" value={state.stitching26 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >27 - Sub Pelvic Support Strap</FormLabel>
                    <RadioGroup row name="subPelvic27" value={state.subPelvic27 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >28 - Belt Keepers</FormLabel>
                    <RadioGroup row name="beltKeepers28" value={state.beltKeepers28 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >29 - Stitching End Patterns</FormLabel>
                    <RadioGroup row name="stitchingEnd29" value={state.stitchingEnd29 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >30 - CSA Product Labels</FormLabel>
                    <RadioGroup row name="CSAProduct30" value={state.CSAProduct30 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >31 - Back Strap</FormLabel>
                    <RadioGroup row name="backStrap31" value={state.backStrap31 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >32 - Stitching</FormLabel>
                    <RadioGroup row name="stitching32" value={state.stitching32 || ""} onChange={handleChange}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="body1" gutterBottom color="primary">
                            LANYARD INSPECTION
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >1L - Stitching</FormLabel>
                        <RadioGroup row name="stitching1L" value={state.stitching1L || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >2L - Attachment Point Snap Hook</FormLabel>
                        <RadioGroup row name="attachmentPoint2L" value={state.attachmentPoint2L || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >3L - Shock Absorber</FormLabel>
                        <RadioGroup row name="shockAbsorber3L" value={state.shockAbsorber3L || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >4L - D-Ring Snap Hook</FormLabel>
                        <RadioGroup row name="dringSnap4L" value={state.dringSnap4L || ""} onChange={handleChange}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <img src="/img/lanyard-diagram.png" alt="" height="250"/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant="title1" gutterBottom color="primary">
                        Inspector must be trained and competent, use good judgement evaluating harnesses and lanyards or any other fall protection equipment.
                        If there is any doubt or question as to the condition of, or if any item is identified as poor, remove it from services immediately!
                    </Typography>
                </Grid>
                
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="approve">
                        Approve for Use?
                    </InputLabel>
                    <Select
                        labelId="approve"
                        id="approve"
                        fullWidth
                        value={state.approve || ""}
                        name="approve"
                        onChange={handleChange}
                        variant="outlined"
                    >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Comments"
                  onChange={handleChange}
                  multiline
                  value={state.comment || ""}
                  />
              </Grid>
   
            </Grid>

            <br />
          
            <Typography align="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSave}
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save"}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save & Submit"}
                </Button>
              </Typography>
            </form>
          </Box>
          </Paper>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditInspectionModal;
