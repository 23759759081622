import axios from "axios";
import {
    CREATE_CREW,
    CREATE_CREW_REQUEST,
    CREATE_CREW_FAIL,
    GET_CREW,
    GET_CREW_REQUEST,
    GET_CREW_FAIL,
    GET_CREW_BY_SUPERVISOR,
    GET_CREW_BY_SUPERVISOR_REQUEST,
    GET_CREW_BY_SUPERVISOR_FAIL,
    GET_CREWS,
    GET_CREWS_REQUEST,
    GET_CREWS_FAIL,
    UPDATE_CREW,
    UPDATE_CREW_REQUEST,
    UPDATE_CREW_FAIL,
    DELETE_CREW,
    DELETE_CREW_REQUEST,
    DELETE_CREW_FAIL,
    ARCHIVE_CREW,
    ARCHIVE_CREW_REQUEST,
    ARCHIVE_CREW_FAIL,
    
    CREATE_SIGNIN,
    CREATE_SIGNIN_REQUEST,
    CREATE_SIGNIN_FAIL,
    SUBMIT_SIGNIN,
    SUBMIT_SIGNIN_REQUEST,
    SUBMIT_SIGNIN_FAIL,
    UPDATE_SIGNIN,
    UPDATE_SIGNIN_REQUEST,
    UPDATE_SIGNIN_FAIL,
    CREATE_SIGNOUT,
    CREATE_SIGNOUT_REQUEST,
    CREATE_SIGNOUT_FAIL,
    UPDATE_SIGNOUT,
    UPDATE_SIGNOUT_REQUEST,
    UPDATE_SIGNOUT_FAIL,
    SUBMIT_SIGNOUT,
    SUBMIT_SIGNOUT_REQUEST,
    SUBMIT_SIGNOUT_FAIL,
    GET_SIGNS,
    GET_SIGNS_REQUEST,
    GET_SIGNS_FAIL,
    GET_SIGN,
    GET_SIGN_REQUEST,
    GET_SIGN_FAIL,
    GET_SIGNS_BY_USER,
    GET_SIGNS_BY_USER_REQUEST,
    GET_SIGNS_BY_USER_FAIL,
    GET_SIGNIN_BY_USER,
    GET_SIGNIN_BY_USER_REQUEST,
    GET_SIGNIN_BY_USER_FAIL,
    GET_SIGNOUT_BY_USER,
    GET_SIGNOUT_BY_USER_REQUEST,
    GET_SIGNOUT_BY_USER_FAIL,
    GET_SIGNS_BY_LOCATION,
    GET_SIGNS_BY_LOCATION_REQUEST,
    GET_SIGNS_BY_LOCATION_FAIL,
    DELETE_SIGN,
    DELETE_SIGN_REQUEST,
    DELETE_SIGN_FAIL,
    SET_SIGNIN_DRAFT,
    SET_SIGNIN_DRAFT_REQUEST,
    SET_SIGNIN_DRAFT_FAIL,
    SET_SIGNOUT_DRAFT,
    SET_SIGNOUT_DRAFT_REQUEST,
    SET_SIGNOUT_DRAFT_FAIL,
    DOWNLOAD_SIGNIN,
    DOWNLOAD_SIGNIN_REQUEST,
    DOWNLOAD_SIGNIN_FAIL,
    DOWNLOAD_SIGNOUT,
    DOWNLOAD_SIGNOUT_REQUEST,
    DOWNLOAD_SIGNOUT_FAIL

} from '../types/toolboxTypes';

const token = localStorage.getItem("token:data");

export const createCrew = (crew) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_CREW_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/toolbox/crew`, crew, config);
        dispatch({
            type: CREATE_CREW,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_CREW_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getCrews = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_CREWS_REQUEST,
            });
            
            const URL = `/api/v1/toolbox/crew`;
            const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: GET_CREWS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_CREWS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }   
};


export const getCrew = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_CREW_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/toolbox/crew/${id}`, config);
        dispatch({
            type: GET_CREW,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_CREW_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getCrewBySupervisor = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_CREW_BY_SUPERVISOR_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/toolbox/crew/supervisor/${id}`, config);
        dispatch({
            type: GET_CREW_BY_SUPERVISOR,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_CREW_BY_SUPERVISOR_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
                });
    }
}

export const updateCrew = (crew) => async (dispatch) => {
   
    try {
        dispatch({
            type: UPDATE_CREW_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/toolbox/crew/${crew.id}`, crew, config);
        dispatch({
            type: UPDATE_CREW,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_CREW_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                    ? error.request.data.error
                    : error.message,
        });
    }
}

export const deleteCrew = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_CREW_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/toolbox/crew/${id}`, config);
        dispatch({
            type: DELETE_CREW,
        });
    } catch (error) {
        dispatch({
            type: DELETE_CREW_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const archiveCrew = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ARCHIVE_CREW_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.put(`/api/v1/toolbox/crew/archive/${id}`, null, config);
        dispatch({
            type: ARCHIVE_CREW,
        });
    } catch (error) {
        dispatch({
            type: ARCHIVE_CREW_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const createSignin = (signin) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_SIGNIN_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/toolbox/signin`, signin, config);
        dispatch({
            type: CREATE_SIGNIN,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_SIGNIN_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const submitSignin = (signin) => async (dispatch) => {
    
    try {
        dispatch({
            type: SUBMIT_SIGNIN_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/toolbox/signin/submit`, signin, config);
        dispatch({
            type: SUBMIT_SIGNIN,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUBMIT_SIGNIN_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const updateSignin = (signin) => async (dispatch) => {
    
    try {
        dispatch({
            type: UPDATE_SIGNIN_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/toolbox/signin/${signin.id}`, signin, config);
        dispatch({
            type: UPDATE_SIGNIN,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_SIGNIN_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setSigninDraft = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_SIGNIN_DRAFT_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/toolbox/signin/draft/${id}`, null, config);
        dispatch({
            type: SET_SIGNIN_DRAFT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_SIGNIN_DRAFT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}


export const createSignout = (signout) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_SIGNOUT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/toolbox/signout`, signout, config);
        dispatch({
            type: CREATE_SIGNOUT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_SIGNOUT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const updateSignout = (signout) => async (dispatch) => {
    
    try {
        dispatch({
            type: UPDATE_SIGNOUT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/toolbox/signout/${signout.id}`, signout, config);
        dispatch({
            type: UPDATE_SIGNOUT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_SIGNOUT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setSignoutDraft = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_SIGNOUT_DRAFT_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/toolbox/signout/draft/${id}`, null, config);
        dispatch({
            type: SET_SIGNOUT_DRAFT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_SIGNOUT_DRAFT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}

export const submitSignout = (signout) => async (dispatch) => {
    
    try {
        dispatch({
            type: SUBMIT_SIGNOUT_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/toolbox/signout/submit`, signout, config);
        dispatch({
            type: SUBMIT_SIGNOUT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUBMIT_SIGNOUT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const getSigns = () => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_SIGNS_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/toolbox/signs`, config);
        dispatch({
            type: GET_SIGNS,
            payload: data,
        });
    } catch (error) {
        console.log(error)
        dispatch({
            type: GET_SIGNS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
                });
    }
}

export const getSign = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_SIGN_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/toolbox/sign/${id}`, config);
        dispatch({
            type: GET_SIGN,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SIGN_FAIL,
            payload:
                error.response && error.response.data?.error
                ? error.response.data?.error
                : error.request
                ? error.request.data?.error
                : error.message,
        });
    }
}

export const getSignsByUser = () => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_SIGNS_BY_USER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/toolbox/mysigns/user`, config);
        dispatch({
            type: GET_SIGNS_BY_USER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SIGNS_BY_USER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getSigninByUser = () => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_SIGNIN_BY_USER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/toolbox/signin/user`, config);
        dispatch({
            type: GET_SIGNIN_BY_USER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SIGNIN_BY_USER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getSignoutByUser = () => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_SIGNOUT_BY_USER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/toolbox/signout/user`, config);
        dispatch({
            type: GET_SIGNOUT_BY_USER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SIGNOUT_BY_USER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getSignsByLocation = (location) => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_SIGNS_BY_LOCATION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/toolbox/signs/location/${location}`, config);
        dispatch({
            type: GET_SIGNS_BY_LOCATION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_SIGNS_BY_LOCATION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const deleteSign = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_SIGN_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/toolbox/sign/${id}`, config);
        dispatch({
            type: DELETE_SIGN,
        });
    } catch (error) {
        dispatch({
            type: DELETE_SIGN_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const generateSignin = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DOWNLOAD_SIGNIN_REQUEST,
        });
        const URL = `/api/v1/toolbox/signin/pdf/${id}`;
        const { data } = await axios.put(URL, null, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: DOWNLOAD_SIGNIN,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DOWNLOAD_SIGNIN_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const generateSignout = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DOWNLOAD_SIGNOUT_REQUEST,
        });
        const URL = `/api/v1/toolbox/signout/pdf/${id}`;
        const { data } = await axios.put(URL, null, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: DOWNLOAD_SIGNOUT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DOWNLOAD_SIGNOUT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}