import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  Paper,
  Box,
  IconButton,
  FormControl,
  FormLabel,
  FormControlLabel,
  Button,
  ButtonGroup,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import clsx from "clsx";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {getInspection, setInspectionDraft, getInspections, getInspectionsByUser} from "../../../actions/harnessAction";
import Feedback from "../../../utils/Feedback";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  btnGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
  },
  
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 380,
      [theme.breakpoints.down("sm")]: {
          width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      },
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 350,
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
    color: "#4caf50",
  },
  paper3:{
    textAlign: "center",
    width: "fit-content",
    height: 50,
    display: "flex",
    justifyContent: "center",
    marginTop: 30
  },

}));

const INIT_STATE = {
    unitNumber: "",
    inspector: "",
    supervisor: undefined,
    date: "",
    location: undefined,
    shoulderStrap1:"Good",
    stitching2:"Good",
    buckle3: "Good",
    adjustableRoller4: "Good",
    stitching5: "Good",
    stitching6: "Good",
    buckle7: "Good",
    stitching8: "Good",
    legStrap9: "Good",
    buckle10: "Good",
    buckle11:"Good",
    legStrap12:"Good",
    stitching13:"Good",
    buckle14: "Good",
    stitching15: "Good",
    stitching16:"Good",
    adjustableRoller17: "Good",
    buckle18: "Good",
    stitching19: "Good",
    shoulderStrap20: "Good",
    dorsalDring21: "Good",
    dringBack22: "Good",
    stitching23: "Good",
    stitching24: "Good",
    stitching25: "Good",
    stitching26: "Good",
    subPelvic27: "Good",
    beltKeepers28:"Good",
    stitchingEnd29: "Good",
    CSAProduct30: "Good",
    backStrap31: "Good",
    stitching32: "Good",
    stitching1L: "Good",
    attachmentPoint2L: "Good",
    shockAbsorber3L: "Good",
    dringSnap4L: "Good",
    comment:"",
    approve:"",
    error: null,
    success: false,
    loading: false,
};

const ViewInspectionModal = ({ openViewInspection, handleCloseViewInspection, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, harness } = useSelector((state) => state.harness);

  const { profile, loading } = useSelector((state) => state.users);

    let isAdminGuard = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isHarnessAdmin = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("harness_admin"))
        : false;


useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
        setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
        }));
    }
    return () => {
        abortController.abort();
    };
    }, [error]);

  useEffect(() => {
    if (id){
      dispatch(getInspection(id));
    }
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      unitNumber: harness && harness.unitNumber,
      inspector: harness && harness.inspector,
      supervisor: harness && harness.supervisor,
      date: harness && harness.date,
      location: harness && harness.location?.displayname,
      shoulderStrap1:harness && harness.shoulderStrap1,
      stitching2:harness && harness.stitching2,
      buckle3: harness && harness.buckle3,
      adjustableRoller4: harness && harness.adjustableRoller4,
      stitching5: harness && harness.stitching5,
      stitching6: harness && harness.stitching6,
      buckle7: harness && harness.buckle7,
      stitching8: harness && harness.stitching8,
      legStrap9: harness && harness.legStrap9,
      buckle10: harness && harness.buckle10,
      buckle11:harness && harness.buckle11,
      legStrap12:harness && harness.legStrap12,
      stitching13:harness && harness.stitching13,
      buckle14: harness && harness.buckle14,
      stitching15: harness && harness.stitching15,
      stitching16:harness && harness.stitching16,
      adjustableRoller17: harness && harness.adjustableRoller17,
      buckle18: harness && harness.buckle18,
      stitching19: harness && harness.stitching19,
      shoulderStrap20: harness && harness.shoulderStrap20,
      dorsalDring21: harness && harness.dorsalDring21,
      dringBack22: harness && harness.dringBack22,
      stitching23: harness && harness.stitching23,
      stitching24: harness && harness.stitching24,
      stitching25: harness && harness.stitching25,
      stitching26: harness && harness.stitching26,
      subPelvic27: harness && harness.subPelvic27,
      beltKeepers28:harness && harness.beltKeepers28,
      stitchingEnd29: harness && harness.stitchingEnd29,
      CSAProduct30: harness && harness.CSAProduct30,
      backStrap31: harness && harness.backStrap31,
      stitching32: harness && harness.stitching32,
      stitching1L: harness && harness.stitching1L,
      attachmentPoint2L: harness && harness.attachmentPoint2L,
      shockAbsorber3L: harness && harness.shockAbsorber3L,
      dringSnap4L: harness && harness.dringSnap4L,
      comment: harness && harness.comment,
      approve: harness && harness.approve

    }));
  }, [harness]);

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleSetDraft = (e) => {
    e.preventDefault();
    dispatch(setInspectionDraft(id));
    setTimeout(() => {
      handleCloseViewInspection();
        dispatch(getInspections());
        dispatch(getInspectionsByUser());

        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Inspection set to draft successfully!",
          success: true,
        }));
    }, 1500);
  };

  const printRef = React.useRef()
  const handleDownloadPdf = async () => {
    const element = printRef.current
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4', true);
    
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight()
    const imgWidth = canvas.width
    const imgHeight = canvas.height
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
    const imgX = (pdfWidth - imgWidth * ratio) / 2
    const imgY = (pdfHeight - imgHeight * ratio) / 2

    pdf.addImage(data, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    pdf.save(`Harness_${state.unitNumber}_${state.date?.split("T")[0]}.pdf`);
  };  

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openViewInspection}
        onClose={handleCloseViewInspection}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                View Harness/Lanyard Inspection
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseViewInspection}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        {(harness && harness.status === "Submitted" ) && (isAdminGuard || isHarnessAdmin) && (
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={12}>
                <ButtonGroup className={classes.btnGroup} variant="outlined">
                    <Button
                        size="small"
                        className={classes.btnLink}
                        color="primary"
                        onClick={handleSetDraft}
                    >
                        Set to Draft
                    </Button>
                    <Button
                        size="small"
                        className={clsx(classes.btnLink, classes.statusDarkGreen)}
                        onClick={handleDownloadPdf}
                    >
                        Download as PDF
                    </Button>
                
                </ButtonGroup>
              </Grid>
          </Grid>
                
          )}
          <br/>   
          <Divider />

        <DialogContent className={classes.dialogContent}>
        <div ref={printRef}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>

            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="unitNumber"
                      fullWidth
                      variant="outlined"
                      label="Unit Number"
                      value={state.unitNumber || ""}
                      disabled
                    />
              </Grid>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Operator"
                      value={state.inspector || ""}
                      disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="supervisor"
                      fullWidth
                      variant="outlined"
                      label="Supervisor"
                      value={state.supervisor?.displayname || ""}
                      disabled
                    />
                </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                    name="date"
                    fullWidth
                    variant="outlined"
                    label="Date"
                    value={state.date?.split("T")[0] || ''}
                    disabled    
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    name="location"
                    fullWidth
                    variant="outlined"
                    label="Location"
                    value={state.location || ""}
                    disabled    
                />
              </Grid>

              <Grid item xs={12} sm={12}></Grid>
                
                <Grid item xs={12} sm={4} container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="body1" gutterBottom color="primary">
                            HARNESS INSPECTION
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel > 1 - Shoulder Strap</FormLabel>
                        <RadioGroup row name="shoulderStrap1" value={state.shoulderStrap1 || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled/>
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled/>
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled/>
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >2 - Stitching</FormLabel>
                        <RadioGroup row name="stitching2" value={state.stitching2 || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >3 - Buckle</FormLabel>
                        <RadioGroup row name="buckle3" value={state.buckle3 || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >4 - Adjustable Roller Buckle</FormLabel>
                        <RadioGroup row name="adjustableRoller4" value={state.adjustableRoller4 || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >5 - Stitching</FormLabel>
                        <RadioGroup row name="stitching5" value={state.stitching5 || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >6 - Stitching</FormLabel>
                        <RadioGroup row name="stitching6" value={state.stitching6 || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >7 - Buckle</FormLabel>
                        <RadioGroup row name="buckle7" value={state.buckle7 || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >8 - Stitching</FormLabel>
                        <RadioGroup row name="stitching8" value={state.stitching8 || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >9 - Leg Strap</FormLabel>
                        <RadioGroup row name="legStrap9" value={state.legStrap9 || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >10 - Buckle</FormLabel>
                        <RadioGroup row name="buckle10" value={state.buckle10 || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <img src="/img/harness-diagram.png" alt="" width="500"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >11 - Buckle</FormLabel>
                    <RadioGroup row name="buckle11" value={state.buckle11 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >12 - Leg Strap</FormLabel>
                    <RadioGroup row name="legStrap12" value={state.legStrap12 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >13 - Stitching</FormLabel>
                    <RadioGroup row name="stitching13" value={state.stitching13 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >14 - Buckle</FormLabel>
                    <RadioGroup row name="buckle14" value={state.buckle14 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >15 - Stitching</FormLabel>
                    <RadioGroup row name="stitching15" value={state.stitching15 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >16 - Stitching</FormLabel>
                    <RadioGroup row name="stitching16" value={state.stitching16 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >17 - Adjustable Roller Buckle</FormLabel>
                    <RadioGroup row name="adjustableRoller17" value={state.adjustableRoller17 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >18 - Buckle</FormLabel>
                    <RadioGroup row name="buckle18" value={state.buckle18 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >19 - Stitching</FormLabel>
                    <RadioGroup row name="stitching19" value={state.stitching19 || ""}>
                    < FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >20 - Shoulder Strap</FormLabel>
                    <RadioGroup row name="shoulderStrap20" value={state.shoulderStrap20 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >21 - Dorsal D-Ring</FormLabel>
                    <RadioGroup row name="dorsalDring21" value={state.dorsalDring21 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >22 - D-Ring Back Pack</FormLabel>
                    <RadioGroup row name="dringBack22" value={state.dringBack22 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >23 - Stitching</FormLabel>
                    <RadioGroup row name="stitching23" value={state.stitching23 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >24 - Stitching</FormLabel>
                    <RadioGroup row name="stitching24" value={state.stitching24 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >25 - Stitching</FormLabel>
                    <RadioGroup row name="stitching25" value={state.stitching25 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >26 - Stitching</FormLabel>
                    <RadioGroup row name="stitching26" value={state.stitching26 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >27 - Sub Pelvic Support Strap</FormLabel>
                    <RadioGroup row name="subPelvic27" value={state.subPelvic27 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >28 - Belt Keepers</FormLabel>
                    <RadioGroup row name="beltKeepers28" value={state.beltKeepers28 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >29 - Stitching End Patterns</FormLabel>
                    <RadioGroup row name="stitchingEnd29" value={state.stitchingEnd29 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >30 - CSA Product Labels</FormLabel>
                    <RadioGroup row name="CSAProduct30" value={state.CSAProduct30 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >31 - Back Strap</FormLabel>
                    <RadioGroup row name="backStrap31" value={state.backStrap31 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >32 - Stitching</FormLabel>
                    <RadioGroup row name="stitching32" value={state.stitching32 || ""}>
                        <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled/>
                        <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled/>
                        <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="body1" gutterBottom color="primary">
                            LANYARD INSPECTION
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >1L - Stitching</FormLabel>
                        <RadioGroup row name="stitching1L" value={state.stitching1L || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >2L - Attachment Point Snap Hook</FormLabel>
                        <RadioGroup row name="attachmentPoint2L" value={state.attachmentPoint2L || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >3L - Shock Absorber</FormLabel>
                        <RadioGroup row name="shockAbsorber3L" value={state.shockAbsorber3L || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >4L - D-Ring Snap Hook</FormLabel>
                        <RadioGroup row name="dringSnap4L" value={state.dringSnap4L || ""}>
                            <FormControlLabel value="Good" control={<Radio color="primary"/>} label="Good" disabled />
                            <FormControlLabel value="Poor" control={<Radio color="secondary"/>} label="Poor" disabled />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" disabled />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <img src="/img/lanyard-diagram.png" alt="" height="250"/>
                </Grid>
              
              <Grid item xs={12} sm={6}>
              <TextField
                    name="approve"
                    fullWidth
                    variant="outlined"
                    label=" Approve for Use?"
                    value={state.approve || ""}
                    disabled        
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Comment"
                  multiline
                  value={state.comment || ""}
                  disabled
                  />
              </Grid>

            </Grid>
            
          </Box>
          </Paper>
          </div>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewInspectionModal;
