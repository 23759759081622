import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Icon,
  IconButton,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

import { useHistory } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    color: "#bdbdbd",
  },
  paper: {
    padding: theme.spacing(2),
    // height: 120,
  },
  alerts: {
    color: theme.palette.type === "dark" ? "#bdbdbd" : "#4d72de",
    fontSize: 12,
    fontWeight: 900,
    display: "block",
  },
  alertInfo: {
    fontSize: 12,
    fontWeight: 900,
    display: "block",
    color: theme.palette.type === "dark" ? "#bdbdbd" : "#5a5c69",
  },
  grey: {
    color: "#5a5c69",
  },
  alertLink: {
    fontSize: 12,
    fontWeight: 900,
    color: "#4d72de",
  },
  mt20: {
    marginTop: 20,
  },
  card: {
    position: "relative",
    background: "red",
  },
  cardContent: {
    textAlign: "center",
  },
  document: {
    background: "#3498DB",
    color: "#fff",
  },
  
  safety: {
    background: "#7BC83B",
    color: "#fff",
  },
  quality: {
    background: "#BA55D3",
    color: "#fff",
  },
  attendance: {
    // background: "#4e73df",
    backgroundColor: "#4158D0",
    backgroundImage: "linear-gradient(293deg, #4158D0 0%, #0e50b3 13%)",
    color: "#fff",
  },
  glass: {
    background: "rgba( 255, 255, 255, 0.25 )",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur(8.0px)",
    WebkitBackdropFilter: "blur(8.0px)",
    borderRadius: "10px",
  },
  hazard: {
    background: "#CD6155",
    color: "#fff",
  },
  project: {
    background: "#f6c23e",
    color: "#fff",
  },

  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  icon: {
    width: 50,
    height: 50,
    fill: "#bdbdbd",
  },
  typo: {
    fontWeight: 900,
  },
  permit: {
    background: "#e74a3b",
    color: "#fff",
  },
  emergency: {
    background: "#858796",
    color: "#fff",
  },
}));
const Dashboard = () => {
  const classes = useStyles();

  const history = useHistory();

  const route = (path) => {
    history.push(path);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography
              variant="overline"
              component="small"
              className={classes.alerts}
              gutterBottom
            >
              Alerts
            </Typography>
          </Grid>
          <Grid item>
            <IconButton>
              <Icon className="fas fa-bell" color="disabled" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography
              variant="overline"
              component="small"
              className={classes.alertInfo}
              gutterBottom
            >
              Showing Alerts...
            </Typography>
            <Link href="#" className={classes.alertLink}>
              View all
            </Link>
          </Grid>
        </Grid>
      </Paper>
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        className={classes.mt20}
        spacing={3}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card className={clsx(classes.card, classes.safety)}>
            <CardActionArea
              className={clsx(classes.flex)}
              onClick={() => route("/sign")}
            >
              <CardContent className={classes.cardContent}>
                <Typography className={classes.typo}>
                  Safety
                </Typography>
              </CardContent>

              <Icon className="fas fa-clipboard" fontSize="large" />
            </CardActionArea>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card className={clsx(classes.card, classes.quality)}>
            <CardActionArea
              className={clsx(classes.flex)}
              onClick={() => route("/quality")}
            >
              <CardContent className={classes.cardContent}>
                <Typography className={classes.typo}>
                  Quality
                </Typography>
              </CardContent>

              <Icon className="fas fa-clipboard" fontSize="large" />
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card className={clsx(classes.card, classes.document)}>
            <CardActionArea
              className={classes.flex}
              onClick={() => route("/document")}
            >
              <CardContent className={classes.cardContent}>
                <Typography className={classes.typo}> Documents</Typography>
              </CardContent>

              <Icon className="fas fa-book" fontSize="large" />
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card className={clsx(classes.card, classes.project)}>
            <CardActionArea className={classes.flex}>
              <CardContent className={classes.cardContent}>
                <Typography className={classes.typo} noWrap>
                  Project Execution
                </Typography>
              </CardContent>
              <Icon className="fas fa-comment" fontSize="large" />
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card className={clsx(classes.card, classes.hazard)}>
            <CardActionArea className={classes.flex} onClick={() => route("/report")}>
              <CardContent className={classes.cardContent}>
                <Typography className={classes.typo} noWrap>
                  Report
                </Typography>
              </CardContent>
              <Icon className="fas fa-bullhorn" fontSize="large" />
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
