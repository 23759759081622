import React, { useEffect, useState } from "react";
import {
  makeStyles,
  lighten,
  Tooltip,
  Button,
  Grid,
  Typography,
  Divider,
  TextField,
  FormControl,
  Paper,
  Tab,
  Box,
  FormControlLabel,
  Checkbox,
  ImageList,
  ImageListItem,
  ButtonGroup,
  IconButton,
} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from '@material-ui/lab';
import ButtonIcon from "@material-ui/core/Button";
import LaunchIcon from '@material-ui/icons/Launch';
import { useDispatch, useSelector } from "react-redux";
import {getIncident, getIncidentsByUser, getIncidents, setIncidentDraft, setIncidentClosed, setIncidentOpen, generateReport} from "../../actions/incidentAction";
import EditIncidentReport from "../Modals/Incident/EditIncidentReport";

import Feedback from "../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { useParams } from "react-router-dom";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    // color: "#707070",
    padding: theme.spacing(2),
    color: lighten(theme.palette.common.black, 0.75),
    display: "block",
    fontSize: 14,
    fontWeight: 900,
  },
  
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },

  formControl: {
    minWidth: 1150,
    [theme.breakpoints.down("sm")]: {
      minWidth: 720,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    }
},
formControl2: {
  textAlign: "left",
  minWidth: 550,
  [theme.breakpoints.down("sm")]: {
    minWidth: 350,
  },
  [theme.breakpoints.down("xs")]: {
    minWidth: "100%",
  },
},
formControl3: {
  textAlign: "left",
  minWidth: 240,
  [theme.breakpoints.down("sm")]: {
    minWidth: 200,
  },
  [theme.breakpoints.down("xs")]: {
    minWidth: "100%",
  },
},
formControl4: {
    textAlign: "left",
    minWidth: 600,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
formControl5: {
textAlign: "left",
minWidth: 180,
maxWidth: 180,
[theme.breakpoints.down("sm")]: {
    minWidth: 150,
    maxWidth: 150,
},
[theme.breakpoints.down("xs")]: {
    minWidth: "100%",
},
},

  divider: {
    height: 40,
    margin: 4,
    width: 2,
  },

  status:{
    width: "fit-content",
    height: 50,
    padding:10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    marginLeft: 20
  },
  iconButton: {
    paddingLeft: 50,
    paddingRight: 50,
  },

  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginLeft: 20,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  sign : {
    width: 60,
    height: 30,
    [theme.breakpoints.down("sm")]: {
      width: 60,
      height: 30,
    },
    [theme.breakpoints.down("xs")]: {
      width: 40,
      height: 20,
    },
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },
  imageListItem: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(-2),
    },
  },
  imageList: { 
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(1),

    },
  },

  btnLink: {
    padding: theme.spacing(1.5),
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  btnGroup: {
    display: "flex",
    marginTop: 10,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
  },

  delete: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(-1),
        marginTop: theme.spacing(-3),
    }
  },

  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },

}));

const INIT_STATE = {
    reporter: undefined,
    location: undefined,
    exactLocation: "",
    date: "",
    rootCause: false,
    incidentType: [],
    worker: "",
    injury: [],
    workType: [],
    day: "",
    daysInShift: "",
    testing: "",
    rotation: "",
    sequence: '',
    contactType: [],
    directCause: [],
    causeDescription: "",
    eventType: [],
    eventDescription: "",
    injuryData: [],
    committee: [],
    statement:[],
    riskFactors: [],
    description: "",
    conclusion: "",
    correctiveActions: [],
    reviews:[],
    attachment: [],
    status: "",
    incidentNo: ""
};

const ViewIncidentReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { incident, error, loading, contacts, causes, events} = useSelector((state) => state.incident);
  const { profile } = useSelector((state) => state.users);
  const [val, setVal] = useState('1');
  const [getId, setGetId] = useState("");
  const [openEditIncidentReport, setOpenEditIncidentReport] = useState(false);

  let isAdminGuard = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isIncidentAdmin = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("incident_admin"))
        : false;

const typeOfIncident = [
    {id: 1, name: "Chemical Spill or Release"},
    {id: 2, name: "Environmental"},
    {id: 3, name: "Fire/Explosion"},
    {id: 4, name: "Hazard Observation"},
    {id: 5, name: "Illness"},
    {id: 6, name: "Injury"},
    {id: 7, name: "Property Damage"},
    {id: 8, name: "Near Miss"},
    {id: 9, name: "Security"},
    {id: 10, name: "Vehicle/Equipment Damage"},
    {id: 11, name: "Workplace Violence"},
 ]

const injuryList = [
    {id: 1, name: "CVNT"},
    {id: 2, name: "First Aid"},
    {id: 3, name: "Medical Treatment"},
    {id: 4, name: "Restricted Work Day"},
    {id: 5, name: "Lost Work Day"},
    {id: 6, name: "Fatality"},
]

const workList = [
    {id: 1, name: "Civil"},
    {id: 2, name: "Structural/Crane"},
    {id: 3, name: "Piping/Mechanical"},
    {id: 4, name: "Electrical/Instr."},
    {id: 5, name: "Scaffold/Insulation"},
    {id: 6, name: "Other"},
]

const riskFactorsList = [
    {id: 1, name: "Overestimating Personal Experience or Capabilities"},
    {id: 2, name: "Personal Experience with an Outcome"},
    {id: 3, name: "Potential Profit/Gain from Actions"},
    {id: 4, name: "Familiarity with the Task"},
    {id: 5, name: "Cost of Non Compliance"},
    {id: 6, name: "Role Models Accepting Risk"},
    {id: 7, name: "Serioiusness of Outcome"},
    {id: 8, name: "Overconfidence in Equipment"},
    {id: 9, name: "Overconfidence in Rescue and PPE"},
    {id: 10, name: "Voluntary Actions and Being in Control"},
    {id: 11, name: "Others"},
]

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    if(id) {
      dispatch(getIncident(id));
    }
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
      ...prev,
        reporter: incident && incident.reporter,
        location: incident && incident.location?.displayname,
        exactLocation: incident && incident.exactLocation,
        date: incident && incident.date,
        rootCause: incident && incident.rootCause,
        incidentType: incident && incident.incidentType,
        worker: incident && incident.worker,
        injury: incident && incident.injury,
        workType: incident && incident.workType,
        day: incident && incident.day,
        daysInShift: incident && incident.daysInShift,
        testing: incident && incident.testing,
        rotation: incident && incident.rotation,
        sequence: incident && incident.sequence,
        contactType: incident && incident.contactType,
        directCause: incident && incident.directCause,
        causeDescription: incident && incident.causeDescription,
        eventType: incident && incident.eventType,
        eventDescription: incident && incident.eventDescription,
        injuryData: incident && incident.injuryData,
        committee: incident && incident.committee,
        statement: incident && incident.statement,
        riskFactors: incident && incident.riskFactors,
        description: incident && incident.description,
        conclusion: incident && incident.conclusion,
        correctiveActions: incident && incident.correctiveActions,
        reviews: incident && incident.reviews,
        attachment: incident && incident.attachment,
        status: incident && incident.status,
        incidentNo: incident && incident.incidentNo
        
    }));
  }, [incident]);

  const handleSetDraft = (e) => {
    e.preventDefault();
    dispatch(setIncidentDraft(id));
    setTimeout(() => {
        dispatch(getIncidents());
        dispatch(getIncidentsByUser());

        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Incident Report Set to Draft Successfully!",
          success: true,
        }));
    }, 1500);
  };

  const handleSetClosed = (e) => {
    e.preventDefault();
    dispatch(setIncidentClosed(id));
    setTimeout(() => {
        dispatch(getIncidents());
        dispatch(getIncidentsByUser());

        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Incident Report Closed Successfully!",
          success: true,
        }));
    }, 1500);
  };

  const handleSetOpen = (e) => {
    e.preventDefault();
    dispatch(setIncidentOpen(id));
    setTimeout(() => {
        dispatch(getIncidents());
        dispatch(getIncidentsByUser());

        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Incident Report Opened Successfully!",
          success: true,
        }));
    }, 1500);
  };

  const handleGenerateReport = (e) => {
    e.preventDefault();
    dispatch(generateReport(id));
    setTimeout(() => {
        dispatch(getIncident(id));
        dispatch(getIncidentsByUser());
      
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Incident Report Generated Successfully!",
          success: true,
        }));
    }, 1500);
  };

  const handleTabChange = (event, newVal) => {
    setVal(newVal);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleOpenEditIncidentReport = (id) => {
    setGetId(id);
    setOpenEditIncidentReport(true);
  };

  const handleCloseEditIncidentReport = () => {
    setOpenEditIncidentReport(false);
  };


  return (
    <div className={classes.root}>
      <EditIncidentReport {...{ openEditIncidentReport, handleCloseEditIncidentReport, id: getId }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      
        <Typography variant="overline" className={classes.title}>
            View Incident Report
        </Typography>
        <Grid container justifyContent="space-between" alignItems="center">
            
            <Grid item xs={10} sm={4}>
                {(incident && (incident.status === "Submitted")) && (isAdminGuard || isIncidentAdmin) && (
                    <ButtonGroup className={classes.btnGroup} variant="outlined">  
                        <Button
                            size="small"
                            className={classes.btnLink}
                            color="primary"
                            onClick={handleSetDraft}
                        >
                            Set to Draft
                        </Button>
                        <Button
                            size="small"
                            className={classes.btnLink}
                            color="secondary"
                            onClick={handleSetClosed}
                        >
                            Set to Closed
                        </Button>
                    
                    </ButtonGroup>
                )}
                {(incident && (incident.status === "Closed")) && (isAdminGuard || isIncidentAdmin) && (
                    <ButtonGroup className={classes.btnGroup} variant="outlined">
                        
                        <Button
                            size="small"
                            className={classes.btnLink}
                            color="primary"
                            onClick={handleSetOpen}
                        >
                            Set to Open
                        </Button>
                    
                    </ButtonGroup>
                )}
                
            </Grid>
            <Grid item xs={10} sm={3}>
                {(incident && (incident.status === "Closed")) && (
                    <Paper variant="outlined" className={clsx(classes.status, classes.statusOrange)}>
                        <Typography variant="overline">
                            <ButtonIcon onClick={handleGenerateReport} color="default" size="small">
                                Generate PDF
                            </ButtonIcon>
                        </Typography>
                        <Divider orientation="vertical" className={classes.divider}/>
                        {incident && incident.reportPDF ? 
                            <ButtonIcon onClick={() => window.open(incident.reportPDF, "_blank", "noopener, noreferrer")} className={classes.file}>
                                <i className="fas fa-2x fa-file-pdf" ></i>
                            </ButtonIcon> : "No PDF"}
                            
                    </Paper>
                )}
            </Grid>
            {(incident && (incident.status === "Submitted")) && (isAdminGuard || isIncidentAdmin) && (
                <Grid item xs={2} sm={1}>
                    <Tooltip title="Click to Edit">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                handleOpenEditIncidentReport(id)
                            }}
                        >
                            <LaunchIcon />
                        </IconButton>
                  </Tooltip>
                </Grid>
            )}
        
            <Grid item xs={12} sm={4}>
                <Paper variant="outlined" className={clsx(classes.status, classes.statusDarkGreen)}>
                    <Typography variant="overline">
                        Incident No
                    </Typography>
                    <Divider orientation="vertical" className={classes.divider}/>
                    {incident && incident.incidentNo}
                </Paper>
            </Grid>
            </Grid>
            <br/>   
            <Paper className={classes.paper} variant="outlined">
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={val}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} textColor="secondary" indicatorColor="secondary">
                    <Tab label="Preliminary Report" className={classes.tab} value="1"/>
                    <Tab label="Other Information"  className={classes.tab} value="2"/>
                    <Tab label="Investigation"  className={classes.tab} value="3"/>
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Grid container justifyContent="center" alignItems="center" spacing={3}>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="reporter"
                                fullWidth
                                variant="outlined"
                                label="Reporter"
                                value={state.reporter?.displayname || ""}
                                disabled
                                />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <TextField
                                name="Location"
                                fullWidth
                                variant="outlined"
                                label="Location"
                                value={state.location || ""}
                                disabled
                            />
                        </Grid>
                            
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="exactLocation"
                                fullWidth
                                variant="outlined"
                                label="Exact Location"
                                value={state.exactLocation || ""}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="date"
                                fullWidth
                                variant="outlined"
                                label="Date & Time"
                                value={moment(state.date).format('YYYY-MM-DD HH:mm') || ""}
                                disabled
                                type="datetime-local"   
                            />
                        </Grid>

                        <Grid item xs={10} sm={5} md={5} >
                            <Typography variant="subtitle2" align="left">
                                Root Cause Required?
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sm={1} md={1}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={state.rootCause || false}
                                    name="rootCause"
                                    color="primary"
                                    disabled
                                />
                                }
                            />       
                        </Grid> 
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={classes.formControl2}>
                                <Autocomplete 
                                    multiple
                                    disabled
                                    id="incidentType"
                                    options={typeOfIncident}
                                    value={state.incidentType || []}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) =>
                                    <TextField {...params} variant="outlined" label="Type of Incident"/>}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle2" align="left">
                                If Injury or Illness, List Worker's Name(s) & Implication
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="worker"
                                fullWidth
                                variant="outlined"
                                label="Worker Name"
                                disabled
                                value={state.worker || ""}
                                multiline
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={classes.formControl2}>
                                <Autocomplete 
                                    multiple
                                    id="injury"
                                    value={state.injury || []}
                                    disabled
                                    options={injuryList}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => option.name} 
                                    renderInput={(params) => 
                                    <TextField {...params} variant="outlined" label="Implication" />} 
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={classes.formControl2}>
                                <Autocomplete 
                                    multiple
                                    id="workType"
                                    disabled
                                    value={state.workType || []}
                                    options={workList}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => option.name} 
                                    renderInput={(params) => 
                                    <TextField {...params} variant="outlined" label="Type of Work" />} 
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="day"
                                fullWidth
                                variant="outlined"
                                label="Day of Week"
                                disabled
                                value={state.day || ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="daysInShift"
                                fullWidth
                                variant="outlined"
                                label="Days Into Shifts"
                                disabled
                                value={state.daysInShift || ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="testing"
                                fullWidth
                                variant="outlined"
                                label="Testing"
                                disabled
                                value={state.testing || ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="rotation"
                                fullWidth
                                variant="outlined"
                                label="Shift Rotation"
                                disabled
                                value={state.rotation || ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle2" align="center">
                                SUPPORTING DOCUMENTS
                            </Typography>
                        </Grid>
                        {state.attachment && state.attachment?.length > 0 && (
                        <Grid item xs={12} sm={12}>
                            <Paper className={classes.paper} variant="outlined" square>
                            <ImageList cols={6} rowHeight={100}>
                                {state.attachment.map((item) => (
                                    <ImageListItem key={item.id} className={classes.imageListItem}>
                                    
                                        <ButtonIcon onClick={() => window.open(item.url, "_blank", "noopener, noreferrer")} className={classes.file}>
                                            {item.url?.split(".").pop() === "pdf"? <i className="fas fa-5x fa-file-pdf" ></i>:                        
                                            (item.url?.split(".").pop() === "ppt") || (item.url?.split(".").pop() === "pptx")? <i className="fas fa-5x fa-file-powerpoint"></i>:
                                            item.url?.split(".").pop() === "pptx"? <i className="fas fa-5x fa-file-powerpoint"></i>:
                                            (item.url?.split(".").pop() ==="png") || (item.url?.split(".").pop() ==="jpg") || (item.url?.split(".").pop() ==="jpeg")? <i className="fas fa-5x fa-file-image"></i>:
                                            <i className="fas fa-5x fa-file"></i>}
                                        </ButtonIcon>

                                        <Tooltip title={item.filename}>    
                                            <Typography variant="subtitle2" noWrap className={classes.typo}>
                                                {item.filename}
                                            </Typography>
                                        </Tooltip>

                                    </ImageListItem>
                                ))}
                            </ImageList> 

                            </Paper>
                        </Grid>
                        )}
                        
                    </Grid>
                </TabPanel>
                <TabPanel value="2">
                    <Grid container justifyContent="center" alignItems="center" spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="sequence"
                                fullWidth
                                variant="outlined"
                                label="Sequence of Events"
                                disabled
                                value={state.sequence || ""}
                                multiline
                                helperText="(Pre-Incident, Incident, Post-Incident)"
                                />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl className={classes.formControl}>
                                <Autocomplete 
                                    multiple
                                    id="contactTypes"
                                    disabled
                                    value={state.contactType || []}
                                    options={contacts}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.name} 
                                    renderInput={(params) => 
                                    <TextField {...params} variant="outlined" label=" Primary Type of Contact" />} 
                                />
                                
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormControl className={classes.formControl}>
                                <Autocomplete 
                                    multiple
                                    id="directCause"
                                    disabled
                                    value={state.directCause || []}
                                    options={causes}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.name} 
                                    renderInput={(params) => 
                                    <TextField {...params} variant="outlined" label="Immediate Direct Causes"/>} 
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="causeDescription"
                                fullWidth
                                variant="outlined"
                                label="Direct Cause Description"
                                disabled
                                value={state.causeDescription || ""}
                                multiline
                                helperText="Provide Description of Each Selected Direct Cause"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl className={classes.formControl}>
                                <Autocomplete 
                                    multiple
                                    id="eventType"
                                    disabled
                                    value={state.eventType || []}
                                    options={events}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.name} 
                                    renderInput={(params) => 
                                    <TextField {...params} variant="outlined" label="Type of Event"/>} 
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="eventDescription"
                                fullWidth
                                variant="outlined"
                                label="Event Type Description"
                                disabled
                                value={state.eventDescription || ""}
                                multiline
                                helperText="Provide Description of Each Selected Event Type"
                                />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle2" align="center">
                                INJURY STATISTICAL DATA
                            </Typography>
                        </Grid>
                        {state.injuryData && state.injuryData?.length > 0 && (
                        <Grid item xs={12} sm={12}>
                            <Paper className={classes.paper} variant="outlined" square>
                                <Typography variant="subtitle2" align="center">
                                    Select All That Apply and Provide a Brief Description of Injury
                                </Typography>
                                <img src="/img/body-parts.jpg" alt="" width="200"/>
                                
                                <br />
                                {state.injuryData?.map((item, index) => (
                                <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                            name="part"
                                            fullWidth
                                            variant="outlined"
                                            label="Body Part"
                                            disabled
                                            value={item.part}
                                            size="small"
                                            multiline
                                            />
                                        
                                    </Grid>
                                    <Grid item xs={10} sm={7} md={7}>
                                        <TextField
                                            name="comment"
                                            fullWidth
                                            variant="outlined"
                                            label="Description"
                                            disabled
                                            value={item.comment}
                                            size="small"
                                            multiline
                                        />
                                    </Grid>   

                                </Grid>
                                ))}

                            </Paper>
                        </Grid>
                        )}
                        
                    </Grid>    
                                
                </TabPanel>
                <TabPanel value="3" hidden={incident && incident.status==="Draft"}>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="subtitle2" align="center">
                            INVESTIGATING COMMITTEE
                        </Typography>
                    </Grid>
                    {state.committee && state.committee?.length > 0 && (
                    <Grid item xs={12} sm={12}>
                        <Paper className={classes.paper} variant="outlined" square>
                            {state.committee?.map((item, index) => (
                            <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                                <Grid item xs={6} sm={4} md={4}>
                                    <TextField
                                        name="name"
                                        fullWidth
                                        variant="outlined"
                                        label="Name"
                                        disabled
                                        value={item.name}
                                        size="small"
                                    />
                                </Grid> 
                                <Grid item xs={6} sm={3} md={3}>
                                    <TextField
                                        name="employer"
                                        fullWidth
                                        variant="outlined"
                                        label="Employer"
                                        disabled
                                        value={item.employer}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4} md={4}>
                                    <TextField
                                        name="trade"
                                        fullWidth
                                        variant="outlined"
                                        label="Trade"
                                        disabled
                                        value={item.trade}
                                        size="small"
                                    />
                                </Grid>   

                            </Grid>
                            ))}

                        </Paper>
                    </Grid>
                    )}

                    <Grid item xs={12} sm={12}>
                        <Typography variant="subtitle2" align="center">
                            STATEMENTS TAKEN FROM
                        </Typography>
                    </Grid>
                    {state.statement && state.statement?.length > 0 && (
                    <Grid item xs={12} sm={12}>
                        <Paper className={classes.paper} variant="outlined" square>
                            {state.statement?.map((item, index) => (
                            <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                                <Grid item xs={6} sm={4} md={4}>
                                    <TextField
                                        name="name"
                                        fullWidth
                                        variant="outlined"
                                        label="Name"
                                        disabled
                                        value={item.name}
                                        size="small"
                                    />
                                </Grid> 
                                <Grid item xs={6} sm={3} md={3}>
                                    <TextField
                                        name="trade"
                                        fullWidth
                                        variant="outlined"
                                        label="Trade"
                                        disabled
                                        value={item.trade}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4} md={4}>
                                    <TextField
                                        name="date"
                                        fullWidth
                                        variant="outlined"
                                        label="Date & Time"
                                        disabled
                                        value={moment(item.date).format('YYYY-MM-DD HH:mm') || ""}
                                        size="small"
                                        type="datetime-local"   
                                    />
                                </Grid>   

                            </Grid>
                            ))}

                        </Paper>
                    </Grid>
                    )}

                    <Grid item xs={12} sm={7}>
                        <FormControl variant="outlined" className={classes.formControl4}>
                            <Autocomplete 
                                multiple
                                id="riskFactors"
                                disabled
                                value={state.riskFactors || []}
                                options={riskFactorsList}
                                getOptionSelected={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.name} 
                                renderInput={(params) => 
                                    <TextField {...params} variant="outlined" label="Risk Tolerance Factors"/>}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            name="description"
                            fullWidth
                            variant="outlined"
                            label="If Others, Describe"
                            value={state.description || ""}
                            disabled
                            multiline
                            />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="conclusion"
                            fullWidth
                            variant="outlined"
                            label="Investigators Conclusion and Additional Learnings"
                            value={state.conclusion || ""}
                            disabled
                            multiline
                            />
                    </Grid>

                    <Typography variant="subtitle2" align="center">
                        CORRECTIVE ACTIONS
                    </Typography>

                    {state.correctiveActions && state.correctiveActions?.length > 0 && (
                    <Grid item xs={12} sm={12}>
                        <Paper className={classes.paper} variant="outlined" square>
                            {state.correctiveActions?.map((item, index) => (
                            <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                                <Grid item xs={6} sm={4} md={4}>
                                    <TextField
                                        name="action"
                                        fullWidth
                                        variant="outlined"
                                        label="Specific Action"
                                        disabled
                                        value={item.action}
                                        size="small"
                                        multiline
                                    />
                                </Grid> 
                                <Grid item xs={6} sm={3} md={3}>
                                    <TextField
                                        name="responsible"
                                        fullWidth
                                        variant="outlined"
                                        label="Responsible Person"
                                        disabled
                                        value={item.responsible}
                                        size="small"
                                        multiline
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                    <TextField
                                        name="date"
                                        fullWidth
                                        variant="outlined"
                                        label="Target Completion Date"
                                        disabled
                                        value={item.date?.split("T")[0] || ''}
                                        size="small"
                                        type="date"   
                                    />
                                </Grid>
                                <Grid item xs={6} sm={2} md={2}>
                                    <TextField
                                        name="completed"
                                        fullWidth
                                        variant="outlined"
                                        label="Date Completed"
                                        disabled
                                        value={item.completed?.split("T")[0] || ''}
                                        size="small"
                                        type="date"   
                                    />
                                </Grid>     

                            </Grid>
                            ))}

                        </Paper>
                    </Grid>
                    )}
                
                    <Typography variant="subtitle2" align="center">
                        REVIEWS/SIGNATURES
                    </Typography>

                    {state.reviews && state.reviews?.length > 0 && (
                    <Grid item xs={12} sm={12}>
                        <Paper className={classes.paper} variant="outlined" square>
                            {state.reviews?.map((item, index) => (
                            <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                                <Grid item xs={6} sm={3} md={3}>
                                    <TextField
                                        name="name"
                                        fullWidth
                                        variant="outlined"
                                        label="Name"
                                        disabled
                                        value={item.name}
                                        size="small"
                                    />
                                </Grid> 
                                <Grid item xs={6} sm={3} md={3}>
                                    <TextField
                                        name="position"
                                        fullWidth
                                        variant="outlined"
                                        label="Position"
                                        disabled
                                        value={item.position || ""}
                                        size="small"
                                    />
                                </Grid>
                                
                                <Grid item xs={6} sm={3} md={3}>
                                <img src={item.signature} alt="signature" className={classes.sign} />
                                </Grid>
                            
                                <Grid item xs={6} sm={3} md={3}>
                                    <TextField
                                        name="date"
                                        fullWidth
                                        variant="outlined"
                                        label="Date"
                                        disabled
                                        value={item.date?.split("T")[0] || ''}
                                        size="small"
                                        type="date"   
                                    />
                                </Grid>    

                            </Grid>
                            ))}

                        </Paper>
                    </Grid>
                    )}

                </Grid>
                
                </TabPanel>
            </TabContext>
            </Box>
            </Paper>

    </div>
  );
};

export default ViewIncidentReport;
