module.exports = {
    CREATE_TESTBED: "CREATE_TESTBED",
    CREATE_TESTBED_REQUEST: "CREATE_TESTBED_REQUEST",
    CREATE_TESTBED_FAIL: "CREATE_TESTBED_FAIL",

    SUBMIT_TESTBED: "SUBMIT_TESTBED",
    SUBMIT_TESTBED_REQUEST: "SUBMIT_TESTBED_REQUEST",
    SUBMIT_TESTBED_FAIL: "SUBMIT_TESTBED_FAIL",

    UPDATE_TESTBED: "UPDATE_TESTBED",
    UPDATE_TESTBED_REQUEST: "UPDATE_TESTBED_REQUEST",
    UPDATE_TESTBED_FAIL: "UPDATE_TESTBED_FAIL",

    SET_TESTBED_DRAFT: "SET_TESTBED_DRAFT",
    SET_TESTBED_DRAFT_REQUEST: "SET_TESTBED_DRAFT_REQUEST",
    SET_TESTBED_DRAFT_FAIL: "SET_TESTBED_DRAFT_FAIL",

    SET_TESTBED_SERVICED: "SET_TESTBED_SERVICED",
    SET_TESTBED_SERVICED_REQUEST: "SET_TESTBED_SERVICED_REQUEST",
    SET_TESTBED_SERVICED_FAIL: "SET_TESTBED_SERVICED_FAIL",

    GET_TESTBEDS: "GET_TESTBEDS",
    GET_TESTBEDS_REQUEST: "GET_TESTBEDS_REQUEST",
    GET_TESTBEDS_FAIL: "GET_TESTBEDS_FAIL",

    GET_TESTBED: "GET_TESTBED",
    GET_TESTBED_REQUEST: "GET_TESTBED_REQUEST",
    GET_TESTBED_FAIL: "GET_TESTBED_FAIL",

    GET_TESTBEDS_BY_USER: "GET_TESTBEDS_BY_USER",
    GET_TESTBEDS_BY_USER_REQUEST: "GET_TESTBEDS_BY_USER_REQUEST",
    GET_TESTBEDS_BY_USER_FAIL: "GET_TESTBEDS_BY_USER_FAIL",

    GET_TESTBEDS_BY_LOCATION: "GET_TESTBEDS_BY_LOCATION",
    GET_TESTBEDS_BY_LOCATION_REQUEST: "GET_TESTBEDS_BY_LOCATION_REQUEST",
    GET_TESTBEDS_BY_LOCATION_FAIL: "GET_TESTBEDS_BY_LOCATION_FAIL",

    DELETE_TESTBED: "DELETE_TESTBED",
    DELETE_TESTBED_REQUEST: "DELETE_TESTBED_REQUEST",
    DELETE_TESTBED_FAIL: "DELETE_TESTBED_FAIL",
}