import React, { useEffect, useState } from "react";
import {
    makeStyles,
    lighten,
    Tooltip,
    Grid,
    Typography,
    TextField,
    Paper,
    Box,
    IconButton,
    FormControl,
    FormLabel,
    FormControlLabel,
    Button,
    ButtonGroup,
    Radio,
    RadioGroup,
  } from "@material-ui/core";

import clsx from "clsx";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import LaunchIcon from '@material-ui/icons/Launch';

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {getInspection, setWeeklyInspectionDraft, getInspections, getInspectionsByUser} from "../../actions/vehicleAction"
import EditFleetModal from "../Modals/Vehicle/EditFleetModal";
import Feedback from "../../utils/Feedback"

const useStyles = makeStyles((theme) => ({

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
    
      title: {
        // color: "#707070",
        padding: theme.spacing(1.5),
        color: lighten(theme.palette.common.black, 0.75),
    
        display: "block",
        fontSize: 14,
        fontWeight: 900,
      },
      
      title2: {
        fontSize: 13,
        marginBottom: 10,
        fontWeight: "bold",
    
        },
    
      time: {
        // marginLeft: "auto",
        marginTop: 10,
    
        // width: 120,
        width: "fit-content",
        height: 40,
        padding: theme.spacing(1),
        // textAlign: "center",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      icon: {
        marginRight: 5,
      },
      btnLink: {
        padding: theme.spacing(1.5),
        marginTop: -90,
        width: 200,
        [theme.breakpoints.down("xs")]: {
          width: "40%",
        },
      },
      btn: {
        marginLeft: 20,
        marginBottom: 30,
      },
    
      btnGroup: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
    
      },
      dialog: {
        position: "relative",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          height: "100%",
        },
      },
      formControl: {
          minWidth: 380,
          [theme.breakpoints.down("sm")]: {
              width: "100%",
          },
          [theme.breakpoints.down("xs")]: {
              minWidth: "100%",
          },
      },
      formcontrol2: {
        textAlign: "left",
        minWidth: 350,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
        },
      },
      paper: {
          padding: theme.spacing(4),
          textAlign: "center",
          color: theme.palette.text.secondary,
          marginLeft: 20,
      },
      [theme.breakpoints.down("sm")]: {
          width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      },
      
      typo : {
        fontWeight: "bold",
        fontSize: 13,
      },
      statusDarkGreen: {
        borderColor: "#4caf50",
        color: "#4caf50",
      },
}));

const INIT_STATE = {
    inspector: "",
    supervisor: undefined,
    vehicle: "",
    date: moment().format('YYYY-MM-DD'),
    location: undefined,
    mileage: "",
    tireGuage: "",
    engineOil: "",
    transmissionOil: "",
    coolantLevel: "",
    washerFluid: "",
    brakeFluid: "",
    beltCondition: "",
    brakes: "",
    registration: "",
    fuel: "",
    comment: "",
    nextService: "", 
    error: null,
    success: false,
    loading: false,
};

const VehicleWeeklyInspectionView = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });


  const {error, inspection } = useSelector((state) => state.vehicle);
  const { profile, loading } = useSelector((state) => state.users);

  const [openEdit, setOpenEdit] = useState(false);
  const [getId, setGetId] = useState("");

    let isAdminGuard = profile
    ? (profile &&
        // profile.role && profile.role.name === "admin")
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isVehicleAdmin = profile
    ? (profile &&
        // profile.role && profile.role.name === "vehicle_admin")
        profile.role && profile.role.map((role) => role.name).includes("vehicle_admin"))
        : false;

    let isVehicleManager = profile
    ? (profile &&
        // profile.role && profile.role.name === "vehicle_manager")
        profile.role && profile.role.map((role) => role.name).includes("vehicle_manager"))
        : false;

    let isVehicleSupervisor = profile
    ? (profile &&
        // profile.role && profile.role.name === "vehicle_supervisor")
        profile.role && profile.role.map((role) => role.name).includes("vehicle_supervisor"))
        : false;

    useEffect(() => {
        if (id){
            dispatch(getInspection(id));
        }
        }, [dispatch, id]);
    
    useEffect(() => {
        const abortController = new AbortController();
        if (error && error !== null) {
            setFeed((prev) => ({
            loading: false,
            open: true,
            message: error,
            success: false,
            }));
        }
        return () => {
            abortController.abort();
        };
    }, [error]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            inspector: inspection && inspection.inspector,
            supervisor: inspection && inspection.supervisor,
            vehicle: inspection && inspection.vehicle,
            date: inspection && inspection.date,
            location: inspection && inspection.location?.displayname,
            mileage: inspection && inspection.mileage,
            tireGuage: inspection && inspection.tireGuage,
            engineOil: inspection && inspection.engineOil,
            transmissionOil: inspection && inspection.transmissionOil,
            coolantLevel: inspection && inspection.coolantLevel,
            washerFluid: inspection && inspection.washerFluid,
            brakeFluid: inspection && inspection.brakeFluid,
            beltCondition: inspection && inspection.beltCondition,
            brakes: inspection && inspection.brakes,
            registration: inspection && inspection.registration,
            fuel: inspection && inspection.fuel,
            comment: inspection && inspection.comment,
            nextService: inspection && inspection.nextService,
    
        }));
        }, [inspection]);

    const handleSetDraft = (e) => {
        e.preventDefault();
        dispatch(setWeeklyInspectionDraft(id));
        setTimeout(() => {
            dispatch(getInspections());
            dispatch(getInspectionsByUser());
    
            setFeed((prev) => ({
                loading: false,
                open: true,
                message: "Inspection set to draft successfully!",
                success: true,
            }));
        }, 1500);
        };
    
    const handleOpenEdit = (id) => {
        setGetId(id);
        setOpenEdit(true);
        };

        const handleCloseEdit = () => {
        setOpenEdit(false);
        };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
        };
    
    const printRef = React.useRef()
    const handleDownloadPdf = async () => {
    const element = printRef.current
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4', true);
    
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight()
    const imgWidth = canvas.width
    const imgHeight = canvas.height
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
    const imgX = (pdfWidth - imgWidth * ratio) / 2
    const imgY = 20

    pdf.addImage(data, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    pdf.save(`${inspection && inspection.inspectionType}_${state.vehicle?.name}_${state.date?.split("T")[0]}.pdf`);
    }; 

  return (
    <div className={classes.root}>
    <EditFleetModal {...{ openEdit, handleCloseEdit, id: getId }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Typography variant="overline" gutterBottom className={classes.title}>
        View Vehicle Weekly Inspection
      </Typography>
        <Button
            color="primary"
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ChevronLeftIcon />}
            className={classes.btn}
        >
            Back
        </Button>
        {(inspection && inspection.status === "Submitted") && (isAdminGuard || isVehicleAdmin || isVehicleManager || isVehicleSupervisor) && (
                <ButtonGroup className={classes.btnGroup} variant="outlined">
                    
                    <Button
                        size="small"
                        className={classes.btnLink}
                        color="primary"
                        onClick={handleSetDraft}
                    >
                        Set to Draft
                    </Button>
                    <Button
                        size="small"
                        className={clsx(classes.btnLink, classes.statusDarkGreen)}
                        onClick={handleDownloadPdf}
                    >
                        Download as PDF
                    </Button>
                </ButtonGroup>
            )}
            <br/>
      {/* <Divider/> */}
      <div ref={printRef}>
      <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>

            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Inspector"
                      value={state.inspector || ""}
                      disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="supervisor"
                      fullWidth
                      variant="outlined"
                      label="Supervisor"
                      value={state.supervisor?.displayname || ""}
                      disabled
                    />
                </Grid>

            {isAdminGuard || isVehicleAdmin || isVehicleManager || isVehicleSupervisor ? (
                <Grid item xs={10} sm={5}>
                  <TextField
                    name="vehicle"
                    fullWidth
                    variant="outlined"
                    label="Vehicle"
                    value={state.vehicle?.displayname || ""}
                    disabled
                  ></TextField>
                </Grid>
                
              ) : (
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="vehicle"
                    fullWidth
                    variant="outlined"
                    label="Vehicle"
                    value={state.vehicle?.displayname || ""}
                    disabled
                  ></TextField>
                </Grid>
              )}
                
              {(isAdminGuard || isVehicleAdmin || isVehicleManager || isVehicleSupervisor) && (
                <Grid item xs={2} sm={1}>
                  <Tooltip title="View Vehicle">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handleOpenEdit(state.vehicle?._id);
                      }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <TextField
                    name="date"
                    fullWidth
                    variant="outlined"
                    label="Date"
                    value={state.date?.split("T")[0] || ''}
                    disabled    
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    name="location"
                    fullWidth
                    variant="outlined"
                    label="Location"
                    value={state.location || ""}
                    disabled    
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                    name="mileage"
                    fullWidth
                    variant="outlined"
                    label="Mileage"
                    value={state.mileage || ""}
                    disabled
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    name="fuel"
                    fullWidth
                    variant="outlined"
                    label="Fuel Level"
                    value={state.fuel || ""}
                    disabled
                />
              </Grid>
              <Grid item xs={12} sm={12}>
              <Typography variant="body2" gutterBottom color="primary">
                  1 - Ok;
                  2 - Requires Scheduled Maintenance;
                  3 - Requires Immediate Fix
                </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Tire Guage</FormLabel>
                  <RadioGroup row name="tireGuage" value={state.tireGuage || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Engine Oil Level</FormLabel>
                  <RadioGroup row name="engineOil" value={state.engineOil || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Transmission Oil Level</FormLabel>
                  <RadioGroup row name="transmissionOil" value={state.transmissionOil || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Coolant Level</FormLabel>
                  <RadioGroup row name="coolantLevel" value={state.coolantLevel || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Washer Fluid Level</FormLabel>
                  <RadioGroup row name="washerFluid" value={state.washerFluid || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Brake Fluid Level</FormLabel>
                  <RadioGroup row name="brakeFluid" value={state.brakeFluid || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Belt Condition and Tension</FormLabel>
                  <RadioGroup row name="beltCondition" value={state.beltCondition || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Brakes and Park Brakes</FormLabel>
                  <RadioGroup row name="brakes" value={state.brakes || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Vehicle Registration and Insurance</FormLabel>
                  <RadioGroup row name="registration" value={state.registration || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} sm={6}>
              <TextField
                    name="nextService"
                    fullWidth
                    variant="outlined"
                    label=" Next Service Due Date"
                    disabled
                    value={state.nextService?.split("T")[0] || ''}
                    type="date"     
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Comments/Rapairs Required"
                  multiline
                  value={state.comment || ""}
                  disabled
                  />
              </Grid>

            </Grid>
               
          </Box>
          </Paper>
          </div>
    </div>
  );
};

export default VehicleWeeklyInspectionView;