import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  InputAdornment
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { createItem, getItems, getSuppliers, getUOMs} from "../../../actions/inventoryAction";
import Feedback from "../../../utils/Feedback";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },
  dialog: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
    minWidth: 420,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    },
  },
  formControl2: {
    textAlign: "left",
    minWidth: 420,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },
    
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    },
    fab: {
        right: theme.spacing(-1),
    },

}));

const INIT_STATE = {
    name: "",
    category: "",
    uom: undefined,
    cost: 1,
    stockLevel: 0,
    reorderLevel: 0,
    supplier: undefined,
    width: "",
    dimension: "",
    thickness: "",
    heatNo: "",
    comment: ""
};

const CreateItemModal = ({ openCreate, handleCloseCreate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, loading, suppliers, uoms, categories } = useSelector((state) => state.inventory);

  useEffect(() => {
    dispatch(getItems());
    dispatch(getSuppliers());
    dispatch(getUOMs());
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));

  };


  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        name: state.name,
        category: state.category,
        uom: state.uom,
        cost: state.cost,
        stockLevel: state.stockLevel,
        reorderLevel: state.reorderLevel,
        supplier: state.supplier,
        width: state.width,
        dimension: state.dimension,
        thickness: state.thickness,
        heatNo: state.heatNo,
        comment: state.comment,
    
    };
    dispatch(createItem(data));

    setTimeout(() => {
      handleCloseCreate();
      dispatch(getItems());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Item Created Successfully.",
        success: true,
      }));
    }, 1500);
  };


  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () => !state.name

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openCreate}
        onClose={handleCloseCreate}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Item
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseCreate}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  fullWidth
                  variant="outlined"
                  label="Description/Grade"
                  onChange={handleChange}
                  value={state.name || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                  <FormControl variant="outlined" className={classes.formControl2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                        Item Category
                    </InputLabel>
                    <Select
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={state.category || ""}
                      name="category"
                      onChange={handleChange}
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {categories &&
                        categories.map((category) => (
                          <MenuItem key={category._id} value={category._id}>
                            {category.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
          
              <Grid item xs={12} sm={6} >
                  <FormControl variant="outlined" className={classes.formControl2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                        Unit of Measure
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={state.uom || ""}
                      name="uom"
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {uoms &&
                        uoms.map((uom) => (
                          <MenuItem key={uom._id} value={uom._id}>
                            {uom.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                  name="dimension"
                  fullWidth
                  variant="outlined"
                  label="Dimension"
                  onChange={handleChange}
                  value={state.dimension || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="thickness"
                  fullWidth
                  variant="outlined"
                  label="Thickness"
                  onChange={handleChange}
                  value={state.thickness || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="heatNo"
                  fullWidth
                  variant="outlined"
                  label="Heat Number"
                  onChange={handleChange}
                  value={state.heatNo || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <FormControl variant="outlined" className={classes.formControl2}>
                  <InputLabel id="demo-simple-select-outlined-label">
                      Supplier
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    fullWidth
                    value={state.supplier || ""}
                    name="supplier"
                    onChange={handleChange}
                  >
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    {suppliers &&
                      suppliers.map((supplier) => (
                        <MenuItem key={supplier._id} value={supplier._id}>
                          {supplier.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="cost"
                  fullWidth
                  variant="outlined"
                  label="Cost"
                  onChange={handleChange}
                  value={state.cost || ""}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="stockLevel"
                  fullWidth
                  variant="outlined"
                  label="Target Stock Level"
                  onChange={handleChange}
                  value={state.stockLevel || ""}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="reorderLevel"
                  fullWidth
                  variant="outlined"
                  label="Reorder Level"
                  onChange={handleChange}
                  value={state.reorderLevel || ""}
                  type="number"
                />
              </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                    name="comment"
                    fullWidth
                    variant="outlined"
                    label="Comment"
                    onChange={handleChange}
                    value={state.comment || ""}
                    />
                </Grid>
                
            </Grid>
            <Typography align="center">
              <Button
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                type="submit"
                className={classes.btn}
                disabled={isValid() || loading}
              >
                {loading ? "Loading..." : "Save"}
              </Button>
            </Typography>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateItemModal;
