import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,

} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";

import { useDispatch, useSelector } from "react-redux";
import { getSupplier, getSuppliers, updateSupplier } from "../../../actions/inventoryAction";

import Feedback from "../../../utils/Feedback";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    color: theme.palette.primary.main,
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dialog: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
    minWidth: 420,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    },
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 420,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },
paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
},
[theme.breakpoints.down("sm")]: {
    width: "100%",
},
[theme.breakpoints.down("xs")]: {
    minWidth: "100%",
},

fab: {
    right: theme.spacing(-1),
},

}));

const INIT_STATE = {
    name: "",
    address: "",
    website: "",
    email: "",
    contact: "",
    phone: "",
    hse: [],
    hseExpiry: "",
    qms: [],
    qmsExpiry: "",
    evaluationCriteria: [],
    approvalStatus: "",
    approvalLevel: "",
    comment: "",
    error: null,
    success: false,
    loading: false,
};

const EditSupplierModal = ({ openEdit, handleCloseEdit, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { supplier, error, loading } = useSelector((state) => state.inventory);
  const hseList = [
    {id: 1, name: "COR"},
    {id: 2, name: "COR Equivalent"},
    {id: 3, name: "WSIB"},
    {id: 4, name: "ISO 14001"},
    {id: 5, name: "OHSAS 18001"},
    {id: 6, name: "Other"},
    {id: 7, name: "N/A"},
  ]
  const qmsList = [
    {id: 1, name: "ISO 9001:2008"},
    {id: 2, name: "ISO 9001:2015"},
    {id: 3, name: "ISO 9001:2000"},
    {id: 4, name: "ISO 9001 Compliant"},
    {id: 5, name: "ISO 13483:2003"},
    {id: 6, name: "CSA-Z299.4"},
    {id: 7, name: "AISC"},
    {id: 8, name: "CISC"},
    {id: 9, name: "None"},
    {id: 10, name: "N/A"},
  ]
  const evaluationCriteriaList = [
    {id: 1, name: "Past History"},
    {id: 2, name: "Supplier Questionnaire"},
    {id: 3, name: "QMS System"},
    {id: 4, name: "ISO 9001"},
    {id: 5, name: "COR"},
    {id: 6, name: "WHSCC"},
    {id: 7, name: "Safety Policy"},
    {id: 8, name: "Safety Manual"},
    {id: 9, name: "HSE System"},
    {id: 10, name: "Material Traceability"},
    {id: 11, name: "Incoming Inspection"},
    {id: 12, name: "Maintenance Program"},
    {id: 13, name: "Third Party NACE Inspector"},
    {id: 14, name: "None Required"},
    {id: 15, name: "No Evaluation Completed"},
  ]

  useEffect(() => {
    dispatch(getSuppliers());
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    dispatch(getSupplier(id));
  }, [dispatch, id]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
        name: supplier && supplier.name,
        address: supplier && supplier.address,
        website: supplier && supplier.website,
        email: supplier && supplier.email,
        contact: supplier && supplier.contact,
        phone: supplier && supplier.phone,
        hse: supplier && supplier.hse,
        hseExpiry: supplier && supplier.hseExpiry,
        qms: supplier && supplier.qms,
        qmsExpiry: supplier && supplier.qmsExpiry,
        evaluationCriteria: supplier && supplier.evaluationCriteria,
        approvalStatus: supplier && supplier.approvalStatus,
        approvalLevel: supplier && supplier.approvalLevel,
        comment: supplier && supplier.comment,

    }));
  }, [supplier]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        name: state.name,
        address: state.address,
        website: state.website,
        email: state.email,
        contact: state.contact,
        phone: state.phone,
        hse: state.hse,
        hseExpiry: state.hseExpiry,
        qms: state.qms,
        qmsExpiry: state.qmsExpiry,
        evaluationCriteria: state.evaluationCriteria,
        approvalStatus: state.approvalStatus,
        approvalLevel: state.approvalLevel,
        comment: state.comment,
        id,
    };
    dispatch(updateSupplier(data));
    setTimeout(() => {
      handleCloseEdit();
     
      dispatch(getSuppliers());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Supplier Updated Successfully.",
        success: true,
      }));
    }, 1500);
  };


  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () => !state.name

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth maxWidth="md" className={classes.dialog}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit Supplier
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseEdit}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleSubmit}>
        
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  fullWidth
                  variant="outlined"
                  label="Name"
                  onChange={handleChange}
                  value={state.name || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="address"
                  fullWidth
                  variant="outlined"
                  label="Address"
                  onChange={handleChange}
                  value={state.address || ""}
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="website"
                  fullWidth
                  variant="outlined"
                  label="Website"
                  onChange={handleChange}
                  value={state.website || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  fullWidth
                  variant="outlined"
                  label="Email"
                  onChange={handleChange}
                  value={state.email || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="contact"
                  fullWidth
                  variant="outlined"
                  label="Contact Person"
                  onChange={handleChange}
                  value={state.contact || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="phone"
                  fullWidth
                  variant="outlined"
                  label="Phone"
                  onChange={handleChange}
                  value={state.phone || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <Autocomplete 
                        multiple
                        id="hse"
                        value={state.hse}
                        options={hseList}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name} 
                        onChange={(e, value) => setState((prevState) => ({ ...prevState, hse: value }))} 
                        renderInput={(params) => 
                        <TextField {...params} variant="outlined" label="HSE" />} 
                    />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="hseExpiry"
                      fullWidth
                      variant="outlined"
                      label="HSE Expiry Date"
                      onChange={handleChange}
                      value={state.hseExpiry?.split("T")[0] || ''}
                      type="date"   
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <Autocomplete 
                        multiple
                        id="qms"
                        value={state.qms}
                        options={qmsList}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name} 
                        onChange={(e, value) => setState((prevState) => ({ ...prevState, qms: value }))} 
                        renderInput={(params) => 
                        <TextField {...params} variant="outlined" label="QMS" />} 
                    />                  
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="qmsExpiry"
                      fullWidth
                      variant="outlined"
                      label="QMS Expiry Date"
                      onChange={handleChange}
                      value={state.qmsExpiry?.split("T")[0] || ''}
                      type="date"   
                  />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <Autocomplete 
                        multiple
                        id="evaluationCriteria"
                        value={state.evaluationCriteria}
                        options={evaluationCriteriaList}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name} 
                        onChange={(e, value) => setState((prevState) => ({ ...prevState, evaluationCriteria: value }))} 
                        renderInput={(params) => 
                        <TextField {...params} variant="outlined" label="Evaluation Critria" />} 
                    />
                </FormControl>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formcontrol2}>
                        <InputLabel id="approvalStatus">
                            Approval Status
                        </InputLabel>
                        <Select
                            labelId="approvalStatus"
                            id="approvalStatus"
                            fullWidth
                            value={state.approvalStatus || ""}
                            name="approvalStatus"
                            onChange={handleChange}
                            variant="outlined"
                            required
                        >
                            <MenuItem value="Requested">Requested</MenuItem>
                            <MenuItem value="Pending">Pending</MenuItem>
                            <MenuItem value="Conditional">Conditional</MenuItem>
                            <MenuItem value="Limited Use">Limited Use</MenuItem>
                            <MenuItem value="Limited Use - High Risk">Limited Use - High Risk</MenuItem>
                            <MenuItem value="One Time Use">One Time Use</MenuItem>
                            <MenuItem value="Further Evaluation">Further Evaluation</MenuItem>
                            <MenuItem value="Rejected">Rejected</MenuItem>
                            <MenuItem value="Approved">Approved</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className={classes.formcontrol2}>
                        <InputLabel id="approvalLevel">
                            Approval Levels
                        </InputLabel>
                        <Select
                            labelId="approvalLevel"
                            id="approvalLevel"
                            fullWidth
                            value={state.approvalLevel || ""}
                            name="approvalLevel"
                            onChange={handleChange}
                            variant="outlined"
                        >
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="2">2</MenuItem>
                            <MenuItem value="3">3</MenuItem>
                        </Select>
                        </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                    name="comment"
                    fullWidth
                    variant="outlined"
                    label="Comment"
                    onChange={handleChange}
                    value={state.comment || ""}
                    />
                </Grid>
                
            </Grid>
            <Typography align='center'>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                endIcon={<UpdateIcon />}
                className={classes.btn}
                disabled={isValid() || loading}
              >
                {loading ? "Loading..." : "Update"}
              </Button>
            </Typography> 
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditSupplierModal;
