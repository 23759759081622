import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import "react-html5-camera-photo/build/css/index.css";

import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
// import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorkerRegistration.register()
reportWebVitals()