import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  Paper,
  Fab,
  Tab,
  Box,
  IconButton,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from '@material-ui/lab';
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import { updateSignin, submitSignin, getCrewBySupervisor, getSign, getSignsByUser} from "../../../actions/toolboxAction";
import SignatureModal from "./SignatureModal"
import {getLocations} from "../../../actions/usersAction";
import Feedback from "../../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },

  btn2:{
    marginBottom: 30,
    marginLeft: 10
  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 370,
      [theme.breakpoints.down("sm")]: {
        minWidth: 300,
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      }
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 370,
    [theme.breakpoints.down("sm")]: {
      minWidth: 300,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },

  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },
  sign : {
    width: 60,
    height: 30,
    [theme.breakpoints.down("sm")]: {
      width: 60,
      height: 30,
    },
    [theme.breakpoints.down("xs")]: {
      width: 40,
      height: 20,
    },
  }

  
}));

const INIT_STATE = {
    crew: undefined,
    date: "",
    location: undefined,
    topic: "",
    crewMembers: [{ name: "", absent: false, signature: "", time: "" }],
    addMembers: [{ name: "", absent: false, signature: "", time: "" }],
    crewFit: { fit:false, names: "" },
    crewModified: { modified:false, names: "" },
    checkIn: false,
    safetyBulletin: false,
    weatherConcern: { concern:false, names: "" },
    requireMentor:{ mentor:false, names: "" },
    returnFromVacation:{ vacation:false, names: "" },
    workPermit: false,
    ergonomicConcern: false,
    crewRotation: false,
    awkwardPosition: false,
    privateIssue: false,
    requireMSDS:{ msds:false, names: "" },
    JHACard: false,
    stretchCompleted: false,
    comment: "",
    supervisor: undefined,
};

const EditSignInModal = ({ openEditSignIn, handleCloseEditSignIn, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { sign, error, loading, myCrews} = useSelector((state) => state.toolbox);
  const { profile, locations } = useSelector((state) => state.users);
  const [openSignature, setOpenSignature] = useState(false);
  const [getId, setGetId] = useState("");
  const [getType, setGetType] = useState("");
  const [val, setVal] = useState('1');

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getCrewBySupervisor(profile._id));
  }, [profile._id, dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    if(id) {
      dispatch(getSign(id));
    }
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
      ...prev,
        crew: sign && sign.crew,
        date: sign && sign.date,
        topic: sign && sign.topic,
        location: sign && sign.location?._id,
        crewMembers: sign && sign.crewMembers,
        addMembers: sign && sign.addMembers,
        crewFit: sign && sign.crewFit,
        crewModified: sign && sign.crewModified,
        checkIn: sign && sign.checkIn,
        safetyBulletin: sign && sign.safetyBulletin,
        weatherConcern: sign && sign.weatherConcern,
        requireMentor: sign && sign.requireMentor,
        returnFromVacation: sign && sign.returnFromVacation,
        workPermit: sign && sign.workPermit,
        ergonomicConcern: sign && sign.ergonomicConcern,
        crewRotation: sign && sign.crewRotation,
        awkwardPosition: sign && sign.awkwardPosition,
        privateIssue: sign && sign.privateIssue,
        requireMSDS: sign && sign.requireMSDS,
        JHACard: sign && sign.JHACard,
        stretchCompleted: sign && sign.stretchCompleted,
        comment: sign && sign.comment,
        supervisor: sign && sign.createdBy,

    }));
  }, [sign]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    
  };

  const handleChangeMembers = (index) => (e) => {
    e.persist();
    const { name, value } = e.target;
    const list = [...state.addMembers];
    list[index][name] = value;
    setState((prevState) => ({ ...prevState, addMembers: list }));
};


const handleAddMembers = (e) => {
  e.persist();
  const data = {
      name: '',
      absent: false,
      signature: "",
      time: "",
  }
  setState((prevState) => ({ ...prevState, addMembers: [...prevState.addMembers, data] }));
  let currentState = {
    addMembers: [...state.addMembers, data],
    id
};

dispatch(updateSignin(currentState));
setTimeout(() => {
    dispatch(getSign(id))
  }, 1500);

};

const handleRemoveMembers = (index) => (e) => {
  e.persist();
  const list = [...state.addMembers];
  list.splice(index, 1);
  setState((prevState) => ({ ...prevState, addMembers: list }));
};

const handleOpenSignature = (id, type) => {
  setGetId(id);
  setGetType(type);
  setOpenSignature(true);
};

const handleCloseSignature = () => {
  setOpenSignature(false);
};


  const handleSave = (e) => {
    e.preventDefault();
    let data = {
        crew: state.crew,
        date: state.date,
        topic: state.topic,
        location: state.location,
        crewMembers: [...state.crewMembers],
        addMembers: [...state.addMembers],
        crewFit: state.crewFit,
        crewModified: state.crewModified,
        checkIn: state.checkIn,
        safetyBulletin: state.safetyBulletin,
        weatherConcern: state.weatherConcern,
        requireMentor: state.requireMentor,
        returnFromVacation: state.returnFromVacation,
        workPermit: state.workPermit,
        ergonomicConcern: state.ergonomicConcern,
        crewRotation: state.crewRotation,
        awkwardPosition: state.awkwardPosition,
        privateIssue: state.privateIssue,
        requireMSDS: state.requireMSDS,
        JHACard: state.JHACard,
        stretchCompleted: state.stretchCompleted,
        comment: state.comment,
        id
    };
    dispatch(updateSignin(data));

    setTimeout(() => {
      dispatch(getSignsByUser());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Toolbox Saved Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      crew: state.crew,
      date: state.date,
      topic: state.topic,
      location: state.location,
      crewMembers: [...state.crewMembers],
      addMembers: [...state.addMembers],
      crewFit: state.crewFit,
      crewModified: state.crewModified,
      checkIn: state.checkIn,
      safetyBulletin: state.safetyBulletin,
      weatherConcern: state.weatherConcern,
      requireMentor: state.requireMentor,
      returnFromVacation: state.returnFromVacation,
      workPermit: state.workPermit,
      ergonomicConcern: state.ergonomicConcern,
      crewRotation: state.crewRotation,
      awkwardPosition: state.awkwardPosition,
      privateIssue: state.privateIssue,
      requireMSDS: state.requireMSDS,
      JHACard: state.JHACard,
      stretchCompleted: state.stretchCompleted,
      comment: state.comment,
      id
    };
    dispatch(submitSignin(data));
    setTimeout(() => {
      handleCloseEditSignIn()
        dispatch(getSignsByUser());
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Toolbox Signed On Successfully.",
          success: true,
        }));
    }, 1500);
  };

  const handleTabChange = (event, newVal) => {
    setVal(newVal);
  };


  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.crew || !state.location

  return (
    <div>
      <SignatureModal {...{ openSignature, handleCloseSignature, id: getId, type: getType }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openEditSignIn}
        onClose={handleCloseEditSignIn}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit Toolbox Sign On
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseEditSignIn}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={val}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} textColor="secondary" indicatorColor="secondary">
                  <Tab label="Crew Members" className={classes.tab} value="1"/>
                  <Tab label="Other Information"  className={classes.tab} value="2"/>
                </TabList>
              </Box>
            <TabPanel value="1">
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="supervisor"
                      fullWidth
                      variant="outlined"
                      label="Supervisor"
                      value={state.supervisor?.displayname || ""}
                      disabled
                      required
                    />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="date"
                    fullWidth
                    variant="outlined"
                    label="Date"
                    onChange={handleChange}
                    value={state.date?.split("T")[0] || ''}
                    required
                    type="date"
                    
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                      name="topic"
                      fullWidth
                      variant="outlined"
                      label="Toolbox Talk Topic"
                      onChange={handleChange}
                      value={state.topic || ""}
                      multiline
                    />
                </Grid>
                
                <Grid item xs={12} sm={6} >
                  <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                    Job Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={state.location || ''}
                      name="location"
                      onChange={handleChange}
                      label="Location"
                      required
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {locations &&
                        locations.map((location) => (
                          <MenuItem key={location._id} value={location._id}>
                            {location.displayname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                </Grid>

                <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                    <Autocomplete 
                        id="crew"
                        options={myCrews}
                        getOptionSelected={(option) => option.name === state.crew?.name}
                        getOptionLabel={(option) => option.name || ''} 
                        onChange={(e, value) => {
                          e.persist();
                          setState((prevState) => (
                            { ...prevState, crew: value, crewMembers:value?.members.map((item, index) => (
                            { name: item.name, absent: false })
                          
                          )}))
                        }}
                        value={state.crew || ''}
                        disabled
                        renderInput={(params) => 
                        <TextField {...params} variant="outlined" label="Crew Name" />}
                          
                    />
                </FormControl>
                </Grid>
                
            </Grid>
            <br />
            
                <Paper className={classes.paper} variant="outlined" square>
                    <Typography variant="body1">
                        Crew Members
                    </Typography>
                    <br />
                    <Grid container spacing={3}>
                      {state.crewMembers?.map((current, i) => (
                        <Grid container item xs={12} sm={12} md={12} justifyContent="center" alignItems="center" key={i}>
                          <Grid container alignContent="center" spacing={2}>
                            <Grid item xs={8} sm={4} md={4}>
                                <TextField
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    label="Name"
                                    value={current.name}
                                    required
                                    disabled
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={4} sm={2} md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={current.absent}
                                            name="absent"
                                            color="primary"
                                            onChange={(e, value) => setState((prevState) =>
                                              ({ ...prevState, crewMembers: prevState.crewMembers.map((item, index) => 
                                                index === i ? ({ ...item, absent: value }) : item
                                              )})
                                            )}
                  
                                        />
                                    }
                                    label="Absent?"
                                    labelPlacement="start"
                                />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2}>
                              <Tooltip title="Sign">
                                <IconButton
                                  color="primary"
                                  onClick={() => handleOpenSignature(current._id, "crewMembers")}
                                  disabled={current.absent}
                                >
                                  <CreateIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid> 
                            <Grid item xs={5} sm={2} md={2}>
                              <img src={current.signature} alt="signature" className={classes.sign}/>
                            </Grid>
                            <Grid item xs={5} sm={2} md={2}>
                                <TextField
                                    name="time"
                                    fullWidth
                                    variant="outlined"
                                    label="Time"
                                    value={current.time}
                                    required
                                    size="small"
                                    disabled
                                />
                            </Grid> 
                            
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <br />
                    <Typography variant="overline" align="center" className={classes.title2}>
                      Additional Members
                    </Typography>
                    <Fab color="primary" aria-label="add" size="medium" onClick={handleAddMembers} className={classes.fab}>
                        <AddIcon />
                    </Fab>
                </Paper>
                <br />

            {state.addMembers && state.addMembers.length > 0 && (
              <Paper className={classes.paper} variant="outlined" square>
                  {state.addMembers.map((item, index) => (
                    
                  <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                    <Grid item xs={8} sm={4} md={4}>
                      <TextField
                        name="name"
                        fullWidth
                        variant="outlined"
                        label="Name"
                        value={item.name}
                        required
                        size="small"
                        onChange={handleChangeMembers(index)}
                      />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                      <Tooltip title="Sign">
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenSignature(item._id, "addMembers")}
                        >
                          <CreateIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid> 
                    <Grid item xs={4} sm={2} md={2}>
                      <img src={item.signature} alt="signature" className={classes.sign}/>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2}>
                        <TextField
                            name="time"
                            fullWidth
                            variant="outlined"
                            label="Time"
                            value={item.time}
                            required
                            size="small"
                            disabled
                        />
                    </Grid>                 
              
                    <Grid item xs={4} sm={2} md={2}>
                      <IconButton color="secondary" onClick={handleRemoveMembers(index)}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>

                  </Grid>
                  ))}

              </Paper>
            )}
          </TabPanel>
          <TabPanel value="2">
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                  Is Everyone Fit For Duty? (including injury sustained off site, drug/alcohol use, sleep deprivation, sickness, or medication)
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.crewFit?.fit || false}
                        name="fit"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, crewFit: { ...prevState.crewFit, fit: value }}))
                        }}
                      />
                    }
                />        
              </Grid>
              
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="names"
                  fullWidth
                  variant="outlined"
                  label="If No, List Names"
                  onChange={(e) => {
                    e.persist()
                    setState((prevState) => ({ ...prevState, crewFit: { ...prevState.crewFit, names: e.target.value }}))
                  }}
                  multiline
                  value={state.crewFit?.names || ""}
                  disabled={state.crewFit?.fit}
                />
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Any crew members on modified duty
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.crewModified?.modified || false}
                        name="modified"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, crewModified: { ...prevState.crewModified, modified: value }}))
                        }}
                      />
                    }
                />     
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="names"
                  fullWidth
                  variant="outlined"
                  label="If Yes, List Names"
                  onChange={(e) => {
                    e.persist()
                    setState((prevState) => ({ ...prevState, crewModified: { ...prevState.crewModified, names: e.target.value }}))
                  }}
                  multiline
                  value={state.crewModified?.names || ""}
                  disabled={!state.crewModified?.modified}
                />
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
              Are they required to check in with the Nurse?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkIn || false}
                        name="checkIn"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, checkIn: value }))
                        }}
                      />
                    }
                />       
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
              Are there Safety Bulletins to be reviewed?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.safetyBulletin || false}
                        name="safetyBulletin"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, safetyBulletin: value }))
                        }}
                      />
                    }
                />       
              </Grid>

              <Grid item xs={10} sm={5} md={5}>
              <Typography variant="subtitle2" align="left">
                Are there any weather concerns?
              </Typography>
              </Grid>

              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.weatherConcern?.concern || false}
                        name="concern"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, weatherConcern: { ...prevState.weatherConcern, concern: value }}))
                        }}
                      />
                    }

                />     
              </Grid>

              <Grid item xs={12} sm={6} md={6}> 
                <TextField
                  name="names"
                  fullWidth
                  variant="outlined"
                  label="If Yes, List Them"
                  onChange={(e) => {
                    e.persist()
                    setState((prevState) => ({ ...prevState, weatherConcern: { ...prevState.weatherConcern, names: e.target.value }}))
                  }}
                  multiline
                  value={state.weatherConcern?.names || ""}
                  disabled={!state.weatherConcern?.concern}
                />
              </Grid>
              
              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Are there any employees, new or returning from Vacation?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.returnFromVacation?.vacation || false}
                        name="vacation"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, returnFromVacation: { ...prevState.returnFromVacation, vacation: value }}))
                        }}
                      />
                    }
                />      
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="names"
                  fullWidth
                  variant="outlined"
                  label="If Yes, List Names"
                  onChange={(e) => {
                    e.persist()
                    setState((prevState) => ({ ...prevState, returnFromVacation: { ...prevState.returnFromVacation, names: e.target.value }}))
                  }}
                  multiline
                  value={state.returnFromVacation?.names || ""}
                  disabled={!state.returnFromVacation?.vacation}
                />
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                 Do any new workers require a mentor/buddy?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.requireMentor?.mentor || false}
                        name="mentor"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, requireMentor: { ...prevState.requireMentor, mentor: value }}))
                        }}
                      />
                    }
                />     
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="names"
                  fullWidth
                  variant="outlined"
                  label="If Yes, List Names"
                  onChange={(e) => {
                    e.persist()
                    setState((prevState) => ({ ...prevState, requireMentor: { ...prevState.requireMentor, names: e.target.value }}))
                  }}
                  multiline
                  value={state.requireMentor?.names || ""}
                  disabled={!state.requireMentor?.mentor}
                />
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Are any MSDS required?
              </Typography>
              </Grid>    
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.requireMSDS?.msds || false}
                        name="msds"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, requireMSDS: { ...prevState.requireMSDS, msds: value }}))
                        }}
                      />
                    }
                />        
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="names"
                  fullWidth
                  variant="outlined"
                  label="If Yes, List Products"
                  onChange={(e) => {
                    e.persist()
                    setState((prevState) => ({ ...prevState, requireMSDS: { ...prevState.requireMSDS, names: e.target.value }}))
                  }}
                  multiline
                  value={state.requireMSDS?.names || ""}
                  disabled={!state.requireMSDS?.msds}
                />
              </Grid>
              
              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Do we require a hazardous work permit for any of today's tasks?
              </Typography>
              </Grid>  
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.workPermit || false}
                        name="workPermit"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, workPermit: value }))
                        }}
                      />
                    }
                />        
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Ergonomic Safety Concerns?
              </Typography>
              </Grid>  
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.ergonomicConcern || false}
                        name="ergonomicConcern"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, ergonomicConcern: value }))
                        }}
                      />
                    }
                />        
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Crew rotation required for repetitive tasks?
              </Typography>
              </Grid>  
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.crewRotation || false}
                        name="crewRotation"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, crewRotation: value }))
                        }}
                      />
                    }
                />        
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Sustained or awkward positions?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.awkwardPosition || false}
                        name="awkwardPosition"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, awkwardPosition: value }))
                        }}
                      />
                    }
                />      
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Does everyone have JHA Cards?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.JHACard || false}
                        name="JHACard"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, JHACard: value }))
                        }}
                      />
                    }
                />
                 
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                Has the crew attended the Daily Tool Box Meeting and completed their stretches?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.stretchCompleted || false}
                        name="stretchCompleted"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, stretchCompleted: value }))
                        }}
                      />
                    }
                />         
              </Grid>

              <Grid item xs={10} sm={5} md={5} >
              <Typography variant="subtitle2" align="left">
                  Does anyone wish to meet with the F/M in private regarding Safety Issues?
              </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.privateIssue || false}
                        name="privateIssue"
                        color="primary"
                        onChange={(e, value) => {
                          e.persist()
                          setState((prevState) =>({ ...prevState, privateIssue: value }))
                        }}
                      />
                    }
                /> 
        
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Additional Comments or SWP Reviewed?"
                  onChange={handleChange}
                  multiline
                  value={state.comment || ""}
                />
              </Grid>
              
            </Grid>
            <br />
            <Typography align="center" variant="subtitle2">
              Note: Foremen, Supervisors and Operations are to be notified of injuries and incidents in their area and person is to be removed from permit during treatment.<br />
              It is our moral obligation to address At-Risk Behaviour immediately. If you are uncomfortable mentioning it to the person, tell your foreman at once. Don’t wait for your end of shift meetings
            </Typography>
            <br />
            <Typography align="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSave}
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save"}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save & Submit"}
                </Button>
              </Typography>
          </TabPanel>
          </TabContext>
          </Box>
          </Paper>


        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditSignInModal;
