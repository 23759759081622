import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  TextField,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, getLocations } from "../../../actions/usersAction";
import {getInspections, getInspection, setInspectionServiced} from "../../../actions/forkliftAction";

import Feedback from "../../../utils/Feedback";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  btnGroup: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
      },

  },
  dialog: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
    minWidth: 420,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    },
},
formcontrol2: {
    textAlign: "left",
    minWidth: 420,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },
  divider: {
    height: 40,
    margin: 4,
    width: 2,
  },
  divider2: {
    height: 40,
    margin:-2,
    width: 2,
 },
  status:{
    width: "fit-content",
    height: 50,
    padding:8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
  iconButton: {
    paddingLeft: 50,
    paddingRight: 50,
  },

paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
},
paper2:{
    padding: theme.spacing(2),
    textAlign: "center",
    width: "fit-content",
    height: 50,
    display: "flex",
    justifyContent: "center",
    margin: "auto",
  },

[theme.breakpoints.down("sm")]: {
    width: "100%",
},
[theme.breakpoints.down("xs")]: {
    minWidth: "100%",
},

fab: {
    right: theme.spacing(-1),
},
statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    borderColor: "#ef5350",
  },

}));

const INIT_STATE = {
    forklift: "",
    date: moment().format('YYYY-MM-DD'),
    description: "",
    cost: "",
    servicedBy: "",
    error: null,
    success: false,
    loading: false,
};

const ServiceInspectionModal = ({ openService, handleCloseService, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, loading, forklift } = useSelector((state) => state.forklift);
  const { profile} = useSelector((state) => state.users);

  let isAdminGuard = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isForkliftAdmin = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("forklift_admin"))
        : false;

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getLocations());
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
        forklift: forklift && forklift.unitNumber,

    }));
  }, [dispatch,forklift]);

  useEffect(() => {
    if (isAdminGuard || isForkliftAdmin) {
      if (id){
        dispatch(getInspection(id));
      }
      
    }
  }, [dispatch, id, isAdminGuard, isForkliftAdmin]);


  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        date: state.date,
        description: state.description,
        cost: state.cost,
        servicedBy: state.servicedBy,
        id
    };
    dispatch(setInspectionServiced(data));
    setTimeout(() => {
      handleCloseService();
        dispatch(getInspections());
        setFeed((prev) => ({
            loading: false,
            open: true,
            message: "Service Created Successfully.",
            success: true,
        }));
        }, 1500);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.date || !state.description

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog open={openService} onClose={handleCloseService} fullWidth maxWidth="md" className={classes.dialog}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Record Service & Repair
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseService}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
            
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                      name="forklift"
                      fullWidth
                      variant="outlined"
                      label="Forklift Unit Number"
                      value={state.forklift}
                      disabled   
                  />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                      name="date"
                      fullWidth
                      variant="outlined"
                      label="Service Date"
                      onChange={handleChange}
                      value={state.date || moment().format('YYYY-MM-DD')}
                      type="date"     
                  />
              </Grid>
                
              <Grid item xs={12} sm={6}>
                <TextField
                    name="description"
                    fullWidth
                    multiline
                    variant="outlined"
                    label="Description"
                    onChange={handleChange}
                    value={state.description}
                />
              </Grid>
                
              <Grid item xs={12} sm={6}>
                <TextField
                    name="cost"
                    fullWidth
                    variant="outlined"
                    label="Cost"
                    onChange={handleChange}
                    value={state.cost}
                    type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    name="servicedBy"
                    fullWidth
                    variant="outlined"
                    label="Mechanic"
                    onChange={handleChange}
                    value={state.servicedBy}
                />
              </Grid>

            </Grid>
            <Typography align="center">
              <Button
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                type="submit"
                className={classes.btn}
                disabled={isValid() || loading}
              >
                {loading ? "Loading..." : "Save"}
              </Button>
            </Typography>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ServiceInspectionModal;
