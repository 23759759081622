import { combineReducers } from "redux";
import usersReducer from "./usersReducer";
import documentReducer from "./documentReducer";
import toolboxReducer from "./toolboxReducer";
import vehicleReducer from "./vehicleReducer";
import awpReducer from "./awpReducer";
import forkliftReducer from "./forkliftReducer";
import harnessReducer from "./harnessReducer";
import craneReducer from "./craneReducer";
import scaffoldReducer from "./scaffoldReducer";
import swagerReducer from "./swagerReducer";
import testbedReducer from "./testbedReducer";
import inventoryReducer from "./inventoryReducer";
import incidentReducer from "./incidentReducer";


const rootReducer = combineReducers({
  users: usersReducer,
  documents: documentReducer,
  toolbox: toolboxReducer,
  vehicle: vehicleReducer,
  awp: awpReducer,
  forklift: forkliftReducer,
  harness: harnessReducer,
  inventory: inventoryReducer,
  crane: craneReducer,
  swager: swagerReducer,
  testbed: testbedReducer,
  scaffold: scaffoldReducer,
  incident: incidentReducer
  
});

export default rootReducer;
