import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Backdrop,
  Chip,
  lighten,
  Button,
  ButtonGroup,
  Avatar,
  CircularProgress
} from "@material-ui/core";
import clsx from "clsx";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";


import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import { useDispatch, useSelector } from "react-redux";
import { TablePaginationActions } from "../../utils/TablePaginationAction";
import Search from "../../utils/Search";
import { getInspectionsByUser } from "../../actions/awpAction";
import CreateInspectionModal from "../Modals/AWP/CreateInspectionModal";
import Feedback from "../../utils/Feedback";
import EditInspectionModal from "../Modals/AWP/EditInspectionModal";
import DeleteInspectionModal from "../Modals/AWP/DeleteInspectionModal";
import ViewInspectionModal from "../Modals/AWP/ViewInspectionModal";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),

    // width: "100%",
    overflow: "hidden",
  },
  title: {
    color: lighten(theme.palette.common.black, 0.75),
    display: "block",
    fontSize: 15,
    fontWeight: 900,
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginBottom: 30,
  },
  btn2: {
    marginBottom: 30,
    marginLeft: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 350,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.8rem",

  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#138D75",
    backgroundColor: "#138D75",
    color: "#fff",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  info: {
    padding: theme.spacing(4),
    marginBottom: 30,
  },
  typo: {
    lineHeight: 1,
    fontWeight: 900,
  },
  avatar: {
    margin: "auto",
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: 30,
  },
  link: {
    color: theme.palette.type === "light" ? "#333" : "#fff",
  },
}));

const AWPInspection = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");
  const [openCreateInspection, setOpenCreateInspection] = useState(false);
  const [openEditInspection, setOpenEditInspection] = useState(false);
  const [openViewInspection, setOpenViewInspection] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [getId, setGetId] = useState("");

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { loading, error, awpsByUser } = useSelector((state) => state.awp);
//   const {profile} = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getInspectionsByUser());
  }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  const handleOpenCreateInspection = () => {
    setOpenCreateInspection(true);
  };

  const handleCloseCreateInspection = () => {
    setOpenCreateInspection(false);
  };

  const handleOpenEditInspection = (id) => {
    setGetId(id);
    setOpenEditInspection(true);
  };

  const handleCloseEditInspection = () => {
    setOpenEditInspection(false);
  };

  const handleOpenViewInspection = (id) => {
    setGetId(id);
    setOpenViewInspection(true);
  };

  const handleCloseViewInspection = () => {
    setOpenViewInspection(false);
  };

  const handleOpenDelete = (id) => {
    setGetId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  let filteredRows = () =>
    awpsByUser === undefined
      ? []
      : awpsByUser.filter((row) => {
          if (search !== "") {
            return (
              row.unitNumber?.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.inspector?.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.date?.split("T")[0].toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.status?.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.location?.displayname.toLowerCase().indexOf(search.toLowerCase()) !== -1

            );
          } else {
            return row;
          }
        });


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, Array(50) - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <CreateInspectionModal {...{ openCreateInspection, handleCloseCreateInspection }} />
      <EditInspectionModal {...{ openEditInspection, handleCloseEditInspection, id: getId }} />
      <ViewInspectionModal {...{ openViewInspection, handleCloseViewInspection, id: getId }} />
      <DeleteInspectionModal {...{ openDelete, handleCloseDelete, id: getId }} />

      {feed.success === false && (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Typography className={classes.title} variant="overline">
        Aerial Work Platform Inspection
      </Typography>
      <Grid container alignItems="center" spacing={3}>
        <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleOpenCreateInspection}
              className={classes.btn}
            >
              Create Inspection
            </Button>
        </Grid>
        <Grid item>
          <Search
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>
     
      <Paper>
        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Unit Number</TableCell>
                <TableCell>Operator</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                        {row.unitNumber}
                    </TableCell>

                    <TableCell component="th" scope="row">
                            <Chip
                                avatar={
                                <Avatar
                                    alt={row.createdBy.photo}
                                    src={row.createdBy.photo}
                                />
                                }
                                label={row.inspector}
                                className={classes.chip}
                            />

                    </TableCell>
                    
                    <TableCell component="th" scope="row">
                        {row.date?.split("T")[0]}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.location?.displayname}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Chip
                            label={row.status}
                            variant="outlined"
                            className={row.status === "Draft" ? clsx(classes.chip) : clsx(classes.chip, classes.statusDarkGreen)}
                        />

                    </TableCell>
                      <TableCell>
                        <ButtonGroup variant="contained">
                          <Button
                            color="primary"
                            {...(
                                row.status === "Draft" ? { onClick: () => handleOpenEditInspection(row._id) } : 
                                row.status === "Submitted" || row.status === "Serviced" ? { onClick: () => handleOpenViewInspection(row._id) } : null
                            )}
                                
                          >
                            {row.status === "Draft" ? <EditIcon/> : <VisibilityIcon/>}
                          </Button>
                          <Button
                            disabled = {row.status === "Submitted" || row.status === "Serviced" ? true : false}
                            color="secondary"
                            onClick={() => handleOpenDelete(row._id)}
                          >
                            <DeleteIcon />
                          </Button>
                        </ButtonGroup>
                      </TableCell>

                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{
                      fontWeight: 900,
                      color: "#616161",
                      textAlign: "center",
                    }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default AWPInspection;
