import React from "react";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  SwipeableDrawer,
  Hidden,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  useTheme,
  Badge,
  Avatar,
  withStyles,
  Tooltip,
  Grid,
  Menu,
  MenuItem,
  Backdrop,
  useMediaQuery,
  ListItemAvatar,
  Icon,
  alpha,
  Collapse,
  darken
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Brightness4Icon from "@material-ui/icons/Brightness4";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from "@material-ui/icons/Settings";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import DecriptionIcon from "@material-ui/icons/Description";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CommuteIcon from '@material-ui/icons/Commute';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import StorageIcon from "@material-ui/icons/Storage"
import StoreIcon from '@material-ui/icons/Store';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import TramIcon from '@material-ui/icons/Tram';
import RvHookupIcon from '@material-ui/icons/RvHookup';
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import BusinessIcon from '@material-ui/icons/Business';
import GolfCourseIcon from '@material-ui/icons/GolfCourse';
import LocalMoviesIcon from '@material-ui/icons/LocalMovies';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import KingBedIcon from '@material-ui/icons/KingBed'
import ReportIcon from '@material-ui/icons/Report';
import EventIcon from '@material-ui/icons/Event';
import ViewStreamIcon from '@material-ui/icons/ViewStream';

import { useDispatch, useSelector } from "react-redux";
import { getProfile, logout } from "../../actions/usersAction";
import { CHANGE_WIDTH } from "../../types/userTypes";

import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import process from "process";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: 270,
      flexShrink: 0,
    },

  },

  drawerSmall: {
    [theme.breakpoints.up("md")]: {
      width: 60,
      flexShrink: 0,
    },
  },

  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 270px)`,
      marginLeft: 270,
    },
  },

  appBarSmall: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 60px)`,
      marginLeft: 60,
    },
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    minHeight: 64,
  },

  menuHeader: {
    textAlign: "center",
    width: "100%",
  },
  menuTitle: {
    fontFamily: "Carter One",
    fontSize: 13,
    fontWeight: 900,
    // color: "#bdbdbd",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuTitle2: {
    fontSize: 13,
    fontWeight: 900,
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]: {
      display: "block",
      paddingLeft: 10,
    },
  },
  drawerPaper: {
    backgroundImage: "url(/img/hills-illustration.jpg)",
    backgroundSize: "cover",
    whiteSpace: "nowrap",
    backgroundPosition: "center",
    backgroundBlendMode: "soft-light",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "dark"? darken("#424242", 0.05) : darken("#182653", 0.05),
    width: 270,
    background: theme.palette.type === "light" ? "#15264f" : "#212121",
    // background: "#282c4f",
    color: "#fefefe",
    fontWeight: 700,
    height: "100%",
    overflow: "hidden",
    "& svg": {
      fill: "#fff",
    },
  },

  drawerPaperSmall: {
    backgroundImage: "url(/img/hills-illustration.jpg)",
    backgroundSize: "cover",
    whiteSpace: "nowrap",
    backgroundPosition: "center",
    backgroundBlendMode: "soft-light",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "dark"? darken("#424242", 0.05) : darken("#182653", 0.05),
    width: 60,
    background: theme.palette.type === "light" ? "#15264f" : "#212121",
    // background: "#282c4f",
    color: "#fefefe",
    fontWeight: 700,
    height: "100%",
    overflow: "hidden",
    "& svg": {
      fill: "#fff",
    },
  },

  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    // minHeight: "100vh",
    width: "100%",
    // overflow: "scroll",
  },
  footer: {
    // flexGrow: 1,
    // marginTop: "5%",
    padding: theme.spacing(3, 2),
    // marginTop: "auto",
    // backgroundColor:
    //   theme.palette.type === "light"
    //     ? theme.palette.grey[200]
    //     : theme.palette.grey[800],
    [theme.breakpoints.down("sm")]: {
      fontSize: 8,
      marginTop: "10%",
    },
  },
  footerText: {
    fontSize: 13,
    fontFamily: "Rubik",
  },
  wrapper: {
    flexGrow: 1,
    // width: "100%",

    // height: "100%",
    minHeight: "100vh",

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "scroll",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    overflow: "hidden",
    // flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  name: {
    color: "#bdbdbd",
    fontSize: 13,
    fontWeight: 900,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  avatarHeader: {
    textAlign: "center",
    display: "grid",
    justifyItems: "center",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  small: {
    color: theme.palette.type === "light" ? "#bdbdbd" : "#fff",
    fontSize: 14,
    fontFamily: "Rubik",
  },
  name2: {
    color: theme.palette.type === "light" ? "#757575" : "#fff",
    fontFamily: "Rubik",
  },
  dropdown: {
    fontFamily: "Rubik",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  avatarMd: {
    marginRight: 5,
    padding: 1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  avatarSm: {
    marginRight: 5,
    padding: 1,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  activeBorder: {
    borderRight: "3px solid #e33371",
  },
  activeInnerBorder: {
    borderRight: `3px solid ${alpha("#009688", 0.6)}`,
  },
  active: {
    color: alpha("#e33371", 0.8),
  },
  activeInner: {
    color: alpha("#009688", 0.8),
  },
  hoverBorder: {
    "&:hover": {
      color: "#e33371",
      borderRight: "3px solid #e33371",
      transition: theme.transitions.create("border", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
      animation: "$fadeIn 400ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0.5,
      color: alpha("#e33371", 0.8),
      borderRight: `3px solid ${alpha("#e33371", 0.8)}`,
    },
    to: {
      opacity: 1,
      color: "#e33371",
      borderRight: "3px solid #e33371",
    },
  },
  hoverInnerBorder: {
    "&:hover": {
      borderRight: "3px solid #009688",
      transition: theme.transitions.create("border", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  scrollable: {
    overflowY: "auto",
    height: "100vh",
    "&::-scrollbar-track": {
      borderWidth: "1px",
      borderColor: "#fff",
      borderStyle: "solid",
      backgroundColor: "#fff",
      padding: "2px",
    },
    "&::-scrollbar": {
      width: "10px",
      display: "none",
      backgroundColor: "#424858",
    },
    "&::-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "rgba(0,0,0,0.2)",
      borderStyle: "solid",
      borderColor: "#f7f7f7",
      borderWidth: "1px",
    },
    "&::-moz-scrollbar-track": {
      borderWidth: "1px",
      borderColor: "#fff",
      borderStyle: "solid",
      backgroundColor: "#fff",
      padding: "2px",
    },
    "&::-moz-scrollbar": {
      width: "10px",
      display: "none",
      backgroundColor: "#424858",
    },
    "&::-moz-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "rgba(0,0,0,0.2)",
      borderStyle: "solid",
      borderColor: "#f7f7f7",
      borderWidth: "1px",
    },
    "&::-webkit-scrollbar-track": {
      borderWidth: "1px",
      borderColor: "#fff",
      borderStyle: "solid",
      backgroundColor: "#fff",
      padding: "2px",
    },
    "&::-webkit-scrollbar": {
      width: "10px",
      display: "none",
      backgroundColor: "#424858",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "rgba(0,0,0,0.2)",
      borderStyle: "solid",
      borderColor: "#f7f7f7",
      borderWidth: "1px",
    },
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const Layout = ({ children, toggleDarkTheme, win }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const history = useHistory();
  const { profile, loading, drawerWidth } = useSelector((state) => state.users);

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  React.useLayoutEffect(() => {
    const token = localStorage.getItem("token:data");
    if (token || token !== undefined) {
      dispatch(getProfile());
    }
  }, [dispatch]);


  const handleDrawerToggle = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMobileOpen(open);
  };

  const route = (path) => {
    history.push(path);
    if (matches) {
      setMobileOpen(false);
    }
  };

  const matchRoute = (path) => {
    return location.pathname === path;
  };

  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-search-account-menu";

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuRoute = (path) => {
    history.push(path);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    history.push("/");

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleDropdown = (path) => {
    setOpen((prev) => ({...prev, [path]: !prev[path]}))
  }

  let isAdminGuard = profile
    ? (profile &&
        profile.role && profile.role.map(role => role.name).includes("admin"))
        // profile.role && profile.role.name === "admin")
     : false;

  let isToolboxAdmin = profile
    ? (profile &&
        profile.role && profile.role.map(role => role.name).includes("toolbox_admin"))
        // profile.role && profile.role.name === "toolbox_admin")
      : false;
  let isToolboxViewer = profile
    ? (profile &&
        profile.role && profile.role.map(role => role.name).includes("toolbox_viewer"))
        // profile.role && profile.role.name === "toolbox_admin")
      : false;

  let isToolboxSupervisor = profile
    ? (profile &&
        profile.role && profile.role.map(role => role.name).includes("toolbox_supervisor"))
        // profile.role && profile.role.name === "toolbox_supervisor")
      : false;
  
  let isVehicleOperator = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("vehicle_operator"))
      // profile.role && profile.role.name === "vehicle_operator")
    : false;

  let isVehicleAdmin = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("vehicle_admin"))
      // profile.role && profile.role.name === "vehicle_admin")
    : false;

  let isVehicleManager = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("vehicle_manager"))
      // profile.role && profile.role.name === "vehicle_manager")
    : false;
    
  let isVehicleSupervisor = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("vehicle_supervisor"))
      // profile.role && profile.role.name === "vehicle_supervisor")
    : false;

  let isAWPOperator = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("awp_operator"))
    : false;

  let isAWPAdmin = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("awp_admin"))
    : false;
  
  let isForkliftOperator = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("forklift_operator"))
    : false;

  let isForkliftAdmin = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("forklift_admin"))
    : false;
    
  let isHarnessOperator = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("harness_operator"))
    : false;

  let isHarnessAdmin = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("harness_admin"))
    : false;

  let isCraneOperator = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("crane_operator"))
    : false;

  let isCraneAdmin = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("crane_admin"))
    : false;

  let isScaffoldOperator = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("scaffold_operator"))
    : false;

  let isScaffoldAdmin = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("scaffold_admin"))
    : false;

  let isSwagerOperator = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("swager_operator"))
    : false;

  let isSwagerAdmin = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("swager_admin"))
    : false;

  let isTestbedOperator = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("testbed_operator"))
    : false;

  let isTestbedAdmin = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("testbed_admin"))
    : false;

  let isInventoryAdmin = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("inventory_admin"))
    : false;

  let isInventoryUser = profile
  ? (profile &&
      profile.role && profile.role.map(role => role.name).includes("inventory_user"))
    : false;

    let isIncidentAdmin = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("incident_admin"))
        : false;

  let isIncidentUser = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("incident_user"))
      : false;

  const toggleDrawerWidth = () => {
    dispatch({
      type: CHANGE_WIDTH
    })
  };


  const renderMenu = (
    <Menu
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.menu}
      PaperProps={{
        style: {
          [theme.breakpoints.up("md")]: {
            width: 300,
          }
        },
      }}
    >
      <div className={classes.avatarHeader}>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot"
        >
          <Avatar src={(profile && profile.photo) || "/img/user.svg"} />
        </StyledBadge>
        <Typography variant="subtitle2" className={classes.name2}>
          {profile && profile.displayname}
        </Typography>
        <Typography variant="body2" className={classes.name2}>
          {profile && profile.title}
        </Typography>

      </div>
      <Divider />
      <MenuItem onClick={() => handleMenuRoute("/account")} className={classes.dropdown}>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>Account</Grid>
          <Grid item>
            <AccountBoxIcon style={{ marginTop: 10}} />
          </Grid>
        </Grid>
      </MenuItem>
      <MenuItem onClick={handleLogout} className={classes.dropdown}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>Logout</Grid>
          <Grid item>
            <ExitToAppIcon style={{ marginTop: 10}} />
          </Grid>
        </Grid>

     </MenuItem>
    </Menu>
  );

  const drawer = (
    <div>
      <div className={clsx(classes.toolbar, classes.menuHeader)}>
        <ListItem>
          <ListItemAvatar style={{ padding: 1 }}>
            <Avatar
              src="/img/dfbgear-darkbg.png"
              alt="logo"
              className={classes.avatarSm}
            />
          </ListItemAvatar>

          <ListItemText
            primary={
              <Typography className={classes.menuTitle2}>DF Barnes App</Typography>
            }
          />
        </ListItem>
        <IconButton onClick={toggleDrawerWidth}>
          {drawerWidth === 270 ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>

      </div>
      <Divider />
      <div className={classes.scrollable}>
      <List>
        <ListItem
          button
          onClick={() => route("/dashboard")}
          className={clsx(
            matchRoute("/dashboard") && classes.activeBorder,
            classes.hoverBorder
          )}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>

        {(isAdminGuard || isToolboxAdmin || isToolboxSupervisor || isToolboxViewer) && (
        <ListItem
          button
          onClick={() => handleDropdown("toolbox")}
          className={classes.hoverBorder}
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary={"Toolbox"} />
          {open.toolbox ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        )}

        <Collapse in={open.toolbox} timeout="auto" unmountOnExit>
        {(isAdminGuard || isToolboxAdmin || isToolboxSupervisor || isToolboxViewer) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/document/toolbox")}
              className={clsx(
                matchRoute("/document/toolbox") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <DecriptionIcon/>
              </ListItemIcon>
              <ListItemText primary={"Toolbox Talks"} />
            </ListItem>
          </List>
          )}

          {(isAdminGuard || isToolboxAdmin || isToolboxViewer) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/toolbox/sign/all")}
              className={clsx(
                matchRoute("/toolbox/sign/all") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"All Sign On/Off"} />
            </ListItem>
          </List>)}
          
          {(isAdminGuard || isToolboxAdmin || isToolboxSupervisor) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/toolbox/sign")}
              className={clsx(
                matchRoute("/toolbox/sign") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"Sign On/Off"} />
            </ListItem>
          </List>
          )}

          {(isAdminGuard || isToolboxAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/toolbox/crew")}
              className={clsx(
                matchRoute("/crew") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary={"Crews"} />
            </ListItem>
          </List>)}
        </Collapse>

        {(isAdminGuard || isVehicleOperator || isVehicleAdmin || isVehicleManager || isVehicleSupervisor) && (
        <ListItem
          button
          onClick={() => handleDropdown("vehicle")}
          className={classes.hoverBorder}
        >
          <ListItemIcon>
            <LocalShippingIcon/>
          </ListItemIcon>
          <ListItemText primary={"Vehicle"} />
          {open.vehicle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        )}

        <Collapse in={open.vehicle} timeout="auto" unmountOnExit>

          {(isAdminGuard || isVehicleAdmin || isVehicleManager || isVehicleSupervisor) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/vehicle/inspection/all")}
              className={clsx(
                matchRoute("/vehicle/inspection/all") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"All Vehicle Inspections"} />
            </ListItem>
          </List>)}
          
          {(isAdminGuard || isVehicleAdmin || isVehicleOperator || isVehicleManager || isVehicleSupervisor) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/vehicle/inspection")}
              className={clsx(
                matchRoute("/vehicle/inspection") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"Vehicle Inspection"} />
            </ListItem>
          </List>
          )}

          {(isAdminGuard || isVehicleAdmin || isVehicleManager || isVehicleSupervisor) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/vehicle/fleet")}
              className={clsx(
                matchRoute("/vehicles") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <CommuteIcon />
              </ListItemIcon>
              <ListItemText primary={"Fleet"} />
            </ListItem>
          </List>)}
        </Collapse>

        {(isAdminGuard || isAWPOperator || isAWPAdmin) && (
        <ListItem
          button
          onClick={() => handleDropdown("awp")}
          className={classes.hoverBorder}
        >
          <ListItemIcon>
            <TramIcon/>
          </ListItemIcon>
          <ListItemText primary={"Aerial Work Platform"} />
          {open.awp ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        )}

        <Collapse in={open.awp} timeout="auto" unmountOnExit>

          {(isAdminGuard || isAWPAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/awp/inspection/all")}
              className={clsx(
                matchRoute("/awp/inspection/all") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"All AWP Inspections"} />
            </ListItem>
          </List>)}
          
          {(isAdminGuard || isAWPAdmin || isAWPOperator) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/awp/inspection")}
              className={clsx(
                matchRoute("/awp/inspection") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"AWP Inspection"} />
            </ListItem>
          </List>
          )}

        </Collapse>

        {(isAdminGuard || isForkliftOperator || isForkliftAdmin) && (
        <ListItem
          button
          onClick={() => handleDropdown("forklift")}
          className={classes.hoverBorder}
        >
          <ListItemIcon>
            <RvHookupIcon/>
          </ListItemIcon>
          <ListItemText primary={"Forklift"} />
          {open.forklift ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        )}

        <Collapse in={open.forklift} timeout="auto" unmountOnExit>

          {(isAdminGuard || isForkliftAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/forklift/inspection/all")}
              className={clsx(
                matchRoute("/forklift/inspection/all") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"All Forklift Inspections"} />
            </ListItem>
          </List>)}
          
          {(isAdminGuard || isForkliftAdmin || isForkliftOperator) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/forklift/inspection")}
              className={clsx(
                matchRoute("/forklift/inspection") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"Forklift Inspection"} />
            </ListItem>
          </List>
          )}  

        </Collapse>

        {(isAdminGuard || isHarnessOperator || isHarnessAdmin) && (
        <ListItem
          button
          onClick={() => handleDropdown("harness")}
          className={classes.hoverBorder}
        >
          <ListItemIcon>
            <DeviceHubIcon/>
          </ListItemIcon>
          <ListItemText primary={"Harness"} />
          {open.harness ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        )}

        <Collapse in={open.harness} timeout="auto" unmountOnExit>

          {(isAdminGuard || isHarnessAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/harness/inspection/all")}
              className={clsx(
                matchRoute("/harness/inspection/all") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"All Harness Inspections"} />
            </ListItem>
          </List>)}
          
          {(isAdminGuard || isHarnessAdmin || isHarnessOperator) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/harness/inspection")}
              className={clsx(
                matchRoute("/harness/inspection") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"Harness Inspection"} />
            </ListItem>
          </List>
          )}  
          
        </Collapse>

        {(isAdminGuard || isCraneOperator || isCraneAdmin) && (
        <ListItem
          button
          onClick={() => handleDropdown("crane")}
          className={classes.hoverBorder}
        >
          <ListItemIcon>
            <GolfCourseIcon/>
          </ListItemIcon>
          <ListItemText primary={"Overhead Crane"} />
          {open.crane ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        )}

        <Collapse in={open.crane} timeout="auto" unmountOnExit>

          {(isAdminGuard || isCraneAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/crane/inspection/all")}
              className={clsx(
                matchRoute("/crane/inspection/all") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"All Crane Inspections"} />
            </ListItem>
          </List>)}
          
          {(isAdminGuard || isCraneAdmin || isCraneOperator) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/crane/inspection")}
              className={clsx(
                matchRoute("/crane/inspection") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"Crane Inspection"} />
            </ListItem>
          </List>
          )}  

        </Collapse>

        {(isAdminGuard || isScaffoldOperator || isScaffoldAdmin) && (
        <ListItem
          button
          onClick={() => handleDropdown("scaffold")}
          className={classes.hoverBorder}
        >
          <ListItemIcon>
            <LocalMoviesIcon/>
          </ListItemIcon>
          <ListItemText primary={"Scaffold"} />
          {open.scaffold ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        )}

        <Collapse in={open.scaffold} timeout="auto" unmountOnExit>

          {(isAdminGuard || isScaffoldAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/scaffold/inspection/all")}
              className={clsx(
                matchRoute("/scaffold/inspection/all") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"All Scaffold Inspections"} />
            </ListItem>
          </List>)}
          
          {(isAdminGuard || isScaffoldAdmin || isScaffoldOperator) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/scaffold/inspection")}
              className={clsx(
                matchRoute("/scaffold/inspection") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"Scaffold Inspection"} />
            </ListItem>
          </List>
          )}  

        </Collapse>

        {(isAdminGuard || isSwagerOperator || isSwagerAdmin) && (
        <ListItem
          button
          onClick={() => handleDropdown("swager")}
          className={classes.hoverBorder}
        >
          <ListItemIcon>
            <HourglassFullIcon/>
          </ListItemIcon>
          <ListItemText primary={"Swager Press"} />
          {open.swager ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        )}

        <Collapse in={open.swager} timeout="auto" unmountOnExit>

          {(isAdminGuard || isSwagerAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/swager/inspection/all")}
              className={clsx(
                matchRoute("/swager/inspection/all") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"All Swager Inspections"} />
            </ListItem>
          </List>)}
          
          {(isAdminGuard || isSwagerAdmin || isSwagerOperator) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/swager/inspection")}
              className={clsx(
                matchRoute("/swager/inspection") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"Swager Inspection"} />
            </ListItem>
          </List>
          )}  

        </Collapse>

        {(isAdminGuard || isTestbedOperator || isTestbedAdmin) && (
        <ListItem
          button
          onClick={() => handleDropdown("testbed")}
          className={classes.hoverBorder}
        >
          <ListItemIcon>
            <KingBedIcon/>
          </ListItemIcon>
          <ListItemText primary={"Test Bed"} />
          {open.testbed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        )}

        <Collapse in={open.testbed} timeout="auto" unmountOnExit>

          {(isAdminGuard || isTestbedAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/testbed/inspection/all")}
              className={clsx(
                matchRoute("/testbed/inspection/all") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"All Test Bed Inspections"} />
            </ListItem>
          </List>)}
          
          {(isAdminGuard || isTestbedAdmin || isTestbedOperator) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/testbed/inspection")}
              className={clsx(
                matchRoute("/testbed/inspection") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"Test Bed Inspection"} />
            </ListItem>
          </List>
          )}  

        </Collapse>

        {(isAdminGuard || isInventoryAdmin || isInventoryUser) && (
        <ListItem
          button
          onClick={() => handleDropdown("inventory")}
          className={classes.hoverBorder}
        >
          <ListItemIcon>
            <StorageIcon/>
          </ListItemIcon>
          <ListItemText primary={"Inventory"} />
          {open.inventory ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        )}

        <Collapse in={open.inventory} timeout="auto" unmountOnExit>
        {(isAdminGuard  || isInventoryAdmin) && (
        <List disablePadding>
            <ListItem
              button
              onClick={() => route("/inventory/move/all")}
              className={clsx(
                matchRoute("/inventory/move/all") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <SyncAltIcon/>
              </ListItemIcon>
              <ListItemText primary={"All Item Moves"} />
            </ListItem>
          </List>
        )}
        {(isAdminGuard  || isInventoryAdmin || isInventoryUser) && (
        <List disablePadding>
            <ListItem
              button
              onClick={() => route("/inventory/move")}
              className={clsx(
                matchRoute("/inventory/move") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <SyncAltIcon/>
              </ListItemIcon>
              <ListItemText primary={"Item Move"} />
            </ListItem>
          </List>
        )}

        {(isAdminGuard || isInventoryAdmin) && (
        <List disablePadding>
          <ListItem
            button
            onClick={() => route("/inventory/item")}
            className={clsx(
              matchRoute("/inventory/item") && classes.activeBorder,
              classes.hoverBorder)}
          >
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary={"Items"} />
          </ListItem>
        </List>
        )}

        {(isAdminGuard || isInventoryAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/inventory/category")}
              className={clsx(
                matchRoute("/inventory/category") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"Item Categories"} />
            </ListItem>
          </List>
        )}


        {(isAdminGuard || isInventoryAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/inventory/supplier")}
              className={clsx(
                matchRoute("/inventory/supplier") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary={"Suppliers"} />
            </ListItem>
          </List>
        )}

        {(isAdminGuard || isInventoryAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/inventory/store")}
              className={clsx(
                matchRoute("/inventory/store") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary={"Stores"} />
            </ListItem>
          </List>
        )}

        {(isAdminGuard || isInventoryAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/inventory/uom")}
              className={clsx(
                matchRoute("/inventory/uom") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <SquareFootIcon />
              </ListItemIcon>
              <ListItemText primary={"Units of Measure"} />
            </ListItem>
          </List>
        )}
        </Collapse>

        {(isAdminGuard || isIncidentUser || isIncidentAdmin) && (
        <ListItem
          button
          onClick={() => handleDropdown("incident")}
          className={classes.hoverBorder}
        >
          <ListItemIcon>
            <ReportIcon/>
          </ListItemIcon>
          <ListItemText primary={"Incident Report"} />
          {open.incident ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        )}

        <Collapse in={open.incident} timeout="auto" unmountOnExit>

        {(isAdminGuard || isIncidentAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/incident/report/all")}
              className={clsx(
                matchRoute("/incident/report/all") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"All Incident Reports"} />
            </ListItem>
          </List>
        )}
          
        {(isAdminGuard || isIncidentAdmin || isIncidentUser) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/incident/report")}
              className={clsx(
                matchRoute("/incident/report") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"Incident Report"} />
            </ListItem>
          </List>
        )}

        {(isAdminGuard || isIncidentAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/incident/contact")}
              className={clsx(
                matchRoute("/incident/contact") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ViewStreamIcon />
              </ListItemIcon>
              <ListItemText primary={"Contact Types"} />
            </ListItem>
          </List>
        )}    

        {(isAdminGuard || isIncidentAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/incident/cause")}
              className={clsx(
                matchRoute("/incident/cause") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"Direct Causes"} />
            </ListItem>
          </List>
        )}

        {(isAdminGuard || isIncidentAdmin) && (
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/incident/event")}
              className={clsx(
                matchRoute("/incident/event") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary={"Event Types"} />
            </ListItem>
          </List>
        )}  

        </Collapse>


        {isAdminGuard && (
          <>
          <ListItem
            button
            onClick={() => handleDropdown("settings")}
            className={classes.hoverBorder}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={"Settings"} />
            {open.settings ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>

          <Collapse in={open.settings} timeout="auto" unmountOnExit>
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/users")}
              className={clsx(
                matchRoute("/users") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <Icon
                  className="fas fa-users"
                  style={{ color: "#fff" }}
                />
              </ListItemIcon>
              <ListItemText primary={"Users"} />
            </ListItem>
          </List>
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/roles")}
              className={clsx(
                matchRoute("/roles") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <Icon
                  className="fas fa-user-tag"
                  style={{ color: "#fff" }}
                />
              </ListItemIcon>
              <ListItemText primary={"Roles"} />
            </ListItem>
          </List>
          <List disablePadding>
            <ListItem
              button
              onClick={() => route("/locations")}
              className={clsx(
                matchRoute("/locations") && classes.activeBorder,
                classes.hoverBorder)}
            >
              <ListItemIcon>
                <LocationOnIcon />
              </ListItemIcon>
              <ListItemText primary={"Locations"} />
            </ListItem>
          </List>
          </Collapse>
          </>
        )}
        
      </List>
      </div>
      {/* <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  // console.log(profile);
  const container = win !== undefined ? () => win().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={drawerWidth === 270 ? classes.appBar: classes.appBarSmall } color="default">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle(true)}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {/* <>
            <Avatar
              src="/img/dfbgear-lightbg.png"
              alt="logo"
              className={classes.avatarMd}
              component={Link}
              to="/dashboard"
            />
            <Typography className={classes.menuTitle}>DF Barnes App</Typography>
          </> */}
          <div className={classes.grow} />

          <Box className={classes.flex}>
            <Tooltip title="View Messages" arrow>
              <IconButton>
                <MailIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="toggle light/dark theme" arrow>
              <IconButton onClick={toggleDarkTheme}>
                {theme.palette.type === "light" ? (
                  <Brightness4Icon />
                ) : (
                  <BrightnessHighIcon style={{ color: "#ffc107" }} />
                )}
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" className={classes.divider} />
            <Typography component="small" className={classes.name}>
              {profile && profile.displayname}
            </Typography>
            <IconButton edge="end" onClick={handleMenuOpen}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar src={(profile && profile.photo) || "/img/user.svg"} />
              </StyledBadge>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <nav className={drawerWidth === 250 ? classes.drawer: classes.drawerSmall} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smDown implementation="css">
          <SwipeableDrawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onOpen={handleDrawerToggle(true)}
            onClose={handleDrawerToggle(false)}
            classes={{
              paper: drawerWidth === 250 ? classes.drawerPaper : classes.drawerPaperSmall,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            color="primary"
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: drawerWidth === 250 ? classes.drawerPaper : classes.drawerPaperSmall,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.wrapper}>
          <div>{children}</div>
          <Box pt={4} className={classes.footer}>
            <Typography
              // variant="body2"
              // color="textSecondary"
              align="center"
              className={classes.footerText}
            >
              Copyright &copy;{" "}
              <a
                style={{ color: "#4d72de" }}
                href="https://dfbarnes.com/"
                target="__blank"
              >
                DF Barnes,
              </a>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </div>
      </main>
      {renderMenu}

      <Backdrop className={classes.backdrop} open={loading ? loading : false}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Layout;