import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ButtonGroup,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from '@material-ui/icons/Lock';
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import { useDispatch, useSelector } from "react-redux";
import {getUsers, getUser, updateUser, sendPasswordLink, activateUser, deactivateUser, getRoles, getLocations} from "../../../actions/usersAction";

import Feedback from "../../../utils/Feedback";
import SetPasswordModal from "./SetPasswordModal";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  btnGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },


  dialog: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",

    },
  },

  formControl: {
    minWidth: 420,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    },
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
    color: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
    color: "#ff9800",
  },
  statusRed: {
    borderColor: "#ef5350",
  },

}));

const INIT_STATE = {
  firstname: "",
  lastname: "",
  email: "",
  title: "",
  role: [],
  location: undefined,
  phone: "",
};

const EditUserModal = ({ openEdit, handleCloseEdit, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [openPassword, setOpenPassword] = useState(false);
  const [getId, setGetId] = useState("");
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { user, error, loading, roles, locations } = useSelector((state) => state.users);

  useEffect(() => {
    getRoles();
    getLocations();
  }, [dispatch]);


  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    dispatch(getUser(id));
  }, [dispatch, id]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      firstname: user && user.firstname,
      lastname: user && user.lastname,
      email: user && user.email,
      title: user && user.title,
      role: user && user.role,
      location: user && user.location?._id,
      phone: user && user.phone,
    }));
  }, [user]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOpenPassword = (id) => {
    setGetId(id);
    setOpenPassword(true);
  };

  const handleClosePassword = () => {
    setOpenPassword(false);
  };


  const handleSendLink = (e) => {
    e.preventDefault();
    let data = { email: state.email, path:"setPassword"};
    dispatch(sendPasswordLink(data));

    setTimeout(() => {
      handleCloseEdit();
      dispatch(getUsers());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Password Link Sent Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleActivate = (e) => {
    e.preventDefault();
    dispatch(activateUser(id));
    setTimeout(() => {
      handleCloseEdit();
      dispatch(getUsers());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "User Activated Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleDeactivate = (e) => {
    e.preventDefault();
    dispatch(deactivateUser(id));
    setTimeout(() => {
      handleCloseEdit();
      dispatch(getUsers());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "User Deactivated Successfully.",
        success: true,
      }));
    }, 1500);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      firstname: state.firstname,
      lastname: state.lastname,
      email: state.email,
      title: state.title,
      role: state.role,
      location: state.location,
      phone: state.phone,
      id,
    };
    dispatch(updateUser(data));
    setTimeout(() => {
      handleCloseEdit();
      dispatch(getUsers());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "User Updated Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.firstname || !state.lastname || !state.email || !state.title || !state.role

  return (
    <div>
      <SetPasswordModal {...{ openPassword, handleClosePassword, id: getId }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth maxWidth="md" className={classes.dialog}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit User Data
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseEdit}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        {/* <Button
          size="large"
          className={classes.btnLink}
          endIcon={<SendIcon />}
          onClick={handleSendLink}
        >
          Send Password Link
        </Button>
        <Button
          size="large"
          endIcon={<SendIcon />}
        >
          Deactivate User
        </Button> */}
        <ButtonGroup className={classes.btnGroup} variant="outlined">
        <Button
            size="small"
            className={clsx(classes.btnLink, classes.statusOrange)}
            endIcon={<LockIcon />}
            onClick={() => handleOpenPassword(id)}
          >
            Set User Password
          </Button>
          <Button
            size="small"
            className={classes.btnLink}
            color="primary"
            endIcon={<SendIcon />}
            onClick={handleSendLink}
          >
            Send Password Link
          </Button>
          {user && user.active? (
            <Button
            size="small"
            className={classes.btnLink}
            onClick={handleDeactivate}
            color="secondary"
            endIcon={<PersonAddDisabledIcon />}
            >
              Deactivate User
            </Button>) :(
              <Button
              size="small"
              className={clsx(classes.btnLink, classes.statusDarkGreen)}
              onClick={handleActivate}
              endIcon={<PersonIcon />}
            >
              Activate User
            </Button>)
            }
          
        </ButtonGroup>
        <br/>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                  name="firstname"
                  fullWidth
                  variant="outlined"
                  label="First Name"
                  onChange={handleChange}
                  value={state.firstname || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="lastname"
                  fullWidth
                  variant="outlined"
                  label="Last Name"
                  onChange={handleChange}
                  value={state.lastname || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  fullWidth
                  variant="outlined"
                  label="Email"
                  onChange={handleChange}
                  value={state.email || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="title"
                  fullWidth
                  variant="outlined"
                  label="Title"
                  onChange={handleChange}
                  value={state.title || ""}
                  required
                />
              </Grid>
              <Grid item xs={12}  sm={6}>
                <TextField
                  name="phone"
                  fullWidth
                  variant="outlined"
                  label="Phone"
                  onChange={handleChange}
                  value={state.phone || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="location">
                 Primary Location
                </InputLabel>
                <Select
                  labelId="location"
                  name="location"
                  fullWidth
                  value={state.location || ""}
                  onChange={handleChange}
                  label="Location"
                >
                  <MenuItem value={null}>
                    <em>None</em>
                  </MenuItem>
                  {locations &&
                    locations.map((location) => (
                      <MenuItem key={location._id} value={location._id}>
                        {location.displayname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Autocomplete 
                    multiple 
                    id="role"
                    value={state.role || []}    
                    fullWidth
                    options={roles} 
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.displayname} 
                    onChange={(e, value) => setState((prevState) => ({ ...prevState, role: value }))} 
                    renderInput={(params) => 
                    <TextField {...params} variant="outlined" label="Roles" />} 
                    />
                </FormControl>
              </Grid>
              
            </Grid>
            <Typography align='center'>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                endIcon={<UpdateIcon />}
                className={classes.btn}
                disabled={isValid() || loading}
              >
                {loading ? "Loading..." : "Update"}
              </Button>
            </Typography> 
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditUserModal;
