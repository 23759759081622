import {
    CREATE_CREW,
    CREATE_CREW_REQUEST,
    CREATE_CREW_FAIL,
    GET_CREW,
    GET_CREW_REQUEST,
    GET_CREW_FAIL,
    GET_CREW_BY_SUPERVISOR,
    GET_CREW_BY_SUPERVISOR_REQUEST,
    GET_CREW_BY_SUPERVISOR_FAIL,
    GET_CREWS,
    GET_CREWS_REQUEST,
    GET_CREWS_FAIL,
    UPDATE_CREW,
    UPDATE_CREW_REQUEST,
    UPDATE_CREW_FAIL,
    DELETE_CREW,
    DELETE_CREW_REQUEST,
    DELETE_CREW_FAIL,
    ARCHIVE_CREW,
    ARCHIVE_CREW_REQUEST,
    ARCHIVE_CREW_FAIL,

    CREATE_SIGNIN,
    CREATE_SIGNIN_REQUEST,
    CREATE_SIGNIN_FAIL,
    SUBMIT_SIGNIN,
    SUBMIT_SIGNIN_REQUEST,
    SUBMIT_SIGNIN_FAIL,
    UPDATE_SIGNIN,
    UPDATE_SIGNIN_REQUEST,
    UPDATE_SIGNIN_FAIL,
    CREATE_SIGNOUT,
    CREATE_SIGNOUT_REQUEST,
    CREATE_SIGNOUT_FAIL,
    UPDATE_SIGNOUT,
    UPDATE_SIGNOUT_REQUEST,
    UPDATE_SIGNOUT_FAIL,
    SUBMIT_SIGNOUT,
    SUBMIT_SIGNOUT_REQUEST,
    SUBMIT_SIGNOUT_FAIL,
    GET_SIGNS,
    GET_SIGNS_REQUEST,
    GET_SIGNS_FAIL,
    GET_SIGN,
    GET_SIGN_REQUEST,
    GET_SIGN_FAIL,
    GET_SIGNS_BY_USER,
    GET_SIGNS_BY_USER_REQUEST,
    GET_SIGNS_BY_USER_FAIL,
    GET_SIGNIN_BY_USER,
    GET_SIGNIN_BY_USER_REQUEST,
    GET_SIGNIN_BY_USER_FAIL,
    GET_SIGNOUT_BY_USER,
    GET_SIGNOUT_BY_USER_REQUEST,
    GET_SIGNOUT_BY_USER_FAIL,
    GET_SIGNS_BY_LOCATION,
    GET_SIGNS_BY_LOCATION_REQUEST,
    GET_SIGNS_BY_LOCATION_FAIL,
    DELETE_SIGN,
    DELETE_SIGN_REQUEST,
    DELETE_SIGN_FAIL,
    SET_SIGNIN_DRAFT,
    SET_SIGNIN_DRAFT_REQUEST,
    SET_SIGNIN_DRAFT_FAIL,
    SET_SIGNOUT_DRAFT,
    SET_SIGNOUT_DRAFT_REQUEST,
    SET_SIGNOUT_DRAFT_FAIL,
    DOWNLOAD_SIGNIN,
    DOWNLOAD_SIGNIN_REQUEST,
    DOWNLOAD_SIGNIN_FAIL,
    DOWNLOAD_SIGNOUT,
    DOWNLOAD_SIGNOUT_REQUEST,
    DOWNLOAD_SIGNOUT_FAIL

} 

from '../types/toolboxTypes';

const initState = {
    crew: null,
    crews: [],
    myCrews: [],
    sign: null,
    signs: [],
    signsByUser: [],
    signinByUser: [],
    signoutByUser: [],
    signsByLocation:[],
    error: null,
    loading: false,
  };

const toolboxReducer = (state = initState, action) => {
    switch (action.type) {

        case CREATE_CREW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_CREW:
            return {
                ...state,
                loading: false,
                crew: action.payload,
            };
        case CREATE_CREW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_CREW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CREW:
            return {
                ...state,
                loading: false,
                crew: action.payload.data,
            };
        case GET_CREW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_CREW_BY_SUPERVISOR_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CREW_BY_SUPERVISOR:
            return {
                ...state,
                loading: false,
                myCrews: action.payload.data,
            };
        case GET_CREW_BY_SUPERVISOR_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_CREWS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CREWS:
            return {
                ...state,
                loading: false,
                crews: action.payload.data
            };
        case GET_CREWS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_CREW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_CREW:
            return {
                ...state,
                loading: false,
                crew: action.payload.data,
            };
        case UPDATE_CREW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DELETE_CREW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_CREW:
            return {
                ...state,
                loading: false,
                crew: action.payload,
            };
        case DELETE_CREW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case ARCHIVE_CREW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ARCHIVE_CREW:
            return {
                ...state,
                loading: false,
                crew: action.payload,
            };
        case ARCHIVE_CREW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case CREATE_SIGNIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_SIGNIN:
            return {
                ...state,
                loading: false,
                sign: action.payload,
            };
        case CREATE_SIGNIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SUBMIT_SIGNIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SUBMIT_SIGNIN:
            return {
                ...state,
                loading: false,
                sign: action.payload.data,
            };
        case SUBMIT_SIGNIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case UPDATE_SIGNIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_SIGNIN:
            return {
                ...state,
                loading: false,
                sign: action.payload.data,
            };
        case UPDATE_SIGNIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case SET_SIGNIN_DRAFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_SIGNIN_DRAFT:
            return {
                ...state,
                loading: false,
                sign: action.payload.data,
            };
        case SET_SIGNIN_DRAFT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CREATE_SIGNOUT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_SIGNOUT:
            return {
                ...state,
                loading: false,
                sign: action.payload,
            };
        case CREATE_SIGNOUT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case SUBMIT_SIGNOUT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SUBMIT_SIGNOUT:
            return {
                ...state,
                loading: false,
                sign: action.payload.data,
            };
        case SUBMIT_SIGNOUT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_SIGNOUT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_SIGNOUT:
            return {
                ...state,
                loading: false,
                sign: action.payload.data,
            };
        case UPDATE_SIGNOUT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SET_SIGNOUT_DRAFT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_SIGNOUT_DRAFT:
            return {
                ...state,
                loading: false,
                sign: action.payload.data,
            };
        case SET_SIGNOUT_DRAFT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        case GET_SIGNS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SIGNS:
            return {
                ...state,
                loading: false,
                signs: action.payload.data
            };
        case GET_SIGNS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_SIGN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SIGN:
            return {
                ...state,
                loading: false,
                sign: action.payload.data,
            };
        case GET_SIGN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_SIGNS_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SIGNS_BY_USER:
            return {
                ...state,
                loading: false,
                signsByUser: action.payload.data
            };
        case GET_SIGNS_BY_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_SIGNIN_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SIGNIN_BY_USER:
            return {
                ...state,
                loading: false,
                signinByUser: action.payload.data
            };
        case GET_SIGNIN_BY_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_SIGNOUT_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SIGNOUT_BY_USER:
            return {
                ...state,
                loading: false,
                signoutByUser: action.payload.data
            };
        case GET_SIGNOUT_BY_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case GET_SIGNS_BY_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SIGNS_BY_LOCATION:
            return {
                ...state,
                loading: false,
                signs: action.payload.data
            };
        case GET_SIGNS_BY_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case DELETE_SIGN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_SIGN:
            return {
                ...state,
                loading: false,
                sign: action.payload,
            };
        case DELETE_SIGN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DOWNLOAD_SIGNIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DOWNLOAD_SIGNIN:
            return {
                ...state,
                loading: false,
                sign: action.payload.data,
            };
        case DOWNLOAD_SIGNIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DOWNLOAD_SIGNOUT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DOWNLOAD_SIGNOUT:
            return {
                ...state,
                loading: false,
                sign: action.payload.data,
            };
        case DOWNLOAD_SIGNOUT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                };


        default:
            return state;
        
    }    
}


export default toolboxReducer;