import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  Paper,
  Box,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Checkbox
} from "@material-ui/core";
import {ToggleButton} from '@material-ui/lab';
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {createInspection, submitInspection, getInspectionsByUser} from "../../../actions/craneAction"
import { getLocations, getUsers } from "../../../actions/usersAction";
import Feedback from "../../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },

  btn2:{
    marginBottom: 30,
    marginLeft: 10
  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 400,
      [theme.breakpoints.down("sm")]: {
          minWidth:330,
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      }
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 400,
    [theme.breakpoints.down("sm")]: {
        minWidth:330,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },
  green: {
    color: "#4caf50",
    fontWeight: 500,
    },  
  red: {
    color: "#ef5350",
    fontWeight: 500,
    },
  orange: {
    color: "#F5B041",
    fontWeight: 500,
    },


}));

const INIT_STATE = {
    unitNumber: "",
    inspector: "",
    supervisor: undefined,
    date: moment().format('YYYY-MM-DD'),
    location: undefined,
    mainVisualDamage:"1",
    reason:"",
    mainControl: "1",
    mainPushButtons: "1",
    mainSoundsNormal: "1",
    mainMovement: "1",
    mainBreaksPositive: "1",
    mainUpperLimits: "1",
    mainLowerLimits: "1",
    mainBlock:"1",
    mainSheaves:"1",
    mainRopeConnection:"1",
    mainProperDrum: "1",
    mainHooks: "1",
    auxHoist: false,
    auxVisualDamage:"1",
    auxControl: "1",
    auxPushButtons: "1",
    auxSoundsNormal: "1",
    auxMovement: "1",
    auxBreaksPositive: "1",
    auxUpperLimits: "1",
    auxLowerLimits: "1",
    auxBlock:"1",
    auxSheaves:"1",
    auxRopeConnection:"1",
    auxProperDrum: "1",
    auxHooks: "1",
    auxTrolley: "1",
    checkCrane:"1",
    oilLeaks: "1",
    inspectPendant: "1",
    remoteControl: false,
    chainHoist: "1",
    checkChain: "1",
    inspectSlings: "1",
    spreaderBars: "1",
    approvedOperator: false,
    reviewLog: false,
    raiseHook: false,
    switchController: false,
    inspectRigging: false,
    comment:"",
    fuel:"",
    nextService:""
    
};


const CreateInspectionModal = ({ openCreateInspection, handleCloseCreateInspection }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, loading } = useSelector((state) => state.crane);
  const { profile, locations, users } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getInspectionsByUser());
    dispatch(getUsers());
    dispatch(getLocations());
    }, [dispatch]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      location: profile && profile.location?._id,
    }));
  }, [profile]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    
  };

  const handleToggle = () => {
    setState({ ...state, auxHoist: !state.auxHoist });
  };


  const handleSave = (e) => {
    e.preventDefault();
    let data = {
        unitNumber: state.unitNumber,
        inspector: state.inspector,
        supervisor: state.supervisor,
        date: state.date,
        location: state.location,
        mainVisualDamage:state.mainVisualDamage,
        reason:state.reason,
        mainControl: state.mainControl,
        mainPushButtons: state.mainPushButtons,
        mainSoundsNormal: state.mainSoundsNormal,
        mainMovement: state.mainMovement,
        mainBreaksPositive: state.mainBreaksPositive,
        mainUpperLimits: state.mainUpperLimits,
        mainLowerLimits: state.mainLowerLimits,
        mainBlock:state.mainBlock,
        mainSheaves:state.mainSheaves,
        mainRopeConnection:state.mainRopeConnection,
        mainProperDrum: state.mainProperDrum,
        mainHooks: state.mainHooks,
        auxHoist: state.auxHoist,
        auxVisualDamage:state.auxVisualDamage,
        auxControl: state.auxControl,
        auxPushButtons: state.auxPushButtons,
        auxSoundsNormal: state.auxSoundsNormal,
        auxMovement: state.auxMovement,
        auxBreaksPositive: state.auxBreaksPositive,
        auxUpperLimits: state.auxUpperLimits,
        auxLowerLimits: state.auxLowerLimits,
        auxBlock:state.auxBlock,
        auxSheaves:state.auxSheaves,
        auxRopeConnection:state.auxRopeConnection,
        auxProperDrum: state.auxProperDrum,
        auxHooks: state.auxHooks,
        auxTrolley: state.auxTrolley,
        checkCrane:state.checkCrane,
        oilLeaks: state.oilLeaks,
        inspectPendant: state.inspectPendant,
        remoteControl: state.remoteControl,
        chainHoist: state.chainHoist,
        checkChain: state.checkChain,
        inspectSlings: state.inspectSlings,
        spreaderBars: state.spreaderBars,
        approvedOperator: state.approvedOperator,
        reviewLog: state.reviewLog,
        raiseHook: state.raiseHook,
        switchController: state.switchController,
        inspectRigging: state.inspectRigging,
        comment:state.comment,
        fuel:state.fuel,
        nextService:state.nextService
    };
    dispatch(createInspection(data));

    setTimeout(() => {
      handleCloseCreateInspection();
      dispatch(getInspectionsByUser());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Overhead Crane Inspection Saved Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        unitNumber: state.unitNumber,
        inspector: state.inspector,
        supervisor: state.supervisor,
        date: state.date,
        location: state.location,
        mainVisualDamage:state.mainVisualDamage,
        reason:state.reason,
        mainControl: state.mainControl,
        mainPushButtons: state.mainPushButtons,
        mainSoundsNormal: state.mainSoundsNormal,
        mainMovement: state.mainMovement,
        mainBreaksPositive: state.mainBreaksPositive,
        mainUpperLimits: state.mainUpperLimits,
        mainLowerLimits: state.mainLowerLimits,
        mainBlock:state.mainBlock,
        mainSheaves:state.mainSheaves,
        mainRopeConnection:state.mainRopeConnection,
        mainProperDrum: state.mainProperDrum,
        mainHooks: state.mainHooks,
        auxHoist: state.auxHoist,
        auxVisualDamage:state.auxVisualDamage,
        auxControl: state.auxControl,
        auxPushButtons: state.auxPushButtons,
        auxSoundsNormal: state.auxSoundsNormal,
        auxMovement: state.auxMovement,
        auxBreaksPositive: state.auxBreaksPositive,
        auxUpperLimits: state.auxUpperLimits,
        auxLowerLimits: state.auxLowerLimits,
        auxBlock:state.auxBlock,
        auxSheaves:state.auxSheaves,
        auxRopeConnection:state.auxRopeConnection,
        auxProperDrum: state.auxProperDrum,
        auxHooks: state.auxHooks,
        auxTrolley: state.auxTrolley,
        checkCrane:state.checkCrane,
        oilLeaks: state.oilLeaks,
        inspectPendant: state.inspectPendant,
        remoteControl: state.remoteControl,
        chainHoist: state.chainHoist,
        checkChain: state.checkChain,
        inspectSlings: state.inspectSlings,
        spreaderBars: state.spreaderBars,
        approvedOperator: state.approvedOperator,
        reviewLog: state.reviewLog,
        raiseHook: state.raiseHook,
        switchController: state.switchController,
        inspectRigging: state.inspectRigging,
        comment:state.comment,
        fuel:state.fuel,
        nextService:state.nextService,
        path: "crane/inspection/view"
    };
    dispatch(submitInspection(data));
    setTimeout(() => {
      handleCloseCreateInspection()
      dispatch(getInspectionsByUser());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Overhead Crane Inspection Submitted Successfully.",
        success: true,
      }));
    }, 1500);
  };


  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.unitNumber || !state.date || !state.location

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openCreateInspection}
        onClose={handleCloseCreateInspection}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Overhead Crane Inspection
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseCreateInspection}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <form onSubmit={handleSubmit}>
        
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="unitNumber"
                    fullWidth
                    variant="outlined"
                    label="Unit Number"
                    onChange={handleChange}
                    value={state.unitNumber}
                    required
                />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Operator"
                      onChange={handleChange}
                      value={state.inspector}
                      required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <Autocomplete 
                            id="supervisor"
                            options={users}
                            getOptionSelected={(option, value) => option._id === value._id}
                            getOptionLabel={(option) => option.displayname} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, supervisor: value }))} 
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Supervisor" />} 
                        />
                    </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="date"
                      fullWidth
                      variant="outlined"
                      label="Date"
                      onChange={handleChange}
                      value={state.date || moment().format('YYYY-MM-DD')}
                      required
                      type="date"   
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                    Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={state.location || ""}
                      name="location"
                      onChange={handleChange}
                      label="Location"
                      required
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {locations &&
                        locations.map((location) => (
                          <MenuItem key={location._id} value={location._id}>
                            {location.displayname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                        Fuel Level
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        fullWidth
                        value={state.fuel || ""}
                        name="fuel"
                        onChange={handleChange}
                        label="Fuel Level"
                        variant="outlined"
                    >
                        <MenuItem value="Empty">Empty</MenuItem>
                        <MenuItem value="1/4">1/4</MenuItem>
                        <MenuItem value="1/2">1/2</MenuItem>
                        <MenuItem value="3/4">3/4</MenuItem>
                        <MenuItem value="Full">Full</MenuItem>
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
              <Typography variant="body2" gutterBottom color="primary">
                  1 - Ok;
                  2 - Requires Scheduled Maintenance;
                  3 - Requires Immediate Fix
                </Typography>
                </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Walk Around Visual Damage Inspection</FormLabel>
                  <RadioGroup row name="mainVisualDamage" value={state.mainVisualDamage || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" />
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" />
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {(state.mainVisualDamage === "2" || state.mainVisualDamage === "3") && (
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="reason"
                  fullWidth
                  variant="outlined"
                  label="Briefly describe the damage"
                  onChange={handleChange}
                  multiline
                  value={state.reason || ""}
                  />
              </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Controls</FormLabel>
                  <RadioGroup row name="mainControl" value={state.mainControl || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Push Buttons</FormLabel>
                  <RadioGroup row name="mainPushButtons" value={state.mainPushButtons || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Sounds Normaly</FormLabel>
                  <RadioGroup row name="mainSoundsNormal" value={state.mainSoundsNormal || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Movement is Smooth</FormLabel>
                  <RadioGroup row name="mainMovement" value={state.mainMovement || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Breaks Positive</FormLabel>
                  <RadioGroup row name="mainBreaksPositive" value={state.mainBreaksPositive || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Upper Limits</FormLabel>
                  <RadioGroup row name="mainUpperLimits" value={state.mainUpperLimits || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Lower Limits</FormLabel>
                  <RadioGroup row name="mainLowerLimits" value={state.mainLowerLimits || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Upper and Lower Block</FormLabel>
                  <RadioGroup row name="mainBlock" value={state.mainBlock || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Sheaves</FormLabel>
                  <RadioGroup row name="mainSheaves" value={state.mainSheaves || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Rope and Connections</FormLabel>
                  <RadioGroup row name="mainRopeConnection" value={state.mainRopeConnection || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Proper Drum Spooling</FormLabel>
                  <RadioGroup row name="mainProperDrum" value={state.mainProperDrum || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Hooks</FormLabel>
                  <RadioGroup row name="mainHooks" value={state.mainHooks || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ToggleButton>
                    <Typography >
                        Auxilliary Hoist (If Equipped, Flip Switch)
                    </Typography>
                    <Switch
                        checked={state.auxHoist}
                        onChange={handleToggle}
                        name="auxHoist"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        value={state.auxHoist || false}
                    />
                </ToggleButton>
              </Grid>
              {state.auxHoist && (
              <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Walk Around Visual Damage Inspection</FormLabel>
                    <RadioGroup row name="auxVisualDamage" value={state.auxVisualDamage || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" />
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" />
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Controls</FormLabel>
                    <RadioGroup row name="auxControl" value={state.auxControl || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Push Buttons</FormLabel>
                    <RadioGroup row name="auxPushButtons" value={state.auxPushButtons || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Sounds Normaly</FormLabel>
                    <RadioGroup row name="auxSoundsNormal" value={state.auxSoundsNormal || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Movement is Smooth</FormLabel>
                    <RadioGroup row name="auxMovement" value={state.auxMovement || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Breaks Positive</FormLabel>
                    <RadioGroup row name="auxBreaksPositive" value={state.auxBreaksPositive || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Upper Limits</FormLabel>
                    <RadioGroup row name="auxUpperLimits" value={state.auxUpperLimits || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Lower Limits</FormLabel>
                    <RadioGroup row name="auxLowerLimits" value={state.auxLowerLimits || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Upper and Lower Block</FormLabel>
                    <RadioGroup row name="auxBlock" value={state.auxBlock || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Sheaves</FormLabel>
                    <RadioGroup row name="auxSheaves" value={state.auxSheaves || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Rope and Connections</FormLabel>
                    <RadioGroup row name="auxRopeConnection" value={state.auxRopeConnection || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Proper Drum Spooling</FormLabel>
                    <RadioGroup row name="auxProperDrum" value={state.auxProperDrum || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Hooks</FormLabel>
                    <RadioGroup row name="auxHooks" value={state.auxHooks || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                    <FormLabel >Trolley</FormLabel>
                    <RadioGroup row name="auxTrolley" value={state.auxTrolley || ""} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                        <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                    </RadioGroup>
                    </FormControl>
                </Grid>
              </Grid>

              )}
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Crane for Loose Parts </FormLabel>
                  <RadioGroup row name="checkCrane" value={state.checkCrane || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Oil Leaks or Other Visual Damage</FormLabel>
                  <RadioGroup row name="oilLeaks" value={state.oilLeaks || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Inspect Pendants Control Casing for Damage to Buttons and Festoon Cable</FormLabel>
                  <RadioGroup row name="inspectPendant" value={state.inspectPendant || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Chain Hoist - Inspect Hoist and Hook for Damage</FormLabel>
                  <RadioGroup row name="chainHoist" value={state.chainHoist || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Check Chain for Twist and Stretch</FormLabel>
                  <RadioGroup row name="checkChain" value={state.checkChain || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Inspect Slings and Hardware for Damage</FormLabel>
                  <RadioGroup row name="inspectSlings" value={state.inspectSlings || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Spreader Bars, Lifting Beams, Tag lines</FormLabel>
                  <RadioGroup row name="spreaderBars" value={state.spreaderBars || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
                <Typography align="left">
                    Remote Control Battery is Charged
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.remoteControl || false}
                        name="remoteControl"
                        color="primary"
                        onChange={(e, value) => {
                            e.persist()
                            setState((prevState) =>({ ...prevState, remoteControl: value }))
                          }}
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
                <Typography  align="left">
                    Approved Operator
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.approvedOperator || false}
                        name="approvedOperator"
                        color="primary"
                        onChange={(e, value) => {
                            e.persist()
                            setState((prevState) =>({ ...prevState, approvedOperator: value }))
                          }}
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
                <Typography  align="left">
                    Review Log Entries
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.reviewLog || false}
                        name="reviewLog"
                        color="primary"
                        onChange={(e, value) => {
                            e.persist()
                            setState((prevState) =>({ ...prevState, reviewLog: value }))
                          }}
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
                <Typography  align="left">
                    Raise Hook Close to Upper Limit
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.raiseHook || false}
                        name="raiseHook"
                        color="primary"
                        onChange={(e, value) => {
                            e.persist()
                            setState((prevState) =>({ ...prevState, raiseHook: value }))
                          }}
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
                <Typography  align="left">
                    Switch Controller Off and Secure
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.switchController || false}
                        name="switchController"
                        color="primary"
                        onChange={(e, value) => {
                            e.persist()
                            setState((prevState) =>({ ...prevState, switchController: value }))
                          }}
                      />
                    }
                />        
              </Grid>
              <Grid item xs={10} sm={5} md={5} >
                <Typography  align="left">
                    Inspect Rigging and Store Correctly
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} md={1} >
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.inspectRigging || false}
                        name="inspectRigging"
                        color="primary"
                        onChange={(e, value) => {
                            e.persist()
                            setState((prevState) =>({ ...prevState, inspectRigging: value }))
                          }}
                      />
                    }
                />        
              </Grid>
                
              <Grid item xs={12} sm={6}>
              <TextField
                    name="nextService"
                    fullWidth
                    variant="outlined"
                    label=" Next Service Due Date"
                    onChange={handleChange}
                    value={state.nextService}
                    type="date"     
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Comments/Rapairs Required"
                  onChange={handleChange}
                  multiline
                  value={state.comment || ""}
                  />
              </Grid>
   
            </Grid>
            <br />
            
            <Typography align="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSave}
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save"}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save & Submit"}
                </Button>
              </Typography>
              </form>
          </Box>
          </Paper>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateInspectionModal;
