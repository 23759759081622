import axios from "axios";
import {
    CREATE_CRANE,
    CREATE_CRANE_REQUEST,
    CREATE_CRANE_FAIL,
    SUBMIT_CRANE,
    SUBMIT_CRANE_REQUEST,
    SUBMIT_CRANE_FAIL,
    UPDATE_CRANE,
    UPDATE_CRANE_REQUEST,
    UPDATE_CRANE_FAIL,
    SET_CRANE_DRAFT,
    SET_CRANE_DRAFT_REQUEST,
    SET_CRANE_DRAFT_FAIL,
    SET_CRANE_SERVICED,
    SET_CRANE_SERVICED_REQUEST,
    SET_CRANE_SERVICED_FAIL,
    GET_CRANE,
    GET_CRANE_REQUEST,
    GET_CRANE_FAIL,
    GET_CRANES,
    GET_CRANES_REQUEST,
    GET_CRANES_FAIL,
    GET_CRANES_BY_USER,
    GET_CRANES_BY_USER_REQUEST,
    GET_CRANES_BY_USER_FAIL,
    GET_CRANES_BY_LOCATION,
    GET_CRANES_BY_LOCATION_REQUEST,
    GET_CRANES_BY_LOCATION_FAIL,
    DELETE_CRANE,
    DELETE_CRANE_REQUEST,
    DELETE_CRANE_FAIL,

} from "../types/craneTypes"

const token = localStorage.getItem("token:data");
export const createInspection = (crane) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_CRANE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/crane/inspection`, crane, config);
        dispatch({
            type: CREATE_CRANE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_CRANE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const submitInspection = (crane) => async (dispatch) => {
    
    try {
        dispatch({
            type: SUBMIT_CRANE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/crane/inspection/submit`, crane, config);
        dispatch({
            type: SUBMIT_CRANE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUBMIT_CRANE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const updateInspection = (crane) => async (dispatch) => {
    
    try {
        dispatch({
            type: UPDATE_CRANE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/crane/inspection/${crane.id}`, crane, config);
        dispatch({
            type: UPDATE_CRANE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_CRANE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setInspectionDraft = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_CRANE_DRAFT_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/crane/inspection/draft/${id}`, null, config);
        dispatch({
            type: SET_CRANE_DRAFT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_CRANE_DRAFT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setInspectionServiced = (service) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_CRANE_SERVICED_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/crane/inspection/serviced/${service.id}`, service, config);
        dispatch({
            type: SET_CRANE_SERVICED,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_CRANE_SERVICED_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}


export const getInspections = () => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_CRANES_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/crane/inspections`, config);
        dispatch({
            type: GET_CRANES,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_CRANES_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
                });
    }
}

export const getInspection = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_CRANE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/crane/inspection/${id}`, config);
        dispatch({
            type: GET_CRANE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_CRANE_FAIL,
            payload:
                error.response && error.response.data?.error
                ? error.response.data?.error
                : error.request
                ? error.request.data?.error
                : error.message,
        });
    }
}

export const getInspectionsByUser = () => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_CRANES_BY_USER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/crane/myinspections/user`, config);
        dispatch({
            type: GET_CRANES_BY_USER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_CRANES_BY_USER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const getInspectionsByLocation = (id) => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_CRANES_BY_LOCATION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/crane/inspections/location/${id}`, config);
        dispatch({
            type: GET_CRANES_BY_LOCATION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_CRANES_BY_LOCATION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const deleteInspection = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_CRANE_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/crane/inspection/${id}`, config);
        dispatch({
            type: DELETE_CRANE,
        });
    } catch (error) {
        dispatch({
            type: DELETE_CRANE_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

