import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  Paper,
  Box,
  IconButton,
  FormControl,
  FormLabel,
  FormControlLabel,
  Button,
  ButtonGroup,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import clsx from "clsx";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import CloseIcon from "@material-ui/icons/Close";
import BuildIcon from '@material-ui/icons/Build';
import AddIcon from "@material-ui/icons/Add"
import { useDispatch, useSelector } from "react-redux";
import {getInspection, setInspectionDraft, getInspections, getInspectionsByUser} from "../../../actions/forkliftAction";
import ServiceInspectionModal from "./ServiceInspectionModal";
import Feedback from "../../../utils/Feedback";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  btnGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
  },
  
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 380,
      [theme.breakpoints.down("sm")]: {
          width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      },
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 350,
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
    color: "#4caf50",
  },
  paper3:{
    textAlign: "center",
    width: "fit-content",
    height: 50,
    display: "flex",
    justifyContent: "center",
    marginTop: 30
  },

}));

const INIT_STATE = {
    unitNumber: "",
    inspector: "",
    supervisor: undefined,
    date: "",
    location: undefined,
    hour: "",
    visualDamage:"1",
    reason:"",
    fireExtinguisher: "1",
    safetyDecals: "1",
    quickDisconnect: "1",
    fenders: "1",
    greasePoint: "1",
    driveTrain: "1",
    hydraulicLines: "1",
    hydraulicOil:"1",
    engineOil:"1",
    coolant:"1",
    airFilter: "1",
    belt: "1",
    battery:"1",
    powerLockout: "1",
    brakeFluid: "1",
    visualLeaks: "1",
    windows: "1",
    mirrors: "1",
    seats: "1",
    cleanliness: "1",
    controlLevers: "1",
    steeringBrakes: "1",
    parkBrakes: "1",
    backupAlarms: "1",
    guages:"1",
    lights: "1",
    dashLights: "1",
    horn: "1",
    operationOverhaul: "1",
    forks: "1",
    outriggers: "1",
    tires: "1",
    wheel: "1",
    inspectSafety: "1",
    inspectTow: "1",
    inspectReceiver: "1",
    comment:"",
    fuel:"",
    nextService:"",
    service:{
      date:"",
      description:"",
      servicedBy: "",
      cost: ""
    },
    error: null,
    success: false,
    loading: false,
};

const ViewInspectionModal = ({ openViewInspection, handleCloseViewInspection, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, forklift } = useSelector((state) => state.forklift);
  const [openService, setOpenService] = useState(false);
  const [getId, setGetId] = useState("");

  const { profile, loading } = useSelector((state) => state.users);

    let isAdminGuard = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("admin"))
    : false;

    let isForkliftAdmin = profile
    ? (profile &&
        profile.role && profile.role.map((role) => role.name).includes("forklift_admin"))
        : false;


useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
        setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
        }));
    }
    return () => {
        abortController.abort();
    };
    }, [error]);

  useEffect(() => {
    if (id){
      dispatch(getInspection(id));
    }
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
      ...prev,
        unitNumber: forklift && forklift.unitNumber,
        inspector: forklift && forklift.inspector,
        supervisor: forklift && forklift.supervisor,
        date: forklift && forklift.date,
        location: forklift && forklift.location?.displayname,
        hour: forklift && forklift.hour,
        visualDamage:forklift && forklift.visualDamage,
        reason:forklift && forklift.reason,
        fireExtinguisher: forklift && forklift.fireExtinguisher,
        safetyDecals: forklift && forklift.safetyDecals,
        quickDisconnect: forklift && forklift.quickDisconnect,
        fenders: forklift && forklift.fenders,
        greasePoint: forklift && forklift.greasePoint,
        driveTrain: forklift && forklift.driveTrain,
        hydraulicLines: forklift && forklift.hydraulicLines,
        hydraulicOil:forklift && forklift.hydraulicOil,
        engineOil:forklift && forklift.engineOil,
        coolant:forklift && forklift.coolant,
        airFilter: forklift && forklift.airFilter,
        belt: forklift && forklift.belt,
        battery:forklift && forklift.battery,
        powerLockout: forklift && forklift.powerLockout,
        brakeFluid: forklift && forklift.brakeFluid,
        visualLeaks: forklift && forklift.visualLeaks,
        windows: forklift && forklift.windows,
        mirrors: forklift && forklift.mirrors,
        seats: forklift && forklift.seats,
        cleanliness: forklift && forklift.cleanliness,
        controlLevers: forklift && forklift.controlLevers,
        steeringBrakes: forklift && forklift.steeringBrakes,
        parkBrakes: forklift && forklift.parkBrakes,
        backupAlarms: forklift && forklift.backupAlarms,
        guages:forklift && forklift.guages,
        lights: forklift && forklift.lights,
        dashLights: forklift && forklift.dashLights,
        horn: forklift && forklift.horn,
        operationOverhaul: forklift && forklift.operationOverhaul,
        forks: forklift && forklift.forks,
        outriggers: forklift && forklift.outriggers,
        tires: forklift && forklift.tires,
        wheel: forklift && forklift.wheel,
        inspectSafety: forklift && forklift.inspectSafety,
        inspectTow: forklift && forklift.inspectTow,
        inspectReceiver: forklift && forklift.inspectReceiver,
        comment:forklift && forklift.comment,
        fuel:forklift && forklift.fuel,
        nextService:forklift && forklift.nextService,
        service: forklift && forklift.service

    }));
  }, [forklift]);

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleSetDraft = (e) => {
    e.preventDefault();
    dispatch(setInspectionDraft(id));
    setTimeout(() => {
      handleCloseViewInspection();
        dispatch(getInspections());
        dispatch(getInspectionsByUser());

        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Inspection set to draft successfully!",
          success: true,
        }));
    }, 1500);
  };

  const handleOpenService = (id) => {
    setGetId(id);
    setOpenService(true);
  };

  const handleCloseService = () => {
    setOpenService(false);
  };


  const printRef = React.useRef()
  const handleDownloadPdf = async () => {
    const element = printRef.current
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4', true);
    
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight()
    const imgWidth = canvas.width
    const imgHeight = canvas.height
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
    const imgX = (pdfWidth - imgWidth * ratio) / 2
    const imgY = (pdfHeight - imgHeight * ratio) / 2

    pdf.addImage(data, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    pdf.save(`Forklift_${state.unitNumber}_${state.date?.split("T")[0]}.pdf`);
    };  

  return (
    <div>
      <ServiceInspectionModal {...{ openService, handleCloseService, id: getId }} />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openViewInspection}
        onClose={handleCloseViewInspection}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                View Forklift Inspection
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseViewInspection}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        {(forklift && (forklift.status === "Submitted" || forklift.status === "Serviced")) && (isAdminGuard || isForkliftAdmin) && (
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={8} sm={8}>
                <ButtonGroup className={classes.btnGroup} variant="outlined">
                    <Button
                        size="small"
                        className={classes.btnLink}
                        color="primary"
                        onClick={handleSetDraft}
                    >
                        Set to Draft
                    </Button>
                    <Button
                        size="small"
                        className={clsx(classes.btnLink, classes.statusDarkGreen)}
                        onClick={handleDownloadPdf}
                    >
                        Download as PDF
                    </Button>
                
                </ButtonGroup>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Paper variant="outlined" className={classes.paper3}>
                  <IconButton color="primary" onClick={() => handleOpenService(forklift._id)}>
                    <BuildIcon/>
                    <Tooltip title="Record Services & Repairs">
                        <AddIcon />
                    </Tooltip>
                </IconButton>
                </Paper>
              </Grid>
          </Grid>
                
          )}
          <br/>   
          <Divider />

        <DialogContent className={classes.dialogContent}>
        <div ref={printRef}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>

            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="unitNumber"
                      fullWidth
                      variant="outlined"
                      label="Unit Number"
                      value={state.unitNumber || ""}
                      disabled
                    />
              </Grid>
              <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Operator"
                      value={state.inspector || ""}
                      disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="supervisor"
                      fullWidth
                      variant="outlined"
                      label="Supervisor"
                      value={state.supervisor?.displayname || ""}
                      disabled
                    />
                </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                    name="date"
                    fullWidth
                    variant="outlined"
                    label="Date"
                    value={state.date?.split("T")[0] || ''}
                    disabled    
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    name="location"
                    fullWidth
                    variant="outlined"
                    label="Location"
                    value={state.location || ""}
                    disabled    
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                    name="fuel"
                    fullWidth
                    variant="outlined"
                    label="Fuel Level"
                    value={state.fuel || ""}
                    disabled
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                    name="hour"
                    fullWidth
                    variant="outlined"
                    label="Hours"
                    value={state.hour || ""}
                    disabled
                />
              </Grid>
              <Grid item xs={12} sm={12}>
              <Typography variant="body2" gutterBottom color="primary">
                  1 - Ok;
                  2 - Requires Scheduled Maintenance;
                  3 - Requires Immediate Fix
                </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Walk Around Visual Damage Inspection</FormLabel>
                  <RadioGroup row aria-label="visualDamage" name="visualDamage" value={state.visualDamage || ""} >
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              {(state.visualDamage == "2" || state.visualDamage == "3") && (
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="reason"
                  fullWidth
                  variant="outlined"
                  label="Briefly describe the damage"
                  disabled
                  multiline
                  value={state.reason || ""}
                  />
              </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Fire Extinguisher</FormLabel>
                  <RadioGroup row name="fireExtinguisher" value={state.fireExtinguisher || ""} >
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Safety Decals</FormLabel>
                  <RadioGroup row name="safetyDecals" value={state.safetyDecals || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Quick Disconnect Assembly</FormLabel>
                  <RadioGroup row name="quickDisconnect" value={state.quickDisconnect || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Fenders</FormLabel>
                  <RadioGroup row name="fenders" value={state.fenders || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Grease Points</FormLabel>
                  <RadioGroup row name="greasePoint" value={state.greasePoint || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Drive Train - Drive Shafts</FormLabel>
                  <RadioGroup row name="driveTrain" value={state.driveTrain || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Hydraulic Lines</FormLabel>
                  <RadioGroup row name="hydraulicLines" value={state.hydraulicLines || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Engine Oil Level</FormLabel>
                  <RadioGroup row name="engineOil" value={state.engineOil || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Coolant Level</FormLabel>
                  <RadioGroup row name="coolant" value={state.coolant || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Air Filter</FormLabel>
                  <RadioGroup row name="airFilter" value={state.airFilter || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Belt</FormLabel>
                  <RadioGroup row name="belt" value={state.belt || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Battery Connections - Clean & Dry</FormLabel>
                  <RadioGroup row name="battery" value={state.battery || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Power Lockout</FormLabel>
                  <RadioGroup row name="powerLockout" value={state.powerLockout || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Brake Fluid Level - if equipped</FormLabel>
                  <RadioGroup row name="brakeFluid" value={state.brakeFluid || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Visual Leaks</FormLabel>
                  <RadioGroup row name="visualLeaks" value={state.visualLeaks || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Windows & Wipers</FormLabel>
                  <RadioGroup row name="windows" value={state.windows || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Mirrors</FormLabel>
                  <RadioGroup row name="mirrors" value={state.mirrors || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Seats & Seatbelts</FormLabel>
                  <RadioGroup row name="seats" value={state.seats || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Cleanliness</FormLabel>
                  <RadioGroup row name="cleanliness" value={state.cleanliness || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Control Levers</FormLabel>
                  <RadioGroup row name="controlLevers" value={state.controlLevers || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Steering Brakes</FormLabel>
                  <RadioGroup row name="steeringBrakes" value={state.steeringBrakes || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Park Brakes</FormLabel>
                  <RadioGroup row name="parkBrakes" value={state.parkBrakes || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Back Up Alarms</FormLabel>
                  <RadioGroup row name="backupAlarms" value={state.backupAlarms || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Guages</FormLabel>
                  <RadioGroup row name="guages" value={state.guages || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Lights</FormLabel>
                  <RadioGroup row name="lights" value={state.lights || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Dash Lights</FormLabel>
                  <RadioGroup row name="dashLights" value={state.dashLights || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Horn</FormLabel>
                  <RadioGroup row name="horn" value={state.horn || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Operation Overhaul</FormLabel>
                  <RadioGroup row name="operationOverhaul" value={state.operationOverhaul || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Forks - Bent or Cracked</FormLabel>
                  <RadioGroup row name="forks" value={state.forks || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Outriggers</FormLabel>
                  <RadioGroup row name="outriggers" value={state.outriggers || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Tires</FormLabel>
                  <RadioGroup row name="tires" value={state.tires || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Wheel Lug Nuts</FormLabel>
                  <RadioGroup row name="wheel" value={state.wheel || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Inspect Safety Hitch</FormLabel>
                  <RadioGroup row name="inspectSafety" value={state.inspectSafety || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Inspect Tow Bar</FormLabel>
                  <RadioGroup row name="inspectTow" value={state.inspectTow || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Inspect Receiver, Coupler & Pin</FormLabel>
                  <RadioGroup row name="inspectReceiver" value={state.inspectReceiver || ""}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" disabled/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" disabled/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} sm={6}>
              <TextField
                    name="nextService"
                    fullWidth
                    variant="outlined"
                    label=" Next Service Due Date"
                    disabled
                    value={state.nextService?.split("T")[0] || ''}
                    type="date"     
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Comments/Rapairs Required"
                  multiline
                  value={state.comment || ""}
                  disabled
                  />
              </Grid>

            </Grid>

            {forklift && forklift.service && (
              <Grid>
                <br/>
                <Divider/>
                <Typography variant="overline" className={classes.title}>
                  Services/Repairs Completed
                </Typography>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="serviceDate"
                        fullWidth
                        variant="outlined"
                        label="Service Date"
                        value={state.service?.date?.split("T")[0] || ""}
                        type="date"
                        disabled
                        size="small"     
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="description"
                      fullWidth
                      variant="outlined"
                      label="Description"
                      multiline
                      value={state.service?.description || ""}
                      disabled
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="servicedBy"
                        fullWidth
                        variant="outlined"
                        label="Serviced By"
                        value={state.service?.servicedBy || ""}
                        disabled
                        size="small"    
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="cost"
                      fullWidth
                      variant="outlined"
                      label="Cost"
                      value={state.service?.cost || ""}
                      disabled
                      size="small"
                    />
                  </Grid>
                  </Grid>
                </Grid>
            )}
            
          </Box>
          </Paper>
          </div>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewInspectionModal;
