import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  Paper,
  Box,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {createInspection, submitInspection, getInspectionsByUser} from "../../../actions/scaffoldAction"
import { getLocations, getUsers } from "../../../actions/usersAction";
import Feedback from "../../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },

  btn2:{
    marginBottom: 30,
    marginLeft: 10
  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 400,
      [theme.breakpoints.down("sm")]: {
          minWidth:340,
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      }
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 400,
    [theme.breakpoints.down("sm")]: {
        minWidth:340,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },
  green: {
    color: "#4caf50",
    fontWeight: 500,
    },
  yellow: {
    color: "#FFD700",
    fontWeight: 500
  },
  red: {
    color: "#ef5350",
    fontWeight: 500,
    },
  orange: {
    color: "#F5B041",
    fontWeight: 500,
    },


}));

const INIT_STATE = {
    unitNumber: "",
    inspector: "",
    supervisor: undefined,
    date: moment().format('YYYY-MM-DD'),
    location: undefined,
    colour: "",
    elevation: "",
    height:"",
    length:"",
    width:"",
    loadWeight:"",
    specialLoad:"",
    hoarding:false,
    system:false,
    tube:false,
    bridge:false,
    hanger:false,
    roller:false,
    tower:false,
    cantelever:false,
    frames:false,
    erect:false,
    dismantle:false,
    addition:false,
    modification:false,
    mudSills: "Yes",
    longitudinalBracing: "Yes",
    internalBracing: "Yes",
    horizontalBracing: "Yes",
    checkClamps: "Yes",
    allClamps: "Yes",
    allConnections: "Yes",
    scaffoldSquare: "Yes",
    topRail: "Yes",
    midRail: "Yes",
    toeBoard: "Yes",
    ladder: "Yes",
    ladderSecurely: "Yes",
    ladder3: "Yes",
    ladderCage: "Yes",
    safeAccess: "Yes",
    safeAccessOther: "Yes",
    gate: "Yes",
    crawl: "Yes",
    basePlates: "Yes",
    deckLevel: "Yes",
    deckFully: "Yes",
    transoms: "Yes",
    correctPlank: "Yes",
    rollingScaffold: "Yes",
    anySpan: "Yes",
    screwjack: "Yes",
    noTube: "Yes",
    tieIns: "Yes",
    comment: "",    
};


const CreateInspectionModal = ({ openCreateInspection, handleCloseCreateInspection }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, loading } = useSelector((state) => state.scaffold);
  const { profile, locations, users } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getInspectionsByUser());
    dispatch(getUsers());
    dispatch(getLocations());
    }, [dispatch]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      location: profile && profile.location?._id,
    }));
  }, [profile]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    
  };


  const handleSave = (e) => {
    e.preventDefault();
    let data = {
        unitNumber: state.unitNumber,
        inspector: state.inspector,
        supervisor: state.supervisor,
        date: state.date,
        location: state.location,
        colour:state.colour,
        elevation:state.elevation,
        height:state.height,
        length:state.length,
        width:state.width,
        loadWeight:state.loadWeight,
        specialLoad:state.specialLoad,
        hoarding:state.hoarding,
        system:state.system,
        tube:state.tube,
        bridge:state.bridge,
        hanger:state.hanger,
        roller:state.roller,
        tower:state.tower,
        cantelever:state.cantelever,
        frames:state.frames,
        erect:state.erect,
        dismantle:state.dismantle,
        addition:state.addition,
        modification:state.modification,
        mudSills: state.mudSills,
        longitudinalBracing: state.longitudinalBracing,
        internalBracing: state.internalBracing,
        horizontalBracing: state.horizontalBracing,
        checkClamps: state.checkClamps,
        allClamps: state.allClamps,
        allConnections: state.allConnections,
        scaffoldSquare: state.scaffoldSquare,
        topRail: state.topRail,
        midRail: state.midRail,
        toeBoard: state.toeBoard,
        ladder: state.ladder,
        ladderSecurely: state.ladderSecurely,
        ladder3: state.ladder3,
        ladderCage: state.ladderCage,
        safeAccess: state.safeAccess,
        safeAccessOther: state.safeAccessOther,
        gate: state.gate,
        crawl: state.crawl,
        basePlates: state.basePlates,
        deckLevel: state.deckLevel,
        deckFully: state.deckFully,
        transoms: state.transoms,
        correctPlank: state.correctPlank,
        rollingScaffold: state.rollingScaffold,
        anySpan: state.anySpan,
        screwjack: state.screwjack,
        noTube: state.noTube,
        tieIns: state.tieIns,
        comment:state.comment,
    };
    dispatch(createInspection(data));

    setTimeout(() => {
      handleCloseCreateInspection();
      dispatch(getInspectionsByUser());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Scaffold Inspection Saved Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        unitNumber: state.unitNumber,
        inspector: state.inspector,
        supervisor: state.supervisor,
        date: state.date,
        location: state.location,
        colour:state.colour,
        elevation:state.elevation,
        height:state.height,
        length:state.length,
        width:state.width,
        loadWeight:state.loadWeight,
        specialLoad:state.specialLoad,
        hoarding:state.hoarding,
        system:state.system,
        tube:state.tube,
        bridge:state.bridge,
        hanger:state.hanger,
        roller:state.roller,
        tower:state.tower,
        cantelever:state.cantelever,
        frames:state.frames,
        erect:state.erect,
        dismantle:state.dismantle,
        addition:state.addition,
        modification:state.modification,
        mudSills: state.mudSills,
        longitudinalBracing: state.longitudinalBracing,
        internalBracing: state.internalBracing,
        horizontalBracing: state.horizontalBracing,
        checkClamps: state.checkClamps,
        allClamps: state.allClamps,
        allConnections: state.allConnections,
        scaffoldSquare: state.scaffoldSquare,
        topRail: state.topRail,
        midRail: state.midRail,
        toeBoard: state.toeBoard,
        ladder: state.ladder,
        ladderSecurely: state.ladderSecurely,
        ladder3: state.ladder3,
        ladderCage: state.ladderCage,
        safeAccess: state.safeAccess,
        safeAccessOther: state.safeAccessOther,
        gate: state.gate,
        crawl: state.crawl,
        basePlates: state.basePlates,
        deckLevel: state.deckLevel,
        deckFully: state.deckFully,
        transoms: state.transoms,
        correctPlank: state.correctPlank,
        rollingScaffold: state.rollingScaffold,
        anySpan: state.anySpan,
        screwjack: state.screwjack,
        noTube: state.noTube,
        tieIns: state.tieIns,
        comment:state.comment,
        path: "scaffold/inspection/view"
    };
    dispatch(submitInspection(data));
    setTimeout(() => {
      handleCloseCreateInspection()
      dispatch(getInspectionsByUser());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Scaffold Inspection Submitted Successfully.",
        success: true,
      }));
    }, 1500);
  };


  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.unitNumber || !state.date || !state.location

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openCreateInspection}
        onClose={handleCloseCreateInspection}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Scaffold Inspection
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseCreateInspection}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="unitNumber"
                        fullWidth
                        variant="outlined"
                        label="Unit Number"
                        onChange={handleChange}
                        value={state.unitNumber}
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Operator"
                      onChange={handleChange}
                      value={state.inspector}
                      required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formcontrol2}>
                        <Autocomplete 
                            id="supervisor"
                            options={users}
                            getOptionSelected={(option, value) => option._id === value._id}
                            getOptionLabel={(option) => option.displayname} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, supervisor: value }))} 
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Supervisor" />} 
                        />
                    </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="date"
                      fullWidth
                      variant="outlined"
                      label="Date"
                      onChange={handleChange}
                      value={state.date || moment().format('YYYY-MM-DD')}
                      required
                      type="date"   
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                    Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={state.location || ""}
                      name="location"
                      onChange={handleChange}
                      label="Location"
                      required
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {locations &&
                        locations.map((location) => (
                          <MenuItem key={location._id} value={location._id}>
                            {location.displayname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                      name="elevation"
                      fullWidth
                      variant="outlined"
                      label="Elevation"
                      onChange={handleChange}
                      value={state.elevation}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel > Tag Colour</FormLabel>
                        <RadioGroup row name="colour" value={state.colour || ""} onChange={handleChange}>
                            <FormControlLabel value="Green" className={classes.green} control={<Radio color="primary"/>} label="Green" />
                            <FormControlLabel value="Yellow" className={classes.yellow} control={<Radio color="primary"/>} label="Yellow" />
                            <FormControlLabel value="Red" className={classes.red} control={<Radio color="primary"/>} label="Red" />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                      name="height"
                      fullWidth
                      variant="outlined"
                      label="Total Height"
                      onChange={handleChange}
                      value={state.height}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="width"
                      fullWidth
                      variant="outlined"
                      label="Total Width"
                      onChange={handleChange}
                      value={state.width}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="length"
                      fullWidth
                      variant="outlined"
                      label="Total Length"
                      onChange={handleChange}
                      value={state.length}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className={classes.formcontrol2}>
                        <InputLabel id="weight">
                            Load Weight
                        </InputLabel>
                        <Select
                            id="weight"
                            fullWidth
                            value={state.loadWeight || ""}
                            name="loadWeight"
                            onChange={handleChange}
                            variant="outlined"
                        >
                            <MenuItem value="Light(25lb)">Light (25lb)</MenuItem>
                            <MenuItem value="Medium(50lb)">Medium (50lb)</MenuItem>
                            <MenuItem value="Heavy(75lb)">Heavy (75lb)</MenuItem>
                            <MenuItem value="Special">Special</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {state && state.loadWeight === "Special" && (
                    <Grid item xs={12} sm={6}>
                    <TextField
                        name="specialLoad"
                        fullWidth
                        variant="outlined"
                        label="Special Load"
                        onChange={handleChange}
                        value={state.specialLoad}
                        />
                    </Grid>
                )}
                
                <Grid item xs={12} sm={12}></Grid>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                
                    <Grid item xs={4} sm={3} md={3} >
                        <Typography  align="left">
                            Hoarding
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.hoarding || false}
                                name="hoarding"
                                color="primary"
                                onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, hoarding: value }))
                                }}
                            />
                            }
                        />        
                    </Grid>

                    <Grid item xs={4} sm={3} md={3} >
                        <Typography  align="left">
                            System
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.system || false}
                                name="system"
                                color="primary"
                                onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, system: value }))
                                }}
                            />
                            }
                        />        
                    </Grid>

                    <Grid item xs={4} sm={3} md={3} >
                        <Typography  align="left">
                            Tube/Clamp
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.tube || false}
                                name="tube"
                                color="primary"
                                onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, tube: value }))
                                }}
                            />
                            }
                        />        
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} >
                        <Typography  align="left">
                           Bridge
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.bridge || false}
                                name="bridge"
                                color="primary"
                                onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, bridge: value }))
                                }}
                            />
                            }
                        />        
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} >
                        <Typography  align="left">
                            Hanger
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.hanger || false}
                                name="hanger"
                                color="primary"
                                onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, hanger: value }))
                                }}
                            />
                            }
                        />        
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} >
                        <Typography  align="left">
                            Roller
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.roller || false}
                                name="roller"
                                color="primary"
                                onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, roller: value }))
                                }}
                            />
                            }
                        />        
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} >
                        <Typography  align="left">
                            Tower
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.tower || false}
                                name="tower"
                                color="primary"
                                onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, tower: value }))
                                }}
                            />
                            }
                        />        
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} >
                        <Typography  align="left">
                            Canterlever
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.cantelever || false}
                                name="cantelever"
                                color="primary"
                                onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, cantelever: value }))
                                }}
                            />
                            }
                        />        
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} >
                        <Typography  align="left">
                            Frames
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.frames || false}
                                name="frames"
                                color="primary"
                                onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, frames: value }))
                                }}
                            />
                            }
                        />        
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} >
                        <Typography  align="left">
                            Erect
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.erect || false}
                                name="erect"
                                color="primary"
                                onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, erect: value }))
                                }}
                            />
                            }
                        />        
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} >
                        <Typography  align="left">
                            Dismantle
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.dismantle || false}
                                name="dismantle"
                                color="primary"
                                onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, dismantle: value }))
                                }}
                            />
                            }
                        />        
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} >
                        <Typography  align="left">
                            Addition
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.addition || false}
                                name="addition"
                                color="primary"
                                onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, addition: value }))
                                }}
                            />
                            }
                        />        
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} >
                        <Typography  align="left">
                            Modification
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.modification || false}
                                name="modification"
                                color="primary"
                                onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, modification: value }))
                                }}
                            />
                            }
                        />        
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} ></Grid>
                
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
            
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Mud Sills in Place and Secure</FormLabel>
                        <RadioGroup row name="mudSills" value={state.mudSills || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Longitudinal Bracing in Place</FormLabel>
                        <RadioGroup row name="longitudinalBracing" value={state.longitudinalBracing || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Internal Bracing in Place</FormLabel>
                        <RadioGroup row name="internalBracing" value={state.internalBracing || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Horizontal Bracing in Place</FormLabel>
                        <RadioGroup row name="horizontalBracing" value={state.horizontalBracing || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Check Clamps in Place in All Areas</FormLabel>
                        <RadioGroup row name="checkClamps" value={state.checkClamps || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >All Clamps Tight</FormLabel>
                        <RadioGroup row name="allClamps" value={state.allClamps || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >All Connections Tight</FormLabel>
                        <RadioGroup row name="allConnections" value={state.allConnections || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Scaffold Square, Straight and Plumb in all Directions</FormLabel>
                        <RadioGroup row name="scaffoldSquare" value={state.scaffoldSquare || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Top Rail in Place and Secured</FormLabel>
                        <RadioGroup row name="topRail" value={state.topRail || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Mid Rail in Place and Secured</FormLabel>
                        <RadioGroup row name="midRail" value={state.midRail || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Toe Board in Place and Secured</FormLabel>
                        <RadioGroup row name="toeBoard" value={state.toeBoard || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Ladder in Place (Broken at 24")</FormLabel>
                        <RadioGroup row name="ladder" value={state.ladder || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Ladder Securely Installed</FormLabel>
                        <RadioGroup row name="ladderSecurely" value={state.ladderSecurely || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Ladder 3" Over Deck</FormLabel>
                        <RadioGroup row name="ladder3" value={state.ladder3 || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Ladder Cage (Deck Over 24")</FormLabel>
                        <RadioGroup row name="ladderCage" value={state.ladderCage || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Safe Access On/Off Ladder</FormLabel>
                        <RadioGroup row name="safeAccess" value={state.safeAccess || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Safe Access Other than Ladder</FormLabel>
                        <RadioGroup row name="safeAccessOther" value={state.safeAccessOther || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Gate in Place</FormLabel>
                        <RadioGroup row name="gate" value={state.gate || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Crawl On</FormLabel>
                        <RadioGroup row name="crawl" value={state.crawl || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Base Plates Tightened and Secured</FormLabel>
                        <RadioGroup row name="basePlates" value={state.basePlates || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Deck Level (No Tripping Hazard)</FormLabel>
                        <RadioGroup row name="deckLevel" value={state.deckLevel || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Deck Fully Planked and Secured</FormLabel>
                        <RadioGroup row name="deckFully" value={state.deckFully || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Transoms Installed Where Necessary</FormLabel>
                        <RadioGroup row name="transoms" value={state.transoms || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Correct Plank Overlap (6"-12")</FormLabel>
                        <RadioGroup row name="correctPlank" value={state.correctPlank || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Rolling Scaffold Brakes in Place (3:1 Ratio)</FormLabel>
                        <RadioGroup row name="rollingScaffold" value={state.rollingScaffold || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Any Span Greater Than 10" or 3m Picked Up</FormLabel>
                        <RadioGroup row name="anySpan" value={state.anySpan || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Screwjack Adjustments Must Not Exceed 12"</FormLabel>
                        <RadioGroup row name="screwjack" value={state.screwjack || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >No Tube or Members Overextended</FormLabel>
                        <RadioGroup row name="noTube" value={state.noTube || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="outlined" className={classes.formcontrol2}>
                        <FormLabel >Tie Ins Adequate and Secure</FormLabel>
                        <RadioGroup row name="tieIns" value={state.tieIns || ""} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="secondary"/>} label="No" />
                            <FormControlLabel value="N/A" control={<Radio color="default"/>} label="N/A" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        name="comment"
                        fullWidth
                        variant="outlined"
                        label="Comments"
                        onChange={handleChange}
                        multiline
                        value={state.comment || ""}
                        />
                    </Grid>
                    
                </Grid>
   
            </Grid>
            <br />
            
            <Typography align="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSave}
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save"}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save & Submit"}
                </Button>
              </Typography>
              </form>
          </Box>
          </Paper>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateInspectionModal;
