import React, { useState, useEffect } from "react";
import "./App.css";
import {
  Button,
  CssBaseline,
  MuiThemeProvider,
  Snackbar,
  Backdrop,
  makeStyles,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import theme from "./theme";

import { Provider } from "react-redux";
import store from "./store";

import Layout from "./components/Auth/Layout";
import LoginComponent from "./components/Auth/LoginComponent";
import Users from "./components/Admin/User";
import Roles from "./components/Admin/Role";
import Locations from "./components/Admin/Location";
import Account from "./components/User/Account";
import SetPasswordComponent from "./components/Auth/SetPasswordComponent";
import Dashboard from "./components/Auth/Dashboard"
import Document from "./components/User/Document";
import ToolBoxDocument from "./components/User/ToolBoxDocument";
import SafetyDocument from "./components/User/SafetyDocument";
import QualityDocument from "./components/User/QualityDocument";
import EmployeeDocument from "./components/User/EmployeeDocument";
import HSEDocument from "./components/User/HSEDocument";
import HSEPracticesDocument from "./components/User/HSEPracticesDocument";
import HSEProceduresDocument from "./components/User/HSEProceduresDocument";
import HSEFallProtectionDocument from "./components/User/HSEFallProtectionDocument";
import HSEGasMonitorDocument from "./components/User/HSEGasMonitorDocument";
import DocumentView from "./components/User/DocumentView";
import Sign from "./components/User/Sign";
import ToolBoxSign from "./components/User/ToolBoxSign";
import ToolBoxSignAll from "./components/User/ToolBoxSignAll";
import Crew from "./components/User/Crew";
import Fleet from "./components/User/Fleet";
import VehicleInspection from "./components/User/VehicleInspection";
import VehicleInspectionAll from "./components/User/VehicleInspectionAll";
import VehicleDailyInspectionView from "./components/User/VehicleDailyInspectionView";
import VehicleWeeklyInspectionView from "./components/User/VehicleWeeklyInspectionView";

import AWPInspection from "./components/User/AWPInspection";
import AWPInspectionAll from "./components/User/AWPInspectionAll";
import AWPInspectionView from "./components/User/AWPInspectionView";

import ForkliftInspection from "./components/User/ForkliftInspection";
import ForkliftInspectionAll from "./components/User/ForkliftInspectionAll";
import ForkliftInspectionView from "./components/User/ForkliftInspectionView";

import HarnessInspection from "./components/User/HarnessInspection";
import HarnessInspectionAll from "./components/User/HarnessInspectionAll";

import CraneInspection from "./components/User/CraneInspection";
import CraneInspectionAll from "./components/User/CraneInspectionAll";
import CraneInspectionView from "./components/User/CraneInspectionView";

import ScaffoldInspection from "./components/User/ScaffoldInspection";
import ScaffoldInspectionAll from "./components/User/ScaffoldInspectionAll";

import SwagerInspection from "./components/User/SwagerInspection";
import SwagerInspectionAll from "./components/User/SwagerInspectionAll";
import SwagerInspectionView from "./components/User/SwagerInspectionView";

import TestbedInspection from "./components/User/TestbedInspection";
import TestbedInspectionAll from "./components/User/TestbedInspectionAll";
import TestbedInspectionView from "./components/User/TestBedInspectionView";

import ToolboxGuard from "./components/guards/ToolboxGuard";
import AuthGuard from "./components/guards/AuthGuard";
import AdminGuard from "./components/guards/AdminGuard";
import VehicleGuard from "./components/guards/VehicleGuard";
import AWPGuard from "./components/guards/AWPGuard";
import ForkliftGuard from "./components/guards/ForkliftGuard";
import CraneGuard from "./components/guards/CraneGuard";
import HarnessGuard from "./components/guards/HarnessGuard";
import ScaffoldGuard from "./components/guards/ScaffoldGuard";
import InventoryGuard from "./components/guards/InventoryGuard";
import SwagerGuard from "./components/guards/SwagerGuard";
import TestbedGuard from "./components/guards/TestbedGuard";
import IncidentGuard from "./components/guards/IncidentGuard";

import Quality from "./components/User/Quality";
import UOM from "./components/User/UOM";
import Store from "./components/User/Store";
import Supplier from "./components/User/Supplier";
import ItemCategory from "./components/User/ItemCategory";
import Item from "./components/User/Item";
import ItemMove from "./components/User/ItemMove";
import ItemMoveAll from "./components/User/ItemMoveAll";
import ItemMoveView from "./components/User/ItemMoveView";

import packageJson from "../package.json";
import moment from "moment";

import Report from "./components/User/Report";
import IncidentEvent from "./components/User/IncidentEvent";
import IncidentCause from "./components/User/IncidentCause";
import IncidentContact from "./components/User/IncidentContact";
import IncidentReport from "./components/User/IncidentReport";
import IncidentReportAll from "./components/User/IncidentReportAll";
import IncidentReportView from "./components/User/IncidentReportView";



const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function App() {
  const classes = useStyles();
  const [theming, setTheme] = useState(theme);
  const [showReload, setShowReload] = useState(false);

  const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;

        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          setShowReload(true);

          setIsLatestBuildDate(false);
          // refreshCacheAndReload();
        } else {
          setIsLatestBuildDate(true);
          setShowReload(false);
        }
      });
  }, []);

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload();
  };

  const toggleDarkTheme = () => {
    let newPaletteType = theming.palette.type === "light" ? "dark" : "light";
    setTheme({
      palette: {
        type: newPaletteType,
      },
      typography: {
        fontFamily: "Quicksand",
      },
    });
  };

  const handleClose = () => {
    setShowReload(false);
  };

  const muiTheme = createTheme(theming);

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />

        <Router>
          <Switch>
            <Route exact path="/" component={LoginComponent} />
            <Route path="/setPassword/:passwordToken" component={SetPasswordComponent} />
            <Layout toggleDarkTheme={toggleDarkTheme}>
              <AuthGuard path="/dashboard">
                <Dashboard />
              </AuthGuard>

              <AuthGuard path="/document-view/:id">
                <DocumentView />
              </AuthGuard>
              <AuthGuard path="/document/toolbox">
                <ToolBoxDocument />
              </AuthGuard>
              <AuthGuard path="/document/safety">
                <SafetyDocument />
              </AuthGuard>
              <AuthGuard path="/document/quality">
                <QualityDocument />
              </AuthGuard>
              <AuthGuard path="/document/employee">
                <EmployeeDocument />
              </AuthGuard>
              <AuthGuard path="/document/hse">
                <HSEDocument />
              </AuthGuard>
              <AuthGuard path="/document/hse/practices">
                <HSEPracticesDocument />
              </AuthGuard>
              <AuthGuard path="/document/hse/procedures">
                <HSEProceduresDocument />
              </AuthGuard>
              <AuthGuard path="/document/hse/fall-cert">
                <HSEFallProtectionDocument />
              </AuthGuard>
              <AuthGuard path="/document/hse/gas-cert">
                <HSEGasMonitorDocument />
              </AuthGuard>
              <AuthGuard exact path="/document">
                <Document />
              </AuthGuard>
              <AdminGuard path="/users">
                <Users />
              </AdminGuard>
              <AdminGuard path="/roles">
                <Roles />
              </AdminGuard>
              <AdminGuard path="/locations">
                <Locations />
              </AdminGuard>
              <AuthGuard path="/account">
                <Account />
              </AuthGuard>

              <AuthGuard exact path="/sign">
                <Sign />
              </AuthGuard>
              <ToolboxGuard path="/toolbox/crew">
                <Crew />
              </ToolboxGuard>
              <AuthGuard exact path="/toolbox/sign">
                <ToolBoxSign />
              </AuthGuard>
              <ToolboxGuard exact path="/toolbox/sign/all">
                <ToolBoxSignAll />
              </ToolboxGuard>

              <VehicleGuard path="/vehicle/fleet">
                <Fleet />
              </VehicleGuard>
              <AuthGuard exact path="/vehicle/inspection">
                <VehicleInspection />
              </AuthGuard>
              <AuthGuard exact path="/vehicle/inspection/daily/:id">
                <VehicleDailyInspectionView />
              </AuthGuard>
              <AuthGuard exact path="/vehicle/inspection/weekly/:id">
                <VehicleWeeklyInspectionView />
              </AuthGuard>
              <VehicleGuard exact path="/vehicle/inspection/all">
                <VehicleInspectionAll/>
              </VehicleGuard>

              <AuthGuard exact path="/awp/inspection">
                <AWPInspection />
              </AuthGuard>
              <AuthGuard exact path="/awp/inspection/view/:id">
                <AWPInspectionView />
              </AuthGuard>
              <AWPGuard exact path="/awp/inspection/all">
                <AWPInspectionAll/>
              </AWPGuard>

              <AuthGuard exact path="/forklift/inspection">
                <ForkliftInspection />
              </AuthGuard>
              <AuthGuard exact path="/forklift/inspection/view/:id">
                <ForkliftInspectionView />
              </AuthGuard>
              <ForkliftGuard exact path="/forklift/inspection/all">
                <ForkliftInspectionAll/>
              </ForkliftGuard>

              <AuthGuard exact path="/harness/inspection">
                <HarnessInspection />
              </AuthGuard>
              <HarnessGuard exact path="/harness/inspection/all">
                <HarnessInspectionAll/>
              </HarnessGuard>

              <AuthGuard exact path="/crane/inspection">
                <CraneInspection />
              </AuthGuard>
              <AuthGuard exact path="/crane/inspection/view/:id">
                <CraneInspectionView />
              </AuthGuard>
              <CraneGuard exact path="/crane/inspection/all">
                <CraneInspectionAll/>
              </CraneGuard>

              <AuthGuard exact path="/scaffold/inspection">
                <ScaffoldInspection />
              </AuthGuard>
              <ScaffoldGuard exact path="/scaffold/inspection/all">
                <ScaffoldInspectionAll/>
              </ScaffoldGuard>

              <AuthGuard exact path="/swager/inspection">
                <SwagerInspection />
              </AuthGuard>
              <AuthGuard exact path="/swager/inspection/view/:id">
                <SwagerInspectionView />
              </AuthGuard>
              <SwagerGuard exact path="/swager/inspection/all">
                <SwagerInspectionAll/>
              </SwagerGuard>

              <AuthGuard exact path="/testbed/inspection">
                <TestbedInspection />
              </AuthGuard>
              <AuthGuard exact path="/testbed/inspection/view/:id">
                <TestbedInspectionView />
              </AuthGuard>
              <TestbedGuard exact path="/testbed/inspection/all">
                <TestbedInspectionAll/>
              </TestbedGuard>

              <AuthGuard exact path="/quality">
                <Quality />
              </AuthGuard>
              <InventoryGuard exact path="/inventory/uom">
                <UOM />
              </InventoryGuard>

              <InventoryGuard exact path="/inventory/store">
                <Store />
              </InventoryGuard>
              <InventoryGuard exact path="/inventory/supplier">
                <Supplier />
              </InventoryGuard>
              <InventoryGuard exact path="/inventory/category">
                <ItemCategory />
              </InventoryGuard>
              <InventoryGuard exact path="/inventory/item">
                <Item />
              </InventoryGuard>
              <AuthGuard exact path="/inventory/move">
                <ItemMove />
              </AuthGuard>
              <AuthGuard exact path="/inventory/move/view/:id">
                <ItemMoveView />
              </AuthGuard>
              <InventoryGuard exact path="/inventory/move/all">
                <ItemMoveAll />
              </InventoryGuard>

              <AuthGuard exact path="/report">
                <Report />
              </AuthGuard>
              <IncidentGuard exact path="/incident/event">
                <IncidentEvent />
              </IncidentGuard>
              <IncidentGuard exact path="/incident/cause">
                <IncidentCause />
              </IncidentGuard>
              <IncidentGuard exact path="/incident/contact">
                <IncidentContact />
              </IncidentGuard>
              <AuthGuard exact path="/incident/report">
                <IncidentReport />
              </AuthGuard>
              <IncidentGuard exact path="/incident/report/all">
                <IncidentReportAll/>
              </IncidentGuard>
              <AuthGuard exact path="/incident/report/view/:id">
                <IncidentReportView />
              </AuthGuard>

            </Layout>
          </Switch>
        </Router>
        <Snackbar
          open={showReload}
          onClose={handleClose}
          message="A new version of DFBarnes App is available!, click RELOAD to update"
          onClick={refreshCacheAndReload}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={refreshCacheAndReload}
            >
              Reload
            </Button>
          }
        />
      </MuiThemeProvider>
      <Backdrop
        className={classes.backdrop}
        open={showReload ? showReload : false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Provider>
  );
}

export default App;
