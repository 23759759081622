module.exports = {

    UPLOAD_TOOLBOX_DOCUMENT: "UPLOAD_TOOLBOX_DOCUMENT",
    UPLOAD_TOOLBOX_DOCUMENT_REQUEST: "UPLOAD_TOOLBOX_DOCUMENT_REQUEST",
    UPLOAD_TOOLBOX_DOCUMENT_FAIL: "UPLOAD_TOOLBOX_DOCUMENT_FAIL",

    UPLOAD_SAFETY_DOCUMENT: "UPLOAD_SAFETY_DOCUMENT",
    UPLOAD_SAFETY_DOCUMENT_REQUEST: "UPLOAD_SAFETY_DOCUMENT_REQUEST",
    UPLOAD_SAFETY_DOCUMENT_FAIL: "UPLOAD_SAFETY_DOCUMENT_FAIL",

    UPLOAD_QUALITY_DOCUMENT: "UPLOAD_QUALITY_DOCUMENT",
    UPLOAD_QUALITY_DOCUMENT_REQUEST: "UPLOAD_QUALITY_DOCUMENT_REQUEST",
    UPLOAD_QUALITY_DOCUMENT_FAIL: "UPLOAD_QUALITY_DOCUMENT_FAIL",

    UPLOAD_EMPLOYEE_DOCUMENT: "UPLOAD_EMPLOYEE_DOCUMENT",
    UPLOAD_EMPLOYEE_DOCUMENT_REQUEST: "UPLOAD_EMPLOYEE_DOCUMENT_REQUEST",
    UPLOAD_EMPLOYEE_DOCUMENT_FAIL: "UPLOAD_EMPLOYEE_DOCUMENT_FAIL",

    UPLOAD_HSE_DOCUMENT: "UPLOAD_HSE_DOCUMENT",
    UPLOAD_HSE_DOCUMENT_REQUEST: "UPLOAD_HSE_DOCUMENT_REQUEST",
    UPLOAD_HSE_DOCUMENT_FAIL: "UPLOAD_HSE_DOCUMENT_FAIL",

    GET_DOCUMENTS: "GET_DOCUMENTS",
    GET_DOCUMENTS_REQUEST: "GET_DOCUMENTS_REQUEST",
    GET_DOCUMENTS_FAIL: "GET_DOCUMENTS_FAIL",

    GET_DOCUMENTS_BY_TYPE: "GET_DOCUMENTS_BY_TYPE",
    GET_DOCUMENTS_BY_TYPE_REQUEST: "GET_DOCUMENTS_BY_TYPE_REQUEST",
    GET_DOCUMENTS_BY_TYPE_FAIL: "GET_DOCUMENTS_BY_TYPE_FAIL",

    GET_DOCUMENTS_BY_SUBFOLDER: "GET_DOCUMENTS_BY_SUBFOLDER",
    GET_DOCUMENTS_BY_SUBFOLDER_REQUEST: "GET_DOCUMENTS_BY_SUBFOLDER_REQUEST",
    GET_DOCUMENTS_BY_SUBFOLDER_FAIL: "GET_DOCUMENTS_BY_SUBFOLDER_FAIL",


    GET_DOCUMENTS_BY_LOCATION: "GET_DOCUMENTS_BY_LOCATION",
    GET_DOCUMENTS_BY_LOCATION_REQUEST: "GET_DOCUMENTS_BY_LOCATION_REQUEST",
    GET_DOCUMENTS_BY_LOCATION_FAIL: "GET_DOCUMENTS_BY_LOCATION_FAIL",

    GET_DOCUMENT: "GET_DOCUMENT",
    GET_DOCUMENT_REQUEST: "GET_DOCUMENT_REQUEST",
    GET_DOCUMENT_FAIL: "GET_DOCUMENT_FAIL",

    DELETE_DOCUMENT: "DELETE_DOCUMENT",
    DELETE_DOCUMENT_REQUEST: "DELETE_DOCUMENT_REQUEST",
    DELETE_DOCUMENT_FAIL: "DELETE_DOCUMENT_FAIL",

}