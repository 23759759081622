import axios from "axios";
import {
    CREATE_HARNESS,
    CREATE_HARNESS_REQUEST,
    CREATE_HARNESS_FAIL,
    SUBMIT_HARNESS,
    SUBMIT_HARNESS_REQUEST,
    SUBMIT_HARNESS_FAIL,
    UPDATE_HARNESS,
    UPDATE_HARNESS_REQUEST,
    UPDATE_HARNESS_FAIL,
    SET_HARNESS_DRAFT,
    SET_HARNESS_DRAFT_REQUEST,
    SET_HARNESS_DRAFT_FAIL,
    GET_HARNESS,
    GET_HARNESS_REQUEST,
    GET_HARNESS_FAIL,
    GET_HARNESSES,
    GET_HARNESSES_REQUEST,
    GET_HARNESSES_FAIL,
    GET_HARNESS_BY_USER,
    GET_HARNESS_BY_USER_REQUEST,
    GET_HARNESS_BY_USER_FAIL,
    GET_HARNESS_BY_LOCATION,
    GET_HARNESS_BY_LOCATION_REQUEST,
    GET_HARNESS_BY_LOCATION_FAIL,
    DELETE_HARNESS,
    DELETE_HARNESS_REQUEST,
    DELETE_HARNESS_FAIL,

} from "../types/harnessTypes"

const token = localStorage.getItem("token:data");
export const createInspection = (harness) => async (dispatch) => {
    
    try {
        dispatch({
            type: CREATE_HARNESS_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/harness/inspection`, harness, config);
        dispatch({
            type: CREATE_HARNESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_HARNESS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const submitInspection = (harness) => async (dispatch) => {
    
    try {
        dispatch({
            type: SUBMIT_HARNESS_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.post(`/api/v1/harness/inspection/submit`, harness, config);
        dispatch({
            type: SUBMIT_HARNESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUBMIT_HARNESS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const updateInspection = (harness) => async (dispatch) => {
    
    try {
        dispatch({
            type: UPDATE_HARNESS_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/harness/inspection/${harness.id}`, harness, config);
        dispatch({
            type: UPDATE_HARNESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_HARNESS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const setInspectionDraft = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: SET_HARNESS_DRAFT_REQUEST
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`/api/v1/harness/inspection/draft/${id}`, null, config);
        dispatch({
            type: SET_HARNESS_DRAFT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SET_HARNESS_DRAFT_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request 
                ? error.request.data.error
                : error.message,
        });
    }
}

export const getInspections = () => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_HARNESSES_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/harness/inspections`, config);
        dispatch({
            type: GET_HARNESSES,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_HARNESSES_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
                });
    }
}

export const getInspection = (id) => async (dispatch) => {
    
    try {
        dispatch({
            type: GET_HARNESS_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`/api/v1/harness/inspection/${id}`, config);
        dispatch({
            type: GET_HARNESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_HARNESS_FAIL,
            payload:
                error.response && error.response.data?.error
                ? error.response.data?.error
                : error.request
                ? error.request.data?.error
                : error.message,
        });
    }
}

export const getInspectionsByUser = () => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_HARNESS_BY_USER_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/harness/myinspections/user`, config);
        dispatch({
            type: GET_HARNESS_BY_USER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_HARNESS_BY_USER_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}


export const getInspectionsByLocation = (id) => async (dispatch) => {
   
    try {
        dispatch({
            type: GET_HARNESS_BY_LOCATION_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        let { data } = await axios.get(`/api/v1/harness/inspections/location/${id}`, config);
        dispatch({
            type: GET_HARNESS_BY_LOCATION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_HARNESS_BY_LOCATION_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

export const deleteInspection = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_HARNESS_REQUEST,
        });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.delete(`/api/v1/harness/inspection/${id}`, config);
        dispatch({
            type: DELETE_HARNESS,
        });
    } catch (error) {
        dispatch({
            type: DELETE_HARNESS_FAIL,
            payload:
                error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
}

