import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const ForkliftGuard = ({ children, ...rest }) => {
  const token = localStorage.getItem("token:data");

  const { profile } = useSelector((state) => state.users);

  let isForkliftAdminGuard = profile
    ? (profile.role && 
        ((profile.role.map((role) => role.name).includes("admin")) || (profile.role.map((role) => role.name).includes("forklift_admin")))
      )
    : false;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token && isForkliftAdminGuard ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
};

export default ForkliftGuard;
