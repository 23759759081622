import React, { useEffect, useState } from "react";
import {
  makeStyles,
  IconButton,
  Button,
  Grid,
  Typography,
  TextField,
  Avatar,
  Tab,
  Box,
  Card,
  CardContent,
  Badge,
  InputAdornment,
  Switch,
  Divider,
  List,
} from "@material-ui/core";
import {TabContext, TabList, TabPanel, ToggleButton} from '@material-ui/lab';
import UpdateIcon from "@material-ui/icons/Update";
import UploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Feedback from "../../utils/Feedback";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import {getProfile, uploadPhoto, deletePhoto, updateUserPassword, updateProfile, logout} from "../../actions/usersAction";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  btnLink: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    color: theme.palette.primary.main,
    width: 300,
    
  },
  card: {
    padding: theme.spacing(6),
    marginTop: 20,
    marginBottom: 20,
    width: "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: theme.spacing(2),
    }
  },
  card2: {
    padding: theme.spacing(5),
    marginTop: 20,
    marginBottom: 20,
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: theme.spacing(3),
    }

  },

  avatar: {
    width: 100,
    height: 100,
    margin: "auto",
    marginBottom: 20,
    opacity: 0.9,
    display: "flex",
    backfaceVisibility: "hidden",
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",

    // }
  },

  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },

  delete: {
    color: theme.palette.error.main,
    marginTop: 85,

  },
  
  upload: {
    color: theme.palette.primary.main,
    marginTop: 80,
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    }

  },
  red: {
    color: "#ef5350",
    fontWeight: 500,
  },
  green: {
    color: "#4caf50",
    fontWeight: 500,
  }, 


}));

const INIT_VALUES = {
  firstname: "",
  lastname: "",
  title: "",
  phone: "",
  oldPassword: "",
  newPassword: "",
  showOldPassword: false,
  showNewPassword: false,
  photo: "",
  twofactor: false,
};

const Account = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [values, setValues] = useState(INIT_VALUES);

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { profile, error, loading, user } = useSelector((state) => state.users)
  const history = useHistory();

  const [value, setValue] = useState('1');
  const [file, setFile] = React.useState(undefined)

  useEffect(() => {
    dispatch(getProfile());

    }, [dispatch]);

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      firstname: profile && profile.firstname,
      lastname: profile && profile.lastname,
      title: profile && profile.title,
      phone: profile && profile.phone,
      photo: profile && profile.photo,
      twofactor: profile && profile.twofactor,
    }));
  }, [profile]);

 useEffect(() => {
    const abortController = new AbortController();
    
    return () => {
      abortController.abort();
    };
  }, [history]);

 useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);


  const handleChange = (e) => {
    e.persist();
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleToggle = () => {
    setValues({ ...values, twofactor: !values.twofactor });
  };


  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    let data = {
      firstname: values.firstname,
      lastname: values.lastname,
      title: values.title,
      phone: values.phone,
    };
    dispatch(updateProfile(data));
    setFeed((prev) => ({
      loading: false,
      open: true,
      message: "Profile Updated Successfully.",
      success: true,
    }));
    setTimeout(() => {
      dispatch(getProfile())
    }, 1500);
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    let data = {
      currentPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    dispatch(updateUserPassword(data)).then((res) => {
      if (user && user.token){
        setFeed(() => ({
          loading: false,
          open: true,
          message: "Password Updated Successfully.",
          success: true,
        }));
        dispatch(logout())
        history.push("/")
      }

    })
}


  const handleClickShowOldPassword = () => {
    setValues((prev) => ({
      ...prev,
      showOldPassword: !prev.showOldPassword,
    }));
  };
  const handleClickShowNewPassword = () => {
    setValues((prev) => ({
      ...prev,
        showNewPassword: !prev.showNewPassword,
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !values.firstname || !values.lastname || !values.title || !values.phone;

  const strongCheck = () => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_])(?=.{8,})",
    );
    return strongRegex.test(values.newPassword);
  }

  const numberCheck = () => {
    const numberRegex = new RegExp("^(?=.*[0-9])")
    return numberRegex.test(values.newPassword);
  }

  const uppercaseCheck = () => {
    const uppercaseRegex = new RegExp("^(?=.*[A-Z])")
    return uppercaseRegex.test(values.newPassword);
  }

  const lowercaseCheck = () => {
    const lowercaseRegex = new RegExp("^(?=.*[a-z])")
    return lowercaseRegex.test(values.newPassword);
  }

  const specialCheck = () => {
    const specialRegex = new RegExp("^(?=.*[!@#$%^&*()_])")
    return specialRegex.test(values.newPassword);
  }

  const totalCharactersCheck = () => {
    const totalRegex = new RegExp("^(?=.{8,})")
    return totalRegex.test(values.newPassword);
  }


React.useEffect(() => {
  const upload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("img", file)

      dispatch(uploadPhoto(formData));
      setTimeout(() => {
        dispatch(getProfile())
      }, 1500);
    }
  }
  upload();
}, [file, dispatch]);

const handleDeletePhoto = (e) => {
    e.preventDefault();
    dispatch(deletePhoto());
    setTimeout(() => {  
      dispatch(getProfile())
    }, 1500);
}

const handleUploadPhoto = (e) => {
  e.preventDefault();
  setFile(e.target.files[0]);
};


  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}

      <Box sx={{ width: '100%', typography: 'body1' }}>
      
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} textColor="secondary" indicatorColor="secondary">
            <Tab label="Profile" className={classes.tab} value="1"/>
            <Tab label="Security"  className={classes.tab} value="2"/>
          </TabList>
        </Box>
        <TabPanel value="1">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Card className={classes.card2}>
              <Typography align="center">
              <label htmlFor="photo" className={classes.upload}>
                  <Badge 
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={<UploadIcon/>}
                  >
                     <Avatar src={profile && profile.photo} className={classes.avatar}/>
                  </Badge>

              </label>
            
             <IconButton onClick={handleDeletePhoto} className={classes.delete}>
                <DeleteIcon/>
              </IconButton>


              </Typography>
              

              <input 
                type="file"
                id="photo"
                name="photo"
                accept="image/*"
                onChange={handleUploadPhoto}
                hidden
              />
              <CardContent>
                <Typography variant="h6" component="div" align="center">
                  {profile && profile.displayname}
                </Typography>
                <Typography variant="body2" align="center">
                  {profile && profile.title}
                </Typography>
              </CardContent>

            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Card className={classes.card}>
            <form onSubmit={handleUpdateProfile}>
                <Grid container alignContent="center" spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="firstname"
                      fullWidth
                      variant="outlined"
                      label="First Name"
                      onChange={handleChange}
                      value={values.firstname || ""}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="lastname"
                      fullWidth
                      variant="outlined"
                      label="Last Name"
                      onChange={handleChange}
                      value={values.lastname || ""}
                      required
                    />
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="title"
                      fullWidth
                      variant="outlined"
                      label="Title"
                      onChange={handleChange}
                      value={values.title || ""}
                      required
                    />
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="phone"
                      fullWidth
                      variant="outlined"
                      label="Phone"
                      onChange={handleChange}
                      value={values.phone || ""}
                    />
                  </Grid>
                </Grid>
                <Typography align='center'>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    type="submit"
                    endIcon={<UpdateIcon />}
                    className={classes.btn}
                    disabled={isValid() || loading}
                  >
                    {loading ? "Loading..." : "Update"}
                  </Button>
                </Typography> 
              </form>

            </Card>
          </Grid>
        </Grid>
        </TabPanel>
        <TabPanel value="2">
        <Card className={classes.card}>
        <form onSubmit={handleChangePassword}>
            <Typography variant="body1">
                Change Password
            </Typography>
            <Grid container alignItems="center" spacing={3}>
              
              <Grid item xs={12} md={12}>
              <TextField
                  variant="outlined"
                  fullWidth
                  name="oldPassword"
                  label="Current Password"
                  placeholder="Current Password"
                  type={values.showOldPassword ? "text" : "oldPassword"}
                  value={values.oldPassword}
                  className={classes.textField}
                  onChange={handleChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowOldPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}       
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  placeholder="New Password"
                  type={values.showNewPassword ? "text" : "newPassword"}
                  value={values.newPassword}
                  className={classes.textField}
                  onChange={handleChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  // helperText={
                  //   <span className={classes.helperText}>
                  //       Password needs to have at least 8 characters, including atleast:<br/>
                  //        1 uppercase<br/>
                  //        1 lowercase<br/>
                  //        1 number<br/>
                  //        1 special character
                  //   </span>
                  // }

                    // Check if password is strong enough
                    error={!strongCheck() }             
                />
              </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={0.5}>
                <Grid item xs={12} md={12} >
                  <Typography className={!totalCharactersCheck() ? classes.red: classes.green} variant="caption">
                    Password needs to have at least 8 characters, including
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography className={!uppercaseCheck() ? classes.red: classes.green} variant="caption">
                    1 Uppercase (A-Z)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography className={!lowercaseCheck() ? classes.red: classes.green} variant="caption">
                    1 Lowercase (a-z)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography className={!numberCheck() ? classes.red: classes.green} variant="caption">
                      1 Number (0-9)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography className={!specialCheck() ? classes.red: classes.green} variant="caption">
                      1 Special Character (!@#$%^&*()_)
                  </Typography>
                </Grid>
              </Grid>

             
            
            <Typography align='center'>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                endIcon={<UpdateIcon />}
                className={classes.btn}
                disabled={!strongCheck() || loading}
              >
                {loading ? "Loading..." : "Change Password"}
              </Button>
            </Typography>
          </form>
        </Card>
        <Card className={classes.card}>
        <form>
            <Typography variant="body1">
                Two-Factor Authentication
            </Typography>
            <ToggleButton>
              <Typography variant="body2">
                {values.twofactor ? "Disable Two-Factor Authentication" : "Enable Two-Factor Authentication"}
              </Typography>
              <Switch
                checked={values.twofactor}
                onChange={handleToggle}
                name="twoFactor"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                value={values.twofactor || false}
              />
              
            </ToggleButton>
            <Typography variant="body2">
              Two-Factor Authentication ("2FA") is a system of double authentication. The first one is done with your password and the second one with a code you get from a dedicated mobile app. Popular ones include Authy, Google Authenticator or the Microsoft Authenticator.
            </Typography>
        </form>
        </Card>
        </TabPanel>            
      </TabContext>
    </Box>

    </div>
  );
};

export default Account;
